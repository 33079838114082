import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useEffect, useMemo, useState } from "react";
import {
  TOverViewResult,
  TOverViewTransferResult,
  TQuarterlyInfo,
  TOverviewYears
} from "../../../server/controllers/transfer";

import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../index";
import { getOverview } from "../services/data-service";
import { transferTypes } from "../models/models";
import { useTranslation } from "react-i18next";
import DataTable, { TableStyles } from "react-data-table-component-with-filter";
import { getColor, getColorFromPalette, getPFColor } from "../colors";
import {
  Help,
  Info,
  ModalLoader,
  Render,
  ShowError,
} from "../components/helper-components";

import ReactECharts from "echarts-for-react";
import { ExportToExcel } from "../components/excel-exporter";
import { TOverViewFundingResult } from "../../../server/controllers/funding";

import {
  categoriesByTransferType,
  chartAriaDescription,
  fundingTypes,
  range,
  slugifyCategory,
} from "../helpers/helpers";
import { SwitchButton } from "../components/switch-button/switchButton";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useMediaQuery } from "react-responsive";
import Config from "../config/settings";
import { Fold } from "../components/settings-viewer";
import { DownloadIcon } from "../components/icons";
import { useSettingsStore } from "../context/SettingsStore";
import { Modules } from "../models/modules";
import { TInfoState } from "../App";
import React from "react";
import { Hint } from "../components/info";


/* eslint react-hooks/exhaustive-deps: 0 */


const toEChartsModel_ = (data_: TOverViewTransferResult) =>
  data_.years
    .map((e) => {
      console.log(e)
      return e.periods.reduce((acc, q) => ({ ...acc, [`${q.period}-${q.transferType}`]: q.total }),
        { year: e.year })
      //...[1,2,3,4].map(p => [2,4,31].map(tt => ({[`${p}-${tt}`]: 0})))
      //.flat().reduce((acc, c) => ({...acc, ...c}), {})})
    })

const defaultCategoryPerTransferType = {
  2: "unbekannt",
  4: "Förderung",
  31: "ORF-Beitrag gemäß § 31 Abs. 1 ORF-G",
};

const getCategoriesPerTransferType = (data_: TOverViewTransferResult) => {
  const categories = {
    2: new Set<string>(),
    4: new Set<string>(),
    31: new Set<string>(),
  };
  data_.years.forEach((e) => {
    e.periods.forEach((q) => {
      categories[q.transferType].add(!q.category ? defaultCategoryPerTransferType[q.transferType] : q.category);
    });
  });

  return {
    2: Array.from(categories[2]),
    4: Array.from(categories[4]),
    31: Array.from(categories[31]),
  };
}

const toEChartsModel = (data_: TOverViewTransferResult) =>
  data_.years
    .map((e) => e.periods.map((q) => ({
      year: e.year, fullPeriod: `${e.year}/${q.period}`, ...q,
      category: (!q.category || (q.category && q.category.length === 0)) ? defaultCategoryPerTransferType[q.transferType] : q.category
    }))
      .reduce((acc, c) => ({ ...acc, [c.fullPeriod + c.transferType]: { ...acc[c.fullPeriod + c.transferType], [c.category]: c.total, ...c } }), {})

    ).reduce((acc, e) => [...acc, ...Object.values(e)], [] as any)
//.reduce((acc, c) => [...acc, ...c], [])
//.sort((a, b) => ((a.year*10+a.period) - (b.year*10-b.period)));


const toYearEChartsModel = (data_: TOverViewTransferResult) =>
  data_.years
    .map((e) => ({
      year: e.year,
      ...e.periods.reduce(
        (acc, q) => ({
          ...acc,
          [q.transferType]: acc[q.transferType] + q.total,
          prediction: q.prediction || acc.prediction,
        }),
        { 2: 0, 4: 0, 31: 0, prediction: false }
      ),
    }))
    .sort((a, b) => a.year - b.year);

const toFundingYearEChartsModel = (data_: TOverViewFundingResult) =>
  data_
    .map((item) => {
      return {
        ...item.yearsData.reduce(
          (acc, q) => ({
            ...acc,
            [q.name]: q.total,
          }),
          fundingTypes.reduce((acc, fundingType,) => ({ ...acc, [fundingType]: 0 }), { year: item.year })
        ),
      };
    })
    .sort((a, b) => a.year - b.year);

const toTableData = (o: TOverViewTransferResult, byCategory: boolean) =>
  byCategory ? toTableDataByCategory(o) :
    transferTypes.map((tt) => ({
      transferType: tt,
      data: o.years
        .map((e) => ({
          year: e.year,
          ...e.periods
            .filter((q) => q.transferType === tt)
            .reduce((acc, q) => ({ ...acc, [`Q${q.period}`]: q.total }), {}),
        }))
        .sort((a, b) => a.year - b.year),
    }));

const toTableDataByCategory = (o: TOverViewTransferResult) =>
  transferTypes.map((tt) => ({
    transferType: tt,
    data: Object.entries(o.years
      .map(y => y.periods.filter(p => p.transferType === tt).reduce((acc, q) => {
        const halfyear = y.year * 10 + q.period;
        return {
          ...acc,
          [halfyear]: {
            ...acc[halfyear],
            [q.category || (tt === 31 ? "ORF-Beitrag gemäß § 31 Abs. 1 ORF-G" : "Förderung")]: q.total,
          },
        };
      }, {})).reduce((acc, c) => ({ ...acc, ...c }), {}))
      .map(([k, v]) => ({ halfyear: parseInt(k, 10), ...v as any }))

  }));

type TTableModel = ReturnType<typeof toTableData>;
type TEChartsModel = ReturnType<typeof toEChartsModel>;
type TYearEChartsModel = ReturnType<typeof toYearEChartsModel>;
type TFundingYearEChartsModel = ReturnType<typeof toFundingYearEChartsModel>;

/*
const missingPeriods = (data: TOverViewTransferResult) => {
  const minPeriod = Math.min(...data.years[0].periods.map((p) => p.period));
  if (minPeriod > 1) {
    return range(1, minPeriod - 1).map((p) =>
      [2, 4, 31].map(tt => ({
        year: data.years[0].year, period: p, transferType: tt, entries: 0, total: 0
      }))).flat();
  } else {
    return []
  }
}
  */


type TOverviewState = {
  echartsModel?: TEChartsModel;
  tableModel: TTableModel;
  overview: TOverViewResult;
  forecastData: TOverViewTransferResult;
  fundingForecastData: TOverViewTransferResult;
  yearChartModel?: TYearEChartsModel;
  fundingYearChartModel?: TFundingYearEChartsModel;
  categories: ReturnType<typeof getCategoriesPerTransferType>;
  set: string;
  pending: boolean;
  yearsWithForecast: boolean;
  quartersWithForecast: boolean;
  forcastOnLast: number;
  enablePredictions: boolean;
  byCategory: boolean;
};

const sortData = (data) =>
  data
    .sort((a, b) => a.year - b.year)
    .map(({ year, periods }) => ({
      year,
      periods: periods.map((q) => ({ ...q, prediction: false })),
    }));

const getRelevantData = (
  data: TOverviewYears,
  consider: number
) => {
  let relevant = data.slice(data.length - consider - 1);
  const needsPrediction =
    relevant[0].periods.length !==
    relevant[relevant.length - 1].periods.length;
  if (needsPrediction) {
    relevant = relevant.slice(0, relevant.length - 1);
  }
  return relevant;
};

const getFractions = (data: TOverviewYears, useHalfyears: boolean, consider: number = 4) => {
  const relevant = getRelevantData(data, consider);
  const numberOfPeriods = useHalfyears ? 2 : 4;
  const init = {
    2: { ...range(1, numberOfPeriods).reduce((acc, p) => ({ ...acc, [p]: 0 }), {}), total: 0 },
    4: { ...range(1, numberOfPeriods).reduce((acc, p) => ({ ...acc, [p]: 0 }), {}), total: 0 },
  };
  const relevantTransferTypes: number[] = [2, 4];
  const sums = relevant.reduce(
    (acc, { periods: qs }) =>
      qs.reduce(
        (ac, q) =>
          relevantTransferTypes.includes(q.transferType)
            ? {
              ...ac,
              [q.transferType]: {
                ...ac[q.transferType],
                [q.period]: ac[q.transferType][q.period] + q.total,
                total: ac[q.transferType].total + q.total,
              },
            }
            : ac,
        acc
      ),
    init
  );
  return Object.entries(sums).reduce(
    (ac, [k, v]) => ({
      ...ac,
      [k]: {
        ...range(1, numberOfPeriods).reduce(
          (acc, q) => ({ ...acc, [q]: v[q] / v["total"] }),
          {}
        ),
      },
    }),

    {}
  );
};

const addTransferPredictions = (dataSorted, useHalfyears: boolean, consider: number = 4) => {
  if (
    dataSorted[dataSorted.length - 2].periods.length !==
    dataSorted[dataSorted.length - 1].periods.length
  ) {
    const fractions = getFractions(dataSorted, useHalfyears, consider);
    const currentYear = dataSorted[dataSorted.length - 1];
    const typePredictions: TQuarterlyInfo[] = [];
    const availableTransferTypes = new Set<number>(currentYear.periods.map((p) => p.transferType));
    availableTransferTypes.delete(31);
    availableTransferTypes.forEach((tType) => {
      const existing = currentYear.periods.filter(
        ({ transferType: t }) => t === tType
      );
      const runningSums = existing.reduce(
        ({ sum, percentage }, { total, period }) => ({
          sum: sum + total,
          percentage: percentage + tType in fractions ? fractions[tType][period] : 0,
        }),
        { sum: 0, percentage: 0 }
      );
      const onePercent = runningSums.sum / runningSums.percentage;
      const maxPeriod = useHalfyears ? 2 : 4;
      for (let q = existing.length + 1; q <= maxPeriod; q++) {
        typePredictions.push({
          period: q,
          transferType: tType,
          entries: 0,
          total: onePercent * tType in fractions ? fractions[tType][q] : 0,
          prediction: true,
        });
      }
    });
    currentYear.periods = currentYear.periods.concat(typePredictions);
    dataSorted[dataSorted.length - 1] = currentYear;
  }
  return dataSorted;
};

const initialValues = (categories: string[]) =>
  categories.reduce((acc, c) => ({ ...acc, [c]: 0 }), {});

export const overviewSlice = createSlice({
  name: "overview",
  initialState: {
    echartsModel: [],
    tableModel: [],
    overview: {} as any,
    forecastData: {} as any,
    fundingForecastData: [] as any,
    yearChartModel: [],
    fundingYearChartModel: [],
    set: "No",
    pending: true,
    yearsWithForecast: false,
    quartersWithForecast: false,
    forcastOnLast: 4,
    enablePredictions: false,
    categories: { 2: [], 4: [], 31: [] },
    byCategory: true,
  } as TOverviewState,
  reducers: {
    setOverview: (state, action: PayloadAction<TOverViewResult>) => {
      //console.log("Setting State: ")
      //console.log(action.payload)

      const dataWithPredictions = addTransferPredictions(
        sortData(action.payload.transfers.years),
        action.payload.transfers.slots === "halfyears",
        state.forcastOnLast
      );
      return {
        ...state,
        overview: action.payload,
        enablePredictions:
          dataWithPredictions.length > 1 &&
          dataWithPredictions[dataWithPredictions.length - 1].periods.filter(
            (p) => p.prediction
          ).length > 0,
        forecastData: {
          slots: action.payload.transfers.slots,
          years: dataWithPredictions
        },
        tableModel: toTableData(action.payload.transfers, state.byCategory),
        categories: getCategoriesPerTransferType(action.payload.transfers),
        echartsModel: state.byCategory ? toEChartsModel(
          state.quartersWithForecast
            ? dataWithPredictions
            : action.payload.transfers
        ) : toEChartsModel_(
          state.quartersWithForecast
            ? dataWithPredictions
            : action.payload.transfers
        ),
        yearChartModel: toYearEChartsModel(
          state.yearsWithForecast
            ? dataWithPredictions
            : action.payload.transfers
        ),
        fundingYearChartModel: toFundingYearEChartsModel(
          action.payload.fundings
        ),
        pending: false,
        set: "Yes",
      };
    },
    setPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    setByCategory: (state, action: PayloadAction<boolean>) => {
      state.byCategory = action.payload;
    },
    setForecastYears: (state, action: PayloadAction<boolean>) => {
      state.yearsWithForecast = action.payload;
      state.yearChartModel = toYearEChartsModel(
        action.payload ? state.forecastData : state.overview.transfers
      );
    },
    setForecastQuarters: (state, action: PayloadAction<boolean>) => {
      state.quartersWithForecast = action.payload;
      state.echartsModel = state.byCategory ? toEChartsModel(
        action.payload ? state.forecastData : state.overview.transfers
      ) : toEChartsModel_(
        action.payload ? state.forecastData : state.overview.transfers
      );
    },
  },
});

const { setOverview, setPending, setForecastQuarters, setForecastYears, setByCategory } =
  overviewSlice.actions;

export const styles: TableStyles = {
  table: {
    style: {
      background: "inherit",
    },
  },
  rows: {
    style: {
      background: "inherit",
    },
  },
  header: {
    style: {
      display: "inherit",
      background: "inherit",
    },
  },
  headCells: {
    style: {
      fontWeight: "bold",
    },
  },
  headRow: {
    style: {
      background: "inherit",
    },
  },
};

const chartRefs = {
  2: React.createRef<ReactECharts>(),
  4: React.createRef<ReactECharts>(),
  31: React.createRef<ReactECharts>(),
}



export const Overview = () => {
  const {
    tableModel,
    pending,
    echartsModel,
    yearChartModel,
    fundingYearChartModel,
    yearsWithForecast,
    quartersWithForecast,
    enablePredictions,
    categories,
    byCategory
  } = useSelector<AppState, TOverviewState>((state) => state.overview);
  const { useHalfyears } = useSelector<AppState, TInfoState>((state) => state.info);
  const isMobilePortrait = useMediaQuery({ maxWidth: 600 });
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation();
  const settings = useSettingsStore();
  const colorSettings = settings.modules.colors;

  useEffect(() => {
    // if (Object.keys(overview).length > 0) return;
    dispatch(setPending(true));
    getOverview(useHalfyears, byCategory)
      .then((d) => dispatch(setOverview(d)))
      .catch((err) => {
        setError(err?.response?.data ?? err.message);
      })
      .finally(() => {
        dispatch(setPending(false));
      });
  }, [useHalfyears, byCategory]);
  const isMobileLandscape = useMediaQuery({
    maxHeight: 575.98,
    orientation: "landscape",
  });
  const isMobile = isMobileLandscape || isMobilePortrait;

  const defaultOptions = useMemo(
    () => ({
      legend: {
        top: 135,
      },
      textStyle: {
        fontFamily: getComputedStyle(document.documentElement).getPropertyValue(
          "--font-family"
        ),
        color: getComputedStyle(document.documentElement).getPropertyValue(
          "--charts-text-color"
        ),
      },
      grid: { top: 160 },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
        formatter: (params) => {
          params = params instanceof Array ? params : [params];
          const includesForecast =
            params.filter((e) => e.data.prediction).length > 0;
          const fmt = new Intl.NumberFormat(i18n.language, {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
          });
          let d = params
            .map(
              (p) =>
                `${p.marker} ${p.seriesName}${p.data["prediction"] ? "*" : ""
                } <span style="float:right;margin-left:20px;font-size:14px;color:${p.data["prediction"] ? "#aaa" : "#666"
                };font-weight:900">${fmt.format(
                  p.data[p.dimensionNames[p.seriesIndex + 1]] || 0
                )}</span>`
            )
            .join("<br/>");
          if (params.length > 1) {
            if (!includesForecast) {
              const s = params
                .filter((e) => !e.data.prediction)
                .reduce(
                  (acc, p) => acc + (p.data[p.dimensionNames[p.seriesIndex + 1]] || 0),
                  0
                );
              d = `${d}<br/><span style="font-weight:900">${t(
                "Total"
              )}</span><span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${fmt.format(
                s
              )}</span>`;
            } else {
              const s = params.reduce(
                (acc, p) => acc + p.data[p.dimensionNames[p.seriesIndex + 1]],
                0
              );
              d = `${d}<br/>${t(
                "Total"
              )}*<span style="float:right;margin-left:20px;font-size:14px;color:#aaa;font-weight:900">${fmt.format(
                s
              )}</span>`;
              d += `<br>*) ${t("Forecast")}`;
            }
          } else if (includesForecast) {
            d += `<br>*) ${t("Forecast")}`;
          }
          return `${params[0].name}<br/>${d}`;
        },
      },
      title: {
        show: true,
        textStyle: {
          fontSize: isMobile
            ? Config.mobile.charts.title_font_size
            : Config.desktop.charts.title_font_size,
          height: 80,
          fontWeight: isMobile
            ? Config.mobile.charts.title_font_weight
            : Config.desktop.charts.title_font_weight,
          fontFamily: getComputedStyle(
            document.documentElement
          ).getPropertyValue("--font-family"),
          overflow: "truncate",
          color: getComputedStyle(document.documentElement).getPropertyValue(
            "--charts-title-color"
          ),
        },
        subtextStyle: {
          fontSize: 14,
          height: 40,
          fontFamily: getComputedStyle(
            document.documentElement
          ).getPropertyValue("--font-family"),
          overflow: "truncate",
          color: getComputedStyle(document.documentElement).getPropertyValue(
            "--charts-subtitle-color"
          ),
        },
        // padding: [-80,5,5,5],
      },
      toolbox: {
        show: true,
        orient: "horizontal",
        left: "center",
        top: "bottom",
        feature: {
          mark: { show: true },
          dataView: { show: false, readOnly: false },
          magicType: { show: true, type: ["line", "bar", "stack"] },
          restore: { show: false },
          saveAsImage: {
            show: true,
            icon: DownloadIcon,
          },
        },
      },
      xAxis: { type: "category" },
      yAxis: {
        axisLabel: {
          formatter: (value) => `${value / 1000000} Mio.`,
        },
      },
      animation: process.env.NODE_ENV !== "development",
    }),
    [t]
  );

  const toCurrency = (field: string) => (row: any) =>
    row[field]?.toLocaleString(i18n.language, {
      style: "currency",
      currency: "EUR",
    });

  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    const colorsFromCSS = range(3, 6)
      .map(i => getComputedStyle(document.documentElement)
        .getPropertyValue(`--echart-color-${i}`)
        .trim(),).filter(c => c.length > 0)
    setColors(colorsFromCSS);
  }, []);

  const getColorFromIndex = (idx: number, count: number) => {
    return colors.length > 0 ? getColorFromPalette(idx, count, colors, 0.2) : getColor(idx, count); // Return color if available, otherwise empty string
  };

  const categoryColorsByType = useMemo(() => (tt) =>
    categoriesByTransferType[tt].reduce((acc, c) => ({ ...acc, [slugifyCategory(c)]: colorSettings[slugifyCategory(c)] }), {}),
    [categoriesByTransferType, colorSettings]
  );

  useEffect(() =>
    Object.keys(chartRefs).forEach((k) => {
      if (chartRefs[k].current) {
        chartRefs[k].current.getEchartsInstance().clear();
      }
    }), [byCategory, useHalfyears]);

  const options = useMemo(
    () => (tType) => {
      return ({
        ...defaultOptions,
        ...chartAriaDescription(t,
          t("overview_tranfser_type", { transfer_type: t(`§${tType}`) }),
          t(useHalfyears ? "overview_transfer_subtext_halfyears" : "overview_transfer_subtext") +
            (quartersWithForecast ? " (" + t("includes_forecasts") + ")" : "")),
        title: {
          ...defaultOptions.title,
          text: t("overview_tranfser_type", { transfer_type: t(`§${tType}`) }),
          subtext:
            t(useHalfyears ? "overview_transfer_subtext_halfyears" : "overview_transfer_subtext") +
            (quartersWithForecast ? " (" + t("includes_forecasts") + ")" : "") +
            `\n${t("Source")}: KommAustria, ${t(
              "Date"
            )}: ${new Date().toLocaleString()}` +
            `\nLink: ${window.location.href}`,
        },
        dataset: [
          {
            dimensions: byCategory ?
              ["fullPeriod", ...categoriesByTransferType[tType], "period", "transferType"] :
              ["year", ...range(1, useHalfyears ? 2 : 4).map(p => `${p}-${tType}`)],
            source: byCategory ? echartsModel.filter((v) => v.transferType === tType)
              .map((v) => ({ ...initialValues(categories[tType]), ...v }))
              .sort((a, b) => (a.year * 10 + a.period) - (b.year * 10 + b.period)) : echartsModel,
          },
        ],
        series: byCategory ?
          categoriesByTransferType[tType].map((cat, i) => ({
            type: "bar",
            // datasetIndex: i + 1,
            name: t(cat),
            emphasis: { focus: "series" },
            color: categoryColorsByType(tType)[slugifyCategory(cat)], //getColorFromIndex(i, categoriesByTransferType[tType].length),
          }))
          : range(1, useHalfyears ? 2 : 4).map(p => ({
            type: "bar",
            //datasetIndex: p,
            name: `${useHalfyears ? t('Halfyear') + ' ' : 'Q'}${p}`,
            emphasis: { focus: "series" },
            color: getColorFromIndex(p, useHalfyears ? 2 : 4),
          }))
        ,
      })
    },
    [echartsModel, colors, i18n.language, i18n.language, t, quartersWithForecast, byCategory]
  );

  const yearsOptions = useMemo(
    () => ({
      ...defaultOptions,
      ...chartAriaDescription(t,t("Timeline"),t("overview_timeline_subtext") +
      (yearsWithForecast ? " (" + t("includes_forecasts") + ")" : "")),
      title: {
        ...defaultOptions.title,
        text: t("Timeline"),
        subtext:
          t("overview_timeline_subtext") +
          (yearsWithForecast ? " (" + t("includes_forecasts") + ")" : "") +
          `\n${t("Source")}: KommAustria, ${t(
            "Date"
          )}: ${new Date().toLocaleString()}` +
          `\nLink: ${window.location.href}`,
      },

      dataset: [
        {
          dimensions: ["year", "2", "4"],
          source: yearChartModel,
        },
      ],
      series: [
        {
          type: "bar",
          name: t("§2"),
          emphasis: { focus: "series" },
          color: colorSettings.advertising,
        },
        {
          type: "bar",
          name: t("§4"),
          emphasis: { focus: "series" },
          color: colorSettings.forderung,
        },
      ],
    }),
    [yearChartModel, t, colors, i18n.language]
  );

  const fundingYearsOptions = useMemo(
    () => ({
      ...defaultOptions,
      ...chartAriaDescription(t,t("TimelinePF"),t("overview_general_intro_pf")),
      title: {
        ...defaultOptions.title,
        text: t("TimelinePF"),
        subtext:
          t("overview_general_intro_pf") +
          `\n${t("Source")}: KommAustria, ${t(
            "Date"
          )}: ${new Date().toLocaleString()}` +
          `\nLink: ${window.location.href}`,
      },
      dataset: [
        {
          dimensions: ["year", ...fundingTypes],
          source: fundingYearChartModel,
        },
      ],
      series: fundingTypes.map((ft, i) => ({
        type: "bar",
        name: t(ft),
        emphasis: { focus: "series" },
        color: getPFColor(i + 1, fundingTypes.length),
      })),
    }),
    [fundingYearChartModel, t, i18n.language]
  );
  const fundingYearColums = useMemo(
    () => [
      {
        name: t("Year"),
        selector: (row) => row["year"],
        sortable: true,
        width: "5em",
      },
      ...fundingTypes.map((ft) => ({
        name: t(ft),
        selector: (row) => row[ft],
        sortable: true,
        format: toCurrency(ft),
        right: true,
      })),

      {
        name: t("Total"),
        cell: (row: any) => (
          <span>
            {fundingTypes
              .reduce((acc, ft) => acc + row[ft], 0)
              .toLocaleString(i18n.language, {
                style: "currency",
                currency: "EUR",
              })}
          </span>
        ),
        right: true,
      },
    ],
    [i18n.language, t]
  );
  const yearColums = useMemo(
    () => [
      {
        name: t("Year"),
        selector: (row) => row["year"],
        sortable: true,
        width: "5em",
      },
      {
        name: t("§2"),
        selector: (row) => row["2"],
        format: toCurrency("2"),
        right: true,
      },
      {
        name: t("§4"),
        selector: (row) => row["4"],
        format: toCurrency("4"),
        right: true,
      },
      {
        name: t("Total"),
        cell: (row: any) => (
          <span>
            {((row["2"] ?? 0) + (row["4"] ?? 0)).toLocaleString(i18n.language, {
              style: "currency",
              currency: "EUR",
            })}
          </span>
        ),
        right: true,
      },
    ],
    [i18n.language, t]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo(
    () => (tt) => byCategory ? [
      {
        name: t("Halfyear"),
        selector: (row) => row["halfyear"],
        sortable: true,
        width: "12%",
      },
      ...categoriesByTransferType[tt].map((cat) => ({
        name: t(cat),
        selector: (row) => row[cat] || 0,
        format: toCurrency(cat),
        right: true,
      })),
      {
        name: t("Total"),
        cell: (row: any) => (
          <span>
            {categoriesByTransferType[tt]
              .reduce((acc, cat) => acc + (row[cat] || 0), 0)
              .toLocaleString(i18n.language, {
                style: "currency",
                currency: "EUR",
              })}
          </span>
        ),
        right: true,
      },
    ] : [
      {
        name: t("Year"),
        selector: (row) => row["year"],
        sortable: true,
        width: "12%",
      },
      ...(useHalfyears ? [1, 2].map(h => ({
        name: `${t('Halfyear')} ${h}`,
        selector: (row) => row[`Q${h}`],
        format: toCurrency(`Q${h}`),
        right: true,
      })) : [1, 2, 3, 4].map(q => ({
        name: t(`Q${q}`),
        selector: (row) => row[`Q${q}`],
        format: toCurrency(`Q${q}`),
        right: true,
      })))
      ,
      {
        name: "Total",
        cell: (row: any) => (
          <span>
            {(
              (row.Q1 ?? 0) +
              (row.Q2 ?? 0) +
              (row.Q3 ?? 0) +
              (row.Q4 ?? 0)
            ).toLocaleString(i18n.language, {
              style: "currency",
              currency: "EUR",
            })}
          </span>
        ),
        right: true,
      },
    ],
    [i18n.language, t, useHalfyears, byCategory]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ShowError error={error} onClose={() => setError("")} />
      <ModalLoader isPending={pending} />
      <p>
      <span dangerouslySetInnerHTML={{ __html: useHalfyears ? t("overview_halfyear_intro") : t("overview_quarter_intro")}}></span>
      </p>
      { /*[0,1].map(i => (
        <div key={i} style={{ backgroundColor: getColorFromIndex(i, 2), width: "20px", height: "20px" }}></div>
      ))*/}
      <Card data-test-id="timelineChart" className="card-framed">
        <CardContent>
          <Grid
            container
            justifyContent="flex-end"
            data-test-id="timelineForecastToggle"
          >
            <Hint translationKey={`additional_info_overview_year`} />
          </Grid>
          <Render when={enablePredictions && Config.overview.forecastEnabled}>
            <Grid
              container
              justifyContent="flex-end"
              data-test-id="timelineForecastToggle"
            >
              <SwitchButton
                simple={true}
                selValue={yearsWithForecast}
                label={
                  <span>
                    {t("Forecast")}{" "}
                    <Help
                      text={t("info_forecast")}
                      aria-label={t("info_forecast")}
                    />
                  </span>
                }
                onChange={(checked: boolean) => {
                  dispatch(setForecastYears(checked));
                }}
                aria-label={t("Forecast")}
              ></SwitchButton>
            </Grid>
          </Render>

          <ReactECharts
            option={yearsOptions}
            style={{
              height: isMobile
                ? Config.mobile.charts.height
                : Config.desktop.charts.height,
            }}
            opts={{ locale: i18n.language.split("-")[0] }}
            aria-label={t("Forecast") + " chart"}
          />
          <Info
            text={t(
              "You can change the diagram by clicking on the toolbar beneath the chart"
            )}
          />
          <Render when={!isMobilePortrait}>
            <Fold
              id={`yearly-overview-table`}
              header={t("Raw Data")}
              fold={true}
              open={false}
              icon={<></>}
              aria-label={t("Raw Data")}
            >
              <DataTable
                title={
                  t("overview_timeline_subtext") +
                  (yearsWithForecast
                    ? " (" + t("includes_forecasts") + ")"
                    : "") +
                  `, ${t("Source")}: KommAustria, ${t(
                    "Date"
                  )}: ${new Date().toLocaleString()}`
                }
                customStyles={styles}
                columns={yearColums}
                data={yearChartModel ?? []}
                actions={
                  <ExportToExcel
                    columns={yearColums}
                    data={yearChartModel ?? []}
                    fileName={`Yearly-Overview}`}
                  />
                }
              />
            </Fold>
          </Render>
        </CardContent>
      </Card>
      <Render when={settings.isModuleEnabled(Modules.Flows_Fundings)}>
        <Card className="card-framed">
          <CardContent>
            <ReactECharts
              option={fundingYearsOptions}
              style={{
                height: isMobile
                  ? Config.mobile.charts.height
                  : Config.desktop.charts.height,
              }}
              opts={{ locale: i18n.language.split("-")[0] }}
              aria-label={t("TimelinePF") + " chart"}
            />
            <Info
              text={t(
                "You can change the diagram by clicking on the toolbar beneath the chart"
              )}
            />
            <Render when={!isMobilePortrait}>
              <Fold
                id={`yearly-funding-overview-table`}
                header={t("Raw Data")}
                fold={true}
                open={false}
                icon={<></>}
                aria-label={t("Raw Data")}
              >
                <DataTable
                  title={t("TimelinePF")}
                  customStyles={styles}
                  columns={fundingYearColums as any}
                  data={fundingYearChartModel ?? []}
                  actions={
                    <ExportToExcel
                      columns={fundingYearColums}
                      data={fundingYearChartModel ?? []}
                      fileName={`Yearly-Overview}`}
                    />
                  }
                />


              </Fold>
            </Render>
          </CardContent>
        </Card>
      </Render>
      <Grid
        container
        justifyContent="flex-end"
        data-test-id="timelineForecastToggle"
      >
        <SwitchButton
          simple={true}
          key={`byCategory-switch`}
          selValue={byCategory}
          label={
            <span>
              {t("By Category")}{ " "}
              <Help
                text={t("info_by_category")}
              />
            </span>
          }
          onChange={(checked: boolean) => {
            dispatch(setByCategory(checked));
          }}

        ></SwitchButton>
      </Grid>
      {transferTypes.map((tt) => (
        <div key={tt} className={`payment-type-${tt}`}>
        <Card
          key={tt}
          data-test-id={`paymentChart-${tt}`}
          className="card-framed"
        >
          <CardContent>

            <Grid
              container
              justifyContent="flex-end"
              data-test-id="timelineForecastToggle"
            >
              <Hint translationKey={`additional_info_${tt}`} />
              <Render when={enablePredictions && Config.overview.forecastEnabled}>
                <SwitchButton
                  simple={true}
                  selValue={quartersWithForecast}
                  label={
                    <span>
                      {t("Forecast")}{" "}
                      <Help
                        text={t("info_forecast")}
                        aria-label={t("info_forecast")}
                      />
                    </span>
                  }
                  onChange={(checked: boolean) => {
                    dispatch(setForecastQuarters(checked));
                  }}
                ></SwitchButton>
              </Render>
            </Grid>


            <ReactECharts
              option={options(tt)}
              key={tt}
              ref={
                chartRefs[tt]
              }
              style={{
                height: isMobile
                  ? Config.mobile.charts.height
                  : Config.desktop.charts.height,
              }}
              opts={{ locale: i18n.language.split("-")[0] }}
            />
            <Info
              text={t(
                "You can change the diagram by clicking on the toolbar beneath the chart"
              )}
            />

            <Render when={!isMobilePortrait}>
              <Fold
                id={`paymentTable-${tt}`}
                header={t("Raw Data")}
                fold={true}
                open={false}
                icon={<></>}
              >
                <DataTable
                  title={
                    t(useHalfyears ? "overview_transfer_subtext_halfyears" : "overview_transfer_subtext") +
                    ` ${t(`§${tt}`)} ` +
                    (quartersWithForecast
                      ? " (" + t("includes_forecasts") + ")"
                      : "") +
                    `, ${t("Source")}: KommAustria, ${t(
                      "Date"
                    )}: ${new Date().toLocaleString()}`
                  }
                  customStyles={styles}
                  columns={columns(tt)}
                  data={
                    tableModel.find((e) => e.transferType === tt)?.data || []
                  }
                  actions={
                    <ExportToExcel
                      columns={columns(tt)}
                      data={
                        tableModel.find((e) => e.transferType === tt)?.data || []
                      }
                      fileName={`Spendings_${tt}`}
                    />
                  }
                />
              </Fold>
            </Render>
          </CardContent>
        </Card>
        </div>
      ))}
    </>
  );
};
