import { Chip, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Render } from "./helper-components";
import MenuItem from "@mui/material/MenuItem";
import Config from "../config/settings";

export type SujetViewModeSelectorProps = {
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  id: string;
  value: any;
  options: string[];
};

export const SujetViewModeSelector = ({
  id,
  value,
  options,
  onChange,
}: SujetViewModeSelectorProps) => {
  const { t } = useTranslation();

  return (
    <Render when={options.length > 0}>
      <label
        aria-label={
          options.length > 1
            ? `${t("View Mode")} ${options.length} ${t("Options available")}`
            : t("View Mode")
        }
      >
        <TextField
          id="select-view-type"
          select
          defaultValue=""
          label={
            options.length > 1
              ? <>
                  {t("View Mode")}{" "}
                  <Chip
                    size="small"
                    label={`${options.length} ${t("Options available")}`}
                    variant="filled"
                  />
              </>
              : t("View Mode")
          }
          aria-label={
            options.length > 1
              ? `${t("View Mode")} ${options.length} ${t("Options available")}`
              : t("View Mode")
          }
          variant={Config.input.labelVariant}
          fullWidth={true}
          value={value}
          // helperText={
          //   <span>
          //     {t("Use pre-configured groupings")}{" "}
          //     <Help text="group_types_help" aria-label="Group types help" />
          //   </span>
          // }
          onChange={onChange}
        >
          {/* <MenuItem value="" aria-label={t("None")}>
            {t("None")}
          </MenuItem> */}
          {options.map((r, i) => (
            <MenuItem value={r} key={`viewtype_${i}`} aria-label={t(r)}>
              {t(r)}
            </MenuItem>
          ))}
        </TextField>
      </label>
    </Render>
  );
};
