import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiSelectComp } from "./multi-select";

export type TransferMultiSelectCompProps = {
    value: string[];
    orgType: 'org' | 'media';
    placeholder: string;
    label: string;
    onChange: (v: string[]) => void
    searchNames: any
  }

type TSearchState = {
  org: {
    length: number
    hits: string[]

  },
  media: {
    length: number
    hits: string[]

  }
}

export const TransferMultiSelectComp = ({ value, onChange, searchNames, orgType, placeholder, label }: TransferMultiSelectCompProps) => {
  const { t } = useTranslation()
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [search, setSearch] = useState<TSearchState>({
    org: { length: 0, hits: [] },
    media: { length: 0, hits: [] }
  });

  const populateList = (orgType: 'org' | 'media') => (name: string) => {
    if (loadingOptions) return;
    if ((name.length > search[orgType].length) && name.length === 3) {
      setLoadingOptions(true)
      searchNames(name, orgType).then(
        names => setSearch({ ...search, [orgType]: { length: name.length, hits: names } })
      )
        .finally(() => setLoadingOptions(false))
      return ['A', 'B', 'C'];
    }
    if ((name.length < search[orgType].length) && name.length < 3) {
      setSearch({ ...search, [orgType]: { length: name.length, hits: [] } })
      return [];
    }
  }

    return (
      <>
      <MultiSelectComp options={orgType === "org" ? search.org.hits : search.media.hits} 
          value={value} placeholder={placeholder} label={label} 
          onChange={(newValue) => onChange(newValue)}
          getOptionLabel={(v) => orgType==="media" ? v['name'] : v}
        onSearch={(text) => populateList(orgType)(text)} noOptionsText={t('Start typing')}/>
      </>
    )
}