import { t } from "i18next";
import { IFlowsFundingQuery, TYearlyFundingInfo } from "../pages/flows/flows-funding";
import { fundingTypes, getColorFromPaymentDetailType } from "./helpers";
import { IFlowsQuery } from "../pages/flows/flows-transfers";
import {
  getFlow,
  getFlowFunding,
} from "../services/data-service";
import {
  TTimelineFundingResult,
} from "./flows-funding.helper";

export type TMixedFlowsRecord = {
  amount: number;
  receiverMedia: string;
  organisation: string; // = payer
  organisationGroup: string;
  paymentType: "PF" | "MT";
  paymentTypeFull: "Förderungstransparenz" | "Medientransparenz";
  paymentDetailType: string; // fundingType or transferType
  fundingType?: string;
  transferType?: number; // 2 = Werbung, 4 = Förderung
  receiverGroups?: string;
  showFundingBasis?: boolean;
  fundingBasis?: string;
};

export type TMixedFlowsResult = TMixedFlowsRecord[];

export type TTimelineResult = { period: string; total: number }[];

export type TMixedFlowsQuery = {
  showFundingBasis?: boolean;
  showAbsoluteValues?: boolean;
  from?: number;
  to?: number;
  receiver: string[];
  receiverGroups: string[];
  mediaGroupType: string;
};

const rtrOrgName = "Rundfunk und Telekom Regulierungs-GmbH (RTR-GmbH)";
const kommAustriaOrgName = 'Kommunikationsbehörde Austria ("KommAustria")';

export const getMixedFlows = async (
  query: TMixedFlowsQuery,
  showFundingBasis: boolean,
  otherMediaDisabled: boolean,
  restrictTimelineToRange: boolean
) => {
  const fundingQuery: IFlowsFundingQuery = {
    fundingType: fundingTypes.map((_, idx) => idx),
    receiver: query.receiver,
    receiverGroups: query.receiverGroups,
    from: query.from,
    mediaGroupType: query.mediaGroupType,
    to: query.to,
  };
  const transferQuery: IFlowsQuery = {
    organisations: [],
    mediaOwner: [],
    mediaGroups: query.receiverGroups,
    media: query.receiver,
    from: +(query.from + "1"),
    to: +(query.to + "4"),
    pType: [2],
    mediaGroupType: query.mediaGroupType,
  };

  let [{ flows: fundingFlows, timeline: fundingTimeline }, { flows, timeline }] = await Promise.all([
    getFlowFunding(fundingQuery, otherMediaDisabled),
    getFlow(transferQuery),
  ]);
  if (!otherMediaDisabled) {
    fundingFlows = fundingFlows.filter(
      (item) => item.receiver.toLowerCase() !== "other receivers"
    );
    flows = flows.filter(
      (item) => item.media.toLowerCase() !== "other media"
    );
  }

  // TODO remove slices
  let mixed: TMixedFlowsResult = fundingFlows.map((item) => {
    return {
      amount: item.amount,
      receiverMedia:
        item.receiver.toLocaleLowerCase() === "other receivers"
          ? getOtherReceiversName()
          : item.receiver,
      fundingType: item.fundingType,
      paymentType: "PF",
      paymentTypeFull: "Förderungstransparenz",
      paymentDetailType: item.fundingType,
      fundingBasis: item.fundingBasis,
      organisation:
        item.fundingType === "Pressefoerderung" ||
          item.fundingType === "Publizistikfoerderung"
          ? kommAustriaOrgName
          : rtrOrgName,
      showFundingBasis: showFundingBasis,
      receiverGroups: item.receiverGroups,
    } as TMixedFlowsRecord;
  });

  mixed.push(
    ...flows.map((item) => {
      return {
        amount: item.amount,
        receiverMedia:
          item.media.toLocaleLowerCase() === "other media"
            ? getOtherReceiversName()
            : item.media,
        organisation: item.organisation,
        paymentType: "MT",
        paymentTypeFull: "Medientransparenz",
        paymentDetailType: item.transferType === 2 ? "Werbung" : "Förderung", // TODO check for other methods to get string
        transferType: item.transferType,
        showFundingBasis: showFundingBasis,
        organisationGroup: item.orgGroup,
        receiverGroups: item.mediaGroup,
        fundingBasis: "",
      } as TMixedFlowsRecord;
    })
  );

  const transferDataByYear = timeline
    .map(({ period, total }) => ({ year: Number.parseInt(period.toString().substring(0, 4), 10), total }))
  const emptyTransfersPerYear = transferDataByYear.map(d => d.year)
    .reduce((acc, v) => ({ ...acc, [v]: 0 }), {})
  let transfersPerYear = Object.entries(transferDataByYear
    .reduce((acc, v) => ({ ...acc, [v.year]: acc[v.year] + v.total }), emptyTransfersPerYear))
    .map(([year, total]) => ({ year: Number.parseInt(year, 10), total }))
  if (restrictTimelineToRange) {
    transfersPerYear = transfersPerYear
      .filter(d => d.year >= (query.from || 0) && d.year <= (query.to || 0))
    fundingTimeline = fundingTimeline
      .filter(d => d.year >= (query.from || 0) && d.year <= (query.to || 0))
  }

  const allYears = [...transfersPerYear.map(d => d.year), ...fundingTimeline.map(d => d.year)]
  const firstYear = Math.min(...allYears)
  const lastYear = Math.max(...allYears)
  const years = Array.from(Array(lastYear - firstYear + 1)).map((_, i) => i + firstYear)
  let dataPerYear = years.reduce((acc, y) => ({ ...acc, [y]: [] as TYearlyFundingInfo[] }), {})
  dataPerYear = transfersPerYear.reduce((acc, d) => {
    acc[d.year].push({ name: "Werbung", entries: 1, total: d.total });
    return acc
  }, dataPerYear)
  dataPerYear = fundingTimeline.reduce((acc, d) => {
    acc[d.year] = [...acc[d.year], ...d.yearsData]
    return acc
  }, dataPerYear)
  const result = Object.entries(dataPerYear)
    .map(([year, data]) => ({
      year: Number.parseInt(year, 10),
      yearsData: data as TYearlyFundingInfo[]
    }))

  return { flows: mixed, timeline: result as TTimelineFundingResult };
};

export const mergeMixedArrayBasedOnProperty = (array: any, property: any) => {
  const newArray = new Map();
  array.forEach((item: any) => {
    const propertyValue = item[property];
    newArray.has(propertyValue)
      ? newArray.set(
        propertyValue,
        getMergedMixedFlowsEntry(item, newArray.get(propertyValue))
      )
      : newArray.set(propertyValue, item);
  });
  return Array.from(newArray.values());
};

const getMergedMixedFlowsEntry = (item, item2) => {
  const mergedItem = { ...item, ...item2 };
  mergedItem.amount = item.amount > item2.amount ? item.amount : item2.amount;
  return { ...item, ...item2 };
};

type TSankeyLinkModel = {
  source: string;
  target: string;
  value: number;
  normalValue: number;
  lineStyle?: {
    color?: string;
  };
};

export const getMixedFlowsLinks = (
  result: TMixedFlowsResult,
  fundingBaseColors: Map<string, string>,
  showAbsoluteVisualisation: boolean
) => {
  let linkModel: TSankeyLinkModel[] = [];

  // to calculate percentage in tooltip the sums are saved
  const payerSumMap: Map<string, number> = new Map();
  const receiverSumGroupMap: Map<string, number> = new Map();
  const receiverSumMap: Map<string, number> = new Map();
  const fundingBaseSumMap: Map<string, number> = new Map();

  // key = paymentDetailType+|+fundingBasis+|+receiverGroups
  const receiverGroupMap: Map<string, number> = new Map();

  // key = paymentDetailType+|+receiverGroups+|+receiver
  const receiverMap: Map<string, number> = new Map();

  // key = paymentDetailType+|+fundindBase
  const fundingBaseMap: Map<string, number> = new Map();

  for (const item of result.filter((item) => item.amount)) {
    if (item.receiverGroups) {
      const receiverGroupKey =
        item.paymentDetailType +
        "|" +
        (item.showFundingBasis ? item.fundingBasis + "|" : "|") +
        item.receiverGroups;
      if (receiverGroupMap.has(receiverGroupKey))
        receiverGroupMap.set(
          receiverGroupKey,
          ((receiverGroupMap.get(receiverGroupKey)
            ? receiverGroupMap.get(receiverGroupKey)
            : 0) as number) + item.amount
        );
      else receiverGroupMap.set(receiverGroupKey, item.amount);

      if (receiverSumGroupMap.has(item.receiverGroups))
        receiverSumGroupMap.set(item.receiverGroups, (receiverSumGroupMap.get(item.receiverGroups) as number) + item.amount);
      else receiverSumGroupMap.set(item.receiverGroups, item.amount);
    }
    const receiverKey = item.paymentDetailType + "|" + (item.receiverGroups ? item.receiverGroups : "") +
      "|" + (item.showFundingBasis && item.fundingBasis ? item.fundingBasis : "") +
      "|" + item.receiverMedia;
    if (receiverMap.has(receiverKey))
      receiverMap.set(receiverKey, ((receiverMap.get(receiverKey) ? receiverMap.get(receiverKey) : 0) as number) + item.amount);
    else receiverMap.set(receiverKey, item.amount);

    if (receiverSumMap.has(item.receiverMedia))
      receiverSumMap.set(item.receiverMedia, (receiverSumMap.get(item.receiverMedia) as number) + item.amount);
    else receiverSumMap.set(item.receiverMedia, item.amount);

    if (payerSumMap.has(item.paymentDetailType))
      payerSumMap.set(item.paymentDetailType, (payerSumMap.get(item.paymentDetailType) as number) + item.amount);
    else payerSumMap.set(item.paymentDetailType, item.amount);

    if (item.showFundingBasis && item.fundingBasis) {
      if (fundingBaseSumMap.has(item.fundingBasis))
        fundingBaseSumMap.set(item.fundingBasis, (fundingBaseSumMap.get(item.fundingBasis) as number) + item.amount);
      else fundingBaseSumMap.set(item.fundingBasis, item.amount);

      const fundingKey = item.paymentDetailType + "|" + item.fundingBasis;
      if (fundingBaseMap.has(fundingKey))
        fundingBaseMap.set(
          fundingKey,
          ((fundingBaseMap.get(fundingKey)
            ? fundingBaseMap.get(fundingKey)
            : 0) as number) + item.amount
        );
      else fundingBaseMap.set(fundingKey, item.amount);
    }
  }

  for (const key of Array.from(fundingBaseMap.keys())) {
    const paymentDetailType = key.split("|")[0];
    const fundingBase = key.split("|")[1];
    linkModel.push({
      source: paymentDetailType,
      target: fundingBase,
      value: showAbsoluteVisualisation ? fundingBaseMap.get(key) as number : Math.log10(fundingBaseMap.get(key) as number),
      normalValue: fundingBaseMap.get(key) as number,
      lineStyle: {
        color: getColorFromPaymentDetailType(paymentDetailType),
      },
    });
  }

  for (const key of Array.from(receiverMap.keys())) {
    const paymentDetailType = key.split("|")[0];
    const receiverGroup = key.split("|")[1];
    const fundingBase = key.split("|")[2];
    const receiverMedia = key.split("|")[3];
    let color = getColorFromPaymentDetailType(paymentDetailType);
    if (fundingBase && fundingBaseColors.has(fundingBase)) {
      color = fundingBaseColors.get(fundingBase) as string;
    }
    linkModel.push({
      source: receiverGroup
        ? receiverGroup
        : fundingBase
          ? fundingBase
          : paymentDetailType,
      target: receiverMedia,
      value: showAbsoluteVisualisation ? receiverMap.get(key) as number : Math.log10(receiverMap.get(key) as number),
      normalValue: receiverMap.get(key) as number,
      lineStyle: {
        color: color,
      },
    });
  }

  for (const key of Array.from(receiverGroupMap.keys())) {
    const paymentDetailType = key.split("|")[0];
    const fundingBase = key.split("|")[1];
    const receiverGroup = key.split("|")[2];
    let color = getColorFromPaymentDetailType(paymentDetailType);
    if (fundingBase && fundingBaseColors.has(fundingBase)) {
      color = fundingBaseColors.get(fundingBase) as string;
    }
    linkModel.push({
      source: fundingBase ? fundingBase : paymentDetailType,
      target: receiverGroup,
      value: showAbsoluteVisualisation ? receiverGroupMap.get(key) as number : Math.log10(receiverGroupMap.get(key) as number),
      normalValue: receiverGroupMap.get(key) as number,
      lineStyle: {
        color: color,
      },
    });
  }
  return {
    linkModel,
    payerSumMap,
    receiverSumGroupMap,
    receiverSumMap,
    fundingBaseSumMap
  };
};

export const addElementIfNotExist = <T>(oldList: T[], value: T): T[] => {
  return oldList.indexOf(value) ? [...oldList, value] : [...oldList];
};

export const getOtherReceiversName = () => {
  return t("Other receivers");
};

export const getOtherReceiversForRec = (rec: string) => {
  return t("Other receivers") + " ("+rec+")";
};

export const getOthers = () => {
  return t("Others");
};

export const getOtherFundingTypesName = () => {
  return t("Other fundingtypes");
};
