import { FC } from "react";
import Config from "../config/settings";
import {  Tooltip } from "@mui/material";
import Badge from '@mui/material/Badge';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

interface Props {
    mimeType: string;
    width?: number | string;
    height?: number | string;
    thumbnailUrl?: string;
}

export const MimeTypeIcon: FC<Props> = ({ mimeType, width, height, thumbnailUrl }) => {
  const isAudio = Config.ads.audioTypes.includes(mimeType ?? "")
  const isDocument = Config.ads.fileTypes.includes(mimeType ?? "")
  const isVideo = Config.ads.videoTypes.includes(mimeType ?? "")

  const imageUrl = thumbnailUrl ?? `/img/fa-file-image.webp`

  const thumbnail = {
    audio: `/img/fa-file-audio.webp`,
    document: `/img/fa-file-pdf.webp`,
    video: `/img/fa-file-video.webp`,
    image: imageUrl,
  }[isAudio ? "audio" : isDocument ? "document" : isVideo ? "video" : "image"]

  return (
    <Tooltip title={mimeType}>
      <Badge badgeContent={<PlayCircleIcon/>} >
        <img src={thumbnail} alt={mimeType} width={width} height={height} />
      </Badge>
    </Tooltip>
    
  )
}
