/* eslint  @typescript-eslint/no-unused-vars: 0 */
/* eslint  react-hooks/exhaustive-deps: 0 */

import { useContext, useEffect, useMemo, useState } from "react";
import "./App.scss";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "./context/auth-context";
import { ErrorBoundary, Render } from "./components/helper-components";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import BarChartIcon from "@mui/icons-material/BarChart";
import EditIcon from "@mui/icons-material/Edit";
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import Home from "@mui/icons-material/Home";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FlareIcon from "@mui/icons-material/Flare";

import { useMediaQuery } from "react-responsive";
import { Avatar, Button, CSSObject, Divider, IconButton, Menu, Stack, styled, Theme, Toolbar, } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MuiDrawer, { DrawerProps } from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import ArticleIcon from "@mui/icons-material/Article";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Tooltip from "@mui/material/Tooltip";
import Collapse from "@mui/material/Collapse";
import BusinessIcon from "@mui/icons-material/Business";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { AppState } from ".";
import { TInfoState } from "./App";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import Config from "./config/settings";
import { Logo } from "./config/logo";
import { LanguageMenu } from "./components/LanguageMenu";
import Settings from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MailIcon from "@mui/icons-material/Mail";
import PaymentIcon from "@mui/icons-material/Payment";
import TableChartIcon from "@mui/icons-material/TableChart";
import TuneIcon from "@mui/icons-material/Tune";
import LanguageIcon from "@mui/icons-material/Language";
import CloseIcon from "@mui/icons-material/Close";
import { Modules } from "./models/modules";
import { useSettingsStore } from "./context/SettingsStore";
import ScrollToTop from "./helpers/scrollToTop";
import { SlotsMenu, SlotsSwitch } from "./components/slots-switch";
import NewspaperIcon from '@mui/icons-material/Newspaper';

let drawerWidth = "200px";

// TEMPORARY SOLUTION!
if (Config.skin === "rtr") {
  drawerWidth = "0px";
}

const rtrServicesUrl = "https://www.rtr.at/rtr/service/startseite.de.html";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme, isMobilePortrait: boolean): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: isMobilePortrait ? "0" : `60px`,
  boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 8px",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  backgroundColor: "var(--primary-color)",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: "var(--primary-color)",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth})`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const adminLinks = [
  {
    to: "administration",
    label: "Administration",
    prefix: false,
    icon: <Settings />,
    subLinks: [
      {
        to: "/users",
        label: "Users",
        prefix: false,
        icon: <ManageAccountsIcon />,
        subLinks: [],
      },
      {
        to: "/group-types",
        label: "Group Types",
        prefix: false,
        icon: <SupervisorAccountIcon />,
        subLinks: [],
      },
      {
        to: "/settings",
        label: "Settings",
        prefix: false,
        icon: <TuneIcon />,
        subLinks: [],
      },
      {
        to: "/languages",
        label: "Languages",
        prefix: false,
        icon: <LanguageIcon />,
        subLinks: [],
      },
    ],
    showOnMobilePortrait: true,
    hide: false,
  },
];

const dataLinks = [
  {
    to: "data",
    label: "Daten",
    prefix: false,
    icon: <ApartmentIcon />,
    subLinks: [
      {
        to: "/organisation/list",
        label: "Organisations",
        prefix: false,
        icon: <BusinessIcon />,
        subLinks: [],
      },
      {
        to: "/zip/list",
        label: "Zip Codes",
        prefix: false,
        icon: <MailIcon />,
        subLinks: [],
      },
      {
        to: "/transfer",
        label: "Transfers",
        prefix: false,
        icon: <PaymentIcon />,
        subLinks: [],
      },
      {
        to: "/media",
        label: "Media List",
        prefix: false,
        icon: <NewspaperIcon />,
        subLinks: [],
      },
    ],
    showOnMobilePortrait: true,
    hide: false,
  },
];

const groupLinks = [
  {
    to: "/groups",
    label: "Grouping",
    prefix: false,
    icon: <TableChartIcon />,
    subLinks: [],
    showOnMobilePortrait: true,
    hide: false,
  },
];

interface CustomDrawerProps extends DrawerProps {
  isMobilePortrait: boolean;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) =>
    !["open", "isMobilePortrait"].includes(prop.toString()),
})<CustomDrawerProps>(({ theme, open, isMobilePortrait }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme, isMobilePortrait),
    "& .MuiDrawer-paper": closedMixin(theme, isMobilePortrait),
  }),
}));

const compareLocation = (item: any, location: any) => {
  if (item.subLinks && item.subLinks.length > 0) {
    return item.subLinks.find((it) => compareLocation(it, location));
  }
  return item.prefix
    ? location.pathname.startsWith(item.to)
    : item.to === location.pathname;
};

const MobileApp = () => {
  const { t } = useTranslation();
  const settings = useSettingsStore();
  const { user, hasRole } = useContext(AuthContext);
  const { blogs, newsSeen, appVersion } = useSelector<AppState, TInfoState>(
    (state) => state.info
  );
  const navigate = useNavigate();

  const publicLinks = useMemo(
    () => [
      {
        to: "/home",
        label: "Home",
        prefix: false,
        icon: <Home />,
        subLinks: [],
        hide: false,
      },
      {
        to: "/overview",
        label: "Overview",
        prefix: true,
        icon: <BarChartIcon />,
        subLinks: [],
        hide: false,
      },
      {
        to: "/top",
        label: "Top Values",
        prefix: true,
        match: 1,
        icon: <DonutSmallOutlinedIcon />,
        subLinks: [],
        hide: !(settings.isModuleEnabled(Modules.Top_Fundings) || settings.isModuleEnabled(Modules.Top_Transfer)),
      },
      {
        to: "/flows",
        label: "Flows",
        match: 1,
        prefix: true,
        icon: <QueryStatsOutlinedIcon />,
        subLinks: [],
        hide: !(settings.isModuleEnabled(Modules.Flows_Fundings) || settings.isModuleEnabled(Modules.Flows_Mixed) || settings.isModuleEnabled(Modules.Flows_Transfer)),
      },
      {
        to: "/ads",
        label: "ads",
        prefix: true,
        match: 1,
        icon: <FormatListBulletedIcon />,
        subLinks: [] as any,
        showOnMobilePortrait: true,
        hide: !settings.isModuleEnabled(Modules.Sujets),
      },
      {
        to: "/universe",
        label: "Universe",
        prefix: false,
        match: 1,
        icon: <FlareIcon />,
        subLinks: [],
        hide: !settings.isModuleEnabled(Modules.AdsUniverse),
        showOnMobilePortrait: true,
      },
      {
        to: "/news",
        label: "News",
        prefix: false,
        icon:
          !newsSeen && blogs.length > 0 ? (
            <Badge badgeContent={blogs.length}>
              <AnnouncementIcon />
            </Badge>
          ) : (
            <AnnouncementIcon />
          ),
        subLinks: [] as any,
        showOnMobilePortrait: true,
        hide: blogs.length === 0 || !settings.isModuleEnabled(Modules.Blog),
      },
      {
        to: "/search",
        label: "Search",
        prefix: false,
        icon: <SearchIcon />,
        subLinks: [] as any,
        showOnMobilePortrait: true,
        hide: false,
      },
    ],
    [blogs, newsSeen, settings]
  );

  const infoLinks = useMemo(
    () => [
      {
        to: "/about",
        label: "About us",
        prefix: false,
        icon: <InfoIcon />,
        subLinks: [],
        showOnMobilePortrait: true,
        hide: !settings.isModuleEnabled(Modules.AboutUs),
      },
      {
        to: "/impress",
        label: "Impress",
        prefix: false,
        icon: <ArticleIcon />,
        subLinks: [],
        showOnMobilePortrait: true,
        hide: false,
      },
    ],
    [settings]
  )

  const blogLinks = useMemo(() =>
    settings.isModuleEnabled(Modules.Blog)
      ? [
        {
          to: "/blog",
          label: "Blog",
          prefix: false,
          icon: <EditIcon />,
          subLinks: [],
          showOnMobilePortrait: true,
          hide: false,
        },
      ]
      : [],
    [settings]
  )

  const allLinks = publicLinks
    .concat(infoLinks)
    .concat(adminLinks)
    .concat(dataLinks)
    .concat(groupLinks)
    .concat(blogLinks);
  const initialLinkState = allLinks
    .filter(({ subLinks }) => subLinks.length > 0)
    .reduce((acc, cur) => ({ ...acc, [cur.to]: false }), {});
  const [open, setOpen] = useState(false);
  const [openLinks, setOpenLinks] = useState(initialLinkState);
  const location = useLocation();
  const [selMenuItem, setSelMenuItem] = useState(publicLinks[0]);

  useEffect(() => {
    const item = allLinks.find((it) => compareLocation(it, location));
    if (item) setSelMenuItem(item);
    if (location.pathname === "/") navigate("/home");
  }, [location, allLinks]);

  const handleDrawerClick = () => {
    setOpen(!open);
  };

  const StyledBottomNavigationAction = styled(BottomNavigationAction)(`
  &.Mui-selected {
    color: var(--primary-color);
  }
`);
  const { isLoggedIn, signout } = useContext(AuthContext);

  const Navigation = () => {
    return (
      <BottomNavigation
        showLabels
        value={tabIndex}
        onChange={(event, newValue) => {
          setTabIndex(newValue);
          navigate(["/", "/overview", "/top", "/flows"][newValue]);
        }}
        sx={{
          "& .MuiBottomNavigationAction-root": {
            backgroundColor: "var(--primary-color)",
            color: "#fff",
          },
          "& .MuiBottomNavigationAction-root .Mui-selected": {
            color: "var(--primary-color)",
          },
        }}
        aria-label="Bottom navigation"
      >
        <StyledBottomNavigationAction label={t("Overview")} icon={<Home />} />
        <StyledBottomNavigationAction
          label={t("Overview")}
          icon={<BarChartIcon />}
        />
        <StyledBottomNavigationAction
          label={t("Top Values")}
          icon={<DonutSmallOutlinedIcon />}
        />
        <StyledBottomNavigationAction
          label={t("Flows")}
          icon={<QueryStatsOutlinedIcon />}
        />
      </BottomNavigation>
    );
  };

  const RtrFooter = () => (
    <>
      <Divider sx={{ bgcolor: "var(--gray-color)", mt: 5 }} />
      <div className="footer-page max-content-width">
        <Box
          sx={{
            display: "flex",
            gap: 2,
            margin: 2,
            flexDirection: "column",
            width: "100%",
            minWidth: "250px",
          }}
        >
          <div>
            <b>
              RUNDFUNK UND TELEKOM <br />
              REGULIERUNGS-GMBH
            </b>
          </div>
          <div>
            <span>
              <p>A-1060 Wien, Mariahilfer Straße 77-79</p>
              <p>
                Tel.:
                <b> +43 1 58058-0</b>
              </p>
            </span>
          </div>
          <Box sx={{ my: 1 }}>
            <IconButton
              href="mailto:rtr@rtr.at"
              target="_blank"
              aria-label="Mail"
              sx={{ mr: 2 }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                data-svg="mail"
                aria-hidden="true"
              >
                <polyline
                  fill="none"
                  stroke="#000"
                  points="1.4,6.5 10,11 18.6,6.5"
                ></polyline>
                <path d="M 1,4 1,16 19,16 19,4 1,4 Z M 18,15 2,15 2,5 18,5 18,15 Z"></path>
              </svg>
            </IconButton>
            <IconButton
              href="https://twitter.com/rtrgmbh"
              target="_blank"
              aria-label="Twitter"
              sx={{ mr: 2 }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                role="img"
                data-svg="twitter"
              >
                <title>Twitter/X Logo</title>
                <g>
                  <path d="M 15.75 0.960938 L 18.816406 0.960938 L 12.117188 8.621094 L 20 19.039062 L 13.828125 19.039062 L 8.996094 12.71875 L 3.464844 19.039062 L 0.394531 19.039062 L 7.5625 10.847656 L 0 0.960938 L 6.328125 0.960938 L 10.699219 6.738281 Z M 14.675781 17.203125 L 16.375 17.203125 L 5.40625 2.699219 L 3.582031 2.699219 Z M 14.675781 17.203125 "></path>
                </g>
              </svg>
            </IconButton>
            <IconButton
              href="https://at.linkedin.com/company/rtr"
              target="_blank"
              aria-label="Linkedin"
            >
              <svg
                width="20"
                height="20"
                role="img"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                data-svg="linkedin"
              >
                <title>LinkedIn Logo</title>
                <path d="M5.77,17.89 L5.77,7.17 L2.21,7.17 L2.21,17.89 L5.77,17.89 L5.77,17.89 Z M3.99,5.71 C5.23,5.71 6.01,4.89 6.01,3.86 C5.99,2.8 5.24,2 4.02,2 C2.8,2 2,2.8 2,3.85 C2,4.88 2.77,5.7 3.97,5.7 L3.99,5.7 L3.99,5.71 L3.99,5.71 Z"></path>
                <path d="M7.75,17.89 L11.31,17.89 L11.31,11.9 C11.31,11.58 11.33,11.26 11.43,11.03 C11.69,10.39 12.27,9.73 13.26,9.73 C14.55,9.73 15.06,10.71 15.06,12.15 L15.06,17.89 L18.62,17.89 L18.62,11.74 C18.62,8.45 16.86,6.92 14.52,6.92 C12.6,6.92 11.75,7.99 11.28,8.73 L11.3,8.73 L11.3,7.17 L7.75,7.17 C7.79,8.17 7.75,17.89 7.75,17.89 L7.75,17.89 L7.75,17.89 Z"></path>
              </svg>
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <List>
            <ListItem>
              <a href="https://www.rtr.at/rtr/Kontakt/Amtstafel.de.html">
                {t("contact")}
              </a>
            </ListItem>
            <ListItem>
              <a href="https://www.rtr.at/rtr/footer/impressum.de.html">
                {t("publishing information")}
              </a>
            </ListItem>
            <ListItem>
              <a href="https://www.rtr.at/rtr/footer/Datenschutz.de.html">
                {t("data protection")}
              </a>
            </ListItem>
            <ListItem>
              <span dangerouslySetInnerHTML={{ __html: t("whistleBlowing") }}></span>
            </ListItem>
            <ListItem>
              <a href="https://www.rtr.at/rtr/footer/Barrierefreiheit.de.html">
                {t("accessibility")}
              </a>
            </ListItem>
            <ListItem>
              <a href="https://www.rtr.at/medien/startseite_medien.de.html#cookie-einstellungen">
                {t("cookie preferences")}
              </a>
            </ListItem>

          </List>
          <List>
            <ListItem>
              <a href="https://www.rtr.at/medien/presse/Startseite1.de.html">
                {t("press")}
              </a>
            </ListItem>
            <ListItem>
              <a href="https://www.rtr.at/rtr/service/ertr/eRTR.de.html">
                {t("ertr")}
              </a>
            </ListItem>
            <ListItem>
              <a href="https://www.rtr.at/rtr/service/opendata/OpenData.de.html">
                {t("open data")}
              </a>
            </ListItem>
            <ListItem>
              <a href="https://www.rtr.at/medien/service/FAQs/FAQ-Medien.de.html">
                {t("answers")}
              </a>
            </ListItem>
            <ListItem>
              <a href="https://www.rtr.at/rtr/was_wir_tun/Arbeiten_in_der_RTR/Karriere/Karriere.de.html">
                {t("career")}
              </a>
            </ListItem>
            <ListItem>
              <Box>Version: {appVersion}</Box>
            </ListItem>
          </List>
        </Box>
      </div>
    </>
  );

  const changeSubMenuState = (to: string) => {
    if (isMobilePortrait) setOpen(false);
    setOpenLinks({ ...openLinks, [to]: !openLinks[to] });
  };

  const onNavigateTo = (to: string) => {
    if (isMobilePortrait) setOpen(false);
    navigate(to);
  };

  const isSelected = (
    page: { to: string; prefix: boolean; match?: number },
    debug = false
  ) => {
    const parts = (page.match ?? 0) + 1;
    if (debug) {
      console.log(
        parts,
        page.to,
        location.pathname,
        page.match,
        isSelected(page, false)
      );
    }
    if (parts > 1) {
      const pathParts = page.to.split("/");
      if (debug) {
        console.log(
          location.pathname.split("/").slice(0, parts).join("/"),
          pathParts.slice(0, parts).join("/")
        );
      }
      return (
        location.pathname.split("/").slice(0, parts).join("/") ===
        pathParts.slice(0, parts).join("/")
      );
    }
    if (page.prefix && location.pathname.startsWith(page.to)) return true;
    return page.to === location.pathname;
  };

  const toMenuItem = (page) => (
    <>
      <ListItem key={page.to} disablePadding sx={{ display: "block" }}>
        <Tooltip title={t(page.label)} placement="right" arrow>
          <ListItemButton
            sx={{
              minHeight: 52,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              color: isSelected(page)
                ? "var(--primary-color)"
                : "var(--menu-item-color)",
            }}
            onClick={() =>
              "subLinks" in page && page.subLinks.length > 0
                ? changeSubMenuState(page.to)
                : onNavigateTo(page.to)
            }
          >
            {/*<Link to="/" className="nav-link" onClick={() => setOpen(false)} >*/}

            {page.icon ? (
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 1 : "auto",
                  justifyContent: "center",
                  color: isSelected(page)
                    ? "var(--primary-color)"
                    : "var(--menu-item-icon-color)",
                }}
              >
                {page.icon}
              </ListItemIcon>
            ) : (
              <Box sx={{ m: 2 }} />
            )}

            <ListItemText
              primary={t(page.label)}
              sx={{ opacity: open ? 1 : 0 }}
            />
            <Render when={page.subLinks.length > 0}>
              {openLinks[page.to] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Render>
          </ListItemButton>
        </Tooltip>
      </ListItem>
      <Render when={"subLinks" in page && page.subLinks.length > 0}>
        <Collapse
          in={openLinks[page.to]}
          timeout="auto"
          key={`${page.to}-collapse`}
        >
          <Divider />
          <List component="div" disablePadding>
            {page.subLinks.map(toMenuItem)}
          </List>
          <Divider />
        </Collapse>
      </Render>
    </>
  );

  const toHorizontalMenuItem = (page) => (
    <>
      <ListItem key={page.to} disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            height: "70px",
            justifyContent: open ? "initial" : "center",
            mx: "12px",
            py: 1,
            px: 0,
            borderBottom: isSelected(page)
              ? "3px solid var(--primary-color)"
              : "3px solid transparent",
            fontWeight: isSelected(page) ? 700 : 400,
          }}
          onClick={() => {
            "subLinks" in page && page.subLinks.length > 0
              ? changeSubMenuState(page.to)
              : onNavigateTo(page.to);
            closeMobileMenu();
          }}
          disableRipple
        >
          <ListItemText primary={t(page.label)} />
          <Render when={page.subLinks.length > 0}>
            {openLinks[page.to] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Render>
        </ListItemButton>
      </ListItem>
      <Render when={"subLinks" in page && page.subLinks.length > 0}>
        <Collapse
          in={openLinks[page.to]}
          timeout="auto"
          key={`${page.to}-collapse`}
        >
          <List component="div" disablePadding>
            {page.subLinks.map(toHorizontalMenuItem)}
          </List>
        </Collapse>
      </Render>
    </>
  );

  const isMobileLandscape = useMediaQuery({
    maxHeight: 575.98,
    orientation: "landscape",
  });
  const isMobilePortrait = useMediaQuery({ maxWidth: 600 });
  const isMobile = isMobileLandscape || isMobilePortrait;

  const handleLanguageChange = (lang) => {
  };

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);

  const [horizontalMenuAnchorEl, setHorizontalMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const horizontalMenuOpen = Boolean(horizontalMenuAnchorEl);

  const horizontalMenuHandleClick = (event: React.MouseEvent<HTMLElement>) => {
    setHorizontalMenuAnchorEl(event.currentTarget);
  };

  const horizontalMenuHandleClose = () => {
    setHorizontalMenuAnchorEl(null);
  };

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMobileMenuClick = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const getMenuItemLabel = (menuItem) => {
    return menuItem.hide ? t("Not found") : t(menuItem.label);
  }

  const [tabIndex, setTabIndex] = useState<number>(0);

  const navItems = ['Home', 'About', 'Contact'];

  return (
    <>
      <Box className="root-box">
        <ScrollToTop />
        <AppBar open={open} role="banner">
          {Config.skin !== "rtr" && (
            <Toolbar>
              <div className="max-header-width">
                <div className="topbar-content">
                  <span className="toogle-outer-container">
                    <IconButton
                      className="topbar-toogle-button"
                      aria-label="open drawer"
                      onClick={handleDrawerClick}
                      edge="end"
                      sx={{
                        color: Config.topbar.burgerMenuIconColor,
                        marginRight: 0,
                        ...(open && { display: "none" }),
                      }}
                    >
                      <MenuIcon />
                    </IconButton>
                    <span className="page-title-container" aria-label={`${t("title")}: ${getMenuItemLabel(selMenuItem)}`}>
                      {getMenuItemLabel(selMenuItem)}
                    </span>
                  </span>
                  <LanguageMenu
                    onLanguageChange={handleLanguageChange}
                    aria-label={t("LanguageSelection")}
                  />
                </div>
              </div>
            </Toolbar>
          )}

          {Config.skin === "rtr" && (
            <>
              <div className="topbar-thin">
                <Toolbar>
                  <div className="max-header-width">
                    <div className="topbar-content">
                      <span className="toogle-outer-container">
                        <Link to="https://www.rtr.at">
                          <Button
                            sx={{
                              color: "var(--text-on-primary-color) !important",
                              background: "none !important",
                            }}
                            disableRipple
                            disableElevation
                          >
                            {t("About us")}
                          </Button>
                        </Link>
                        <LanguageMenu
                          onLanguageChange={handleLanguageChange}
                          aria-label={t("LanguageSelection")}
                        />
                      </span>
                      <span className="toogle-outer-container">
                        <Stack direction="row" alignItems="center" gap={1} sx={{ height: 40 }}>
                          <IconButton component={Link} to="/search"
                            disableRipple aria-label={t("Search")}
                          >
                            <SearchIcon />
                          </IconButton>
                          <SlotsMenu />
                        </Stack>
                      </span>
                    </div>
                  </div>
                </Toolbar>
              </div>

              <Toolbar>
                <div className="horizontal-menu" role="navigation">
                  <div className="max-header-width">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Link
                        to="/"
                        className="horizontal-menu-logo"
                        aria-label="Home"
                      >
                        <Logo />
                      </Link>
                      <IconButton
                        className="horizontal-menu-toogle"
                        aria-label="open mobile menu"
                        onClick={handleMobileMenuClick}
                        edge="end"
                        sx={{
                          color: "var(--primary-text-color)",
                          marginRight: 1,
                        }}
                        disableRipple
                      >
                        {mobileMenuOpen ? (
                          <CloseIcon fontSize="large" />
                        ) : (
                          <MenuIcon fontSize="large" />
                        )}
                      </IconButton>

                      <List
                        className="horizontal-menu-list"
                        sx={{
                          display: mobileMenuOpen ? "flex !important" : "",
                          justifyContent: "end",
                          alignItems: "center",
                          color: "var(--primary-text-color) !important",
                          marginX: 2,
                          padding: 0,
                          fontSize: "14px",
                          ...(mobileMenuOpen && {
                            flexDirection: "column",
                            alignItems: "flex-start",
                            position: "absolute",
                            top: "70px",
                            margin: "0",
                            width: "100%",
                            padding: "30px 20px 40px 20px",
                            fontSize: "24px",
                            backgroundColor: "var(--primary-color)",
                            color: "#fff !important",
                            "& .MuiListItemButton-root:hover": {
                              color: "#fff !important",
                              backgroundColor: "transparent !important",
                            },
                          }),
                        }}
                      >
                        {publicLinks
                          .filter((l) => !l.hide && l.to !== "/search")
                          .map(toHorizontalMenuItem)}
                        <Render when={hasRole("admin") || hasRole("group")}>
                          <ListItem disablePadding>
                            <ListItemButton
                              aria-haspopup="true"
                              aria-expanded={
                                horizontalMenuOpen ? "true" : undefined
                              }
                              onClick={horizontalMenuHandleClick}
                              sx={{
                                color: mobileMenuOpen
                                  ? "#fff !important"
                                  : "var(--primary-text-color) !important",
                                height: "70px",
                                justifyContent: open ? "initial" : "center",
                                mx: "12px",
                                py: 1,
                                px: 0,
                                borderBottom: "3px solid transparent",
                              }}
                              disableRipple
                            >
                              <ListItemText primary="Admin" />
                              <ExpandMore />
                            </ListItemButton>
                            <Menu
                              anchorEl={horizontalMenuAnchorEl}
                              open={horizontalMenuOpen}
                              onClose={horizontalMenuHandleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              className="horizontal-menu-sub"
                            >
                              <Render when={hasRole("group")}>
                                {groupLinks.map(toHorizontalMenuItem)}
                              </Render>
                              <Render when={hasRole("admin")}>
                                {blogLinks.map(toHorizontalMenuItem)}
                                {dataLinks.map(toHorizontalMenuItem)}
                                {adminLinks.map(toHorizontalMenuItem)}
                              </Render>
                              <Render when={isLoggedIn()}>
                                <ListItem>
                                  <ListItemButton
                                    onClick={() => {
                                      signout();
                                      navigate("/");
                                    }}
                                  >
                                    {t("Logout")} ({user?.username})
                                  </ListItemButton>
                                </ListItem>
                              </Render>
                            </Menu>
                          </ListItem>
                        </Render>
                        <ListItem>
                          <Button
                            variant="outlined"
                            color="primary"
                            href={rtrServicesUrl}
                            disableRipple
                            disableElevation
                            sx={{
                              ...(mobileMenuOpen && {
                                marginTop: 1,
                              }),
                            }}
                          >
                            Unsere Services
                          </Button>
                        </ListItem>
                      </List>
                    </Box>
                  </div>
                </div>
              </Toolbar>
            </>
          )}
        </AppBar>

        {(Config.skin === "fhj" || isMobilePortrait) && (
          <Drawer
            variant="permanent"
            open={open}
            isMobilePortrait={isMobilePortrait}
          >
            <DrawerHeader
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Link to="/" className="navbar-brand appbar-brand">
                <div className="app-brand-wrapper">
                  <Logo />
                </div>
              </Link>
              <IconButton
                aria-label={t("Close Side Menu")}
                onClick={handleDrawerClick}
              >
                <ChevronLeftIcon sx={{ color: "white" }} />
              </IconButton>
            </DrawerHeader>

            <Render when={true}>
              <div className="drawer-outer-container">
                <Box>
                  <List>
                    {publicLinks
                      .filter(
                        (l) =>
                          (!isMobilePortrait ||
                            (l.showOnMobilePortrait && isMobilePortrait)) &&
                          !l.hide
                      )
                      .map(toMenuItem)}
                    <Render when={hasRole("group")}>
                      <Divider />
                      {groupLinks
                        .filter(
                          (l) =>
                            !isMobilePortrait ||
                            (l.showOnMobilePortrait && isMobilePortrait)
                        )
                        .map(toMenuItem)}
                    </Render>
                    <Render when={hasRole("admin")}>
                      {blogLinks
                        .filter(
                          (l) =>
                            !isMobilePortrait ||
                            (l.showOnMobilePortrait && isMobilePortrait)
                        )
                        .map(toMenuItem)}
                      {dataLinks
                        .filter(
                          (l) =>
                            (!isMobilePortrait ||
                              (l.showOnMobilePortrait && isMobilePortrait)) &&
                            !l.hide
                        )
                        .map(toMenuItem)}
                      {adminLinks
                        .filter(
                          (l) =>
                            (!isMobilePortrait ||
                              (l.showOnMobilePortrait && isMobilePortrait)) &&
                            !l.hide
                        )
                        .map(toMenuItem)}
                    </Render>
                  </List>
                </Box>
                <Box>
                  <List>
                    <ListItem key='useHalfyears' disablePadding sx={{ display: "block", px: 2.5, }}>
                      <SlotsSwitch />
                    </ListItem>
                    {infoLinks
                      .filter((l) => !l.hide)
                      .filter(
                        (l) =>
                          !isMobilePortrait ||
                          (l.showOnMobilePortrait && isMobilePortrait)
                      )
                      .map(toMenuItem)}
                  </List>
                  <Render when={isLoggedIn()}>
                    <Divider />
                    <div className="user-container">
                      <Tooltip title={t("Logout")} placement="right" arrow>
                        <Avatar
                          sx={{
                            bgcolor: Config.topbar.avatarBgColor,
                            ":hover": {
                              cursor: "pointer",
                            },
                          }}
                          onClick={() => {
                            signout();
                            navigate("/");
                          }}
                        >
                          <LogoutIcon sx={{ color: "var(--primary-color)" }} />
                        </Avatar>
                      </Tooltip>
                      <Tooltip title={user?.username} placement="right" arrow>
                        <Avatar
                          sx={{ bgcolor: Config.topbar.avatarBgColor }}
                          className="person-icon"
                        >
                          <PersonIcon sx={{ color: "var(--primary-color)" }} />
                        </Avatar>
                      </Tooltip>
                    </div>
                  </Render>
                </Box>
              </div>
            </Render>
            <Box
              sx={{
                alignContent: "center",
                margin: "auto",
                fontSize: "60%",
                marginBottom: "0.5em",
              }}
            >
              Version: {appVersion}
            </Box>
          </Drawer>
        )}
        <Box
          component="main"
          sx={{ p: 0 }}
          style={{ width: "100%", minHeight: "100vh" }}
        >
          <ErrorBoundary>
            {/* <DrawerHeader /> */}
            <div id="content" className="max-content-width">
              {/* <h1>Mobile</h1> */}
              <Outlet />
            </div>
          </ErrorBoundary>
        </Box>
        {isMobilePortrait && Config.skin === "fhj" && (
          <footer>
            <Navigation></Navigation>
          </footer>
        )}
      </Box>
      {Config.skin === "rtr" && <RtrFooter></RtrFooter>}
    </>
  );
};

export default MobileApp;
