import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import * as serviceWorker from './serviceWorker';

import {Provider} from 'react-redux';

// import i18n (needs to be bundled ;))
import './i18n';

import { AuthProvider } from './context/auth-context';
import { userSlice } from './pages/list-users';
import { configureStore } from '@reduxjs/toolkit';
import { zipCodeSlice } from './pages/list-zipcodes';
import { overviewSlice } from './pages/overview';
import { topSlice } from './pages/top/top-transfers';
import { infoSlice } from './App';
import { flowsSlice } from './pages/flows/flows-transfers';
import { searchSlice } from './pages/search';
import { groupingSlice } from './pages/list-groups';
import { organisationsSlice } from './pages/list-organisations';
import { transfersSlice } from './pages/list-transfers';
import { aboutUsSlice } from './pages/about_us';
import { topFundingSlice } from './pages/top/top-fundings';
import { flowsFundingSlice } from './pages/flows/flows-funding';
import { flowsMixedSlice } from './pages/flows/flows-mixed';
import ResponsiveApp from './ResponsiveApp';
import { universeSlice } from './pages/universe';
import { flowSlice as flowRootSlice } from './pages/flows/flows';
import { SettingsProvider } from "./context/SettingsContext";
import {ErrorBoundary} from "./components/helper-components";
import { changeSlice } from './pages/changes';
import { mediaSlice } from './pages/list-media';
import { sujetSlice } from './pages/ads/advertisings';
import { campaignSlice } from './pages/ads/campaigns';
/*
const rootReducer = combineReducers({
  formBuilder: formBuilderReducer
})
*/

export const store = configureStore({
  reducer: {
    users: userSlice.reducer,
    zipCodes: zipCodeSlice.reducer,
    overview: overviewSlice.reducer,
    top: topSlice.reducer,
    info: infoSlice.reducer,
    flow: flowsSlice.reducer,
    search: searchSlice.reducer,
    grouping: groupingSlice.reducer,
    organisations: organisationsSlice.reducer,
    transfers: transfersSlice.reducer,
    aboutUs: aboutUsSlice.reducer,
    topFundings: topFundingSlice.reducer,
    flowFundings: flowsFundingSlice.reducer,
    flowsMixed: flowsMixedSlice.reducer,
    universe: universeSlice.reducer,
    flowRoot: flowRootSlice.reducer,
    change: changeSlice.reducer,
    media: mediaSlice.reducer,
    sujets: sujetSlice.reducer,
    campaign: campaignSlice.reducer
  }
})

export type AppState = ReturnType<typeof store.getState>

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container!)


root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <React.StrictMode>
        <AuthProvider>
          <SettingsProvider>
            <Suspense fallback={<div>Loading...</div>}>
                <ResponsiveApp/>
            </Suspense>
          </SettingsProvider>
        </AuthProvider>
      </React.StrictMode>
    </Provider>
  </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
