import { useEffect, useMemo, useState } from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import {
  objectToParams,
  searchFundingNames,
} from "../../services/data-service";
import {
  Help,
  ModalLoader,
  Render,
  ShowError,
} from "../../components/helper-components";
import { ResponsiveContainer } from "recharts";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faInfoCircle,
  faStream,
} from "@fortawesome/free-solid-svg-icons";

import {
  fundingTypes,
  fundingTypesIndices,
  getAmountRangeString,
  getColorFromPaymentDetailType,
  getFundingValueFromName,
  getTranslatedPaginationOptions,
  isEqual,
  isObjectSubset,
  isTouchSupported,
  objectNormalize,
  toCurrency,
} from "../../helpers/helpers";
import "./flows-transfers.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getPFColor } from "../../colors";
import DataTable, { Media } from "react-data-table-component-with-filter";
import { ExportToExcel } from "../../components/excel-exporter";
import ReactECharts from "echarts-for-react";
import { addElementIfNotExist } from "../../helpers/flows-mixed.helper";
import { Alert, Grid, Tab as TabMui, Tabs as TabsMui } from "@mui/material";

import {
  getFundingFlows,
  getFundingFlowsLinks,
  TFundingFlowsRecord,
  TFundingFlowsResult,
  TTimelineFundingResult,
} from "../../helpers/flows-funding.helper";
import "../top/top.scss";
import "./flows.scss";
import { TInfoState } from "../../App";
import { SwitchButton } from "../../components/switch-button/switchButton";
import { useMediaQuery } from "react-responsive";
import { MultiSelectComp } from "../../components/multi-select/multi-select";
import { TransferMultiSelectComp } from "../../components/multi-select/transfer-multi-select";
import {
  AmountSlider,
  PeriodSlider,
} from "../../components/slider/rangeSlider";
import useTouchDoubleClickChecker from "../../helpers/touchDoubleClickChecker";
import {
  amountRangeReducers,
  baseReducers,
  extractAmountRange,
  IAmountFilterRange,
  IAmountRange,
  IBaseQuery,
  IBaseState,
} from "../../states/base_states";
import { GroupTypeSelector } from "../../components/group-type-selector";
import Config from "../../config/settings";
import { Fold, SettingsViewer } from "../../components/settings-viewer";

import * as echarts from "echarts/core";
import german from "echarts/i18n/langDE-obj";
import { defaultBaseTimelineModel, defaultSankeyOptions } from "./flows";
import {
  defaultFlowsFundingsModuleSettings,
  FlowsFundingsModuleSettings,
} from "./flows-fundings.settings";
import { useSettingsStore } from "../../context/SettingsStore";

echarts.registerLocale("de", german);

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const keysToNames = {
  receiver: "receiver",
  receiverGroups: "receiverGroups",
};

let echartRef;

const fundingTypeIds = fundingTypes.map((_, idx) => idx);

type TSankeyDataModel = {
  name: string;
};

type TSankeyLinkModel = {
  source: string;
  target: string;
  value: number;
};

type TSankeyChartModel = {
  data: TSankeyDataModel[];
  links: TSankeyLinkModel[];
  right?: string;
  edgeLabel?: any;
  orient?: number;
  lineStyle?: {
    color: string;
  };
};

export type TYearlyFundingInfo = {
  name: string;
  entries: number;
  total: number;
};

const toFundingYearEChartsModel = (data_: TTimelineFundingResult) =>
  data_
    .map((item) => {
      return {
        year: item.year,
        ...item.yearsData.reduce(
          (acc, q) => ({
            ...acc,
            [getFundingValueFromName(q.name)]: q.total,
          }),
          fundingTypes.reduce((acc, _, idx) => ({ ...acc, [idx]: 0 }), {})
        ),
      };
    })
    .sort((a, b) => a.year - b.year);

const receiverGroups: string[] = [];
const receivers: string[] = [];
const pfTypes: string[] = [];
const fundingBaseTypes: string[] = [];
let fundingBaseColors: Map<string, string>;

const getDataModel = (
  acc: {
    name: string;
    itemStyle?: { color: string; borderColor: string };
    label?: { position?: string; offset?: any };
  }[],
  currentValue: TFundingFlowsRecord
): TSankeyDataModel[] => {
  if (currentValue.amount) {
    acc.push({
      name: currentValue.fundingType,
      itemStyle: {
        color: getColorFromPaymentDetailType(currentValue.fundingType),
        borderColor: getColorFromPaymentDetailType(currentValue.fundingType),
      },
    });

    receivers.push(currentValue.receiver);
    acc.push({
      name: currentValue.receiver,
      label: { position: "insideRight", offset: [-20, 0] },
    });

    if (currentValue.receiverGroups) {
      receiverGroups.push(currentValue.receiverGroups);
      acc.push({ name: currentValue.receiverGroups });
    }

    if (currentValue.showFundingBasis && currentValue.fundingBasis) {
      const fundingBaseColor =
        "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
      fundingBaseTypes.push(currentValue.fundingBasis);
      fundingBaseColors.set(currentValue.fundingBasis, fundingBaseColor);
      acc.push({
        name: currentValue.fundingBasis,
        itemStyle: { color: fundingBaseColor, borderColor: fundingBaseColor },
      });
    }

    pfTypes.push(currentValue.fundingType);
  }
  return acc;
};

let payerSumMap: Map<string, number> = new Map();
let receiverGroupSumMap: Map<string, number> = new Map();
let fundingBaseSumMap: Map<string, number> = new Map();
let receiverSumMap: Map<string, number> = new Map();

const dataToSankeyDataModel = (
  result: TFundingFlowsResult,
  showAbsoluteVisualisation: boolean,
  summariseValues: boolean
): TSankeyChartModel => {
  fundingBaseColors = new Map();
  const duplicateData = Array.from(new Set(result.reduce(getDataModel, [])));

  const uniqueData = Array.from(
    new Map(duplicateData.map((v) => [JSON.stringify([v.name]), v])).values()
  );

  let res = getFundingFlowsLinks(
    result,
    fundingBaseColors,
    showAbsoluteVisualisation,
    summariseValues,
    uniqueData
  );
  payerSumMap = res.payerSumMap;
  receiverGroupSumMap = res.receiverSumGroupMap;
  receiverSumMap = res.receiverSumMap;
  fundingBaseSumMap = res.fundingBaseSumMap;

  return {
    data: res.uniqueData,
    links: [...res.linkModel],
    layoutIterations: summariseValues ? 0 : 32,
    right: "5%",
    itemStyle: {},
    label: {
      borderWidth: 0,
      color: Config.flows.sankey.flowsLabelColor,
    },
  } as any;
};

export interface IFlowsFundingQuery extends IBaseQuery, IAmountFilterRange {
  fundingType: number[];
  receiver: string[];
  receiverGroups: string[];
  showFundingBasis?: boolean;
  showAbsoluteValues?: boolean;
  summariseValues?: boolean;
  group_type?: string;
  mediaGroupType?: string;
}

export interface IFlowsFundingState
  extends IBaseState<IFlowsFundingQuery, TFundingFlowsResult>,
    IAmountRange<IFlowsFundingQuery, TFundingFlowsResult> {
  timeline: TTimelineFundingResult;
  timelineModel: ReturnType<typeof toFundingYearEChartsModel>;
  sankeyModel: ReturnType<typeof dataToSankeyDataModel>;
  timelineTabularData: { year: number; type: string; amount: number }[];
}

const initialState: IFlowsFundingState = {
  pristine: true,
  data: [] as TFundingFlowsResult,
  timeline: [] as any[] as TTimelineFundingResult,
  timelineModel: [],
  filteredData: [] as TFundingFlowsResult,
  sankeyModel: {} as TSankeyChartModel,
  amountRange: [0, 0],
  timelineTabularData: [],
  query: {
    fundingType: defaultFlowsFundingsModuleSettings.defaultFundingType,
    receiver: defaultFlowsFundingsModuleSettings.defaultReceivers,
    receiverGroups: [],
    showFundingBasis:
      defaultFlowsFundingsModuleSettings.defaultShowFundingBasis,
    group_type: "",
    summariseValues: false,
    showAbsoluteValues: true,
    amountFilterRange: [0, 0],
  } as IFlowsFundingQuery,
  pending: false,
  needsUpdate: false,
};

export const flowsFundingSlice = createSlice({
  name: "flowsFunding",
  initialState,
  reducers: {
    ...baseReducers<
      IFlowsFundingQuery,
      TFundingFlowsResult,
      IFlowsFundingState
    >(),
    ...amountRangeReducers<
      IFlowsFundingQuery,
      TFundingFlowsResult,
      IFlowsFundingState
    >(),
    setFlowFundingData: (
      state,
      action: PayloadAction<{
        data: TFundingFlowsResult;
        showAbsoluteValues: boolean;
        summariseValues: boolean;
      }>
    ) => {
      const {
        data,
        showAbsoluteValues: showAbsoluteVisualisation,
        summariseValues,
      } = action.payload;
      const amountRange = extractAmountRange(data);
      return {
        ...state,
        data: data,
        filteredData: data,
        query: { ...state.query, amountFilterRange: amountRange },
        amountRange: amountRange,
        needsUpdate: false,
        sankeyModel: dataToSankeyDataModel(
          data,
          showAbsoluteVisualisation,
          summariseValues
        ),
      };
    },
    updateSankeyModel: (state) => ({
      ...state,
      sankeyModel: dataToSankeyDataModel(
        state.filteredData,
        state.query.showAbsoluteValues ?? true,
        state.query.summariseValues ?? false
      ),
    }),
    setFundingTimeline: (
      state,
      action: PayloadAction<TTimelineFundingResult>
    ) => ({
      ...state,
      timeline: action.payload,
      timelineModel: toFundingYearEChartsModel(action.payload),
      timelineTabularData: action.payload.reduce(
        (acc, v) => [
          ...acc,
          ...v.yearsData.map((d) => ({
            year: v.year,
            type: d.name,
            amount: d.total,
          })),
        ],
        [] as { year: number; type: string; amount: number }[]
      ),
    }),
    setSummariseValues: (state, action: PayloadAction<boolean>) => {
      state.query.summariseValues = action.payload;
    },
    setPeriods: (
      state,
      action: PayloadAction<{ from: number; to: number }>
    ) => {
      const { from, to } = action.payload;
      return {
        ...state,
        query: {
          ...state.query,
          from: from,
          to: to,
        },
      };
    },
    saveQuery: (state, action: PayloadAction<void>) => ({
      ...state,
      lastQuery: state.query,
    }),
    setShowAbsoluteValues: (state, action: PayloadAction<boolean>) => {
      state.query.showAbsoluteValues = action.payload;
    },
    initializeStateFromSettings: (
      state,
      action: PayloadAction<FlowsFundingsModuleSettings>
    ) => {
      // Update the state with the initial values from the settings
      return {
        ...state,
        query: {
          ...state.query,
          fundingType: action.payload.defaultFundingType,
          receiver: action.payload.defaultReceivers,
          showFundingBasis: action.payload.defaultShowFundingBasis,
        },
      };
    },
  },
});

const {
  setFlowFundingData,
  setPending,
  setQuery,
  setPeriods,
  setNeedsUpdate,
  clearNeedsUpdate,
  setFundingTimeline,
  setSummariseValues,
  setShowAbsoluteValues,
  saveQuery,
  setAmountFilterRange,
  updateFilteredData,
  updateSankeyModel,
  initializeStateFromSettings,
} = flowsFundingSlice.actions;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const FlowsFunding = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const queryParams = useQuery();
  // @ts-ignore
  const location = useLocation();
  const { periodsFunding, groups, otherReceiverFundingEnabled, groupTypes } =
    useSelector<AppState, TInfoState>((state) => state.info);
  const { t, i18n } = useTranslation();
  const moduleSettings = useSettingsStore().modules.flows_fundings;
  const dispatch = useDispatch();
  const {
    pending,
    needsUpdate,
    data,
    query,
    timeline,
    timelineModel,
    sankeyModel,
    lastQuery,
    filteredData,
    amountRange,
    timelineTabularData,
  } = useSelector<AppState, IFlowsFundingState>((state) => state.flowFundings);

  const { tab } = useParams();
  const setTab = (newValue: string, updateRoute: boolean) => {
    if (tab != newValue && updateRoute && moduleSettings.enabled) {
      navigate(`/flows/fundings/${newValue}${location.search}`);
    }
    setUpdateRoute(false);
  };
  useEffect(() => {
    document["lastClick"] = Date.now();
  }, []);

  useEffect(() => {
    if (moduleSettings) {
      dispatch(initializeStateFromSettings(moduleSettings));
    }
  }, [moduleSettings, dispatch]);

  const handleClick = ({ data, componentIndex: selectedFundingType }) => {
    if (isTouchSupported()) {
      const now = Date.now();
      const lastClick = document["lastClick"];
      if (now - lastClick < 600) {
        dispatch(setQuery);
        return selectPeriod(data.period, [selectedFundingType]);
      } else {
        document["lastClick"] = now;
      }
    } else {
      return selectPeriod(data.year, [selectedFundingType]);
    }
  };

  const isMobileLandscape = useMediaQuery({
    maxHeight: 575.98,
    orientation: "landscape",
  });
  const isMobilePortrait = useMediaQuery({ maxWidth: 600 });
  const isMobile = isMobileLandscape || isMobilePortrait;

  const customToolTip = (params) => {
    const fmt = new Intl.NumberFormat(i18n.language, {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });
    let data = params.data;
    if (data.source) {
      let percentage = 0;
      let targetPercentage = 0;
      if (payerSumMap.has(data.source))
        percentage =
          (data.normalValue / (payerSumMap.get(data.source) as number)) * 100;
      else if (receiverGroupSumMap.has(data.source))
        percentage =
          (data.normalValue /
            (receiverGroupSumMap.get(data.source) as number)) *
          100;
      else if (fundingBaseSumMap.has(data.source))
        percentage =
          (data.normalValue / (fundingBaseSumMap.get(data.source) as number)) *
          100;

      if (payerSumMap.has(data.target))
        targetPercentage =
          (data.normalValue / (payerSumMap.get(data.target) as number)) * 100;
      else if (receiverGroupSumMap.has(data.target))
        targetPercentage =
          (data.normalValue /
            (receiverGroupSumMap.get(data.target) as number)) *
          100;
      else if (receiverSumMap.has(data.target))
        targetPercentage =
          (data.normalValue / (receiverSumMap.get(data.target) as number)) *
          100;
      else if (fundingBaseSumMap.has(data.target))
        targetPercentage =
          (data.normalValue / (fundingBaseSumMap.get(data.target) as number)) *
          100;

      return `
              <b>${data.source} ${
        percentage % 100 !== 0 ? "(" + percentage.toFixed(2) + "%)" : ""
      } ${isMobile ? "<br/>" : ""} → ${data.target}
              ${
                targetPercentage % 100 !== 0
                  ? "(" + targetPercentage.toFixed(2) + "%)"
                  : ""
              }</b><br />
              ${fmt.format(data.normalValue)}
        `;
    } else {
      let value = Math.pow(10, params.value);
      if (receiverGroupSumMap.has(params.name))
        value = receiverGroupSumMap.get(params.name) as number;
      if (fundingBaseSumMap.has(params.name))
        value = fundingBaseSumMap.get(params.name) as number;
      if (payerSumMap.has(params.name))
        value = payerSumMap.get(params.name) as number;
      if (receiverSumMap.has(params.name))
        value = receiverSumMap.get(params.name) as number;
      return `
              <b>${params.data.name}</b><br />
              ${fmt.format(value)}
        `;
    }
  };

  const sankeyOptions = useMemo(
    () =>
      defaultSankeyOptions(
        isMobile,
        customToolTip,
        t("flows_funding_title"),
        t("flows_funding_sub_title", {
          period:
            query.to !== query.from
              ? `${query.from ?? 0} - ${query.to ?? 0}`
              : query.to ?? 0,
          fundingTypes:
            query.fundingType.length > 0
              ? query.fundingType.map((e) => t(fundingTypes[e])).join(", ")
              : t("all"),
          receivers:
            query.receiver.length > 0 || (query.receiverGroups?.length ?? 0) > 0
              ? [...query.receiver, ...(query.receiverGroups ?? [])].join(", ")
              : t("all"),
        }) +
          `\n${getAmountRangeString(query, amountRange, i18n, t)}` +
          `\n${t("Source")}: KommAustria, ${t(
            "Date"
          )}: ${new Date().toLocaleString()}` +
          `\nLink: ${window.location.href}`,
        sankeyModel
      ),
    [t, sankeyModel, i18n.language]
  );

  const timelineTooltipFormatter = (params) => {
    params = params instanceof Array ? params : [params];
    const includesForecast = params.filter((e) => e.data.prediction).length > 0;
    const fmt = new Intl.NumberFormat(i18n.language, {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });
    let d = params
      .map(
        (p) =>
          `${p.marker} ${p.seriesName}${
            p.data["prediction"] ? "*" : ""
          } <span style="float:right;margin-left:20px;font-size:14px;color:${
            p.data["prediction"] ? "#aaa" : "#666"
          };font-weight:900">${fmt.format(
            p.data[p.dimensionNames[p.encode.y]]
          )}</span>`
      )
      .join("<br/>");
    if (params.length > 1) {
      if (!includesForecast) {
        const s = params
          .filter((e) => !e.data.prediction)
          .reduce((acc, p) => acc + p.data[p.dimensionNames[p.encode.y]], 0);
        d = `${d}<br/><span style="font-weight:900">${t(
          "Total"
        )}</span><span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${fmt.format(
          s
        )}</span>`;
      } else {
        const s = params.reduce(
          (acc, p) => acc + p.data[p.dimensionNames[p.encode.y]],
          0
        );
        d = `${d}<br/>${t(
          "Total"
        )}*<span style="float:right;margin-left:20px;font-size:14px;color:#aaa;font-weight:900">${fmt.format(
          s
        )}</span>`;
        d += `<br>*) ${t("Forecast")}`;
      }
    } else if (includesForecast) {
      d += `<br>*) ${t("Forecast")}`;
    }
    return `${params[0].name}<br/>${d}`;
  };

  const timelineOptions = useMemo(
    () => ({
      ...defaultBaseTimelineModel(
        isMobile,
        timelineTooltipFormatter,
        t("flows_funding_timeline_title"),
        t("flows_funding_timeline_sub_title", {
          fundingTypes:
            query.fundingType.length > 0
              ? query.fundingType.map((e) => t(fundingTypes[e])).join(", ")
              : t("all"),
          receivers:
            query.receiver.length > 0 || (query.receiverGroups?.length ?? 0) > 0
              ? [...query.receiver, ...(query.receiverGroups ?? [])].join(", ")
              : t("all"),
        }) +
          `\n${t("Source")}: KommAustria, ${t(
            "Date"
          )}: ${new Date().toLocaleString()}` +
          `\nLink: ${window.location.href}`,
        true
      ),
      xAxis: { type: "category" },
      yAxis: {
        axisLabel: {
          formatter: (value) => `${value / 1000000} Mio.`,
        },
      },
      dataset: [
        {
          dimensions: ["year", ...fundingTypesIndices()],
          source: timelineModel,
        },
      ],
      series: fundingTypes.map((ft, i) => ({
        type: "bar",
        name: t(ft),
        emphasis: { focus: "series" },
        color: getPFColor(i + 1, fundingTypes.length),
      })),
    }),
    [timelineModel, t, i18n.language]
  );

  useEffect(() => {
    if (periodsFunding && periodsFunding.length > 0) {
      const maxPeriodIndex = periodsFunding.length - 1;
      const maxPeriod = periodsFunding[maxPeriodIndex];
      if (!query.from || !query.to) {
        dispatch(
          setPeriods({
            from: maxPeriod,
            to: maxPeriod,
          })
        );
      } else {
        dispatch(setNeedsUpdate());
      }
    }
  }, [periodsFunding]);

  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      periodsFunding &&
      periodsFunding.length > 0 &&
      query.fundingType.length +
        query.receiver.length +
        (query.receiverGroups ?? []).length >
        0 &&
      query.from &&
      query.to
    ) {
      const queryString = objectToParams(query);
      const currentQueryStringObj = qs.parse(location.search);
      if (
        !isEqual(objectNormalize(currentQueryStringObj), objectNormalize(query))
      ) {
        navigate(`/flows/fundings/${tab}?${queryString}`);
      }
      dispatch(setNeedsUpdate());
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(updateFilteredData());
    dispatch(updateSankeyModel());
  }, [query.amountFilterRange]);

  useEffect(() => {
    let q = {
      fundingType: [0],
      receiver: [],
      receiverGroups: [],
    };
    ["receiver", "fundingType", "receiverGroups", "amountFilterRange"].forEach(
      (org) => {
        if (queryParams.getAll(org).length > 0) {
          q[org] = queryParams.getAll(org).filter((e) => e.length > 0);
        }
      }
    );
    ["showAbsoluteValues", "showFundingBasis", "summariseValues"].forEach(
      (org) => {
        if (queryParams.get(org)) {
          q[org] = queryParams.get(org) === "true";
        }
      }
    );
    if (queryParams.get("mediaGroupType")) {
      q["mediaGroupType"] = queryParams.get("mediaGroupType");
    }
    ["from", "to"].forEach((org) => {
      if (queryParams.get(org)) {
        q[org] = parseInt(queryParams.get(org) ?? "0");
      }
    });
    // TODO: add summariseValues and showFundingBasis to URL
    if (
      q.receiver.length +
        q.fundingType.length +
        (q.receiverGroups ?? []).length >
        0 &&
      q["from"] &&
      q["to"]
    ) {
      if (!isObjectSubset(q, query)) {
        dispatch(setQuery({ ...query, ...q }));
      }
    }
    //dispatch(setNeedsUpdate());
  }, [location]);

  useEffect(() => {
    if (
      needsUpdate &&
      query.fundingType.length +
        query.receiver.length +
        (query.receiverGroups ?? []).length >
        0 &&
      query.from &&
      query.to &&
      (lastQuery == undefined ||
        !isEqual(lastQuery, query, [
          "summariseValues",
          "showAbsoluteValues",
          "amountFilterRange",
        ]))
    ) {
      setError("");
      dispatch(saveQuery());
      dispatch(setPending(true));
      getFundingFlows(query, otherReceiverFundingEnabled)
        .then(({ flows, timeline }) => {
          dispatch(
            setFlowFundingData({
              data: flows,
              showAbsoluteValues: query.showAbsoluteValues ?? true,
              summariseValues: query.summariseValues ?? false,
            })
          );
          dispatch(setFundingTimeline(timeline));
        })
        .catch((err) => {
          setError(
            err?.response?.data
              ? JSON.stringify(err.response.data)
              : err.message
          );
          dispatch(
            setFlowFundingData({
              data: [],
              showAbsoluteValues: query.showAbsoluteValues ?? true,
              summariseValues: query.summariseValues ?? false,
            })
          );
        })
        .finally(() => {
          dispatch(setPending(false));
          dispatch(clearNeedsUpdate());
        });
    } else if (needsUpdate) {
      dispatch(clearNeedsUpdate());
    }
  }, [needsUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo(
    () => [
      {
        name: t("PF_Type"),
        selector: (row) => row["fundingType"],
        sortable: true,
        hide: Media.MD,
      },
      {
        name: t("Beneficiary"),
        selector: (row) => row["receiver"],
        sortable: true,
      },
      ...(query.mediaGroupType
        ? [
            {
              name: t("Media Group"),
              selector: (row) => row["mediaGroup"],
              sortable: true,
              hide: Media.MD,
            },
          ]
        : []),
      {
        name: t("Fundingbasis"),
        selector: (row) => row["fundingBasis"],
        sortable: true,
        hide: Media.MD,
      },
      /* {
            name: t("Payment type"),
            selector: row => row["transferType"],
            sortable: true,
            format: toPaymentTypeText('transferType'),
            width: "10em"
        },  */ {
        name: t("Amount"),
        selector: (row) => row["amount"],
        sortable: true,
        format: toCurrency("amount"),
        right: true,
      },
    ],
    [t, i18n.language, query.mediaGroupType]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const updateSelection =
    (orgType: "receiver" | "receiverGroups") => (list: string[]) => {
      setError("");
      dispatch(
        setQuery({
          ...query,
          [keysToNames[orgType]]: list.filter((v) => v.length > 0),
        })
      );
    };

  const selectPeriod = (period, selectedFundingType) => {
    dispatch(
      setQuery({
        ...query,
        fundingType: selectedFundingType,
        from: period,
        to: period,
      })
    );
    setUpdateRoute(false);
    setActiveTab("flows");
  };

  const updateFundingType = (list: string[]) => {
    setError("");
    dispatch(
      setQuery({
        ...query,
        fundingType:
          list.length > 0
            ? list
                .filter((v) => v.length > 0)
                .map((x) => fundingTypes.indexOf(x))
            : fundingTypes.map((x) => fundingTypes.indexOf(x)),
      })
    );
    //dispatch(setNeedsUpdate())
  };

  const onChartMouseMove = () => {
    const echartInstance = echartRef.getEchartsInstance();
    echartInstance.getZr().setCursorStyle("pointer");
  };

  const onChartClick = (params) => {
    if (
      params.data.source?.includes("Andere") ||
      params.data.name?.includes("Andere") ||
      params.data.target?.includes("Andere")
    )
      return;

    const fundingBaseName = params.data.name ?? params.data.source;
    if (fundingBaseTypes.includes(fundingBaseName)) {
      const link = sankeyOptions.series.links.find(
        (it) => it.target === fundingBaseName
      );
      if (link) {
        if (receiverGroups.includes(params.data.target)) {
          dispatch(
            setQuery({
              ...query,
              receiverGroups: addElementIfNotExist(
                query.receiverGroups,
                params.data.target
              ),
              receiver: [],
              fundingType: [getFundingValueFromName(link.source)],
            })
          );
        } else if (receivers.includes(params.data.target)) {
          dispatch(
            setQuery({
              ...query,
              receiverGroups: [],
              receiver: addElementIfNotExist(
                query.receiver,
                params.data.target
              ),
              fundingType: [getFundingValueFromName(link.source)],
            })
          );
        } else {
          dispatch(
            setQuery({
              ...query,
              receiverGroups: [],
              receiver: [],
              fundingType: [getFundingValueFromName(link.source)],
            })
          );
        }
      }
    } else if (params.data.name && pfTypes.includes(params.data.name)) {
      const nam = params.data.name ? params.data.name : params.data.source;
      dispatch(
        setQuery({
          ...query,
          fundingType: [getFundingValueFromName(nam)],
          receiverGroups: [],
          receiver: [],
        })
      );
    } else if (params.data.source && pfTypes.includes(params.data.source)) {
      const rec = params.data.target;
      const ft = params.data.source;
      if (receiverGroups.includes(rec)) {
        dispatch(
          setQuery({
            ...query,
            receiverGroups: addElementIfNotExist(query.receiverGroups, rec),
            receiver: [],
            fundingType: [getFundingValueFromName(ft)],
          })
        );
      } else if (receivers.includes(rec)) {
        dispatch(
          setQuery({
            ...query,
            receiver: addElementIfNotExist(query.receiver, rec),
            receiverGroups: [],
            fundingType: [getFundingValueFromName(ft)],
          })
        );
      }
    } else {
      const nam = params.data.name ? params.data.name : params.data.target;
      if (receiverGroups.includes(nam)) {
        dispatch(
          setQuery({
            ...query,
            receiverGroups: addElementIfNotExist(query.receiverGroups, nam),
            receiver: [],
            fundingType: fundingTypeIds,
          })
        );
      } else if (receivers.includes(nam)) {
        dispatch(
          setQuery({
            ...query,
            receiver: addElementIfNotExist(query.receiver, nam),
            receiverGroups: [],
            fundingType: fundingTypeIds,
          })
        );
      }
    }
  };

  const onEvents = {
    click: onChartClick,
    mousemove: onChartMouseMove,
  };

  const touchDoubleClickChecker = useTouchDoubleClickChecker();

  const [touchEchartsEvents] = useState({
    click: (event) => {
      touchDoubleClickChecker(
        () => onChartClick(event),
        () => {},
        250
      );
    },
    dblclick: onChartClick,
  });

  const Settings = () => {
    return (
      <div className="settings" data-test-id="flowFundingsSettings">
        <Grid container spacing={3} data-test-id="flowFlundingSettings">
          <Grid item xs={12} lg={4} md={6}>
            <MultiSelectComp
              options={fundingTypes}
              value={query.fundingType.map((x) => fundingTypes[x])}
              placeholder={t("Select a funding type")}
              label={t("Funding type")}
              onChange={(newValue) => updateFundingType(newValue)}
              getOptionLabel={(option) => t(option)}
            />
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <SwitchButton
              simple={true}
              selValue={!query.showAbsoluteValues ?? false}
              onlabel={
                <span>
                  {t("Logarithmic scale")} <Help text="scale_help" />
                </span>
              }
              offlabel={
                <span>
                  {t("Logarithmic scale")} <Help text={"scale_help"} />
                </span>
              }
              onIconD={Config.switchButton.icons.numbersIconD}
              offIconD={Config.switchButton.icons.percentIconD}
              onChange={(checked: boolean) => {
                dispatch(setShowAbsoluteValues(!checked));
                dispatch(
                  setFlowFundingData({
                    data: data,
                    showAbsoluteValues: !checked,
                    summariseValues: query.summariseValues ?? false,
                  })
                );
              }}
            ></SwitchButton>

            <SwitchButton
              simple={true}
              selValue={query.showFundingBasis ?? false}
              label={
                <span>
                  {t("show_details_funding")}{" "}
                  <Help text={t("show_details_funding_help")} />
                </span>
              }
              onIconD={Config.switchButton.icons.visibilityIconD}
              offIconD={Config.switchButton.icons.visibilityHideIconD}
              onChange={(checked: boolean) => {
                dispatch(setSummariseValues(false));
                dispatch(
                  setQuery({
                    ...query,
                    showFundingBasis: checked,
                  })
                );
              }}
            ></SwitchButton>
            <Render when={!query.showFundingBasis}>
              <SwitchButton
                simple={true}
                selValue={query.summariseValues ?? false}
                onlabel={
                  <span>
                    {t("summarise_values")}{" "}
                    <Help
                      translatedText={
                        Config.flows.summarise.minAmountOfFlows
                          ? t("summarise_values_percent_minAmount", {
                              percent: (
                                Config.flows.summarise.percentThreshold * 100
                              ).toString(),
                              minAmount:
                                Config.flows.summarise.minAmountOfFlows.toString(),
                            })
                          : t("summarise_values_percent", {
                              percent: (
                                Config.flows.summarise.percentThreshold * 100
                              ).toString(),
                            })
                      }
                    />
                  </span>
                }
                offlabel={
                  <span>
                    {t("summarise_values")}{" "}
                    <Help
                      translatedText={
                        Config.flows.summarise.minAmountOfFlows
                          ? t("summarise_values_percent_minAmount", {
                              percent: (
                                Config.flows.summarise.percentThreshold * 100
                              ).toString(),
                              minAmount:
                                Config.flows.summarise.minAmountOfFlows.toString(),
                            })
                          : t("summarise_values_percent", {
                              percent: (
                                Config.flows.summarise.percentThreshold * 100
                              ).toString(),
                            })
                      }
                    />
                  </span>
                }
                onChange={(checked: boolean) => {
                  dispatch(setSummariseValues(checked));
                  dispatch(
                    setFlowFundingData({
                      data: data,
                      showAbsoluteValues: query.showAbsoluteValues ?? true,
                      summariseValues: checked,
                    })
                  );
                }}
              ></SwitchButton>
            </Render>
          </Grid>
          <Grid item xs={12} lg={4} md={6}>
            <TransferMultiSelectComp
              value={query.receiver}
              orgType="media"
              placeholder={t("Select a Beneficiary")}
              label={t("Beneficiaries")}
              searchNames={searchFundingNames}
              onChange={(e) => {
                updateSelection("receiver")(e);
              }}
            />
            <GroupTypeSelector
              id="select-group-type-org-funding-flow"
              value={query.mediaGroupType}
              availableGroupTypes={groupTypes}
              type="media"
              onChange={(e) => {
                dispatch(
                  setQuery({
                    ...query,
                    receiverGroups: [],
                    mediaGroupType: e.target.value,
                  })
                );
              }}
            />
            <Render
              when={
                query.mediaGroupType != undefined &&
                query.mediaGroupType.length > 0
              }
            >
              <MultiSelectComp
                options={groups
                  .filter(
                    (g) =>
                      g.type === "media" &&
                      g.group_type === query.mediaGroupType
                  )
                  .map((g) => g.name)
                  .sort((a, b) => a.localeCompare(b))}
                value={query.receiverGroups}
                placeholder={t("Select a Group")}
                label={t("Groups")}
                getOptionLabel={(option) => option}
                onChange={(newValue) =>
                  updateSelection("receiverGroups")(newValue)
                  
                }
              />
            </Render>
          </Grid>
          <Grid item xs={12}>
            <PeriodSlider
              label={t("Period")}
              startPeriod={query.from ?? 0}
              endPeriod={query.to ?? 0}
              step={1}
              onChange={(start, end) =>
                dispatch(setQuery({ ...query, from: start, to: end }))
              }
              periods={periodsFunding}
            ></PeriodSlider>
            <AmountSlider
              label={t("payment_range_per_detail")}
              availableAmounts={amountRange}
              value={query.amountFilterRange ?? [0, 0]}
              onChange={(range) => dispatch(setAmountFilterRange(range))}
            />
          </Grid>
        </Grid>
      </div>
    );
  };
  const [activeTab, setActiveTab] = useState(tab ?? "flows");
  const [updateRoute, setUpdateRoute] = useState(true);

  useEffect(() => {
    setTab(activeTab, updateRoute);
  }, [activeTab, updateRoute]);

  const handleTabChange = (_, newTab) => {
    setActiveTab(newTab);
  };

  const timelineColums = useMemo(
    () => [
      {
        name: t("Year"),
        selector: (row) => row["year"],
        sortable: true,
      },
      {
        name: t("Type"),
        selector: (row) => row["type"],
        sortable: true,
      },
      {
        name: t("Amount"),
        selector: (row) => row["amount"],
        sortable: true,
        format: toCurrency("amount"),
        right: true,
      },
    ],
    [t]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ShowError error={error} onClose={() => setError("")} />
      <ModalLoader isPending={pending} />

      {/*
                    <pre>{JSON.stringify(periods,null,2)}</pre>
                    <pre>{JSON.stringify(periods,null,2)}</pre>
                    <pre>{startPeriod} - {endPeriod}</pre>
                    */}
      <div className={isMobile ? "flows-mobile-settings" : "flows-settings"}>
        <SettingsViewer id="transfer-flows" fold={true} open={!isMobile}>
          <Settings />
        </SettingsViewer>
      </div>
      <Render when={data?.length === 0}>
        <Alert severity="warning">
          {t("No Data found that matches your settings")}
        </Alert>
      </Render>
      <TabsMui
        value={activeTab}
        onChange={handleTabChange}
        orientation={isMobilePortrait ? "vertical" : "horizontal"}
        centered
      >
        <TabMui
          label={
            <span>
              <FontAwesomeIcon icon={faStream} /> {t("Flows")}
            </span>
          }
          value="flows"
        />
        <TabMui
          value="timeline"
          label={
            <span>
              <FontAwesomeIcon icon={faChartLine} /> {t("Timeline")}
            </span>
          }
        />
      </TabsMui>
      {/*<pre>{JSON.stringify(query,null,2)}</pre>*/}
      <Render when={activeTab === "flows" && data?.length > 0 && !error}>
        <div className="text-end info">
          <FontAwesomeIcon icon={faInfoCircle} />{" "}
          {t(
            `${
              isTouchSupported() ? "Double click" : "Click"
            } on the chart to get more information`
          )}
        </div>
        <ResponsiveContainer
          width="100%"
          minHeight={350}
          height={
            data.length * 20 < 1000
              ? 1000
              : sankeyOptions.series.links.length * 20
          }
        >
          <ReactECharts
            option={sankeyOptions}
            ref={(e) => {
              echartRef = e;
            }}
            opts={{ locale: i18n.language.split("-")[0] }}
            style={{ height: "100%", width: "100%" }}
            onEvents={isTouchSupported() ? touchEchartsEvents : onEvents}
          />
        </ResponsiveContainer>
        {/* <div className="datatable-outer-container"> */}
        <Fold
          id={`flows-transfer-table`}
          header={t("Raw Data")}
          fold={true}
          open={false}
          icon={<></>}
        >
          <DataTable
            title={t("Money Flows")}
            pagination={true}
            paginationComponentOptions={getTranslatedPaginationOptions(t)}
            columns={columns}
            data={filteredData}
            actions={
              <ExportToExcel
                data={data}
                columns={columns}
                fileName="MoneyFlow"
              />
            }
          />
        </Fold>
        {/* </div> */}
      </Render>
      <Render when={activeTab === "timeline" && timeline?.length > 0 && !error}>
        <div className="text-end info">
          <FontAwesomeIcon icon={faInfoCircle} />{" "}
          {t(
            `${
              isTouchSupported() ? "Double click" : "Click"
            } on the chart to get more information`
          )}
        </div>
        <ReactECharts
          option={timelineOptions}
          onEvents={{ click: handleClick }}
          style={{ height: 450 }}
          opts={{ locale: i18n.language.split("-")[0] }}
        />
        <Fold
          id={`flows-transfer-timeline-table`}
          header={t("Raw Data")}
          fold={true}
          open={false}
          icon={<></>}
        >
          <DataTable
            title={t("flows_funding_timeline_title")}
            pagination={true}
            paginationComponentOptions={getTranslatedPaginationOptions(t)}
            columns={timelineColums}
            data={timelineTabularData}
            actions={
              <ExportToExcel
                data={timelineTabularData}
                columns={timelineColums}
                fileName="MoneyFlow"
              />
            }
          />
        </Fold>
      </Render>
    </>
  );
};
