
import Config from "../config/settings";
import { getOtherMediaName, getOtherMediaNameForMedia, getOtherOrgName, getOtherOrgNameForOrg } from "../pages/flows/flows-transfers";
import { partition } from "./helpers";

export type SummerizeOptions = {
  percentThreshold: number;
  minAmountOfFlows: number;
}


export const summariseTransfers = (links: any[], rawNodes: {}, nodes: any[],
  payerSumMap: Map<string, number>, receiverSumMap: Map<string, number>,
  { percentThreshold, minAmountOfFlows }: SummerizeOptions) => {
  const [mediaNodesToReduce, noneMediaNodes] = partition(nodes.map(n => ({ ...n, value: payerSumMap.get(n.name) || receiverSumMap.get(n.name) || 0 }))
    .sort((a, b) => b.value - a.value), (n => n.type === 'media'))
  const totalSum = mediaNodesToReduce.reduce((acc, n) => acc + n.value, 0);
  const minNodes = mediaNodesToReduce.slice(0, minAmountOfFlows);
  const [mediaNodesToRemove, mediaNodesToKeepPercentage] = partition(mediaNodesToReduce.slice(minAmountOfFlows), n => n.value / totalSum < percentThreshold);
  const mediaNodesToKeep = mediaNodesToKeepPercentage.concat(minNodes);
  const mediaNamesMap = mediaNodesToRemove.reduce((acc, n) => ({
    ...acc, [n.name]: true
  }), {});
  const [linksToReduce, linksToKeep] = partition(links, l => mediaNamesMap[l.target] && l.targetType === 'media');

  console.log('otherMedias_', linksToReduce, linksToKeep);
  const otherMedias_ = linksToReduce.reduce((acc, l) => {
    const otherIndex = acc.findIndex(i => i.target === getOtherMediaNameForMedia(l.source)
      && i.source === l.source);
    if (otherIndex > -1) {
      acc[otherIndex].value += l.value;
      acc[otherIndex].absolute += l.absolute;
    } else {
      l.lineStyle.color = Config.flows.sankey.othersFlowColor;
      acc.push({
        ...l,
        target: getOtherMediaNameForMedia(l.source),
      });
    }
    return acc;
  }
    , [] as any[]);
  const otherNamePrefix = getOtherMediaName();
  const otherMediaNodes = otherMedias_.filter(({ target }) => target.startsWith(otherNamePrefix)).map(n => {
    return {
      type: n.targetType, name: n.target, label: { position: 'insideRight', offset: [-20, 0] }, itemStyle: {
        color: "rgb(81, 146, 202)",
        borderColor: "rgb(81, 146, 202)",
      }
    };
  })
  const updatedLinks = linksToKeep.concat(otherMedias_)
  const updatedNodes = mediaNodesToKeep.concat(noneMediaNodes).concat(otherMediaNodes)
  const [orgNodesToReduce, noneOrgNodes] = partition(updatedNodes.map(n => ({ ...n, value: payerSumMap.get(n.name) || receiverSumMap.get(n.name) || 0 }))
    .sort((a, b) => b.value - a.value), (n => n.type === 'org'))
  const minOrgNodes = orgNodesToReduce.slice(0, minAmountOfFlows);
  const [orgNodesToRemove, orgNodesToKeepPercentage] = partition(orgNodesToReduce.slice(minAmountOfFlows), n => n.value / totalSum < percentThreshold);
  const orgNodesToKeep = orgNodesToKeepPercentage.concat(minOrgNodes);
  const orgNamesMap = orgNodesToRemove.reduce((acc, n) => ({
    ...acc, [n.name]: true
  }), {});
  const [linksToReduceOrg, linksToKeepOrg] = partition(updatedLinks, l => orgNamesMap[l.source] && l.srcType === 'org');
  const otherOrgs_ = linksToReduceOrg.reduce((acc, l) => {
    const otherIndex = acc.findIndex(i => i.source === getOtherOrgNameForOrg(l.target));
    if (otherIndex > -1) {
      acc[otherIndex].value += l.value;
      acc[otherIndex].absolute += l.absolute;
    } else {
      l.lineStyle.color = Config.flows.sankey.othersFlowColor;
      acc.push({
        ...l,
        source: getOtherOrgNameForOrg(l.target),
      });
    }
    return acc;
  }
    , [] as any[]);
  const otherOrgNodes = otherOrgs_.filter(({ source }) => source.startsWith(getOtherOrgName())).map(n => {
    return {
      type: n.sourceType, name: n.source, label: {}, itemStyle: {
        color: "rgb(81, 146, 202)",
        borderColor: "rgb(81, 146, 202)",
      },
      //value: Math.log10(n.absolute)
    };
  })

    return {
      sumLinks: linksToKeepOrg.concat(otherOrgs_),
      sumNodes: orgNodesToKeep.concat(noneOrgNodes).concat(otherOrgNodes)
    }
}