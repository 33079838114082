import { Checkbox, Tooltip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PermMediaIcon from '@mui/icons-material/PermMedia';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CreditCardIcon from '@mui/icons-material/CreditCard';

export type GroupMemberOption = {
    name: string;
    type: 'mediaOwner' | 'media' | 'org';
}

export type GroupMemberSelectorOptions = {
    onSearch: (name: string) => Promise<GroupMemberOption[]>;
    existingMembers: GroupMemberOption[];
    onChange: (v: GroupMemberOption[]) => void;
}

export const icons = {
    media: <PermMediaIcon fontSize="small" color="primary" />,
    mediaOwner: <ApartmentIcon fontSize="small" color="secondary" />,
    org: <CreditCardIcon fontSize="small" color="primary" />
}

export const GroupMemberSelector = ({
    onSearch,
    existingMembers,
    onChange
}: GroupMemberSelectorOptions) => {

    const selectedMembers = useMemo<GroupMemberOption[]>(() => [], []);
    const [options, setOptions] = useState<GroupMemberOption[]>([]);
    const [filteredOptions, setFilteredOptions] = useState<GroupMemberOption[]>([]);
    const { t } = useTranslation()

    const updateOptions = async (
        newName: string
    ) => {

        if (newName.length === 3) {

            const results = await onSearch(newName);
            setOptions(results
                .filter(opt => !existingMembers.map(({ name, type }) => `${name} - ${type}`).includes(`${opt.name} - ${opt.type}`))
            )
        }
        if (newName.length > 3) {
            setFilteredOptions(options.filter(opt => opt.name.toLowerCase().includes(newName.toLowerCase())));
        }
        if (newName.length < 3) {
            setOptions([]);
            setFilteredOptions([]);
        }
    };

    return (
        <Autocomplete
            multiple
            disableCloseOnSelect={true}
            noOptionsText={t("Start typing")}
            options={filteredOptions}
            value={selectedMembers}
            fullWidth={true}
            getOptionLabel={(v) => v.name}
            renderInput={(params) => <TextField {...params}
                InputLabelProps={params.InputLabelProps as any}
                label={<span>{t("Add")}</span>} />}
            getOptionKey={(v) => `${v.name}-${v.type}`}
            onChange={(_, newValue) => {
                onChange(newValue)
                  const newFilteredOptions = filteredOptions.filter(opt => !newValue.map(({ name, type }) => `${name} - ${type}`).includes(`${opt.name} - ${opt.type}`))
                setFilteredOptions(newFilteredOptions)
            }
            }
            onInputChange={
                (_, name, reason) => {
  
                    if (reason === "input") {
                        updateOptions(name)
                    }
                }
            }
            //onOpen={() => setIsDropdownOpen(true)}
            onClose={() => {
                setOptions([])
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name && option.type === value.type}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    <Tooltip title={t(option.type === "media" ? "medium" : option.type)} arrow >
                        {icons[option.type]}
                    </Tooltip>
                    &nbsp;
                    {option.name}
                </li>
            )}
        />
    )
}    