import { useTranslation } from "react-i18next";
import { AdvertisingDocument } from "../../../../server/models/advertisings";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdvertisings, getEventsFrom, objectToParams } from "../../services/data-service";
import { Box, Button, Card, CardHeader, CardContent, IconButton, Grid } from "@mui/material";
import { TableColumn } from "react-data-table-component-with-filter";
import { ModalLoader, Render, ShowError } from "../../components/helper-components";
import { AuthContext } from "../../context/auth-context";
import ChecklistIcon from '@mui/icons-material/Checklist';
import { AppState } from "../..";
import { infoSlice } from "../../App";
import { getTranslatedPaginationOptions, isEqual, isObjectSubset, toCurrency } from "../../helpers/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { SujetDialog } from "../../components/dialog/SujetDialog";
import { CustomDataTable } from "../../components/data-table/DataTable";
import { MimeTypeIcon } from "../../components/MimeTypeIcon";
import { AdvertisingsDetails } from "../../components/ads/advertisings/AdvertisingsDetails";
import qs from "query-string";
import { Hint } from "../../components/info";

interface SujetQuery {
  organisation: string;
  fileName: string;
  mimeType: string;
}

interface StoredQuery extends SujetQuery {
  sortOrder: 'desc' | 'asc';
  sortBy: keyof AdvertisingDocument | null;
  size: number;
  page: number;
}

interface IAdvertisingState {
  adsList: AdvertisingDocument[];
  error?: string;
  pending: boolean;
  pristine: boolean;
  //message?: string;
  query: SujetQuery;
  storedQuery: StoredQuery;
  sortBy: keyof AdvertisingDocument | null;
  sortOrder: 'desc' | 'asc';
  page: number;
  size: number;
  count: number;
  needsUpdate: boolean;
}

type FilterParams = Record<string, {
  column: TableColumn<AdvertisingDocument>;
  value: string;
}>

const initialState: IAdvertisingState = {
  adsList: [],
  pending: false,
  pristine: true,
  needsUpdate: true,
  sortBy: null,
  sortOrder: 'asc',
  page: 1,
  size: 10,
  count: 0,
  query: {} as SujetQuery,
  storedQuery: {} as StoredQuery
}

export const sujetReducers = {
  setPending: (state: any, action: PayloadAction<boolean>) => {
    state.pending = action.payload;
  },
  setTouched: (state: any) => {
    state.pristine = false;
},
  setError: (state: any, action: PayloadAction<string>) => {
    state.error = action.payload;
  },
  setAdsList: (state: any, action: PayloadAction<AdvertisingDocument[]>) => {
    state.adsList = action.payload;
    state.needsUpdate = false;
  },
  setQuery: (state: any, action: PayloadAction<SujetQuery>) => {
    state.query = action.payload;
  },
  setCount: (state: any, action: PayloadAction<number>) => {
    state.count = action.payload;
  },
  setPage: (state: any, action: PayloadAction<number>) => {
    state.needsUpdate = true;
    state.page = action.payload;
  },
  setSize: (state: any, action: PayloadAction<number>) => {
    state.needsUpdate = true;
    state.size = action.payload;
  },
  setNeedsUpdate: (state: any, action: PayloadAction<boolean>) => {
    state.needsUpdate = action.payload;
  },
  setStoredQuery: (state: any, action: PayloadAction<StoredQuery>) => {
    state.storedQuery = action.payload;
  },
  setSortBy: (state: any, action: PayloadAction<string>) => {
    state.needsUpdate = true;
    state.sortBy = action.payload;
  },
  setSortOrder: (state: any, action: PayloadAction<'desc' | 'asc'>) => {
    state.needsUpdate = true;
    state.sortOrder = action.payload;
  },
  setInitialQuery: (state: any, action: PayloadAction<StoredQuery>) => {
    const { sortBy, sortOrder, page, size, ...query } = action.payload;
    return {
      ...state,
      query: { ...query, },
      sortBy: sortBy,
      sortOrder: sortOrder,
      page: page,
      size: size,
    };
  },
}

export const sujetSlice = createSlice({
  name: 'sujets',
  initialState,
  reducers: sujetReducers
});

const { setNeedsUpdate, setPending, setError,
  setAdsList, setQuery, setCount, setSortBy, setSortOrder,
  setPage, setSize, setStoredQuery, setInitialQuery } = sujetSlice.actions;
const { setMessage } = infoSlice.actions

const getFilterSelector = (name: string) => {
  const selector = (elem: "input" | "select") => `.rdt_Table .rdt_TableHeadRow ${elem}[data-filter-id][name='${name}']`
  const inputElem = document.querySelector<HTMLInputElement>(selector("input"))
  const dropdownElem = document.querySelector<HTMLSelectElement>(selector("select"))
  return inputElem ?? dropdownElem
}

const updateFilter = (name: string, filterValue: string) => {
  const filterElem = getFilterSelector(name)
  // console.warn("Setting filterElem", filterElem, name, filterValue)
  if (filterElem && filterElem.value !== (filterValue || "")) {
    filterElem.value = filterValue || ""
    filterElem.dispatchEvent(new Event('change'))
  }
}

const getFilterValue = (name: string) => {
  const filterElem = getFilterSelector(name)

  if (filterElem && filterElem.value) {
    return filterElem.value
  }
  return null
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const Advertisings = () => {

  const { needsUpdate, adsList, pending, storedQuery, sortBy, sortOrder,
    query, page, size, count, error } = useSelector((state: AppState) => state.sujets);
  const { hasRole } = useContext(AuthContext);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useQuery();

  const [pristine, setPristine] = useState(true);

  const columns = useMemo<TableColumn<AdvertisingDocument>[]>(() => [
    {
      name: t('organisation'),
      selector: row => row['organisation'],
      sortable: true,
      filterable: true,
      wrap: true,
      width: '35%',
    },
    {
      name: t('fileName'),
      selector: row => row['fileName'],
      sortable: true,
      filterable: true,
      wrap: true,
      width: '28%',
      cell: (row) => (
        <span>
          {/* <MimeTypeIcon mimeType={row.mimeType} height={16} />{' '} */}
          {row.fileName}
        </span>
      )
    },
    {
      name: t('amount'),
      selector: row => row['amount'],
      sortable: true,
      filterable: false,
      format: toCurrency("amount"),
      right: true,
      width: '15%'
    },
    {
      name: t('mimeType'),
      selector: row => row['mimeType'],
      sortable: true,
      filterable: true,
      filterValues: [
        { label: t('Image'), value: 'image'},
        { label: 'Video', value: 'video/mp4'},
        { label: 'PDF', value: 'application/pdf'},
        { label: 'Audio', value: 'audio/mpeg'}],
      center: true,
      width: '8%',
      cell: (row) =>
        <IconButton color="primary" onClick={() => setSelectedSujet(row)}>
          <MimeTypeIcon mimeType={row.mimeType} height={24} />
        </IconButton>
    },
    {
      name: t('usages'),
      selector: row => row['usages'],
      sortable: true,
      filterable: false,
      width: '8%',
    },
  ], [t, i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

  const namesToColumns = useMemo(() => ({
    [t('organisation')]: 'organisation',
    [t('fileName')]: 'fileName',
    [t('category')]: 'category',
    [t('subcategory')]: 'subcategory',
    [t('amount')]: 'amount',
    [t('mimeType')]: 'mimeType',
    [t('ads')]: 'ads',
    [t('usages')]: 'usages'
  }), [columns, t]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.warn("query", pristine, query, sortOrder, sortBy, size, page, location.search)

    const initialQuery = {
      organisation: "",
      fileName: "",
      mimeType: "",
    }
    const queryObj = qs.parse(location.search);
    ["organisation", "fileName", "mimeType"].forEach((param) => {
      if (queryObj[param]) {
        initialQuery[param] = queryObj[param];
      }
    });

    // const queryString = objectToParams(query);
    const newQuery = Object.fromEntries(
      Object.entries({ ...(!pristine ? query : initialQuery), sortOrder, sortBy, size, page })
        .filter(([_, value]) => value)
    );

    const queryString = objectToParams(newQuery);
    const currentQueryStringObj = qs.parse(location.search);
    const queryStringObjFromQueryObject = qs.parse(queryString);
    console.log(
      "isEqual: " +
      isEqual(currentQueryStringObj, queryStringObjFromQueryObject)
    );

    if (!isEqual(currentQueryStringObj, qs.parse(queryString))) {
      navigate(`/ads/sujets?${queryString}`, {
        replace: Object.keys(currentQueryStringObj).length !== Object.keys(queryStringObjFromQueryObject).length,
      });
    }

    dispatch(setNeedsUpdate(true));
  }, [query, sortOrder, sortBy, size, page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.warn("location", location.search, location.key)

    let q = {
      organisation: "",
      fileName: "",
      mimeType: "",
    };
    // TODO: special handling for mimeType
    ["organisation", "fileName", "mimeType"].forEach((param) => {
      if (queryParams.get(param)) {
        q[param] = queryParams.get(param);
      }
    });

    // console.warn("location subset", !isObjectSubset(q, query), q, query)
    if (!isObjectSubset(q, query)) {
      // updateFilter(t('organisation'), q?.organisation ?? "")
      // updateFilter(t('fileName'), q?.fileName ?? "")
      // updateFilter(t('mimeType'), q?.mimeType ?? "")
      dispatch(setQuery(q))
      dispatch(setNeedsUpdate(true));
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.warn("needsUpdate",
    //   needsUpdate, adsList.length, storedQuery, query, sortOrder, sortBy, size, page,
    //   isEqual(storedQuery, { ...query, sortOrder, sortBy, size, page }, [])
    // )

    if (adsList.length === 0 || needsUpdate) {

      if (adsList.length > 0 && isEqual(storedQuery, { ...query, sortOrder, sortBy, size, page }, [])) {
        dispatch(setNeedsUpdate(false))
        return
      }

      const initialQuery = {} as SujetQuery
      const queryObj = qs.parse(location.search);
      // TODO: special handling for mimeType
      ["organisation", "fileName", "mimeType"].forEach((param) => {
        if (queryObj[param]) {
          initialQuery[param] = queryObj[param];
        }
      });

      const newQuery = pristine && location.search.length > 0 ? initialQuery : query

      // console.warn("needsUpdate newQuery", pristine, location.search.length > 0, newQuery, query, storedQuery)

      dispatch(setPending(true))
      getAdvertisings(page, size, sortBy ?? '', sortOrder, newQuery)
        .then(({ data, count }) => {
          dispatch(setCount(count))
          dispatch(setAdsList(data))
        }).then(() => {
          dispatch(setStoredQuery({ ...newQuery, sortOrder, sortBy, size, page }))
          // update filters
          setTimeout(() => {
            updateFilter(t('organisation'), newQuery.organisation)
            updateFilter(t('fileName'), newQuery.fileName)
            updateFilter(t('mimeType'), newQuery.mimeType)
          });

          if (pristine) {
            dispatch(setQuery({ ...newQuery }))
          }
        })
        .catch(err => {
          err.message ? setError(err.message) : setError(err)
        })
        .finally(() => {
          dispatch(setPending(false))
          dispatch(setNeedsUpdate(false))
        })
    }
  }, [needsUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.warn("initialQuery", location.search, pristine)

    // Fill the initial state with the default values from the settings
    const initialQuery: StoredQuery = {
      sortBy: null,
      sortOrder: 'asc',
      page: 1,
      size: 10,
      organisation: "",
      fileName: "",
      mimeType: "",
    }

    if (pristine && location.search.length === 0) {
      dispatch(setInitialQuery(initialQuery));
    } else if (pristine && location.search.length > 0) {
      // Fill the initial state with the values from the query string
      const queryObj = qs.parse(location.search);

      // TODO: special handling for mimeType
      ["organisation", "fileName", "mimeType"].forEach((param) => {
        if (queryObj[param]) {
          initialQuery[param] = queryObj[param];
        }
      });
      const sortByKeys = ["organisation", "amount", "fileName", "mimeType", "sujet"];
      if (queryObj["sortBy"] && sortByKeys.includes(queryObj["sortBy"] as string)) {
        initialQuery.sortBy = queryObj["sortBy"] as keyof AdvertisingDocument;
      }
      if (queryObj["sortOrder"] === "desc") {
        initialQuery.sortOrder = "desc";
      }
      ["page", "size"].forEach((param) => {
        const paramNumber = Number(queryObj[param] as string)
        if (paramNumber) {
          initialQuery[param] = paramNumber;
        }
      })

      dispatch(setInitialQuery(initialQuery));
    }
    // dispatch(setTouched());
    setPristine(false)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateAdvertisings = async () => {
    try {
      dispatch(setError(""));
      dispatch(setPending(true));
      await getEventsFrom("/api/transfers/toAdvertisings", msg => dispatch(setMessage(msg)));
      dispatch(setNeedsUpdate(true))
    } catch (error: any) {
      dispatch(setError(error['message'] ? error.message : error));
    } finally {
      dispatch(setPending(false));
    }
  }

  const [selectedSujet, setSelectedSujet] = useState<AdvertisingDocument>()

  return (
    <div className="advertisings">
      <ModalLoader isPending={pending} />
      <ShowError error={error} onClose={updateAdvertisings} />
        <Render when={hasRole("admin")}>
          <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
            <Button variant="contained" onClick={updateAdvertisings} startIcon={<ChecklistIcon />}
              color="primary" >{t('Update List of Sujets')}</Button>
          </Box>
        </Render>

        {/* pending={JSON.stringify(pending)} <br />
        needsUpdate={JSON.stringify(needsUpdate)} <br />
        query={JSON.stringify(query)} <br />
        storedQuery={JSON.stringify(storedQuery)} */}
        <Grid
          container
          justifyContent="flex-end"
          data-test-id="timelineForecastToggle"
        >
          <Hint translationKey={`additional_info_sujets_sujets`} />
        </Grid>
        <Card>
          <CardHeader title={t("Sujets")} />
          <CardContent className='no-padding'>
            <CustomDataTable
              columns={columns}
              data={adsList}
              pagination
              paginationServer
              onChangePage={e => dispatch(setPage(e))}
              onChangeRowsPerPage={e => dispatch(setSize(e))}
              paginationTotalRows={count}
              progressPending={pending}
              defaultSortFieldId={sortBy ?? undefined}
              defaultSortAsc={sortOrder === 'asc'}
              paginationDefaultPage={page || undefined}
              paginationPerPage={size || undefined}
              expandableRows
              paginationComponentOptions={ getTranslatedPaginationOptions(t) }
              expandableRowsComponent={({ data }) =>
                <AdvertisingsDetails data={data} />
              }
              filterDebounce
              onFilter={(e: FilterParams) => {
                // console.warn("onFilter", pristine, e)
                if (pristine) {
                  return
                }

                const queryFilter = {
                  organisation: getFilterValue(t('organisation')) ?? "",
                  fileName: getFilterValue(t('fileName')) ?? "",
                  mimeType: getFilterValue(t('mimeType')) ?? "",
                }

                const queryColumns = Object.values(e).reduce((acc, { column, value }) => ({
                  ...acc,
                  [namesToColumns[column.name?.toString() ?? "test"]]: value
                }), {} as SujetQuery)

                // console.warn("onFilter new", pristine, e, queryColumns, queryFilter)

                dispatch(setQuery({ ...queryFilter, ...queryColumns }))
                dispatch(setNeedsUpdate(true))
              }}
              sortServer
              onSort={(column, sortDirection) => {
                dispatch(setSortOrder(sortDirection))
                dispatch(setSortBy(namesToColumns[column.name?.toString() ?? ""]))
                //console.log(column, sortDirection)
              }}
            />
          </CardContent>
        </Card>

        <SujetDialog
          open={selectedSujet !== undefined}
          onClose={() => {
            setSelectedSujet(undefined)
          }}
          sujet={selectedSujet}
        />

    </div>
  );

}
