import { PayloadAction } from '@reduxjs/toolkit';

export interface IBaseQuery  {
  from?: number;
  to?: number;
  slots?: 'quarters' | 'halfyears';
}

export interface IBaseWithoutData<S extends IBaseQuery>  {
  pending: boolean;
  pristine: boolean;
  query: S;
  lastQuery?: S;
  needsUpdate: boolean;
  error?: string;
}

export interface IBaseState<S extends IBaseQuery,T extends {}> extends IBaseWithoutData<S>  {
  data: T;
}

export const baseReducersWithoutData = <S extends IBaseQuery, U extends IBaseWithoutData<S>>() => ({
  setPending: (state: U, action: PayloadAction<boolean>) => {
    state.pending = action.payload;
  },
  setPristine: (state: U, action: PayloadAction<boolean>) => {
    state.pristine = action.payload;
  },
  setQuery: (state: U, action: PayloadAction<S>) => {
    state.query = action.payload;
  },
  setPeriod: (state: U, action: PayloadAction<{from: number, to: number}>) => {
    state.query.from = action.payload.from;
    state.query.to = action.payload.to;
  },
  setNeedsUpdateValue: (state: U, action: PayloadAction<boolean>) => {
    state.needsUpdate = action.payload;
  },
  clearNeedsUpdate: (state: U) => {
    state.needsUpdate = false;
  },
  setNeedsUpdate: (state: U) => {
    state.needsUpdate = true;
  },
  saveQuery: (state: U) => {
    state.lastQuery = state.query;
  },
  setTouched: (state: U) => {
    state.pristine = false;
  },
  setError: (state: U, action: PayloadAction<string>) => {
    state.error = action.payload;
  },
  setRange: (state: U, action: PayloadAction<{from: number, to: number}>) => {
    state.query.from = action.payload.from;
    state.query.to = action.payload.to;
  },
  setSlots: (state: U, action: PayloadAction<'quarters' | 'halfyears'>) => {
    state.query.slots = action.payload;
  },
})

export const baseReducers = <S extends IBaseQuery, T extends {}, U extends IBaseState<S ,T>>() => ({
  ...baseReducersWithoutData<S, U>(),
  setData: (state: U, action: PayloadAction<T>) => {
    state.data = action.payload;
    state.needsUpdate = false;
  },
})

export interface IAmountFilterRange {
  amountFilterRange?: [number, number],
}

export interface IAmountRange<Q extends IAmountFilterRange, D extends { amount: number}[]> {
  query: Q,
  amountRange: [number, number],
  filteredData: D,
}

export const amountRangeReducers = <S extends IAmountFilterRange, D extends { amount: number}[], U extends IAmountRange<S,D>>() => ({
  setAmountFilterRange: (state: U, action: PayloadAction<[number, number]>) => {
    state.query.amountFilterRange = action.payload;
  },
  setAmountRange: (state: U, action: PayloadAction<[number, number]>) => {
    state.amountRange = action.payload;
  },
  updateFilteredData: (state, action: PayloadAction<void>) => {
    const [lower, upper] = (state.query.amountFilterRange &&
      state.query.amountFilterRange.map(parseFloat)) ?? [0, Number.MAX_VALUE];
    state.filteredData = state.data.filter(d => d.amount >= lower && d.amount <= upper);
  },
})

export const extractAmountRange = <T extends {amount: number}[]>(data: T ): [number, number] => {
  let min = Number.MAX_VALUE;
  let max = Number.MIN_VALUE;
  data.forEach((d) => {
    if (d.amount < min) min = d.amount;
    if (d.amount > max) max = d.amount;
  });
  return [min, max];
}


export interface IOrgAndMediaQuery extends IBaseQuery {
  media: string[],
  mediaOwner: string[],
  organisations: string[],
  orgGroups?: string[],
  mediaGroups?: string[],
  pType: 2 | 4 | 31 | number[],
  federalState?: string,
  orgGroupType?: string,
  mediaGroupType?: string,
}

export const orgAndMediaReducers = <S extends IOrgAndMediaQuery, T extends {}, U extends IBaseState<S ,T>>() => ({
  ...baseReducers<S, T, U>(),
  setMedia: (state: U, action: PayloadAction<string[]>) => {
    state.query.media = action.payload;
  },
  setMediaOwner: (state: U, action: PayloadAction<string[]>) => {
    state.query.mediaOwner = action.payload;
  },
  setOrganisations: (state: U, action: PayloadAction<string[]>) => {
    state.query.organisations = action.payload;
  },
  setOrgGroups: (state: U, action: PayloadAction<string[]>) => {
    state.query.orgGroups = action.payload;
  },
  setMediaGroups: (state: U, action: PayloadAction<string[]>) => {
    state.query.mediaGroups = action.payload;
  },
  setPType: (state: U, action: PayloadAction<2 | 4 | 31 | number[]>) => {
    state.query.pType = action.payload;
  },
  setFederalState: (state: U, action: PayloadAction<string>) => {
    state.query.federalState = action.payload;
  },
  setOrgGroupType: (state: U, action: PayloadAction<string>) => {
    state.query.orgGroupType = action.payload;
    state.query.orgGroups = [];
  },
  setMediaGroupType: (state: U, action: PayloadAction<string>) => {
    state.query.mediaGroupType = action.payload;
    state.query.mediaGroups = [];
  },
})

