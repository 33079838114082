import { useTranslation } from "react-i18next";
import { TInfoState, infoSlice} from "../App";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "..";
import { FormControlLabel, FormGroup, IconButton, Menu, MenuItem, Switch } from "@mui/material";
import { Help } from "./helper-components";
import MoreIcon from '@mui/icons-material/MoreVert'
import React from "react";


const { setUseHalfyears } = infoSlice.actions;

export const SlotsSwitch = () => {
    const { t } = useTranslation();
    const { useHalfyears } = useSelector<AppState, TInfoState>((state) => state.info);
    const dispatch = useDispatch();

    return (
        <FormGroup>
            <FormControlLabel control={
                <Switch checked={!useHalfyears} size="small"
                onChange={() => dispatch(setUseHalfyears(!useHalfyears))} />
            }
            label={<span>{t('compatibility_mode')} <Help text={'compatibility_help'}/></span>}/>
        </FormGroup>
    )

}

export const SlotsMenu = () => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
      };


    return (
    <div>
        <IconButton
        id="open-switch-to-legacy-mode"
        size="large"
        aria-label={t('open switch to legacy mode menu')}
        edge="end"
        color="inherit"
        onClick={handleClick}        
        disableRipple
        

      >
        <MoreIcon />
      </IconButton>
      <Menu open={open} 
         id="switch-to-legacy-mode"
         aria-label={t('switch to legacy mode menu')}
         aria-labelledby="open-switch-to-legacy-mode"
         anchorEl={anchorEl}
         onClose={() => setAnchorEl(null)}
         anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
         >
        <MenuItem role="menuitem">
            <SlotsSwitch />
        </MenuItem>
      </Menu>
      </div>
    )
}    
