import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TableColumn } from "react-data-table-component-with-filter";
import { Box, IconButton } from "@mui/material";
import { CustomDataTable } from "../../data-table/DataTable";
import { CampaignDocument } from "../../../../../server/models/sujets";
import { getTranslatedPaginationOptions, lineBreakName, toCurrency } from "../../../helpers/helpers";
import { MimeTypeIcon } from "../../MimeTypeIcon";
import { SujetDialog } from "../../dialog/SujetDialog";
import ReactECharts from "echarts-for-react";
import { defaultTreemapOptions, resultToTreemapModel, TreemapKey, TreemapParams, treemapTooltipFormatter, TreemapValue } from "../../../types/sujetTypes";
import { useMediaQuery } from "react-responsive";
import { Legend } from "../../flows/Legend";
import { useSettingsStore } from "../../../context/SettingsStore";
import { Render } from "../../helper-components";

type CampaignSujetEntry = CampaignDocument["ads"][0] & {
  campaign: string;
  organisation: string;
  // for compaitibility with TSujetEntry
  fileSize: number;
  orgGroup: string;
}
type CampaignSujetValue = TreemapValue<CampaignSujetEntry>

interface Props {
  data: CampaignDocument
}

export const CampaignDetails: FC<Props> = ({ data }) => {
  const { t, i18n } = useTranslation();
  const detailsColumns = useMemo<TableColumn<CampaignDocument["ads"][0]>[]>(() => [
    {
      name: t('halfyear'),
      selector: row => row['halfyear'],
      sortable: true,
      filterable: false,
      width: '7%',
    },
    {
      name: t('media'),
      selector: row => row['media'],
      sortable: true,
      filterable: true,
      wrap: true,
      width: '18%',
    },
    {
      name: t('mediaOwner'),
      selector: row => row['mediaOwner'],
      sortable: true,
      filterable: true,
      wrap: true,
      width: '18%',
    },
    {
      name: t('category'),
      selector: row => row['category'],
      sortable: true,
      filterable: true,
      filterValues: ['Online', 'Print', 'Hörfunk', 'Fernsehen', 'Out of Home'],
      width: '12%',
    },
    // {
    //   name: t('subCategory'),
    //   selector: row => row['subCategory'],
    //   sortable: true,
    //   filterable: true,
    //   width: '140px',
    // },
    {
      name: t('fileName'),
      selector: row => row['fileName'],
      sortable: true,
      filterable: true,
      wrap: true,
      width: '16%',
      cell: (row) => (
        <span>
          {/* <MimeTypeIcon mimeType={row.mimeType} height={16} />{' '} */}
          {row.fileName}
        </span>
      )
    },
    {
      name: t('amount'),
      selector: row => row['amount'],
      sortable: true,
      filterable: false,
      format: toCurrency("amount"),
      right: true,
      width: '10%'
    },
    {
      name: t('mimeType'),
      selector: row => row['mimeType'],
      sortable: true,
      filterable: true,
      filterValues: [
        { label: t('Image'), value: 'image'},
        { label: 'Video', value: 'video/mp4'},
        { label: 'PDF', value: 'application/pdf'},
        { label: 'Audio', value: 'audio/mpeg'}
      ],
      center: true,
      width: '8%',
      cell: (row) =>
        <IconButton onClick={() => setSelectedSujet(row)} color="primary">
          <MimeTypeIcon mimeType={row?.mimeType ?? ""} height={24} />
        </IconButton>
    },
  ], [t, i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

  const [selectedSujet, setSelectedSujet] = useState<any>()

  const isMobileLandscape = useMediaQuery({
    maxHeight: 575.98,
    orientation: "landscape",
  });
  const isMobilePortrait = useMediaQuery({ maxWidth: 600 });
  const isMobile = isMobileLandscape || isMobilePortrait;

  const colorSettings = useSettingsStore().modules.colors;

  let treemapRef;

  const treemapKeys = useMemo<TreemapKey<CampaignSujetEntry>[]>(() => ([
    { key: "category", colorKey: "category" },
    { key: "media" },
    { key: "fileName" },
  ]), [])

  const sujetEntries = useMemo(() => data.ads.map<CampaignSujetEntry>((entry) => ({
    ...entry,
    campaign: data.campaign,
    organisation: data.organisation,
    fileSize: 0,
    orgGroup: "",
  })),
  [data.ads, data.campaign, data.organisation])

  const treemapModel = useMemo(() => resultToTreemapModel(sujetEntries, {
      keys: treemapKeys,
      colorSettings,
      echartsRef: treemapRef?.getEchartsInstance(),
    }),
    [colorSettings, sujetEntries, treemapKeys, treemapRef])

  const treemapOptions = useMemo(() =>
    defaultTreemapOptions({
      name: data.campaign,
      chartModel: treemapModel ?? [],
      // treemapTooltipFormatter,
      title: `${t('Campaign')} ${lineBreakName(data.campaign, "\n", 0, isMobile ? document.documentElement.clientWidth / 14 : 1000)}`,
      subtitle: `${t("organisation")}: ${data.organisation}` +
        `\n${t("Source")}: KommAustria, ` +
        `${t("Date")}: ${new Date().toLocaleString()}`,
      isMobile,
      levels: data.ads.length > 30 ? 2 : treemapKeys.length,
      // levels: treemapKeys.length,
      roamEnabled: false,
      showDownload: false,
      top: 80,
      tooltipFormatter: treemapTooltipFormatter(false, isMobile)
    }),
    [treemapModel, treemapKeys.length, isMobile, data.campaign, data.organisation, data.ads.length, t])

    const onChartClickTreemap = useMemo(() => (params: TreemapParams<CampaignSujetValue>) => {
      //console.warn("click", params.type, params, JSON.stringify(params, [""]))
      const { value, data, seriesId, treePathInfo } = params

      const container = document.documentElement

      const isLeaf = data && !(data['children'] && data['children'].length > 0)
      // const { name, value, treePathInfo } = params
      // const treePath = treePathInfo as TreePathNode[]

      const key = typeof value === "number" ? undefined : value?.[1]
      const sujet = typeof value === "number" ? undefined : value?.[2]
      const keys = typeof value === "number" ? undefined : value?.[3]

      if (treePathInfo.length === 1) {
        // reset zoom dataIndex
        container?.setAttribute("data-treemap-zoom-data-index", "")
      }

      const leafDepth = parseInt(treemapOptions.series?.["leafDepth"]) || -1
      const zoomDataIndex = container?.getAttribute("data-treemap-zoom-data-index")
      const zoomNotActiveAlready = (Math.min((keys?.length ?? 0), leafDepth + 1) !== (treePathInfo.length)) || zoomDataIndex

      // if (data) {
      if (data && (zoomNotActiveAlready || leafDepth > 2)) {
        // zoom in one node on click, if node doesnt already zoom in because max leafDepth
        container?.setAttribute("data-treemap-zoom-data-index", params.dataIndex?.toString())

        const echartInstance = treemapRef?.getEchartsInstance();
        echartInstance?._api.dispatchAction({
          type: 'treemapRootToNode',
          from: echartInstance._chartsViews?.[0]?.uid,
          seriesId: seriesId,
          targetNode: echartInstance._chartsViews?.[0]?.findTarget(params['event'].offsetX, params['event'].offsetY)?.node
        })
      }

      // dont do anything on breadcrumb click
      if (!sujet || params.selfType === "breadcrumb") {
        const rootDataIndex = container?.getAttribute("data-treemap-root-data-index")

        if (params.nodeData?.dataIndex !== Number(rootDataIndex) || "") {
          container?.setAttribute("data-treemap-root-data-index", "")

          const echartInstance = treemapRef?.getEchartsInstance();

          setTimeout(() => {
            echartInstance?.resize()
          }, (Number(echartInstance?.getOption().animationDurationUpdate) || 0) * 0.6)
        }

        return
      }

      if (key === "fileName" && isLeaf) {
        // open sujet popup
        // onOpenAds(sujet)
        const rootDataIndex = container?.getAttribute("data-treemap-root-data-index")

        if (data && rootDataIndex === params.dataIndex.toString()) {
          setSelectedSujet(sujet)
        }

        // zoom in one node on click
        if (data) {
          const echartInstance = treemapRef?.getEchartsInstance();

          // const e: ReactECharts = echartInstance

          echartInstance?._api.dispatchAction({
            type: 'treemapRootToNode',
            from: echartInstance._chartsViews?.[0]?.uid,
            seriesId: seriesId,
            targetNode: echartInstance._chartsViews?.[0]?.findTarget(params['event'].offsetX, params['event'].offsetY)?.node
          })

          container?.setAttribute(`data-treemap-root-data-index`, params.dataIndex.toString())

          setTimeout(() => {
            echartInstance?.resize()
          }, (Number(echartInstance?.getOption().animationDurationUpdate) || 0) * 0.8)

        }
      }

    }, [treemapOptions.series, treemapRef])

  return (
    <Box sx={{ border: 1, marginLeft: 6, width: isMobilePortrait ? "calc(100vw - 48px)" : undefined }}>
      <Render when={true}>
          <ReactECharts
            ref={(e) => { treemapRef = e }}
            option={treemapOptions}
            onEvents={{
              click: onChartClickTreemap,
              // dblclick: onChartDblClickTreemap,
            }}
            style={{ height: isMobile ? 500 : 600, marginTop: 6, marginBottom: 1 }}
            opts={{ locale: i18n.language.split("-")[0] }}
          />

        <div style={{ marginTop: "0.5rem", marginBottom: "1rem" }}>
          <Legend center />
        </div>
      </Render>

      <CustomDataTable
        columns={detailsColumns}
        data={data.ads}
        pagination={true}
        paginationComponentOptions={getTranslatedPaginationOptions(t)}
        paginationPerPage={30}
      />

        <SujetDialog
          open={selectedSujet !== undefined}
          onClose={() => {
            setSelectedSujet(undefined)
          }}
          sujet={selectedSujet}
        />

    </Box>
  )
}
