import { Grid } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { HiglightsResponse, getHighlights } from "../services/data-service";
import CountUp from "react-countup";


export const Highlight = () => {

  const [highlights, setHighlights] = useState<HiglightsResponse>({
    halfyear: 0,
    orgCount: 0,
    advertising: 0,
    funding: 0
  });

  useEffect(() => {
    getHighlights().then((highlights) => setHighlights(highlights));
  }, []);

  return (<div className="highlight-element">
    <h2>
      {`${t("rtr_reported_announcements")} ${t("Halfyear")} ${highlights.halfyear.toString().slice(-1)}/${highlights.halfyear.toString().slice(0, -1)}`}
    </h2>
    <Grid container spacing={3} sx={{ padding: 2 }}>
      <Grid item xs={12} sm={12} lg={4}>
        <h3>
          <CountUp end={highlights.orgCount} separator="." duration={4} />
        </h3>
        <h4>{`${t("rtr_number_reporter")}`}</h4>
      </Grid>
      <Grid item xs={12} sm={12} lg={4}>
        <h3>
          <CountUp end={highlights.advertising} separator="." duration={4} />
        </h3>
        <h4>{`${t("rtr_sum_advertising")}`}</h4>
      </Grid>
      <Grid item xs={12} sm={12} lg={4}>
        <h3>
          <CountUp end={highlights.funding} separator="." duration={4} />
        </h3>
        <h4>{`${t("rtr_sum_funding")}`}</h4>
      </Grid>
    </Grid>
  </div>)
}
