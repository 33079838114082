import { TextFieldVariants } from "@mui/material";

const Config = {
  skin: "rtr",
  badgeColor: "secondary",
  desktop: {
    charts: {
      title_font_size: 24,
      height: 400,
      title_font_weight: 700,
    },
  },
  mobile: {
    charts: {
      title_font_size: 18,
      height: 400,
      title_font_weight: 700,
    },
  },
  input: {
    labelVariant: "outlined" as TextFieldVariants,
  },
  overview: {
    forecastEnabled: false,
  },
  flows: {
    maxResults: 1500,
    sankey: {
      minHeight: 600,
      shadowColorForObjectFlows: getComputedStyle(
        document.documentElement
      ).getPropertyValue("--shadow-color-for-object-flows"),
      nodesColor: getComputedStyle(document.documentElement).getPropertyValue(
        "--flows-nodes-color"
      ),
      transferFlowsColor: getComputedStyle(
        document.documentElement
      ).getPropertyValue("--flows-transfer-color"),
      fundingFlowsColor: getComputedStyle(
        document.documentElement
      ).getPropertyValue("--flows-funding-color"),
      flowsLabelColor: getComputedStyle(
        document.documentElement
      ).getPropertyValue("--flows-label-color"),
      othersFlowColor: getComputedStyle(
        document.documentElement
      ).getPropertyValue("--others-flow-color"),
    },
    summarise: {
      percentThreshold: 0.04,
      minAmountOfFlows: 10,
    },
  },
  universe: {
    highlight: {
      enabled: false,
      maxNodes: 500,
    },
    colors: {
      orgGroup: {
        normal: getComputedStyle(document.documentElement).getPropertyValue(
          "--universe-organisation-group-color"
        ),
        selected: getComputedStyle(document.documentElement).getPropertyValue(
          "--universe-organisation-group-selected-color"
        ),
      },
      mediaGroup: {
        normal: getComputedStyle(document.documentElement).getPropertyValue(
          "--universe-media-group-color"
        ),
        selected: getComputedStyle(document.documentElement).getPropertyValue(
          "--universe-media-group-selected-color"
        ),
      },
      organisation: {
        normal: getComputedStyle(document.documentElement).getPropertyValue(
          "--universe-organisation-color"
        ),
        selected: getComputedStyle(document.documentElement).getPropertyValue(
          "--universe-organisation-selected-color"
        ),
      },
      media: {
        normal: getComputedStyle(document.documentElement).getPropertyValue(
          "--universe-media-color"
        ),
        selected: getComputedStyle(document.documentElement).getPropertyValue(
          "--universe-media-selected-color"
        ),
      },
      mediaOwner: {
        normal: getComputedStyle(document.documentElement).getPropertyValue(
          "--universe-mediaOwner-color"
        ),
        selected: getComputedStyle(document.documentElement).getPropertyValue(
          "--universe-mediaOwner-selected-color"
        ),
      },
    },
  },
  topbar: {
    burgerMenuIconColor: getComputedStyle(
      document.documentElement
    ).getPropertyValue("--burger-menu-icon-color"),
    avatarBgColor: getComputedStyle(document.documentElement).getPropertyValue(
      "--avatar-bg-color"
    ),
  },
  home: {
    tableRowComplementaryColor: getComputedStyle(
      document.documentElement
    ).getPropertyValue("--table-row-complementary-color"),
  },
  switchButton: {
    icons: {
      minusIconD: "M19,13H5V11H19V13Z",
      checkIconD: "M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z",
      payerIconD:
        "M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z",
      receiptIconD:
        "M19.5 3.5 18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5zM19 19.09H5V4.91h14v14.18zM6 15h12v2H6zm0-4h12v2H6zm0-4h12v2H6z",
      visibilityIconD:
        "M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z",
      visibilityHideIconD:
        "M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z",
      percentIconD:
        "M7.5 11C9.43 11 11 9.43 11 7.5S9.43 4 7.5 4 4 5.57 4 7.5 5.57 11 7.5 11zm0-5C8.33 6 9 6.67 9 7.5S8.33 9 7.5 9 6 8.33 6 7.5 6.67 6 7.5 6zM4.0025 18.5832 18.59 3.9955l1.4142 1.4143L5.4167 19.9974zM16.5 13c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5zm0 5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z",
      numbersIconD:
        "m20.5 10 .5-2h-4l1-4h-2l-1 4h-4l1-4h-2L9 8H5l-.5 2h4l-1 4h-4L3 16h4l-1 4h2l1-4h4l-1 4h2l1-4h4l.5-2h-4l1-4h4zm-7 4h-4l1-4h4l-1 4z",
    },
  },
  threeStateButton: {
    threeStateButtonSelectedColor: "#39f; !important",
    threeStateButtonSelectedBgColor: "#39f;  !important",
    threeStateButtonSelectedHoverColor: "#39f; !important",
  },
  ads: {
    minWidthForAllColumns: +getComputedStyle(
      document.documentElement
    ).getPropertyValue("--ads-min-width-for-all-columns"),
    combinedColumnSeparator: " | ",
    videoTypes: [
      "video/mp4",
      "video/quicktime",
      "video/x-ms-wmv",
      "video/x-msvideo",
    ],
    imageTypes: ["image/jpeg", "image/png"],
    fileTypes: ["application/pdf"],
    audioTypes: ["audio/mpeg"],
  },
};

export default Config;
