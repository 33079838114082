import { useMemo, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import DataTable, { TableColumn } from 'react-data-table-component-with-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCreditCard, faEdit, faLayerGroup, faNewspaper, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../index';
import { IfNoError, ModalLoader } from '../components/helper-components';
import { IGroupingDocument } from '../../../server/models/grouping';
import {countEntities, getAllGroups, deleteGroup, getGroupTypeList, recreateCategoryGroupings, getEventsFrom} from '../services/data-service';
import { ConfirmDelete } from '../components/deleteDialog';
import {IGroupTypeDocument} from "../../../server/models/group-type";
import { Box, Button, Card, CardContent, CardHeader } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import { infoSlice } from '../App';
import { getTranslatedPaginationOptions } from '../helpers/helpers';


type GroupQuery = {
  name?: string;
  type?: string;
  group_type?: string;
}

interface TGroupingsState {
  groupings: IGroupingDocument[];
  needsUpdate: boolean;
  page: number;
  size: number;
  count: number;
  pending: boolean;
  query: GroupQuery;
}

export const groupingSlice = createSlice({
  name: 'users',
  initialState: {
    groupings: [],
    needsUpdate: true,
    page: 1,
    size: 10,
    count: 0,
    query: {},
    pending: false
  } as TGroupingsState,
  reducers: {
    setGroupingsList: (state, action: PayloadAction<IGroupingDocument[]>) => ({
      ...state,
      groupings: action.payload,
      needsUpdate: false
    }),
    setPage: (state, action: PayloadAction<number>) => ({ ...state, page: action.payload, needsUpdate: true }),
    setSize: (state, action: PayloadAction<number>) => ({ ...state, size: action.payload, needsUpdate: true }),
    setCount: (state, action: PayloadAction<number>) => ({ ...state, count: action.payload }),
    setNeedsUpdate: (state, action: PayloadAction<void>) => ({ ...state, needsUpdate: true }),
    setQuery: (state, action: PayloadAction<GroupQuery>) => ({ ...state, query: action.payload }),
    setPending: (state, action: PayloadAction<boolean>) => ({ ...state, pending: action.payload })
  }
})

export const { setGroupingsList, setNeedsUpdate, setCount,
  setPage, setSize, setPending, setQuery } = groupingSlice.actions;

const {setMessage} = infoSlice.actions;

export const ShowAllGroups = () => {
  const { groupings, needsUpdate, page,
    size, count, pending, query } = useSelector<AppState, TGroupingsState>(state => state.grouping)
  const [error, setError] = useState<string>("");
  const [groupToDelete, setGroupToDelete] = useState<IGroupingDocument | null>(null);
  const [sortBy, setSortBy] = useState<string | null>(null)
  const [isUpdating, setIsUpdating] = useState(false)
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('asc')
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const namesToColumns = useMemo(() => ({
    "Name": "name",
    "Type": "type",
    "Typ": "type",
    "Group Type": "group_type",
    "Gruppentyp": "group_type"
  }), [])
  const [groupTypes, setGroupTypes] = useState<IGroupTypeDocument[]>([]);

  const resetGroupMemberships = () => {
    setIsUpdating(true)
    getEventsFrom('/api/groupings/members/reset', msg => dispatch(setMessage(msg)))
      .finally(() => setIsUpdating(false))
  }

  const recreateCategoryGroups = () => {
    setIsUpdating(true)
    recreateCategoryGroupings()
    .then((resp) => setMessage(t('Category Groupings recreated successfully')))
    .catch(err => setError(err))
    .finally(() => setIsUpdating(false))
  }

  useEffect(() => {
    getGroupTypeList().then(setGroupTypes);
  }, [])

  const columns = useMemo<TableColumn<IGroupingDocument>[]>(() => [
    {
      name: t('Name'),
      selector: row => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: t('Type'),
      selector: row => row.type,
      sortable: true,
      right: false,
      filterable: true,
      filterValues: ['media', 'org'],
    },
    {
      name: t('Group Type'),
      selector: row => row.group_type,
      sortable: true,
      filterable: true,
      cell: (row) => {
        const type = groupTypes.find(g => g._id === row.group_type);
        return t(type?.name);
      },
      filterValues: groupTypes.map(g => ({ value: g._id.toString() , label: t(g.name) })),
    },
    {
      name: t('Active'),
      selector: row => row.isActive,
      maxWidth: "100px",
      minWidth: "50px",
      cell: row => row.isActive ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} color="red" />
    },
    {
      name: t('Actions'),
      cell: row =>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant="contained" color="primary" size="small" href={`/edit_groups/${row._id}`} aria-label={t('Edit')}>
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button variant="contained" color="secondary" size="small" onClick={() => setGroupToDelete(row)} aria-label={t('Delete')}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </Box>
    },
  ], [groupTypes, t]);
  useEffect(() => {
    countEntities('/groupings/count/all').then(c => dispatch(setCount(c)))
      .catch(err => err.message ? setError(err.message) : setError(err))
  }, [dispatch])
  useEffect(() => {
    if (groupings.length === 0 || needsUpdate) {
      dispatch(setPending(true))
      Promise.all([
        countEntities('/groupings/count/all', query),
        getAllGroups(page, size, sortBy ?? '', sortOrder, query)
      ])
        .then(([count, groups_]) => {
          dispatch(setCount(count))
          dispatch(setGroupingsList(groups_))
        }
        ).catch(err => err.message ? setError(err.message) : setError(err))
        .finally(() => dispatch(setPending(false)))
    }
  }, [needsUpdate, dispatch, groupings.length, page, query, size, sortBy, sortOrder])
  const onDelete = () => {
      if (groupToDelete && groupToDelete._id) {
        deleteGroup(groupToDelete._id.toString())
          .then(()=>dispatch(setNeedsUpdate()))
          .catch(err => setError(err))
          .finally(()=>setGroupToDelete(null))
      } else {
        setGroupToDelete(null)
      }
  }



  return <div>
    <ModalLoader isPending={pending || isUpdating }/>
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
        <Button variant="contained" color="primary" href="/newgroup/media" startIcon={<FontAwesomeIcon icon={faNewspaper}/>}>
          {t("New Media Group")}
        </Button>
        <Button variant="contained" color="primary" href="/newgroup/org" startIcon={<FontAwesomeIcon icon={faCreditCard}/>}>
          {t("New Organisation Group")}
        </Button>
        <Button variant="contained" color="primary" onClick={recreateCategoryGroups} startIcon={<FontAwesomeIcon icon={faLayerGroup}/>}>
          {t("Re-Create Category Groupings")}
        </Button>
        <Button variant="contained" color="primary" onClick={resetGroupMemberships} startIcon={<GroupsIcon/>}>
          {t("Re-Initialize all group memberships")}
        </Button>
      </Box>
    <ConfirmDelete show={!!groupToDelete} elementToDelete={groupToDelete}
     propertyToPresent="name"
     onHide={()=>setGroupToDelete(null)} onDelete={onDelete} />
    <IfNoError error={error}>
      <Card>
        <CardHeader title={t("Available Groups")}/>
        <CardContent className='no-padding'>
          <DataTable
            filterServer={true}
            onFilter={e => {
              dispatch(setQuery({
                ...Object.values(e).reduce((acc, { column, value }) =>
                  ({ ...acc, [namesToColumns[column.name?.toString() ?? "test"]]: value }), {})
              }))
              dispatch(setNeedsUpdate())
            }}
            columns={columns}
            data={groupings}
            pagination={true}
            progressPending={pending}
            paginationServer={true}
            onChangePage={e => dispatch(setPage(e))}
            onChangeRowsPerPage={e => dispatch(setSize(e))}
            paginationTotalRows={count}
            paginationComponentOptions={getTranslatedPaginationOptions(t)}
            onSort={(column, sortDirection) => {
              setSortOrder(sortDirection)
              setSortBy(namesToColumns[column.name?.toString() ?? "test"])
              dispatch(setNeedsUpdate())
            }}
          />
        </CardContent>
      </Card>
    </IfNoError>
  </div>


}
