import { ArrowDownward } from "@mui/icons-material";
import { useState } from "react";
import DataTable, { TableProps } from "react-data-table-component-with-filter";

interface CustomTableProps<T> extends TableProps<T> {
  filterDebounce?: boolean | number
}

export function CustomDataTable<T>({ filterDebounce, ...props }: CustomTableProps<T>) {
  const [filterTimeout, setFilterTimeout] = useState<NodeJS.Timeout>()

  const filterDebounceMs = typeof filterDebounce === "number" ? filterDebounce : 500

  return (
    <DataTable
      sortIcon={<ArrowDownward />}
      {...props}
      customStyles={{
        headCells: {
          style: {
            alignItems: "start",
          }
        },
        ...props.customStyles
      }}
      onFilter={(e) => {
        if (!filterDebounce || !props.onFilter) {
          return
        }

        clearTimeout(filterTimeout)
        const timeout = setTimeout(() => {
          props.onFilter?.(e)
        }, filterDebounceMs)
        setFilterTimeout(timeout)

      }}
    />
  )
}
