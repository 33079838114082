import {ModuleSettings} from "../../models/modules";

export interface SujetsModuleSettings extends ModuleSettings {
  categories: string[];
}

export const defaultSujetsModuleSettings: SujetsModuleSettings = {
  enabled: false,
  categories: ["print", "online", "hörfunk", 
    "fernsehen", "out of home", "mixed", "unknown"],
}
