import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSettingsStore } from "../../context/SettingsStore";
import { Tabs, Tab } from "@mui/material";
import { Render } from "../../components/helper-components";
import { Advertisings } from "./advertisings";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import CampaignIcon from '@mui/icons-material/Campaign';
import { Campaigns } from "./campaigns";
import "./ads.scss";

//eslint-disable-file react-hooks/exhaustive-deps

export const Ads: React.FC = () => {
    const { tab } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [updateRoute, setUpdateRoute] = useState(true);
    const moduleSettings = useSettingsStore().modules.sujets;
    const [activeTab, setActiveTab] = useState<string>(tab ?? "sujets");
    const { t} = useTranslation();
    const isMobilePortrait = useMediaQuery({ maxWidth: 600 });

    const setTab = (newValue: string, updateRoute: boolean) => {
        if (tab !== newValue && updateRoute && moduleSettings.enabled) {
          navigate(`/ads/${newValue}${location.search}`, {
            replace: location.search.length === 0,
          });
        }
        setUpdateRoute(true);
      };

      useEffect(() => {
        if (tab !== undefined && tab !== "undefined") {
          setActiveTab(tab);
        }
      }, [tab]);

      useEffect(() => {
        setTab(activeTab, updateRoute);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [activeTab, updateRoute]);

      const handleTabChange = (event, newTab) => {
        setActiveTab(newTab);
      };

      return   <div>

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        orientation={isMobilePortrait ? "vertical" : "horizontal"}
        centered
        sx={{ marginY: 2 }}
      >
        <Tab
          label={
            <span>
              <AdsClickIcon /> {t("Sujets")}
            </span>
          }
          value="sujets"
        />
        <Tab
          value="campaigns"
          label={
            <span>
              <CampaignIcon /> {t("Campaigns")}
            </span>
          }
        />
      </Tabs>
      <Render when={activeTab === "sujets"}>
          <Advertisings/>
      </Render>
      <Render when={activeTab === "campaigns"}>
          <Campaigns/>
      </Render>
    </div>
}
