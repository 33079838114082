import {ModuleSettings} from "../../models/modules";

export interface TopTransferModuleSettings extends ModuleSettings {
  defaultOrgGroupType: string;
  defaultMediaGroupType: string;
  maxNumberOfGroupMembers: number;
  // ...
}

export const defaultTopTransferModuleSettings: TopTransferModuleSettings = {
  enabled: false,
  defaultOrgGroupType: "membership",
  defaultMediaGroupType: "membership",
  maxNumberOfGroupMembers: 20,
  // ...
}
