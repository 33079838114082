import {ModuleSettings} from "../models/modules";

export interface ColorsModuleSettings extends ModuleSettings {
  advertising: string;
  unbekannt: string;
  print: string;
  online: string;
  horfunk: string;
  fernsehen: string;
  out_of_home: string;
  forderung: string;
  orfbeitrag_gemass_31_abs_1_orfg: string;
  kompensation_nach_31_abs_11_und_13a_orfg
}

export const defaultColorsModuleSettings: ColorsModuleSettings = {
  enabled: true,
  advertising: "dodgerblue",
  forderung: "green",
  unbekannt: "gray",
  print: "blue",
  online: "gold",
  horfunk: "orange",
  fernsehen: "purple",
  out_of_home: "brown",
  orfbeitrag_gemass_31_abs_1_orfg: "dodgerblue",
  kompensation_nach_31_abs_11_und_13a_orfg: "navy",
}
