import React, {createContext, useEffect, useState} from "react";
import {SettingsStore} from "./SettingsStore";
import {createOrUpdateSettings, getSettings} from "../services/data-service";
import {ISettings} from "../../../server/models/settings";
import {ModulesSettings} from "../models/modules";
import {defaultAboutUsModuleSettings} from "../pages/about_us.settings";
import {defaultAdsUniverseModuleSettings} from "../pages/universe.settings";
import {defaultBlogModuleSettings} from "../pages/blog/blog.settings";
import {defaultTopFundingsModuleSettings} from "../pages/top/top-fundings.settings";
import {defaultSujetsModuleSettings} from "../pages/ads/ads.settings";
import {defaultFlowsFundingsModuleSettings} from "../pages/flows/flows-fundings.settings";
import {defaultFlowsMixedModuleSettings} from "../pages/flows/flows-mixed.settings";
import {defaultTopTransferModuleSettings} from "../pages/top/top-transfer.settings";
import {defaultFlowsTransferModuleSettings} from "../pages/flows/flows-transfer.settings";
import {defaultGeneralSettings} from "../models/generalSettings";
import { defaultColorsModuleSettings } from "../pages/colors.settings";


type SettingsContextType = {
  settings: SettingsStore;
  updateSettings: (settings: ISettings, onSuccess?: () => void, onError?: () => void) => void;
}
export const SettingsContext = createContext<SettingsContextType>({} as SettingsContextType);

export const defaultModuleSettings: ModulesSettings = {
  colors: defaultColorsModuleSettings,
  aboutUs: defaultAboutUsModuleSettings,
  adsUniverse: defaultAdsUniverseModuleSettings,
  blog: defaultBlogModuleSettings,
  flows_fundings: defaultFlowsFundingsModuleSettings,
  flows_mixed: defaultFlowsMixedModuleSettings,
  flows_transfer: defaultFlowsTransferModuleSettings,
  sujets: defaultSujetsModuleSettings,
  top_fundings: defaultTopFundingsModuleSettings,
  top_transfer: defaultTopTransferModuleSettings
}

export const defaultSettings: ISettings = {
  general: defaultGeneralSettings,
  modules: defaultModuleSettings
}

export const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
  const [settings, setSettings] = useState<SettingsStore>(new SettingsStore({} as ISettings));

  // Initially load the settings from the database
  useEffect(() => {
    getSettings()
      .then((settings) => {
        if (settings === null) throw new Error("Settings not found");
        console.log("Settings found", settings)
        setSettings(new SettingsStore(mergeSettings(defaultSettings, settings)));
      }).catch((err) => {
      // ToDo: Show error message/redirect to error page because settings are required
      console.error("Loaded fallback settings;", err);
    });
  }, [])

  const updateSettings = (settings: ISettings, onSuccess?: () => void, onError?: () => void) => {
    createOrUpdateSettings(settings)
      .then((settings) => {
        setSettings(new SettingsStore(settings));
        console.log("Settings updated", settings)
        if (onSuccess) onSuccess()
      }).catch((err) => {
      console.error("Error updating settings", err)
      if (onError) onError()
    })
  }

  return (
    <SettingsContext.Provider value={{settings, updateSettings}}>
      {children}
    </SettingsContext.Provider>
  )
}

// Helper to merge the fetched settings with the default settings, so that there are no problems when settings are added
// but not yet present in the database
function mergeSettings(defaults: ISettings, fetched: ISettings): ISettings {
  let merged = {...defaults};
  for (const key in fetched) {
    if (fetched[key] instanceof Object && !(fetched[key] instanceof Array)) {
      merged[key] = mergeSettings(merged[key], fetched[key]);
    } else {
      merged[key] = fetched[key];
    }
  }
  return merged;
}
