import React from 'react';
import palettes from 'nice-color-palettes';

const ColorViewer: React.FC = () => {
    const colorPalettes = palettes.slice(0, 100);
    return (
        <div>
            {colorPalettes.map((palette, index) => (
                <div key={index} style={{ display: 'flex' }}>
                    <div style={{ marginRight: '10px' }}>{index}</div>
                    {palette.map((color, colorIndex) => (
                        <div
                            key={colorIndex}
                            style={{
                                backgroundColor: color,
                                width: '50px',
                                height: '50px',
                                margin: '5px',
                            }}
                        ></div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default ColorViewer;
