import { EChartsOption, TreemapSeriesOption } from "echarts";
import { DownloadIcon } from "../components/icons";
import { commonChartOptions, getCategoryColor, lineBreakHtml, shortenName } from "../helpers/helpers";
import i18n from "../i18n";
import { t } from "i18next";
import { colorToRGBA, getContrastColor, shadeColor } from "../helpers/color";
import Config from "../config/settings";
import { ColorsModuleSettings } from "../pages/colors.settings";


export type TSujetResponseEntry = {
  amount: number;
  sujet: string;
  fileName: string;
  fileSize: number;
  mimeType: string;
  subCategory: string;
  campaign: string;
  halfyear: number;
};

export type TSujetEntry = TSujetResponseEntry & {
  organisation: string;
  media: string;
  mediaOwner: string;
  orgGroup: string;
  category: string;
};

export type TSujetEntryKeys = keyof TSujetEntry;

// make a type from this data
export type TreemapEventParams<Value extends any[] = number[], TreePath = TreePathNode<Value>> = {
  borderColor: string
  color: string
  componentIndex: number
  componentSubType: string
  componentType: string
  data: TreemapItem[]
  dataIndex: number
  dataType: string
  dimensionNames: string[]
  encode: {
    value: number[]
  }
  name: string
  selfType?: "breadcrumb"
  seriesId: string
  seriesIndex: number
  seriesName: string
  seriesType: string
  treeAncestors: TreePath[]
  treePathInfo: TreePath[]
  type: string
  value: number | Value
}

export type TreePathNode<Value extends any[] = number[]> = {
  dataIndex: number
  name: string
  // this is actually of type number | number[] from echarts side
  // but is used for metadata, eg, [number, string / keyof TSujetEntry, TSujetEntry]
  value: number | Value
}

export type TreemapValue<T> = [number, keyof T, T, TreemapKey<T>[], any?]
export type SujetValue = TreemapValue<TSujetEntry>

export type TreemapParams<T extends any[]> = TreemapEventParams<T> & {
  // set when selfType === "breadcrumb", so click on breadcrumbs, otherwise undefined
  nodeData?: {
    dataIndex: number
    name: string
  }
}
export type SujetTreemapParams = TreemapParams<SujetValue>

// export type SujetTreemapParams = TreemapEventParams<[number, keyof TSujetEntry, TSujetEntry], SujetTreePathNode>

export type SujetTreePathNode = TreePathNode<SujetValue>

export type TreemapItem = NonNullable<TreemapSeriesOption["data"]>[0]

export type TreemapModelOptions = {
  keys: TreemapKey<TSujetEntry>[]
  colorSettings: ColorsModuleSettings
  echartsRef: any // ReactECharts | null
}

export type TreemapKey<T> = {
  key: keyof T
  fallback?: string
  colorKey?: keyof T
}

export const resultToTreemapModel = (
  sujets: TSujetEntry[],
  options: TreemapModelOptions,
): TreemapSeriesOption["data"] => {
  if (options.keys.length === 0) {
    return undefined
  }

  // console.time("result")
  // const startTime = Date.now()
  console.log("treemap options", options)

  const tree = createTree(sujets, options)
  const model = mapTreeToTreemap(tree, options)

  // const nodes = treemapNode(sujets, sujets, options.keys ?? [], options.categoryColorGetter)

  // console.timeEnd("result")
  // console.warn("result", Date.now() - startTime)

  return model
};

type TreeNode = {
  value: number
  sujet: TSujetEntry
  children?: Tree
}

type Tree = Record<string, TreeNode>

export const createTree = (sujets: TSujetEntry[], options: TreemapModelOptions) => {
  const tree: Tree = {}

  sujets.forEach(sujet => {
    let currentSubTree = tree

    options.keys.forEach((key, i) => {
      const levelName = sujet[key.key] || key.fallback || ""
      const lastLevel = i === options.keys.length - 1
      let currentNode = currentSubTree[levelName]

      if (!currentNode) {
        currentNode = {
          value: sujet.amount,
          sujet: sujet,
          children: lastLevel ? undefined : {}
        }
        currentSubTree[levelName] = currentNode
      } else {
        currentNode.value += sujet.amount
      }

      if (!lastLevel) {
        currentSubTree = currentNode.children ?? {}
      }
    })
  })

  return tree
}

const mapTreeToTreemap = (tree: Tree, options: TreemapModelOptions, level = 0): TreemapItem[] => {
  const reverseKeys = options.keys.slice().reverse()
  const categoryColorGetter = getCategoryColor(options.colorSettings)
  const treeMap = Object.entries(tree).map(([levelName, node]) => {

    const config = options.keys[level]
    const styleConfig = reverseKeys.find((key, i, all) => key.colorKey && ((all.length - 1 - i) <= level)) ?? config

    const style = categoryStyle(categoryColorGetter, styleConfig.colorKey, levelName, node.sujet, true, level)

    // const isLeaf = level === options.keys.length - 1

    const isAudio = Config.ads.audioTypes.includes(node.sujet?.mimeType ?? "")
    const isDocument = Config.ads.fileTypes.includes(node.sujet?.mimeType ?? "")
    const isVideo = Config.ads.videoTypes.includes(node.sujet?.mimeType ?? "")

    // const imageUrl = node.sujet.sujet
    const imageUrl = node.sujet.sujet

    const thumbnail = {
      audio: `/img/fa-file-audio.webp`,
      document: `/img/fa-file-pdf.webp`,
      video: `/img/fa-file-video.webp`,
      // TODO: replace with sujet link
      image: imageUrl,
    }[isAudio ? "audio" : isDocument ? "document" : isVideo ? "video" : "image"]

    // console.log("thumbnail", thumbnail, node.sujet.mimeType, isAudio, isDocument, node.sujet)

    const treemapItem: TreemapItem = {
      name: levelName,
      value: ([node.value, config.key, node.sujet, options.keys] as SujetValue) as any,
      // value: [node.value, config.key, node.sujet, options.echartsRef] as any,
      itemStyle: {
        ...style.itemStyle as any,
      },
      label: {
        ...style.label,
        ...(config.key === "fileName" && {
          align: "center",
          padding: [16, 0, 0, 0], // [top, right, bottom, left]
          // the 3 options below make label vertically centered,
          // but then it sticks out on the bottom for small nodes
          // position: "insideBottom",
          // verticalAlign: "middle",
          // padding: [-16, 0, 0, 0], // [top, right, bottom, left]
          rich: {
            thumbnail: {
              backgroundColor: {
                image: thumbnail
              },
              height: isAudio || isDocument || isVideo ? 24 : 32,
            },
            thumbnailLarge: {
              backgroundColor: {
                image: thumbnail
              },
              height: isAudio || isDocument || isVideo ? 36 : 64,
            },
            label: {
              fontSize: 16,
              // lineHeight: 16,
              align: "left",
              // verticalAlign: "middle",
              opacity: 0.8,
            },
            title: {
              fontSize: 16,
              align: "left"
            },
            header: {
              fontSize: 18,
              fontWeight: "bold",
            }
          }
        }),
      },
      upperLabel: {
        textBorderColor: "transparent",
        ...style.upperLabel,
      },
      children: node.children ? mapTreeToTreemap(node.children, options, level + 1) : undefined,
    }

    return treemapItem
  })

  return treeMap
}

export const treemapNode = (
  sujets: TSujetEntry[],
  allSujets: TSujetEntry[],
  keyConfigs: TreemapKey<TSujetEntry>[],
  colorGetter?: (category: string) => any,
  level = 0
): TreemapItem[] | undefined => {
  // note: the value of a node is typed as number[] but also stores other metadata
  // for access in tooltips etc, like sujet index, current key and sujet

  const config = keyConfigs[level]
  const lastLevel = level === keyConfigs.length - 1;

  if (!config) {
    return undefined
  }

  if (sujets.length === 1) {
    const sujet = sujets[0]
    const levelName = (sujet[config.key]?.toString() || config.fallback) ?? ""

    const style = categoryStyle(colorGetter, config.colorKey, levelName, sujet, false, level)

    const childNodes = !lastLevel ? treemapNode(sujets, allSujets, keyConfigs, colorGetter, level + 1) : undefined

    return [{
      name: levelName,
      // value: [sujet.amount, config.key, sujet] as any,
      value: [sujet.amount, config.key, sujet] as any,
      itemStyle: {
        ...style.itemStyle as any,
      },
      upperLabel: {
        // color: "#000",
        textBorderColor: "transparent",
        ...style.upperLabel,
      },
      children: lastLevel || !childNodes?.length ? undefined : childNodes,
      //treemapNode(filteredSujets, allSujets, keyConfigs, colorGetter, level + 1)
    }]
  }

  // partiition all sujets for children once here, instead of filter for each sujet
  const sujetMap = new Map<string, TSujetEntry[]>()

  sujets.forEach(sujet => {
    const levelName = (sujet[config.key]?.toString() || config.fallback) ?? ""

    // const levelKey = sujet[config.key].toString()
    const sujetsForKey = sujetMap.get(levelName)
    if (sujetsForKey) {
      sujetsForKey.push(sujet)
    } else {
      sujetMap.set(levelName, [sujet])
    }
  })

  // if (level === 0) {
  //   console.warn("sujetMap", [...sujetMap.entries()])
  // }

  const map = new Map<string, TreemapItem>()

  sujets.forEach(sujet => {
    const levelName = (sujet[config.key]?.toString() || config.fallback) ?? ""
    // const sujetIndex = allSujets.indexOf(sujet)

    // const filteredSujets = sujets.length > 1 ? sujets.filter((s) => s[config.key] === sujet[config.key]) : sujets
    const filteredSujets = sujetMap.get(levelName) ?? []

    const node = map.get(levelName)

    if (node) {
      // if (level === 0) {
      //   console.time(sujet.sujet)
      // }
      const childNodes = !lastLevel && filteredSujets.length > 0 ? treemapNode(filteredSujets, allSujets, keyConfigs, colorGetter, level + 1) : undefined
      // if (level === 0) {
      //   console.timeEnd(sujet.sujet)
      // }

      if (node.value !== undefined) {
        node.value[0] += sujet.amount
      } else {
        node.value = [sujet.amount, config.key, sujet] as any
      }

      if (lastLevel || !childNodes?.length) {
        if (node.children) {
          node.children.push(...(childNodes ?? []))
        } else {
          node.children = childNodes
        }
      }

      // map.set(levelName, {
      //   ...node,
      //   // value: [(node.value?.[0] as number || 0) + sujet.amount, config.key, sujet] as any,
      //   value: [(node.value?.[0] as number || 0) + sujet.amount, config.key, sujet] as any,
      //   children: lastLevel || (childNodes?.length ?? 0) === 0
      //     ? node.children
      //     // : treemapNode(filteredSujets, allSujets, keyConfigs, colorGetter, level + 1),
      //     : childNodes,
      // })
    } else {
      const style = categoryStyle(colorGetter, config.colorKey, levelName, sujet, false, level)

      const childNodes = !lastLevel ? treemapNode(filteredSujets, allSujets, keyConfigs, colorGetter, level + 1) : undefined

      map.set(levelName, {
        name: levelName,
        // value: [sujet.amount, config.key, sujet] as any,
        value: [sujet.amount, config.key, sujet] as any,
        itemStyle: {
          ...style.itemStyle as any,
        },
        upperLabel: {
          // color: "#000",
          textBorderColor: "transparent",
          ...style.upperLabel,
        },
        children: lastLevel || (childNodes?.length ?? 0) === 0 ? undefined : childNodes,
        //treemapNode(filteredSujets, allSujets, keyConfigs, colorGetter, level + 1)
      })
    }
  })

  return [...map.values()] //.sort((a, b) => (b.value as any) - (a.value as any))
}

type TreemapStyleOptions = {
  itemStyle?: TreemapSeriesOption["itemStyle"]
  label?: TreemapSeriesOption["label"]
  upperLabel?: TreemapSeriesOption["upperLabel"]
}

const categoryStyle: (
  colorGetter: ((category: string) => any) | undefined,
  key?: string,
  level?: string | number,
  sujet?: TSujetEntry,
  border?: boolean,
  levelIndex?: number
) => TreemapStyleOptions = (colorGetter, key, level, sujet, border, levelIndex) => {
  // if (!level || !(key === "category" || key === "media")) {
  if (!level || !key || levelIndex === 0) {
    return {}
  }

  const categoryName = sujet?.[key] ?? level
  const categoryColor = colorToRGBA(`${colorGetter?.(`${categoryName}`)}`, 1)

  const opacity = 0.4
  // lighten to match legend colors (which uses colors with opacity 0.4)
  const color = shadeColor(categoryColor, 1 - opacity)
  const borderColor = shadeColor(categoryColor, 1 - opacity - 0.1)
  const [contrastColor] = getContrastColor(color)
  const [contrastBorder] = getContrastColor(borderColor)
  // const borderColor = shadeColor(categoryColor, 0.3 + (contrastColor === "#fff" ? 0.1 : -0.2))

  return {
    itemStyle: {
      borderColor: border ? borderColor : undefined,
      color: color,
    },
    label: {
      color: contrastColor,
    },
    upperLabel: {
      color: contrastBorder,
    }
  }
}

// export const generateSujetsHash = (sujets: TSujetEntry[], pre: string): number => {
//   const hashContent = pre + sujets.map(s => s.sujet).sort().join(";")
//   return generateHash(hashContent)
// }

interface TreemapOptions {
  name: string
  chartModel: TreemapSeriesOption[]
  title: string
  subtitle: string
  isMobile: boolean,
  labelFormatter?: (p: any) => string,
  tooltipFormatter?: (p: any) => string,
  levels?: number
  roamEnabled?: boolean
  top?: number,
  showDownload?: boolean
}

export const defaultTreemapOptions: (options: TreemapOptions) => EChartsOption = ({
  name,
  chartModel,
  title,
  subtitle,
  isMobile,
  // formatter: (p: any) => string,
  levels,
  roamEnabled,
  top = 60,
  showDownload,
  labelFormatter,
  tooltipFormatter,
}) =>
  // isMobile
  //   ? {
  //     ...commonChartOptions(isMobile),
  //     series: {
  //       type: "sunburst",
  //       center: ["50%", "50%"],
  //       nodeClick: false,
  //       data: chartModel,
  //       radius: [30, "70%"],
  //       levels: [
  //         {},
  //         {
  //           r0: "35%",
  //           r: "90%",
  //           label: {
  //             show: true,
  //             rotate: "radial",
  //             width: 100,
  //             status: "normal",
  //             overflow: "truncate",
  //             position: "left",
  //             formatter,
  //           },
  //           emphasis: {
  //             rotate: 0,
  //             focus: "ancestor",
  //             label: {
  //               formatter,
  //             },
  //           },
  //           downplay: {
  //             label: {
  //               rotate: "radial",
  //               formatter,
  //             },
  //           },
  //           itemStyle: {
  //             borderWidth: 3,
  //           },
  //         },
  //         {
  //           r0: "90%",
  //           r: "98%",
  //           label: {
  //             show: false,
  //             position: "outside",
  //             rotate: 0,
  //             padding: 3,
  //             silent: false,
  //             formatter,
  //           },
  //           emphasis: {
  //             rotate: 0,
  //             focus: "ancestor",
  //             label: {
  //               formatter,
  //             },
  //           },
  //           downplay: {
  //             label: {
  //               rotate: 0,
  //               formatter,
  //             },
  //           },
  //           itemStyle: {
  //             borderWidth: 3,
  //           },
  //         },
  //       ],
  //       label: {
  //         fontFamily: getComputedStyle(
  //           document.documentElement
  //         ).getPropertyValue("--font-family"),
  //       },
  //     },
  //     animation: process.env.NODE_ENV !== "development",
  //     title: {
  //       ...commonChartOptions(isMobile).title,
  //       text,
  //       subtext,
  //     },
  //   }
    // :
    ({
      // dataset is the "new way" for data, instead of series.data option
  
      series: {
        name,
        data: chartModel,
        type: "treemap",
        top: top,
        // maximum number of levels shown in the treemap
        // everything over this will be shown on click on a node
        // on click "zooms" in and sets the clicked item as new "root"
        leafDepth: levels,
        // leafDepth: levels > 3 || (chartModel.length === 1 && (chartModel[0].data?.length ?? 0) <= 3) ? 3 : 2,
        nodeClick: "link",
        // minimum size in square-pixels to be shown
        visibleMin: isMobile ? 2000 : 1000,
        // minimum size in square-pixels to show children, below it is shown grouped
        childrenVisibleMin: isMobile ? 20000 : 15000,
        // dataGroupId
        // see https://echarts.apache.org/en/option.html#series-treemap.label.formatter
        label: {
          fontFamily: getComputedStyle(
            document.documentElement
          ).getPropertyValue("--font-family"),
          formatter: labelFormatter ?? (treemapLabelFormatter as any),
        },
        labelLayout: (params) => {
          // console.log("labelLayout", params, params.rect)

          return {
              fontSize: Math.max(Math.min(Math.sqrt(params.rect.width * params.rect.height) / (isMobile ? 20 : 24), 16), 12) * (isMobile ? 0.95 : 1)
          }
        },
        upperLabel: {
          show: true,
          // height: 30,
          fontFamily: getComputedStyle(
            document.documentElement
          ).getPropertyValue("--font-family"),
          fontWeight: "bold",
          formatter: "{b}",
          textBorderColor: "transparent",
        },
        center: ["50%", "55%"],
        radius: [30, "70%"],
        roam: roamEnabled || "move",
        // levels: [
        //   {},
        //   {
        //     r0: isMobile ? "35%" : "15%",
        //     r: isMobile ? "90%" : "70%",
        //     label: {
        //       show: true,
        //       rotate: "radial",

        //       align: "right",
        //       overflow: "truncate",
        //       formatter,
        //     },
        //     emphasis: {
        //       rotate: 0,
        //       focus: "ancestor",
        //       label: {
        //         formatter,
        //       },
        //     },
        //     downplay: {
        //       label: {
        //         rotate: "radial",
        //         formatter,
        //       },
        //     },
        //     itemStyle: {
        //       borderWidth: 2,
        //     },
        //   },
        //   {
        //     r0: "70%",
        //     r: "80%",
        //     label: {
        //       show: true,
        //       position: "outside",
        //       rotate: 0,
        //       padding: 2,
        //       silent: false,
        //       formatter,
        //     },
        //     emphasis: {
        //       rotate: 0,
        //       focus: "ancestor",
        //       label: {
        //         formatter,
        //       },
        //     },
        //     downplay: {
        //       label: {
        //         rotate: 0,
        //         formatter,
        //       },
        //     },
        //     itemStyle: {
        //       borderWidth: 2,
        //     },
        //   },
        // ],
        // # levels
        levels: [
          {
            itemStyle: {
              borderWidth: 0,
              gapWidth: 5
            },
          },
          {
            // colorAlpha: [0.7, 1],
            itemStyle: {
              gapWidth: 2,
            },
          },
          {
            // colorSaturation: [0.35, 0.5],
            itemStyle: {
              gapWidth: 1,
              borderColorSaturation: 0.6,
            },
            upperLabel: {
              // textBorderColor: "transparent",
              color: "#fff",
            }
          },
          {
            // colorSaturation: [0.35, 0.5],
            itemStyle: {
              gapWidth: 1,
              // borderColorSaturation: 0.6,
            },
            upperLabel: {
              // textBorderColor: "transparent",
              // color: "#fff",
            }
          }
        ]
      },
      animation: process.env.NODE_ENV !== "development",
      toolbox: {
        show: true,
        orient: "horizontal",
        left: "right",
        top: isMobile ? "bottom" : "top",
        feature: {
          mark: { show: true },
          dataView: { show: false, readOnly: false },
          magicType: { show: false, type: ["line", "bar", "stack"] },
          restore: { show: false },
          saveAsImage: {
            show: showDownload ?? true,
            icon: DownloadIcon,
          },
          // dataZoom: { show: true, type: ["zoom"] },
        },
      },
      tooltip: {
        formatter: tooltipFormatter ?? (treemapTooltipFormatter(true, isMobile) as any),
        confine: isMobile,
      },
      title: {
        ...commonChartOptions(isMobile).title,
        text: title,
        subtext: subtitle,
      },
    } as EChartsOption);


// gets the "data" from TreemapItem from the respective treemap node
// export const treemapTooltipFormatter = <T extends any[]>(filterable: boolean) => (info: TreemapParams<T>) => {
export const treemapTooltipFormatter = (filterable: boolean, isMobile?: boolean) => (info: SujetTreemapParams) => {
  const { name, value, treePathInfo, dataIndex } = info
  const treePath = treePathInfo // as TreePathNode[]

  const amount: number = typeof value === "number"  ? value : value[0]
  // const sujetIndex: number = typeof value === "number"  ? undefined : value?.[1]

  const key = typeof value === "number"  ? undefined : value?.[1]
  const sujet = typeof value === "number"  ? undefined : value?.[2]

  const fmt = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
  });

  const fmtPercent = new Intl.NumberFormat(i18n.language, {
    style: "percent",
    minimumFractionDigits: 2,
    maximumSignificantDigits: 2,
  });

  // console.warn("treepath", info, amount)

  const preKeys: Record<string, string> = {
    media: t("to2"),
    category: t("Advertising"),
    organisation: t("from"),
    campaign: t("Campaign"),
    fileName: t("Advertisement"),
  }

  const ancestorPath = treePath.filter(x => x.name).slice(1, -1).map((node) => {
    const key: keyof TSujetEntry | undefined = typeof node.value === "number"  ? undefined : node.value?.[1]
    const pre = key ? preKeys[key] ?? key : key

    // ignore media and organisation in path, they are always shown below with from/to
    if (key === "media" || key === "organisation") {
      return null
    }

    return `${pre ? pre + ": " : ""}${node.name}`
  }).filter(x => x)

  const fullPath = ancestorPath.join(" <br /> ")

  const previousNode = treePath.slice().reverse().find((x, i) => i !== 0 || treePath.length <= 1)
  const previousAmount = typeof previousNode?.value === "number" ? previousNode?.value : previousNode?.value[0]
  const totalAmount = typeof treePath[0]?.value === "number" ? treePath[0]?.value : treePath[0]?.value[0]

  const valuePercent = amount / (previousAmount ?? amount)
  const valuePercentTotal = amount / (totalAmount ?? amount)

  const defaultName = treePath.length === 1 ? t("Total") : ""
  const preName = key ? preKeys[key] : ""

  const parentNameShort = shortenName(treePath[treePath.length - 2]?.name, isMobile ? 80 : undefined)
  const parentName = treePath.length > 1 ? ` ${t("of")} ${parentNameShort}` : ""
  // don't show parent percent if it is on the second level, so parent == total
  const parentPercent = treePath.length > 2 ? `${fmtPercent.format(valuePercent)}${parentName}, ` : ""

  const parentPercentInfo = `${parentPercent}${fmtPercent.format(valuePercentTotal)} ${t("total")}`

  const showFilterInfo = filterable && ["media", "mediaOwner", "organisation"].includes(key ?? "")
  // const showSujetDetails = key === "fileName"
  const showSujetDetails = document.documentElement.getAttribute(`data-treemap-root-data-index`) === dataIndex.toString()

  const from = `${t("from")}: ${lineBreakHtml(sujet?.organisation, 8, isMobile ? 40 : undefined) ?? ""}`
  const to = `${t("to2")}: ${lineBreakHtml(sujet?.mediaOwner, 6, isMobile ? 40 : undefined) ?? ""}`

  if (treePath.length === 1) {
    return (`
      ${preName ? preName + ": " : ""}
      <b>${name || defaultName}</b>
      <br /><br />

      ${fmt.format(amount ?? value)} (${fmtPercent.format(valuePercent)})
    `)
  }

  return (
    `
      ${preName ? preName + ": " : ""}
      <b>${lineBreakHtml(name, preName.length * 2.3) || defaultName}</b>
      ${fullPath ? `<br /> ${fullPath}` : ""} <br /><br />

      ${sujet?.organisation ? `${from} <br />` : ""}
      ${sujet?.mediaOwner ? `${to} <br />` : ""}

      ${fmt.format(amount ?? value)} (${lineBreakHtml(parentPercentInfo, 0, isMobile ? 30 : 1000)})

      <br />
      ${!showSujetDetails ? `<br />${t("Click to show sujet details")}` : ""}
      ${showFilterInfo ? `<br />${t("Double click to filter")}` : ""}
      ${showSujetDetails ? `<br />${t("Click to show sujet")}` : ""}
     `
  );
};

export const treemapLabelFormatter = (info: SujetTreemapParams) => {
  const { name, value, dataIndex } = info

  const amount: number = typeof value === "number"  ? value : value[0]
  const key = typeof value === "number"  ? undefined : value?.[1]
  const sujet = typeof value === "number"  ? undefined : value?.[2]
  // const echartsInstance = typeof value === "number"  ? undefined : value?.[3]

  // console.log("treemapLabelFormatter", info, echartsInstance)

  const fmt = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
  });

  if (key === "fileName") {
    const rootDataIndex = document.documentElement.getAttribute(`data-treemap-root-data-index`)

    if (rootDataIndex === dataIndex.toString()) {
      return `
        {header|${sujet?.fileName}}\n

        {label|${t("Organisation")}}: {title|${sujet?.organisation}}\n
        {label|${t("Media")}}: {title|${sujet?.media}}\n
        {label|${t("Media Owner")}}: {title|${sujet?.mediaOwner}}\n
        {label|${t("Category")}}: {title|${sujet?.category}}\n
        {label|${t("Campaign")}}: {title|${sujet?.campaign || t("Not assigned")}}\n
        {label|${t("Amount")}}: {title| ${fmt.format(amount)}}\n
        {thumbnailLarge|}
      `
    }

    return `${name}\n${fmt.format(amount)}\n{thumbnail|}`
  }

  return `${name}\n${fmt.format(amount)}`
}
