import React, { useContext, useEffect, useMemo } from 'react';
import { CampaignDocument } from '../../../../server/models/sujets';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../..';
import { useTranslation } from 'react-i18next';
import { getTranslatedPaginationOptions, isEqual, isObjectSubset, toCurrency } from '../../helpers/helpers';
import { getCampaigns, getEventsFrom, objectToParams } from '../../services/data-service';
import { Box, Button, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { infoSlice } from '../../App';
import { ModalLoader, ShowError, Render } from '../../components/helper-components';
import { AuthContext } from '../../context/auth-context';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { CustomDataTable } from '../../components/data-table/DataTable';
import { CampaignDetails } from '../../components/ads/campaign/CampaignDetails';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from "query-string";
import { Hint } from '../../components/info';

interface CampaignQuery {
  organisation: string;
  campaign: string;
    // amount: number;
}

interface StoredQuery extends CampaignQuery {
    sortOrder: 'desc' | 'asc';
    sortBy: keyof CampaignDocument | null;
    size: number;
    page: number;
}

interface ICampaignState {
    campaignList: CampaignDocument[];
    error?: string;
    pending: boolean;
    pristine: boolean;
    //message?: string;
    query: CampaignQuery;
    storedQuery: StoredQuery;
    sortBy: keyof CampaignDocument | null;
    sortOrder: 'desc' | 'asc';
    page: number;
    size: number;
    count: number;
    needsUpdate: boolean;
}

const initialState: ICampaignState = {
    campaignList: [],
    pending: false,
    pristine: true,
    needsUpdate: true,
    sortBy: null,
    sortOrder: 'asc',
    page: 1,
    size: 10,
    count: 0,
    query: {} as CampaignQuery,
    storedQuery: {} as StoredQuery
}

export const campaignReducers = {
    setPending: (state: any, action: PayloadAction<boolean>) => {
        state.pending = action.payload;
    },
    setTouched: (state: any) => {
        state.pristine = false;
    },
    setError: (state: any, action: PayloadAction<string>) => {
        state.error = action.payload;
    },
    setCampaignList: (state: any, action: PayloadAction<CampaignDocument[]>) => {
        state.campaignList = action.payload;
        state.needsUpdate = false;
    },
    setQuery: (state: any, action: PayloadAction<CampaignQuery>) => {
        state.query = action.payload;
    },
    setCount: (state: any, action: PayloadAction<number>) => {
        state.count = action.payload;
    },
    setPage: (state: any, action: PayloadAction<number>) => {
        state.needsUpdate = true;
        state.page = action.payload;
    },
    setSize: (state: any, action: PayloadAction<number>) => {
        state.needsUpdate = true;
        state.size = action.payload;
    },
    setNeedsUpdate: (state: any, action: PayloadAction<boolean>) => {
        state.needsUpdate = action.payload;
    },
    setStoredQuery: (state: any, action: PayloadAction<StoredQuery>) => {
        state.storedQuery = action.payload;
    },
    setSortBy: (state: any, action: PayloadAction<string>) => {
        state.needsUpdate = true;
        state.sortBy = action.payload;
    },
    setSortOrder: (state: any, action: PayloadAction<'desc' | 'asc'>) => {
        state.needsUpdate = true;
        state.sortOrder = action.payload;
    },
    setInitialQuery: (state: any, action: PayloadAction<StoredQuery>) => {
      const { sortBy, sortOrder, page, size, ...query } = action.payload;
      return {
        ...state,
        query: { ...query, },
        sortBy: sortBy,
        sortOrder: sortOrder,
        page: page,
        size: size,
      };
    },
}

export const campaignSlice = createSlice({
    name: 'sujets',
    initialState,
    reducers: campaignReducers
});

const { setMessage } = infoSlice.actions

const { setNeedsUpdate, setPending, setError,
    setCampaignList, setQuery, setCount, setSortBy, setSortOrder,
    setPage, setSize, setStoredQuery,
    setInitialQuery, setTouched
  } = campaignSlice.actions;

const updateFilter = (name: string, filterValue: string) => {
  const filterElem = document.querySelector<HTMLInputElement>(`.rdt_Table .rdt_TableHeadRow input[data-filter-id][name='${name}']`)
  // console.warn("Setting filterElem1", filterElem)
  if (filterElem && filterElem.value !== filterValue) {
    filterElem.value = filterValue || ""
    filterElem.dispatchEvent(new Event('change'))
  }
}

const getFilterValue = (name: string) => {
  const filterElem = document.querySelector<HTMLInputElement>(`.rdt_Table .rdt_TableHeadRow input[data-filter-id][name='${name}']`)

  if (filterElem && filterElem.value) {
    return filterElem.value
  }
  return null
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const Campaigns: React.FC = () => {
    const { needsUpdate, campaignList, pending, storedQuery, sortBy, sortOrder,
        query, page, size, count, error, pristine } = useSelector((state: AppState) => state.campaign);
    const { t, i18n } = useTranslation();
    const { hasRole } = useContext(AuthContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = useQuery();

    const updateCampaigns = async () => {
      try {
        dispatch(setError(""));
        dispatch(setPending(true));
        await getEventsFrom("/api/transfers/toCampaigns", msg => dispatch(setMessage(msg)));
        dispatch(setNeedsUpdate(true))
      } catch (error: any) {
        dispatch(setError(error['message'] ? error.message : error));
      } finally {
        dispatch(setPending(false));
      }
    }

    const columns = useMemo(() => [
        {
            name: t('organisation'),
            selector: row => row['organisation'],
            sortable: true,
            filterable: true,
            wrap: true,
            width: '36%'
        },
        {
            name: t('campaign'),
            selector: row => row['campaign'],
            sortable: true,
            filterable: true,
            wrap: true,
            width: '30%'
        },
        {
            name: t('amount'),
            selector: row => row['amount'],
            sortable: true,
            filterable: false,
            format: toCurrency("amount"),
            right: true,
            width: '15%'
        },
        {
            name: t('numberOfAds'),
            selector: row => row['numberOfAds'],
            sortable: true,
            filterable: false,
            width: '8%'
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [t, i18n.language]);

    const namesToColumns = useMemo(() => ({
        [t('organisation')]: 'organisation',
        [t('campaign')]: 'campaign',
        [t('amount')]: 'amount',
        [t('numberOfAds')]: 'numberOfAds',
    }), [t]);

    useEffect(() => {
      // console.warn("query", pristine, query, sortOrder, sortBy, size, page, location.search)

      const initialQuery = {
        organisation: "",
        campaign: "",
      }
      const queryObj = qs.parse(location.search);
      ["organisation", "campaign",].forEach((param) => {
        if (queryObj[param]) {
          initialQuery[param] = queryObj[param];
        }
      });

      // const queryString = objectToParams(query);
      const newQuery = Object.fromEntries(
        Object.entries({ ...(!pristine ? query : initialQuery), sortOrder, sortBy, size, page })
          .filter(([_, value]) => value)
      );

      const queryString = objectToParams(newQuery);
      const currentQueryStringObj = qs.parse(location.search);
      const queryStringObjFromQueryObject = qs.parse(queryString);
      console.log(
        "isEqual: " +
        isEqual(currentQueryStringObj, queryStringObjFromQueryObject)
      );

      if (!isEqual(currentQueryStringObj, qs.parse(queryString))) {
        navigate(`/ads/campaigns?${queryString}`, {
          replace: Object.keys(currentQueryStringObj).length !== Object.keys(queryStringObjFromQueryObject).length,
        });
      }

      dispatch(setNeedsUpdate(true));
    }, [query, sortOrder, sortBy, size, page]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      // console.warn("location", location.search, location.key)

      let q = {
        organisation: "",
        campaign: "",
      };
      ["organisation", "campaign"].forEach((param) => {
        if (queryParams.get(param)) {
          q[param] = queryParams.get(param);
        }
        // else {
        //   q[param] = "";
        // }
      });

      // console.warn("location subset", !isObjectSubset(q, query), q, query)
      if (!isObjectSubset(q, query)) {
        // updateFilter(t('organisation'), q?.organisation ?? "")
        // updateFilter(t('campaign'), q?.campaign ?? "")
        dispatch(setQuery(q))
        dispatch(setNeedsUpdate(true));
      }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      // console.warn("needsUpdate",
      //   needsUpdate, campaignList.length, storedQuery, query, sortOrder, sortBy, size, page,
      //   isEqual(storedQuery, { ...query, sortOrder, sortBy, size, page }, [])
      // )

      if (campaignList.length === 0 || needsUpdate) {

        if (campaignList.length > 0 && isEqual(storedQuery, { ...query, sortOrder, sortBy, size, page }, [])) {
          dispatch(setNeedsUpdate(false))
          return
        }

        const initialQuery = {} as CampaignQuery
        const queryObj = qs.parse(location.search);
        ["organisation", "campaign",].forEach((param) => {
          if (queryObj[param]) {
            initialQuery[param] = queryObj[param];
          }
        });

        const newQuery = pristine && location.search.length > 0 ? initialQuery : query
          // (Object.fromEntries(Object.entries(query).filter(([_, value]) => value)) as CampaignQuery)

        // console.warn("needsUpdate newQuery", pristine, newQuery, query, storedQuery)

        dispatch(setPending(true))
        getCampaigns(page, size, sortBy ?? '', sortOrder, newQuery)
          .then(({ data, count }) => {
            dispatch(setCount(count))
            dispatch(setCampaignList(data))
          }).then(() => {
            dispatch(setStoredQuery({ ...newQuery, sortOrder, sortBy, size, page }))
            // update filters
            updateFilter(t('organisation'), newQuery.organisation)
            updateFilter(t('campaign'), newQuery.campaign)

            if (pristine) {
              dispatch(setQuery({ ...newQuery }))
            }
          })
          .catch(err => {
            err.message ? setError(err.message) : setError(err)
          })
          .finally(() => {
            dispatch(setPending(false))
          })
      }
    }, [needsUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      // console.warn("initialQuery", location.search, pristine)

      // Fill the initial state with the default values from the settings
      const initialQuery: StoredQuery = {
        sortBy: null,
        sortOrder: 'asc',
        page: 1,
        size: 10,
        organisation: "",
        campaign: "",
      }

      if (pristine && location.search.length === 0) {
        dispatch(setInitialQuery(initialQuery));
      } else if (pristine && location.search.length > 0) {
        // Fill the initial state with the values from the query string
        const queryObj = qs.parse(location.search);

        ["organisation", "campaign"].forEach((param) => {
          if (queryObj[param]) {
            initialQuery[param] = queryObj[param];
          }
        });
        const sortByKeys = ["organisation", "campaign", "amount", "numberOfAds"]
        if (queryObj["sortBy"] && sortByKeys.includes(queryObj["sortBy"] as string)) {
          initialQuery.sortBy = queryObj["sortBy"] as keyof CampaignDocument;
        }
        if (queryObj["sortOrder"] === "desc") {
          initialQuery.sortOrder = "desc";
        }
        ["page", "size"].forEach((param) => {
          const paramNumber = Number(queryObj[param] as string)
          if (paramNumber) {
            initialQuery[param] = paramNumber;
          }
        })

        // console.warn("initialQuery params", location.search, initialQuery)

        dispatch(setInitialQuery(initialQuery));
      }
      dispatch(setTouched());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <div className='campaigns'>
      <ModalLoader isPending={pending} />
      <ShowError error={error} onClose={updateCampaigns} />
      <Render when={hasRole("admin")}>
        <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
          <Button variant="contained" onClick={updateCampaigns} startIcon={<ChecklistIcon />}
            color="primary" >{t('Update List of Campaigns')}</Button>
        </Box>
      </Render>

      {/* pending={JSON.stringify(pending)} <br />
      needsUpdate={JSON.stringify(needsUpdate)} <br />
      query={JSON.stringify(query)} <br />
      storedQuery={JSON.stringify(storedQuery)} */}
        <Grid
          container
          justifyContent="flex-end"
          data-test-id="timelineForecastToggle"
        >
          <Hint translationKey={`additional_info_sujets_campaigns`} />
        </Grid>
      <Card>
        <CardHeader title={t("Campaigns")} />
        <CardContent className='no-padding'>
          <CustomDataTable
            columns={columns}
            data={campaignList}
            pagination
            paginationServer
            onChangePage={e => dispatch(setPage(e))}
            onChangeRowsPerPage={e => dispatch(setSize(e))}
            paginationTotalRows={count}
            progressPending={pending}
            defaultSortFieldId={sortBy ?? undefined}
            defaultSortAsc={sortOrder === 'asc'}
            paginationDefaultPage={page || undefined}
            paginationPerPage={size || undefined}
            expandableRows
            paginationComponentOptions={ getTranslatedPaginationOptions(t) }
            expandableRowsComponent={({ data }) =>
              <CampaignDetails data={data} />
            }
            filterDebounce
            onFilter={e => {
              // console.warn("onFilter", pristine, e)
              if (pristine) {
                return
              }

              const queryFilter = {
                organisation: getFilterValue(t('organisation')) ?? "",
                campaign: getFilterValue(t('campaign')) ?? "",
              }

              const queryColumns = Object.values(e).reduce((acc, { column, value }) => ({
                ...acc,
                [namesToColumns[column.name?.toString() ?? "test"]]: value
              }), {} as CampaignQuery)

              // console.warn("onFilter new", pristine, e, queryColumns, queryFilter)

              dispatch(setQuery({ ...queryFilter, ...queryColumns }))
              dispatch(setNeedsUpdate(true))
            }}
            sortServer
            onSort={(column, sortDirection) => {
              dispatch(setSortOrder(sortDirection))
              dispatch(setSortBy(namesToColumns[column.name?.toString() ?? ""]))
              //console.log(column, sortDirection)
            }}
          />
        </CardContent>
      </Card>
    </div>
}
