import { useTranslation } from "react-i18next";

import React from "react";
import { Popup } from "./popup";
import Button from "@mui/material/Button";
import { icons } from "./multi-select/group-member-selector";

export const PercentageError: React.FC<{ show: boolean; setShow: any, errorEntities: {name:string, type: 'org'|'media'|'mediaOwner', percent:number}[] }> = ({
  show,
  setShow,
  errorEntities
}) => {
  const { t } = useTranslation();
  return (
    <Popup show={show} setShow={setShow} title={t("Attention!")} description={
      <>
              <p>
          {t(
            "A group entry was assigned more than 100% across all groupings at one time!"
          )}
        </p>
        {errorEntities.map(({name, type, percent}, index) => (
          <p>{icons[type]} {name} ({percent}%)</p>
        ))}
      </>
    } footer={<Button color="primary" variant="contained" onClick={() => setShow(false)}>
    OK
  </Button>} />
  );
};
