import { slugifyCategory } from "../helpers/helpers";
import { Region } from "../models/models";
import { ColorsModuleSettings } from "../pages/colors.settings";
import { IBaseQuery, IBaseWithoutData } from "../states/base_states";

export type TopRecord = {
    organisation: string;
    mediaOwner?: string;
    total: number;
    isGrouping?: boolean;
    children?: TopRecord[];
  };

  export type TTopResult = {
    top: TopRecord[];
    all: number;
    groupings?: [
      {
        members: any[];
        name: string;
        owner?: string;
      }
    ];
  };

  export interface ITopTransferQuery extends IBaseQuery {
    groupType?: string;
    federalState?: Region;
    x: number;
    onlyGroups: boolean;
    resultType: "org" | "media" | "mediaOwner";
    pType: 2 | 4 | 31 | number[];
  }


  export interface TableRecord {
    organisation: string;
    group: string;
    total: number;
  }
  export interface ITopTransferState extends IBaseWithoutData<ITopTransferQuery> {
    chartModel?: TopRecord[];
    sunburstModel: ReturnType<typeof resultToSunburstModel>;
    mediaGroupType: string;
    onlyMediaGroups: boolean;
    orgGroupType: string;
    onlyOrgGroups: boolean;
    otherMediaDisabled: boolean;
    switchState: number;
    groupedByCategory: boolean;
    data?: TTopResult,
    colors: ColorsModuleSettings;
    maxNumberOfGroupMembers: number;
    table: TableRecord[]
  }

  export const resultToSunburstModel = (data: TTopResult, state: ITopTransferState) => {

    return [
      ...data.top.map((t) => {
        if (t.children && t.children.length > 0) {
          const children = t.children.sort((a, b) => b.total - a.total);
          const maxNumberOfGroupMembers = state.maxNumberOfGroupMembers;
          return {
            name: t.organisation || t.mediaOwner,
            value: t.total,
            ...(state.groupedByCategory ? {itemStyle: { color: state.colors[slugifyCategory(t.organisation)]}} : {}),
            percent: Math.round((t.total / data.all) * 10000) / 100,
            children: (children.length > maxNumberOfGroupMembers ? [...children.slice(0,maxNumberOfGroupMembers), { 
              organisation: 'Others', 
              mediaOwner: 'Others',
              total: children.slice(maxNumberOfGroupMembers).reduce((acc, {total}) => acc + total, 0),
            }] :children).map((c) => ({
              name: c.organisation || t.mediaOwner || "not set",
              type: c.organisation ? "organisation" : "mediaOwner",
              mediaOwner: c.mediaOwner,
              value: c.total,
              ...(state.groupedByCategory ? {itemStyle: { 
                color: state.colors[slugifyCategory(t.organisation)],
                opacity: 0.5
              }} : {}),
              percent: Math.round((c.total / data.all) * 10000) / 100,
            })),
          }
        } else return {
            name: t.organisation ? t.organisation : "not set",
            type: t.organisation ? "organisation" : "mediaOwner",
            mediaOwner: t.mediaOwner,
            percent: Math.round((t.total / data.all) * 10000) / 100,
            value: t.total,
          }
        }   
      ),
      {
        name: "Others",
        value: data.all - data.top.reduce((acc, { total }) => acc + total, 0),
        percent:
          Math.round(
            ((data.all - data.top.reduce((acc, { total }) => acc + total, 0)) /
              data.all) *
            10000
          ) / 100,
      },
    ];
  };

  export const resultToChartModel = (data: TTopResult): TopRecord[] => [
    ...data.top,
    {
      organisation: "Others",
      total: data.all - data.top.reduce((acc, { total }) => acc + total, 0),
    },
  ];
