import "./App.scss";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GroupListEntry,
} from "./services/data-service";
import { Blog } from "./pages/blog/blog";
import { GroupType } from "./models/models";

export type TPeriodsResponse = {
  quarters: number[];
  halfyears: number[];
};

export type TInfoState = {
  periods: number[];
  quarters: number[];
  fundingPeriods: number[];
  halfyears: number[];
  useHalfyears: boolean;
  groups: GroupListEntry[];
  groupTypes: GroupType[],
  pending: boolean;
  groupsEnabled: string;
  payerGroupsEnabled: string;
  beneficiaryGroup: string;
  otherMediaDisabled: boolean;
  periodsFunding: number[];
  mixedPeriods: number[];
  showPaymentTypeDetail: boolean;
  fundingGroupsEnabled: string;
  showPaymentType: boolean;
  otherReceiverFundingEnabled: boolean;
  showPaymentTypeFundingDetail: boolean;
  initialized: boolean;
  showAbsoluteVisualisation: boolean;
  blogs: Blog[];
  newsSeen: boolean;
  adsSeen: boolean;
  adsCount: number;
  appVersion: string;
  lastChange?: Date;
  lastImport?: Date;
  message: String;
};

export const infoSlice = createSlice({
  name: "info",
  initialState: {
    periods: [],
    fundingPeriods: [],
    quarters: [],
    halfyears: [],
    groups: [],
    useHalfyears: true,
    groupTypes: [],
    groupsEnabled: '',
    payerGroupsEnabled: '',
    beneficiaryGroup: '',
    otherMediaDisabled: false,
    showPaymentTypeDetail: false,
    showPaymentType: false,
    pending: true,
    periodsFunding: [],
    mixedPeriods: [],
    fundingGroupsEnabled: '',
    otherReceiverFundingEnabled: false,
    showPaymentTypeFundingDetail: false,
    initialized: false,
    blogs: [],
    showAbsoluteVisualisation: false,
    newsSeen: false,
    adsSeen: false,
    adsCount: 0,
    appVersion: '',
    message: "",
  } as TInfoState,
  reducers: {
    setPeriods: (state, action: PayloadAction<TPeriodsResponse>) => ({
      ...state,
      periods: state.useHalfyears ? action.payload.halfyears : action.payload.quarters,
      quarters: action.payload.quarters,
      halfyears: action.payload.halfyears,
    }),
    setPeriodsFunding: (state, action: PayloadAction<number[]>) => ({
      ...state,
      periodsFunding: action.payload,
    }),
    setMixedPeriods: (state, action: PayloadAction<number[]>) => ({
      ...state,
      mixedPeriods: action.payload,
    }),
    setGroups: (state, action: PayloadAction<GroupListEntry[]>) => ({
      ...state,
      groups: action.payload,
    }),
    setGroupTypes: (state, action: PayloadAction<GroupType[]>) => ({
      ...state,
      groupTypes: action.payload,
    }),
    setPending: (state, action: PayloadAction<boolean>) => ({
      ...state,
      pending: action.payload,
    }),
    setGroupsEnabled: (state, action: PayloadAction<string>) => ({
      ...state,
      groupsEnabled: action.payload,
    }),
    setPayerGroupsEnabled: (state, action: PayloadAction<string>) => ({
      ...state,
      payerGroupsEnabled: action.payload,
    }),
    setBeneficiaryGroup: (state, action: PayloadAction<string>) => ({
      ...state,
      beneficiaryGroup: action.payload,
    }),
    setOtherMediaDisabledEnabled: (state, action: PayloadAction<boolean>) => ({
      ...state,
      otherMediaDisabled: action.payload,
    }),
    setFundingGroupsEnabled: (state, action: PayloadAction<string>) => ({
      ...state,
      fundingGroupsEnabled: action.payload,
    }),
    setShowPaymentType: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showPaymentType: action.payload,
    }),
    setShowPaymentTypeDetail: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showPaymentTypeDetail: action.payload,
    }),
    setOtherReceiverFundingEnabled: (state, action: PayloadAction<boolean>) => ({
      ...state,
      otherReceiverFundingEnabled: action.payload,
    }),
    setShowPaymentTypeFundingDetail: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showPaymentTypeFundingDetail: action.payload,
    }),
    setShowAbsoluteVisualisation: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showAbsoluteVisualisation: action.payload,
    }),
    setInitialized: (state, action: PayloadAction<void>) => ({
      ...state,
      initialized: true,
    }),
    setBlogs: (state, action: PayloadAction<Blog[]>) => ({
      ...state,
      blogs: action.payload,
    }),
    setNewsSeen: (state, action: PayloadAction<void>) => ({
      ...state,
      newsSeen: true,
    }),
    setAdsCount: (state, action: PayloadAction<number>) => ({
      ...state,
      adsCount: action.payload,
    }),
    setAdsSeen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      adsSeen: action.payload,
    }),
    setAppVersion: (state, action: PayloadAction<string>) => ({
      ...state,
      appVersion: action.payload,
    }),
    setLastChange: (state, action: PayloadAction<Date>) => ({
      ...state,
      lastChange: action.payload,
    }),
    setLastImport: (state, action: PayloadAction<Date>) => ({
      ...state,
      lastImport: action.payload,
    }),
    setFundingPeriods: (state, action: PayloadAction<number[]>) => ({
      ...state,
      fundingPeriods: action.payload,
    }),
    setUseHalfyears: (state, action: PayloadAction<boolean>) => ({
      ...state,
      useHalfyears: action.payload,
      periods: action.payload ? state.halfyears : state.quarters,
    }),
    setMessage: (state, action: PayloadAction<string>) => ({
      ...state,
      message: action.payload,
    }),
  },
});


export const mergePeriods = (transferPeriod: number[], fundingPeriods: number[]) => {
  let periodsForTimeline: number[] = [];
  const transferYears = transferPeriod.map(it => +(it.toString()).substring(0, 4)).concat(fundingPeriods.filter(it => it !== 0));

  const minPeriod = Math.min.apply(Math, transferYears);
  const maxPeriod = Math.max.apply(Math, transferYears);

  if (isFinite(minPeriod)) {
    for (let i = minPeriod; i <= maxPeriod; i++)
      periodsForTimeline.push(i);
  }
  return periodsForTimeline;
}

/*
const SearchField: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  return <Form inline>
    <FormControl type="text" placeholder="Search"
      onChange={e => {
        dispatch(searchActions.setName(e.target.value))
      }}
      onKeyPress={e => {
        console.log(e)
        if (e.key === 'Enter') {
          dispatch(searchActions.setNeedsUpdate(true))
          history.push('/search')
        }
      }} className="mr-sm-2" />
    <Button variant="outline-success">Search</Button>
  </Form>
}
*/
