import { useEffect, useMemo, useState } from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import {
  getFlow,
  objectToParams,
  searchNames,
} from "../../services/data-service";
import {
  Help,
  ModalLoader,
  Render,
  ShowError,
} from "../../components/helper-components";
import { ResponsiveContainer } from "recharts";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faInfoCircle,
  faListUl,
  faStream,
} from "@fortawesome/free-solid-svg-icons";

import { infoSlice, TInfoState } from "../../App";
import qs from "query-string";
import {
  chartAriaDescription,
  getAmountRangeString,
  getCategoryColor,
  getGroupTypesByType,
  getTranslatedPaginationOptions,
  isEqual,
  isObjectSubset,
  isTouchSupported,
  lineBreakHtml,
  lineBreakName,
  periodToString,
  range,
} from "../../helpers/helpers";
import "./flows.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Badge,
  Box,
  Button,
  Grid,
  Tab as TabMui,
  Tabs as TabsMui,
} from "@mui/material";
import { getColor } from "../../colors";
import { ToggleSwitch } from "../../components/three-state/three-state-switch";
import DataTable from "react-data-table-component-with-filter";
import i18n from "../../i18n";
import { ExportToExcel } from "../../components/excel-exporter";
import ReactECharts from "echarts-for-react";
import "../top/top.scss";
import { useMediaQuery } from "react-responsive";
import { t } from "i18next";
import { SwitchButton } from "../../components/switch-button/switchButton";
import { TransferMultiSelectComp } from "../../components/multi-select/transfer-multi-select";
import { MultiSelectComp } from "../../components/multi-select/multi-select";
import {
  AmountSlider,
  PeriodSlider,
} from "../../components/slider/rangeSlider";
import useTouchDoubleClickChecker from "../../helpers/touchDoubleClickChecker";
import { GroupTypeSelector } from "../../components/group-type-selector";
import Config from "../../config/settings";
import {
  amountRangeReducers,
  extractAmountRange,
  IAmountFilterRange,
  IAmountRange,
  IBaseState,
  IOrgAndMediaQuery,
  orgAndMediaReducers,
} from "../../states/base_states";
import { summariseTransfers, SummerizeOptions } from "../../helpers/flows-transfer.helper";
import { Fold, SettingsViewer } from "../../components/settings-viewer";

import * as echarts from "echarts/core";
import german from "echarts/i18n/langDE-obj";

import { defaultBaseTimelineModel, defaultSankeyOptions } from "./flows";
import { useSettingsStore } from "../../context/SettingsStore";
import { Modules } from "../../models/modules";
import { FlowsTransferModuleSettings } from "./flows-transfer.settings";
import { defaultModuleSettings } from "../../context/SettingsContext";
import { MediaOwnerMultiSelectComp } from "../../components/multi-select/media-owner-multi-select";
import { IMedia } from "../../../../server/models/media";
import { defaultTreemapOptions, resultToTreemapModel, TreemapModelOptions, SujetTreemapParams } from "../../types/sujetTypes";
import { SujetViewModeSelector } from "../../components/sujet-view-mode-selector";
import { SujetDialog } from "../../components/dialog/SujetDialog";
import { ColorsModuleSettings } from "../colors.settings";
import { Legend } from "../../components/flows/Legend";
import { EChartsType } from "echarts";
import { Hint } from "../../components/info";
import { PhotoLibrary } from "@mui/icons-material";

//TODO: export filteredData to excel and add range to chart title

echarts.registerLocale("de", german);

const profile = <T extends any>(name: string, func: (...args: any[]) => T, ...args: any[]): T => {
  const startTime = performance.now();
  const result = func(...args);
  const endTime = performance.now();
  const executionTime = endTime - startTime;
  console.log(`Execution time of ${name}: ${executionTime} milliseconds`);
  return result;
};

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const minHeight = Config.flows.sankey.minHeight;

export const getOtherMediaName = () => {
  return t("Other media");
};

export const getOtherMediaNameForMedia = (media: string) => {
  return t("Other media") + " (" + media + ")";
};

export const getOtherOrgName = () => {
  return t("Other organisations");
};

export const getOtherOrgNameForOrg = (org: string) => {
  return t("Other organisations") + " (" + org + ")";
};

const linkOpacity = 0.4;

type TSankeyModel = {
  nodes: { name: string }[];
  links: {
    source: number;
    target: number;
    value: number;
    transferType: 2 | 4 | 31 | number[];
  }[];
  right?: string;
  lineStyle?: {
    color: string;
  };
  layoutIterations?: number;
  label?: any;
};

// type ReactECharts | null;
let echartRef, treemapRef;

export type TSujetResponseEntry = {
  amount: number;
  sujet: string;
  fileName: string;
  fileSize: number;
  mimeType: string;
  subCategory: string;
  campaign: string;
  halfyear: number;
}

export type TSujetEntry = TSujetResponseEntry & {
  organisation: string;
  media: string;
  mediaOwner: string;
  orgGroup: string;
  category: string;
};

export type TreemapRoot = {
  dataIndices: number[],
  seriesId: string,
  isLeaf: boolean,
  sujet?: TSujetEntry,
  // from: string,
  // targetNode: {
  //   dataIndex: number,
  //   depth: number,
  //   height: number,
  //   isExpand: boolean,
  //   name: string,
  // },
};

export type TFlowsResultRecord = {
  amount: number;
  media: string;
  mediaOwner: string;
  mediaGroup?: string;
  orgGroup?: string;
  organisation: string;
  transferType: 2 | 4 | 31 | number[];
  category?: string;
  sujets: TSujetResponseEntry[];
  subCategories: string[];
  numberOfSujets: number;
};



const toCurrency = (field: string) => (row: any) =>
  row[field]?.toLocaleString(i18n.language, {
    style: "currency",
    currency: "EUR",
  });

export type TFlowResult = TFlowsResultRecord[];

export type TTimelineResult = { period: string; total: number }[];

export type TFlowsQueryResult = {
  flows: TFlowResult;
  timeline: TTimelineResult;
};

export interface IFlowsQuery extends IOrgAndMediaQuery, IAmountFilterRange {
  showAbsoluteValues?: boolean;
  summariseValues?: boolean;
  sujetViewMode?: string
}

const fillupMissingQuarters = (period: string) => {
  const re = /([A-Za-zÀ-ž ]*)(\d)\/(\d{4})/
  const [name, q, year] = period.match(re)?.slice(1) || ["1", "1", "1"]
  if (parseInt(q, 10) > 1) {
    return range(1, parseInt(q, 10) - 1).map((q_) => ({
      period: `${name}${q_}/${year}`,
      quarter: q_,
      year: parseInt(year, 10),
      total: 0,
    }));
  }
  return [];
};

const timelineToEcharModel = (data: TTimelineResult) => [
  ...fillupMissingQuarters(data[0].period),
  ...data.map((e) => ({
    ...e,
    quarter: parseInt((e.period.match(/[A-Za-zÀ-ž ]*(\d)\/\d{4}/) || [])[1]),
    year: parseInt(e.period.split("/")[1]),
  })),
];

export interface IFlowState
  extends IBaseState<IFlowsQuery, TFlowResult>,
  IAmountRange<IFlowsQuery, TFlowResult> {
  timeline: TTimelineResult;
  timelineModel: ReturnType<typeof timelineToEcharModel>;
  chartModel: TSankeyModel;
  selectedOrganisaions: string[];
  organisationsList: string[];
  switchState: number;
  activeTab: number;
  sujets: TSujetEntry[];
  filteredData: TFlowResult;
  summarizeSwitchDisabled: boolean;
  logarithmSwitchDisabled: boolean;
  treemapModel: any[];
  treemapRoot: TreemapRoot | null
}

export const flowsSlice = createSlice({
  name: "flows",
  initialState: {
    pristine: true,
    data: [] as TFlowResult,
    filteredData: [] as TFlowResult,
    timeline: [] as any[] as TTimelineResult,
    switchState: 2,
    timelineModel: [],
    activeTab: 0,
    amountRange: [0, 0],
    sujets: [],
    query: {
      showAbsoluteValues: true,
      summariseValues: false,
      pType: 2,
      organisations: defaultModuleSettings.flows_transfer.defaultOrganisations,
      orgGroups: [],
      media: defaultModuleSettings.flows_transfer.defaultMedia,
      mediaOwner: [],
      mediaGroups: [],
      mediaGroupType: "",
      orgGroupType: "",
      slots: "halfyears",
      amountFilterRange: [0, 0],
      // sujetViewMode: "Campaign",
    } as IFlowsQuery,
    pending: false,
    needsUpdate: false,
    chartModel: {
      node: [],
      nodes: [],
      links: [],
    } as TSankeyModel,

    selectedOrganisaions: [],
    organisationsList: [],
    summarizeSwitchDisabled: false,
    logarithmSwitchDisabled: false,
    treemapModel: [] as ReturnType<typeof resultToTreemapModel>,
    treemapRoot: null,
  } as IFlowState,
  reducers: {
    ...orgAndMediaReducers<IFlowsQuery, TFlowResult, IFlowState>(),
    ...amountRangeReducers<IFlowsQuery, TFlowResult, IFlowState>(),
    setData: (
      state,
      action: PayloadAction<{
        data: TFlowResult;
        showAbsoluteValues: boolean;
        summariseData: boolean;
        colorSettings: ColorsModuleSettings;
        summarizeOptions: SummerizeOptions
      }>
    ) => {
      const { data, showAbsoluteValues, summariseData } = action.payload;
      const amountRange = extractAmountRange(data);
      return {
        ...state,
        data: data,
        filtered: data,
        query: { ...state.query, amountFilterRange: amountRange, showAbsoluteValues },
        amountRange: amountRange,
        needsUpdate: false,
        sujets: extractSujets(data),
        chartModel: profile("responseToSankeyModel",
          responseToSankeyModel,
          data,
          showAbsoluteValues,
          summariseData,
          +state.query.from!,
          +state.query.to!,
          state.query.mediaOwner,
          action.payload.colorSettings,
          action.payload.summarizeOptions
        ),
        /*
        responseToSankeyModel(
          data,
          showAbsoluteValues,
          summariseData,
          +state.query.from!,
          +state.query.to!,
          state.query.mediaOwner,
          action.payload.colorGetterConfig,
        ),
        */
      };
    },
    setTimeline: (state, action: PayloadAction<TTimelineResult>) => ({
      ...state,
      timeline: action.payload,
      timelineModel: profile("timelineToEcharModel", timelineToEcharModel, action.payload) //timelineToEcharModel(action.payload),
    }),
    setQuery: (state, action: PayloadAction<IFlowsQuery>) => ({
      ...state,
      query: { ...state.query, ...action.payload },
      switchState: Array.isArray(action.payload.pType)
        ? (action.payload.pType as number[]).length > 1
          ? 0
          : +action.payload.pType[0]
        : +action.payload.pType,
    }),
    setSummariseValues: (state, action: PayloadAction<boolean>) => {
      state.query.summariseValues = action.payload;
    },
    updateFilteredData: (state, action: PayloadAction<void>) => {
      state.filteredData = state.data.filter((d) => {
        const amountFilterRange = state.query.amountFilterRange ?? [
          0,
          Number.MAX_VALUE,
        ];
        return (
          d.amount >= amountFilterRange[0] && d.amount <= amountFilterRange[1]
        );
      });
    },
    updateModelSankeyModel: (state, action: PayloadAction<{ colorSettings: ColorsModuleSettings } & SummerizeOptions>) => {
      state.chartModel = responseToSankeyModel(
        state.filteredData,
        state.query.showAbsoluteValues ?? true,
        state.query.summariseValues ?? false,
        +state.query.from!,
        +state.query.to!,
        state.query.mediaOwner,
        action.payload.colorSettings,
        { minAmountOfFlows: action.payload.minAmountOfFlows, percentThreshold: action.payload.percentThreshold }
      );
    },
    setMediaGroupType: (state, action: PayloadAction<string>) => ({
      ...state,
      query: {
        ...state.query,
        mediaGroupType: action.payload,
        mediaGroups: [],
      },
    }),
    setOrgGroupType: (state, action: PayloadAction<string>) => ({
      ...state,
      query: {
        ...state.query,
        orgGroupType: action.payload,
        orgGroups: [],
      },
    }),
    setShowAbsoluteValues: (
      state,
      action: PayloadAction<{
        showAbsoluteValues: boolean;
        summariseData: boolean;
        colorSettings: ColorsModuleSettings;
        summarizeOptions: SummerizeOptions;
      }>
    ) => {
      const { showAbsoluteValues, summariseData } = action.payload;
      return {
        ...state,
        query: { ...state.query, showAbsoluteValues: showAbsoluteValues },
        chartModel: responseToSankeyModel(
          state.filteredData,
          showAbsoluteValues,
          summariseData,
          +state.query.from!,
          +state.query.to!,
          state.query.mediaOwner,
          action.payload.colorSettings,
          action.payload.summarizeOptions,
        ),
      };
    },
    extendQuery: (state, action: PayloadAction<Partial<IFlowsQuery>>) => ({
      ...state,
      query: { ...state.query, ...action.payload },
      switchState: Array.isArray(action.payload.pType)
        ? (action.payload.pType as number[]).length > 1
          ? 0
          : +action.payload.pType[0]
        : +(action.payload.pType ?? 2),
    }),
    setActiveTab: (state, action: PayloadAction<number>) => ({
      ...state,
      activeTab: action.payload,
    }),
    setSummarizeSwitchDisabled: (state, action: PayloadAction<boolean>) => ({
      ...state,
      summarizeSwitchDisabled: action.payload
    }),
    setLogarithmSwitchDisabled: (state, action: PayloadAction<boolean>) => ({
      ...state,
      logarithmSwitchDisabled: action.payload
    }),
    initializeStateFromSettings: (
      state,
      action: PayloadAction<FlowsTransferModuleSettings>
    ) => {
      // Update the state with the initial values from the settings
      return {
        ...state,
        query: {
          ...state.query,
          organisations: action.payload.defaultOrganisations,
          orgGroups: action.payload.defaultOrgGroups,
          media: action.payload.defaultMedia,
          mediaGroups: action.payload.defaultMediaGroups,
          mediaGroupType: action.payload.defaultMediaGroupType,
          orgGroupType: action.payload.defaultOrgGroupType,
        },
      };
    },
    setSujetViewMode: (state, action: PayloadAction<string>) => {
      state.query.sujetViewMode = action.payload;
    },
    setTreemapModel: (state, action: PayloadAction<any[]>) => ({
      ...state,
      treemapModel: action.payload
    }),
    setTreemapRoot: (state, action: PayloadAction<TreemapRoot | null>) => ({
      ...state,
      treemapRoot: action.payload,
    }),
  },
});

const {
  setData,
  setPending,
  setQuery,
  setOrgGroupType,
  setMediaGroupType,
  setNeedsUpdate,
  clearNeedsUpdate,
  setTimeline,
  setRange,
  extendQuery,
  saveQuery,
  setShowAbsoluteValues,
  setTouched,
  setSummariseValues,
  setAmountFilterRange,
  setSujetViewMode,
  updateFilteredData,
  updateModelSankeyModel,
  initializeStateFromSettings,
  setSlots,
  setMedia,
  setMediaOwner,
  setSummarizeSwitchDisabled,
  setLogarithmSwitchDisabled,
  setTreemapModel,
  setTreemapRoot,
} = flowsSlice.actions;

const { setAdsSeen, setUseHalfyears } = infoSlice.actions;

type SankeySumType = {
  amount: number,
  numberOfSujets: number,
  categories: string[],
  subCategories: string[]
}

class SankeySum {
  private map: Map<string, SankeySumType> = new Map();

  add(key: string, amount: number, numberOfSujets: number, category: string, subCategories: string[]) {
    if (this.map.has(key)) {
      const current = this.map.get(key);
      this.map.set(key, {
        amount: (current!.amount || 0) + amount,
        numberOfSujets: (current!.numberOfSujets || 0) + numberOfSujets,
        categories: [...new Set([...(current!.categories || []), category])],
        subCategories: [...new Set([...(current!.subCategories || []), ...subCategories])]
      });
    } else {
      this.map.set(key, { amount, numberOfSujets, categories: category.trim().length > 0 ? [category] : [], subCategories });
    }
  }

  get(key: string): SankeySumType {
    return this.map.get(key) || { amount: 0, numberOfSujets: 0, subCategories: [], categories: [] } as SankeySumType;
  }

  has(key: string) {
    return this.map.has(key);
  }

  clear() {
    this.map.clear();
  }
}


const payerSumMap: Map<string, number> = new Map();
const flowSumMap: SankeySum = new SankeySum();
const receiverSumMap: Map<string, number> = new Map();
const groupsSet: Set<string> = new Set();


type LinkEndType = "org" | "media" | "orgGroup" | "mediaGroup" | "mediaOwner";

const extractSujets = (data: TFlowResult): TSujetEntry[] => {
  return data.reduce((acc, { sujets, organisation, media, mediaGroup, category, orgGroup }) => {
    sujets.forEach(sujet => {
      acc.push({
        ...sujet,
        organisation,
        media,
        mediaOwner: mediaGroup || media,
        category: category || "",
        orgGroup: orgGroup || "",
      })
    })
    return acc;
  }, [] as TSujetEntry[])
}

const responseToSankeyModel = (
  data: TFlowResult,
  showAbsoluteVisualisation: boolean,
  summariseData: boolean,
  pFrom: number,
  pTo: number,
  mediaOwners: String[],
  colorSettings: ColorsModuleSettings,
  summarizeOptions: SummerizeOptions,
): TSankeyModel => {
  const categoryColorGetter = getCategoryColor(colorSettings);
  payerSumMap.clear();
  receiverSumMap.clear();
  flowSumMap.clear();
  const rawNodes = data.reduce(
    (acc, { organisation, media, orgGroup, mediaGroup, mediaOwner, transferType }) => {
      acc = { ...acc, [organisation]: { nodeType: "org" }, ...(media ? { [media]: { nodeType: transferType === 2 ? "media" : "mediaOwner", mediaOwner } } : { [mediaOwner]: { nodeType: "mediaOwner" } }) }; //.add(orgGroup)
      if (orgGroup) {
        groupsSet.add(orgGroup);
        acc = { ...acc, [orgGroup]: "orgGroup" };
      }
      if (mediaGroup) {
        groupsSet.add(mediaGroup);
        acc = { ...acc, [mediaGroup]: "mediaGroup" };
      }
      if (mediaOwner && mediaOwners.includes(mediaOwner)) {
        acc = { ...acc, [mediaOwner]: "mediaOwner" };
      }
      return acc;
    },
    {}
  );

  let nodes = Object.entries(rawNodes).map(([k, v]) => ({
    name: k.startsWith("Other") ? getOtherMediaName() : k,
    type: (v as any)['nodeType'] || v,
    mediaOwner: (v as any)['mediaOwner'],
    label:
      (v as any)['nodeType'] === "media" || (v as any)['nodeType'] === "mediaOwner"
        ? {
          fontFamily: getComputedStyle(
            document.documentElement
          ).getPropertyValue("--font-family"),
          position: "insideRight",
          offset: [-20, 0],
        }
        : {
          fontFamily: getComputedStyle(
            document.documentElement
          ).getPropertyValue("--font-family"),
        },
    itemStyle: {
      color: Config.flows.sankey.nodesColor,
      borderColor: Config.flows.sankey.nodesColor,
    },
  }));
  const linkMaps = {} as {
    [id: string]: {
      type: LinkEndType;
      to: {
        [id: string]: {
          type: LinkEndType;
          [t: number]: {
            [category: string]: {
              amount: number;
              absolute: number;
              subCategories: string[];
              numberOfSujets: number;
            }
          }
        };
      };

    };
  };
  const addLink = (
    from,
    to,
    tType,
    amount,
    category: string,
    subCategories: string[],
    numberOfSujets: number,
    srcType: LinkEndType = "org",
    targetType: LinkEndType = "media",
    hasAd: boolean = false
  ) => {
    const visAmount = showAbsoluteVisualisation ? amount : Math.log10(amount);
    if (linkMaps[from]) {
      if (linkMaps[from].to[to]) {
        if (linkMaps[from].to[to][tType]) {
          if (linkMaps[from].to[to][tType][category]) {
            linkMaps[from].to[to][tType][category].amount += visAmount;
            linkMaps[from].to[to][tType][category].absolute += amount;
            linkMaps[from].to[to][tType][category].subCategories = [...new Set([...linkMaps[from].to[to][tType][category].subCategories, ...subCategories])];
            linkMaps[from].to[to][tType][category].numberOfSujets += numberOfSujets;
          } else {
            linkMaps[from].to[to][tType][category] = {
              amount: visAmount,
              absolute: amount,
              subCategories,
              numberOfSujets,
            };
          }
        } else {
          linkMaps[from].to[to][tType] = {
            [category]: {
              amount: visAmount,
              absolute: amount,
              subCategories,
              numberOfSujets,
            },
          };
        }
      } else {
        linkMaps[from].to[to] = {
          type: targetType,
          [tType]: {
            [category]: {
              amount: visAmount,
              absolute: amount,
              subCategories,
              numberOfSujets,
            },
          },
        };
      }
    } else {
      linkMaps[from] = {
        type: srcType,
        to: {
          [to]: {
            type: targetType,
            [tType]: {
              [category]: {
                amount: visAmount,
                absolute: amount,
                subCategories,
                numberOfSujets,
              },
            },
          },
        },
      };
    }
    if (payerSumMap.has(from))
      payerSumMap.set(from, payerSumMap.get(from) + visAmount);
    else payerSumMap.set(from, visAmount);

    const toKey = to.startsWith("Other") ? getOtherMediaName() : to;
    if (receiverSumMap.has(toKey))
      receiverSumMap.set(toKey, receiverSumMap.get(toKey) + visAmount);
    else receiverSumMap.set(toKey, visAmount);

    const flowKey = from + "||" + to;
    flowSumMap.add(flowKey, visAmount, numberOfSujets, category, subCategories);
  };

  data.forEach((l) => {
    let src = l.organisation;
    let srcType: LinkEndType = "org";
    if (l.orgGroup) {
      addLink(
        l.organisation,
        l.orgGroup,
        l.transferType,
        l.amount,
        l.category || "",
        l.subCategories,
        l.numberOfSujets,
        "org",
        "orgGroup"
      );
      src = l.orgGroup;
      srcType = "orgGroup";
    }
    if (l.mediaGroup) {
      addLink(
        src,
        l.mediaGroup,
        l.transferType,
        l.amount,
        l.category || "",
        l.subCategories,
        l.numberOfSujets,
        srcType,
        "mediaGroup"
      );
      if (l.mediaOwner && mediaOwners.includes(l.mediaOwner)) {
        addLink(
          l.mediaGroup,
          l.mediaOwner,
          l.transferType,
          l.amount,
          l.category || "",
          l.subCategories,
          l.numberOfSujets,
          "mediaGroup",
          "mediaOwner"
        );
        addLink(
          l.mediaOwner,
          l.media,
          l.transferType,
          l.amount,
          l.category || "",
          l.subCategories,
          l.numberOfSujets,
          "mediaOwner",
          "media"
        );
      } else {
        addLink(
          l.mediaGroup,
          l.media || l.mediaOwner,
          l.transferType,
          l.amount,
          l.category || "",
          l.subCategories,
          l.numberOfSujets,
          "mediaGroup",
          l.media ? "media" : "mediaOwner",
        )
      }
    } else {
      if (l.mediaOwner && mediaOwners.includes(l.mediaOwner)) {
        addLink(
          src,
          l.mediaOwner,
          l.transferType,
          l.amount,
          l.category || "",
          l.subCategories,
          l.numberOfSujets,
          srcType,
          "mediaOwner"
        );
        addLink(
          l.mediaOwner,
          l.media,
          l.transferType,
          l.amount,
          l.category || "",
          l.subCategories,
          l.numberOfSujets,
          "mediaOwner",
          "media"
        );
      } else {
        addLink(src,
          l.media || l.mediaOwner,
          l.transferType,
          l.amount,
          l.category || "",
          l.subCategories,
          l.numberOfSujets,
          srcType,
          l.media ? "media" : "mediaOwner",);
      }
    }
  });

  let links = Object.entries(linkMaps).reduce(
    (acc, [from, o]) => [
      ...acc,
      ...Object.entries(o.to).reduce(
        (acc_, [to, targetObj]) => [
          ...acc_,
          ...Object.keys(targetObj).filter(key => ["2", "4"].includes(key)).reduce((acc2, key) => {
            const typedFlow = targetObj[key];
            return Object.keys(typedFlow).reduce((acc2_, category) => {
              const targetObj_ = typedFlow[category];
              return [
                ...acc2_,
                {
                  source: from,
                  type: o.type,
                  srcType: o.type,
                  targetType: targetObj.type,
                  numberOfSujets: targetObj_.numberOfSujets,
                  category,
                  subCategories: targetObj_.subCategories,
                  target: to.startsWith("Other") ? getOtherMediaName() : to,
                  value: targetObj_.amount,
                  absolute: targetObj_.absolute,
                  transferType: key,
                  lineStyle: {
                    opacity: linkOpacity,
                    color:
                      key === "2"
                        ? categoryColorGetter(category)
                        : Config.flows.sankey.fundingFlowsColor,
                  },
                },
              ];
            }, acc2);
          }, [] as any),
        ],
        [] as any
      ),
    ],
    [] as any
  );

  if (summariseData) {
    const res = summariseTransfers(links, rawNodes, nodes, payerSumMap, receiverSumMap, summarizeOptions);
    links = res.sumLinks;
    nodes = res.sumNodes;
  }
  const nodeAbsoluteValueMap = new Map();
  links = links.map((link) => ({
    ...link,
    ...(!showAbsoluteVisualisation && link.target.startsWith(getOtherMediaName()) ? { value: Math.log10(link.absolute) } : {})
  }));

  links.forEach((link) => {
    if (link.srcType === "org" || link.srcType === "mediaGroup" || link.srcType === "mediaOwner") {
      if (nodeAbsoluteValueMap.has(link.source)) {
        nodeAbsoluteValueMap.set(link.source, nodeAbsoluteValueMap.get(link.source) + link.absolute);
      } else {
        nodeAbsoluteValueMap.set(link.source, link.absolute);
      }
    }
    if (link.targetType === "media" || link.targetType === "orgGroup" || (link.targetType === "mediaOwner" && +link.transferType === 4)) {
      if (nodeAbsoluteValueMap.has(link.target)) {
        nodeAbsoluteValueMap.set(link.target, nodeAbsoluteValueMap.get(link.target) + link.absolute);
      } else {
        nodeAbsoluteValueMap.set(link.target, link.absolute);
      }
    }
  }
  );
  nodes = nodes.map((node) => {
    const absolute = nodeAbsoluteValueMap.get(node.name);
    return {
      ...node,
      absolute: absolute,
      //value: showAbsoluteVisualisation ? absolute : Math.log10(absolute),
      /*
      label: {
        ...node.label,
        formatter: (params) => {
          const value = params.value;
          if (value === 0) {
            return "";
          }
          return showAbsoluteVisualisation
            ? value.toLocaleString(i18n.language, {
              style: "currency",
              currency: "EUR",
            })
            : Math.round(Math.pow(10, value)).toLocaleString(i18n.language, {
              style: "currency",
              currency: "EUR",
            });
        },
      },*/
    };
  });
  return {
    nodes: nodes,
    links: links,
    right: "5%",
    layoutIterations: summariseData ? 0 : 32,
    label: {
      borderWidth: 0,
      color: Config.flows.sankey.flowsLabelColor,
    },
  };
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const FlowsTransfers = () => {
  // const { setPayerGroupsEnabled, setBeneficiaryGroup, setShowAbsoluteVisualisation
  // } = infoSlice.actions
  const [error, setError] = useState("");
  const { tab } = useParams();
  const settings = useSettingsStore();
  const moduleSettings = settings.modules.flows_transfer;
  const colorSettings = settings.modules.colors;
  const navigate = useNavigate();
  const location = useLocation();
  const { periods, groups, groupTypes, adsSeen, useHalfyears } = useSelector<
    AppState,
    TInfoState
  >((state) => state.info);
  const [selectedMediaOwners, setSelectedMediaOwners] = useState<IMedia[]>([]);

  /*   const colorGetterConfig = useMemo(() => ({
      categories: sujetSettings.categories,
      paletteIndex: moduleSettings.categoryColorIndex
    }), [sujetSettings.categories, moduleSettings.categoryColorIndex]) */

  //const getCategoryColor = useMemo(() => createColorGetter(colorGetterConfig), [colorGetterConfig])

  const setTab = (newValue: string, updateRoute: boolean) => {
    if (tab != newValue && updateRoute && moduleSettings.enabled) {
      navigate(`/flows/transfers/${newValue}${location.search}`, {
        replace: location.search.length === 0,
      });
    }
    setUpdateRoute(true);
  };
  const isMobileLandscape = useMediaQuery({
    maxHeight: 575.98,
    orientation: "landscape",
  });
  const isMobilePortrait = useMediaQuery({ maxWidth: 600 });
  const isMobile = isMobileLandscape || isMobilePortrait;

  const { t, i18n } = useTranslation();
  const queryParams = useQuery();
  const dispatch = useDispatch();
  const {
    pending,
    needsUpdate,
    data,
    query,
    timeline,
    chartModel,
    switchState,
    timelineModel,
    lastQuery,
    pristine,
    amountRange,
    filteredData,
    sujets,
    summarizeSwitchDisabled,
    logarithmSwitchDisabled,
    treemapModel,
    treemapRoot,
  } = useSelector<AppState, IFlowState>((state) => state.flow);

  const periodToString_ = useMemo(() => periodToString(useHalfyears, t), [t, useHalfyears]);

  const toPaymentTypeText = (field: string) => (row: any) => {
    switch (row[field]) {
      case 2:
        return t("Advertising");
      case 4:
        return t("Funding");
      default:
        return t("Fees");
    }
  };

  useEffect(() => {
    dispatch(setSlots(useHalfyears ? "halfyears" : "quarters"))
  }, [useHalfyears])

  useEffect(() => {
    dispatch(updateFilteredData());
    dispatch(updateModelSankeyModel({
      colorSettings,
      minAmountOfFlows: moduleSettings.minAmountOfFlows,
      percentThreshold: moduleSettings.percentThreshold
    }));
  }, [query.amountFilterRange]);

  useEffect(() => {
    document["lastClick"] = Date.now();
  }, []);

  const handleClick = ({ data }) => {
    const selectedPeriod = data.year * 10 + data.quarter
    if (isTouchSupported()) {
      const now = Date.now();
      const lastClick = document["lastClick"];
      // console.log('lastClick', lastClick)
      if (now - lastClick < 600) {
        return selectPeriod(selectedPeriod);
      } else {
        document["lastClick"] = now;
      }
    } else {
      return selectPeriod(selectedPeriod);
    }
  };

  const timelineTooltipFormatter = (params) => {
    params = params instanceof Array ? params : [params];
    const includesForecast = params.filter((e) => e.data.prediction).length > 0;
    const fmt = new Intl.NumberFormat(i18n.language, {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });
    let d = params
      .map(
        (p) =>
          `${p.marker} ${p.seriesName}${p.data["prediction"] ? "*" : ""
          } <span style="float:right;margin-left:20px;font-size:14px;color:${p.data["prediction"] ? "#aaa" : "#666"
          };font-weight:900">${fmt.format(
            p.data[p.dimensionNames[p.encode.y]]
          )}</span>`
      )
      .join("<br/>");
    if (params.length > 1) {
      if (!includesForecast) {
        const s = params
          .filter((e) => !e.data.prediction)
          .reduce((acc, p) => acc + p.data[p.dimensionNames[p.encode.y]], 0);
        d = `${d}<br/><span style="font-weight:900">${t(
          "Total"
        )}</span><span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${fmt.format(
          s
        )}</span>`;
      } else {
        if (includesForecast) {
          const s = params.reduce(
            (acc, p) => acc + p.data[p.dimensionNames[p.encode.y]],
            0
          );
          d = `${d}<br/>${t(
            "Total"
          )}*<span style="float:right;margin-left:20px;font-size:14px;color:#aaa;font-weight:900">${fmt.format(
            s
          )}</span>`;
          d += `<br>*) ${t("Forecast")}`;
        }
      }
    }
    return `${params[0].name}<br/>${d}`;
  };

  const [colors, setColors] = useState<string[]>([]);

  const theme = useMemo(() =>
    colors.length > 0 ? { color: colors } : {},
    [colors]
  )

  useEffect(() => {
    const colorsFromCSS = range(1, 9).map(i =>
      getComputedStyle(document.documentElement)
        .getPropertyValue(`--echart-color-${i}`)
        .trim()
    ).filter(c => c.length > 0)
    setColors(colorsFromCSS);
  }, []);

  const getColorFromIndex = (idx: number, count: number) => {
    return colors.length > 0 ? colors[idx] : getColor(idx, count); // Return color if available, otherwise empty string
  };

  const timelineOptions = useMemo(
    () => {
      const title = t("flows_timeline_title", {
        paymentType: t(
          query.pType == 2
            ? "advertising_payments"
            : query.pType == 4
              ? "funding_payments"
              : "advertising_and_funding_payments"
        ),
      })
      const subtitle = t("flows_transfer_sub_title", {
        payers:
          query.organisations.length > 0 || (query.orgGroups?.length ?? 0) > 0
            ? [...query.organisations, ...(query.orgGroups ?? [])].join(", ")
            : t("all"),
        receivers:
          query.media.length > 0 || (query.mediaGroups?.length ?? 0) > 0
            ? [...query.media, ...(query.mediaGroups ?? [])].join(", ")
            : t("all"),
      })
      return{
      ...defaultBaseTimelineModel(
        isMobile,
        timelineTooltipFormatter,
        title,
        subtitle +
        `\n${t("Source")}: KommAustria, ${t(
          "Date"
        )}: ${new Date().toLocaleString()}` +
        `\nLink: ${window.location.href}`,
        true
      ),
      ...chartAriaDescription(t, title, subtitle),
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
        formatter: timelineTooltipFormatter,
      },
      xAxis: { type: "category" },
      yAxis: {
        axisLabel: {
          formatter: (value) => `${value / 1000000} Mio.`,
        },
      },
      dataset: [
        {
          dimensions: ["year", "total", "quarter", "transferType"],
          source: timelineModel,
        },
        {
          transform: {
            type: "filter",
            config: {
              and: [{ dimension: "quarter", "=": 1 }],
            },
            print: false,
          },
        },
        {
          transform: {
            type: "filter",
            config: {
              and: [{ dimension: "quarter", "=": 2 }],
            },
            print: false,
          },
        },
        {
          transform: {
            type: "filter",
            config: {
              and: [{ dimension: "quarter", "=": 3 }],
            },
            print: false,
          },
        },
        {
          transform: {
            type: "filter",
            config: {
              and: [{ dimension: "quarter", "=": 4 }],
            },
            print: true,
          },
        },
      ],
      series: range(1, useHalfyears ? 2 : 4).map((i) => ({
        type: "bar",
        datasetIndex: i,
        name: (useHalfyears ? t("Halfyear") : "Q") + i,
        emphasis: { focus: "series" },
        color: getColorFromIndex(i, useHalfyears ? 2 : 4),
        barCategoryGap: "10%",
      })),
      onClick: () => alert("hi!"),
      animation: process.env.NODE_ENV !== "development",
    }},
    [t, timelineModel, colors, i18n.language, useHalfyears]
  );

  useEffect(() => {
    if (query.media.length + query.mediaOwner.length != selectedMediaOwners.length) {
      setSelectedMediaOwners([
        ...query.media.map<IMedia>(name => ({ name, type: "media", group: [] })),
        ...query.mediaOwner.map<IMedia>(name => ({ name, type: "mediaOwner", group: [] }))
      ].sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [query.media, query.mediaOwner]);

  useEffect(() => {
    if (periods && periods.length > 0) {
      //console.log("Setting from and to!!!")
      //console.log("Periods: " + JSON.stringify(periods, null, 2))
      const maxPeriod = periods[periods.length - 1];
      //console.log("Maxperiod: " + maxPeriod)
      if (!query.from || !query.to || !periods.includes(query.from) || !periods.includes(query.to)) {
        dispatch(
          setRange({
            from: maxPeriod,
            to: maxPeriod,
          })
        );
      }
    }
  }, [periods]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    //if (queryStringParsed) {
    //    setQueryStringParsed(false)
    //} else {
    if (
      periods &&
      periods.length > 0 &&
      query.organisations.length +
      query.media.length +
      query.mediaOwner.length +
      (query.orgGroups ?? []).length +
      (query.mediaGroups ?? []).length >
      0 &&
      query.from &&
      query.to
    ) {
      //console.log("Checking query vs. location")
      const queryString = objectToParams(query);
      const currentQueryStringObj = qs.parse(location.search);
      const queryStringObjFromQueryObject = qs.parse(queryString);
      console.log(
        "isEqual: " +
        isEqual(currentQueryStringObj, queryStringObjFromQueryObject)
      );
      if (!isEqual(currentQueryStringObj, qs.parse(queryString))) {
        navigate(`/flows/transfers/${tab}?${queryString}`, {
          replace: Object.keys(currentQueryStringObj).length !== Object.keys(queryStringObjFromQueryObject).length,
        });
      }
      //    }
      dispatch(setNeedsUpdate());
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //console.log("Location changed")
    let q = {
      pType: [2],
      organisations: [],
      orgGroups: [],
      media: [],
      mediaOwner: [],
      mediaGroups: [],
      orgGroupType: "",
      mediaGroupType: "",
      slots: "halfyears" as "halfyears" | "quarters",
    };
    [
      "organisations",
      "media",
      "mediaOwner",
      "pType",
      "orgGroups",
      "mediaGroups",
      "amountFilterRange",
    ].forEach((org) => {
      if (queryParams.getAll(org).length > 0) {
        q[org] = queryParams.getAll(org).filter((e) => e.length > 0);
      } else if (org !== "amountFilterRange") {
        q[org] = [];
      }
    });
    ["orgGroupType", "mediaGroupType"].forEach((org) => {
      if (queryParams.get(org)) {
        q[org] = queryParams.get(org);
      }
    });
    ["showAbsoluteValues", "summariseValues"].forEach((org) => {
      if (queryParams.get(org)) {
        q[org] = queryParams.get(org) === "true";
      }
    });
    ["from", "to"].forEach((org) => {
      if (queryParams.get(org)) {
        q[org] = parseInt(queryParams.get(org) ?? "0");
      }
    });
    if (queryParams.get("slots")) {
      q.slots = queryParams.get("slots") as "quarters" | "halfyears";
      if ((queryParams.get("slots") === "quarters" && useHalfyears) ||
        (queryParams.get("slots") === "halfyears" && !useHalfyears)) {
        dispatch(setUseHalfyears(false))
      }
    }
    if (queryParams.get("sujetViewMode")) {
      if (["Campaign", "Category"].includes(queryParams.get("sujetViewMode") ?? "")) {
        q["sujetViewMode"] = queryParams.get("sujetViewMode")
      }
    }

    if (
      q.organisations.length +
      q.media.length +
      q.mediaOwner.length +
      (q.orgGroups ?? []).length +
      (q.mediaGroups ?? []).length >
      0 &&
      q["from"] &&
      q["to"]
    ) {
      if (!isObjectSubset(q, query)) {
        //setSelectedMediaOwners([] as IMedia[])
        dispatch(extendQuery(q));
        dispatch(setNeedsUpdate());
      }
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const clearModels = () => {
    dispatch(setData({
      data: [],
      showAbsoluteValues: query.showAbsoluteValues ?? false,
      summariseData: query.summariseValues ?? false,
      colorSettings,
      summarizeOptions: {
        minAmountOfFlows: moduleSettings.minAmountOfFlows,
        percentThreshold: moduleSettings.percentThreshold
      }
    }));
    dispatch(setTreemapModel([]));
    dispatch(setTreemapRoot(null));
  }

  useEffect(() => {
    console.log(
      "Cached: " +
      (lastQuery != undefined &&
        isEqual(lastQuery, query, [
          "showAbsoluteValues",
          "summariseValues",
          "sujetViewMode"
        ]))
    );
    if (
      needsUpdate &&
      query.organisations.length +
      query.media.length +
      query.mediaOwner.length +
      (query.orgGroups ?? []).length +
      (query.mediaGroups ?? []).length >
      0 &&
      query.from &&
      query.to &&
      (lastQuery == undefined ||
        !isEqual(lastQuery, query, [
          "showAbsoluteValues",
          "summariseValues",
          "amountFilterRange",
          "sujetViewMode",
        ]))
    ) {
      setError("");
      //console.log("Sending new query")
      dispatch(saveQuery());
      dispatch(setPending(true));
      const startTime = performance.now();
      clearModels();
      getFlow(query)
        .then(({ timeline, flows }) => {
          console.log(`Execution time for loading flows data: ${performance.now() - startTime} milliseconds`)
          dispatch(
            setTimeline(
              timeline.map(({ period, total }) => ({
                total,
                period: periodToString_(parseInt(period)),
              }))
            )
          );
          if (flows.length > (isMobile ? moduleSettings.maxNumberOfFlowsMobile : moduleSettings.maxNumberOfFlows)) {
            dispatch(setSummariseValues(true))
            dispatch(setSummarizeSwitchDisabled(true))
            dispatch(setLogarithmSwitchDisabled(true))

          } else {
            dispatch(setSummarizeSwitchDisabled(false))
            dispatch(setLogarithmSwitchDisabled(false))
          }
          dispatch(
            setData({
              data: flows,
              showAbsoluteValues: query.showAbsoluteValues ?? true,
              summariseData: (flows.length > moduleSettings.maxNumberOfFlows) || (query.summariseValues ?? false),
              colorSettings,
              summarizeOptions: {
                minAmountOfFlows: moduleSettings.minAmountOfFlows,
                percentThreshold: moduleSettings.percentThreshold
              }
            })
          );
          dispatch(setAdsSeen(false))
        })
        .catch((err) => {
          setError(err?.response?.data?.message ?? err.message);
          dispatch(clearNeedsUpdate());
          clearModels()
        })
        .finally(() => dispatch(setPending(false)));
    } else if (needsUpdate) {
      dispatch(clearNeedsUpdate());
    }
  }, [needsUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Fill the initial state with the default values from the settings
    if (pristine && location.search.length == 0) {
      console.log(location.search)
      const settingsToLoad = structuredClone(moduleSettings);
      settingsToLoad.defaultOrgGroupType =
        getGroupTypesByType(groupTypes, "org")
          .find((g) => g.name === moduleSettings.defaultOrgGroupType)
          ?._id?.toString() ?? "";
      settingsToLoad.defaultMediaGroupType =
        getGroupTypesByType(groupTypes, "media")
          .find((g) => g.name === moduleSettings.defaultMediaGroupType)
          ?._id?.toString() ?? "";

      dispatch(initializeStateFromSettings(settingsToLoad));
    }
    dispatch(setTouched());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const timelineColums = useMemo(
    () => [
      {
        name: t("Period"),
        selector: (row) => row["period"],
        sortable: true,
        maxWidth: "200px",
        wrap: true,
      },
      {
        name: t("Amount"),
        selector: (row) => row["total"],
        sortable: true,
        format: toCurrency("total"),
        right: true,
        width: "30%",

      },
    ],
    [t, i18n.language]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo(
    () => [
      {
        name: t("Payer"),
        selector: (row) => row["organisation"],
        sortable: true,
        wrap: true,
      },
      ...(query.orgGroupType
        ? [
          {
            name: t("Payer group"),
            selector: (row) => row["orgGroup"],
            sortable: true,
            wrap: true,
          },
        ]
        : []),
      {
        name: t("Beneficiary"),
        selector: (row) => row["media"],
        sortable: true,
        wrap: true,
      },
      {
        name: t("Media Owner"),
        selector: (row) => row["mediaOwner"],
        sortable: true,
        wrap: true,
      },
      ...(query.mediaGroupType
        ? [
          {
            name: t("Beneficiary group"),
            selector: (row) => row["mediaGroup"],
            sortable: true,
            wrap: true,
          },
        ]
        : []),
      {
        name: t("Payment type"),
        selector: (row) => row["transferType"],
        sortable: true,
        format: toPaymentTypeText("transferType"),
        width: "12%",
      },
      {
        name: t("media_category"),
        selector: (row) => row["category"],
        sortable: true,
        format: row => t(row["category"]),
        wrap: true,
        width: "12%",
      },
      {
        name: t("Amount"),
        selector: (row) => row["amount"],
        sortable: true,
        format: toCurrency("amount"),
        right: true,
        width: "12%",
      },
    ],
    [t, i18n.language, query.orgGroupType, query.mediaGroupType]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const adsColumns = useMemo(
    () => [
      {
        name: t("Period"),
        selector: (row) => row["halfyear"],
        format: (row) => periodToString_(row["halfyear"]),
        sortable: true,
        wrap: true,
      },
      {
        name: t("Payer"),
        selector: (row) => row["organisation"],
        sortable: true,
        wrap: true,
      },
      ...(query.orgGroupType
        ? [
          {
            name: t("Payer group"),
            selector: (row) => row["orgGroup"],
            sortable: true,
          },
        ]
        : []),
      {
        name: t("Beneficiary"),
        selector: (row) => row["media"],
        sortable: true,
        wrap: true,
      },
      // ...(query.mediaGroupType
      //   ? [
      //     {
      //       name: t("Beneficiary group"),
      //       selector: (row) => row["mediaGroup"],
      //       sortable: true,
      //     },
      //   ]
      //   : []),
      {
        name: t("Campaign"),
        selector: (row) => row["campaign"],
        sortable: true,
        wrap: true,
      },
      {
        name: t("Category"),
        selector: (row) => row["category"],
        sortable: true,
        wrap: true,
      },
      {
        name: t("Subcategory"),
        selector: (row) => row["subCategory"],
        sortable: true,
        wrap: true,
      },
      {
        name: t("Amount"),
        selector: (row) => row["amount"],
        sortable: true,
        format: toCurrency("amount"),
        right: true,
      },
    ],
    [t, i18n.language]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const sujetViewMode = useMemo<string>(() => {
    if (["Campaign", "Category"].includes(query.sujetViewMode ?? "")) {
      return t(query.sujetViewMode)
    } else {
      return t("Campaign")
    }
  }, [query.sujetViewMode, i18n.language])

  const sujetViewModes = useMemo(() => ({
    [t("Campaign")]: "Campaign",
    [t("Category")]: "Category",
  }), [i18n.language])

  const [orgSelected, setOrgSelected] = useState(query.organisations.length)
  useEffect(() => {
    setOrgSelected(query.organisations.length)
    // const newSelected = query.organisations.length
    // if (newSelected !== orgSelected) {
    //   setOrgSelected(newSelected)
    // }
  }, [query.organisations.length])

  const [orgGroupSelected, setOrgGroupSelected] = useState(query.orgGroups?.length ?? 0)
  useEffect(() => {
    setOrgGroupSelected(query.orgGroups?.length ?? 0)
    // const newSelected = query.orgGroups?.length ?? 0
    // if (newSelected !== orgGroupSelected) {
    //   setOrgGroupSelected(newSelected)
    // }
  }, [query.orgGroups?.length])

  useEffect(() => {
    if (!query.showAbsoluteValues) {
      dispatch(setSummariseValues(false))
    }
  }, [query.showAbsoluteValues])

  const [mediaSelected, setMediaSelected] = useState(query.media.length + (query.mediaGroups?.length ?? 0))
  useEffect(() => {
    setMediaSelected(query.media.length + (query.mediaGroups?.length ?? 0))
    // const newSelected = query.media.length + (query.mediaGroups?.length ?? 0)
    // if (newSelected !== mediaSelected) {
    //   setMediaSelected(newSelected)
    // }
  }, [query.media.length, query.mediaGroups?.length])

  const [mediaOwnerSelected, setMediaOwnerSelected] = useState(query.mediaOwner.length)
  useEffect(() => {
    setMediaOwnerSelected(query.mediaOwner.length)
    // const newSelected = query.mediaOwner.length
    // if (newSelected !== mediaOwnerSelected) {
    //   setMediaOwnerSelected(newSelected)
    // }
  }, [query.mediaOwner.length])

  // default: org, campaign, category

  const treemapKeys = useMemo<TreemapModelOptions["keys"]>(() => {
    const viewMode = sujetViewModes[sujetViewMode] ?? "Campaign"

    if (orgSelected === 1 && mediaSelected === 1) {
      if (viewMode === "Campaign") {
        return [
          { key: "campaign", fallback: t("Not assigned") },
          { key: "category", colorKey: "category" },
          { key: "fileName" },
        ]
      } else {
        return [
          { key: "campaign", fallback: t("Not assigned") },
          { key: "category", colorKey: "category" },
          { key: "fileName" },
        ]
      }
    } else if (mediaSelected === 1) {
      if (viewMode === "Campaign") {
        return [
          { key: "organisation" },
          { key: "campaign", fallback: t("Not assigned"), colorKey: "category" },
          { key: "fileName" },
        ]
      } else {
        return [
          { key: "organisation" },
          { key: "category", colorKey: "category" },
          { key: "campaign", fallback: t("Not assigned") },
          { key: "fileName" },
        ]
      }
    } else if (mediaOwnerSelected === 1) {
      if (viewMode === "Campaign") {
        return [
          { key: "organisation" },
          { key: "campaign", fallback: t("Not assigned") },
          { key: "media", colorKey: "category" },
          { key: "fileName" },
        ]
      } else {
        return [
          { key: "media" },
          { key: "category", colorKey: "category" },
          { key: "campaign", fallback: t("Not assigned") },
          { key: "fileName" },
        ]
      }
    } else if (orgSelected === 1) {
      if (viewMode === "Campaign") {
        return [
          { key: "campaign", fallback: t("Not assigned") },
          { key: "media", colorKey: "category" },
          { key: "fileName" },
        ]
      } else {
        return [
          { key: "category" },
          { key: "campaign", fallback: t("Not assigned"), colorKey: "category" },
          { key: "media" },
          { key: "fileName" },
        ]
      }
    } else if (mediaSelected > 1) {
      if (viewMode === "Campaign") {
        return [
          { key: "organisation" },
          { key: "campaign", fallback: t("Not assigned") },
          { key: "media", colorKey: "category" },
          { key: "fileName" },
        ]
      } else {
        return [
          { key: "organisation" },
          { key: "category", colorKey: "category" },
          { key: "media" },
          { key: "fileName" },
        ]
      }
    } else if (mediaOwnerSelected > 1) {
      if (viewMode === "Campaign") {
        return [
          { key: "media" },
          { key: "campaign", fallback: t("Not assigned"), colorKey: "category" },
          { key: "organisation" },
          { key: "fileName" },
        ]
      } else {
        return [
          { key: "organisation" },
          { key: "category", colorKey: "category" },
          { key: "media" },
          { key: "fileName" },
        ]
      }
    } else if (orgSelected > 1 || orgGroupSelected > 0) {
      if (viewMode === "Campaign") {
        return [
          { key: "organisation" },
          { key: "campaign", fallback: t("Not assigned") },
          { key: "media", colorKey: "category" },
          { key: "fileName" },
        ]
      } else {
        return [
          { key: "organisation" },
          { key: "category", colorKey: "category" },
          { key: "media" },
          { key: "fileName" },
        ]
      }
    } else {
      if (viewMode === "Campaign") {
        return [
          { key: "organisation" },
          { key: "campaign", fallback: t("Not assigned") },
          { key: "media", colorKey: "category" },
          { key: "fileName" },
        ]
      } else {
        return [
          { key: "organisation" },
          { key: "category", colorKey: "category" },
          { key: "media" },
          { key: "fileName" },
        ]
      }
    }
  }, [orgSelected, mediaSelected, mediaOwnerSelected, sujetViewMode, i18n.language])

  useEffect(() => {
    if (treemapModel.length === 0) {
      const model = profile("resultToTreemapModel", () =>
        resultToTreemapModel(sujets, {
          keys: treemapKeys,
          colorSettings,
          echartsRef: treemapRef?.getEchartsInstance(),
        }))
      dispatch(setTreemapModel(model as any[]))
    }
    /*
     resultToTreemapModel(sujets, {
     keys: treemapKeys,
     categoryColorGetter: createColorGetter(colorGetterConfig),
     echartsRef: treemapRef?.getEchartsInstance(),
   }
   */
  }, [sujets, treemapKeys, colorSettings, treemapRef])

  const [roamEnabled, setRoamEnabled] = useState(false)

  const treemapOptions = useMemo(() => {
    const title = lineBreakName(
      t("flows_ads_title", {
        paymentType: t(
          query.pType == 2
            ? "advertising_payments"
            : query.pType == 4
              ? "funding_payments"
              : "advertising_and_funding_payments"
        ),
        period:
          query.to !== query.from
            ? `${periodToString_(query.from ?? 0)} - ${periodToString_(
              query.to ?? 0
            )}`
            : periodToString_(query.to ?? 0),
      }),
      "\n", 0, isMobile ? document.documentElement.clientWidth / 14 : 1000)
    const subText = t("flows_transfer_sub_title", {
      payers:
        query.organisations.length > 0 || (query.orgGroups?.length ?? 0) > 0
          ? [...query.organisations, ...(query.orgGroups ?? [])].join(", ")
          : t("all"),
      receivers:
        query.media.length > 0 || (query.mediaGroups?.length ?? 0) > 0
          ? [...query.media, ...(query.mediaGroups ?? [])].join(", ")
          : t("all"),
    })
    return {
      ...defaultTreemapOptions({
        name: sujetViewMode == t('Campaign') ? t("All Campaigns") : t("All Categories"),
        chartModel: treemapModel ?? [],
        isMobile,
        levels: 2, // treemapKeys.length,
        roamEnabled,
        top: isMobileLandscape ? 90 : 100,
        // treemapTooltipFormatter,
        title,
        subtitle: subText +
          `\n${t("Source")}: KommAustria, ${t(
            "Date"
          )}: ${new Date().toLocaleString()}` +
          `\nLink: ${window.location.href}`,
      }),
      ...chartAriaDescription(t, title, subText, false),
    }
  },
    [treemapModel, treemapKeys.length, i18n.language, sujetViewMode, roamEnabled])

  const updateSelection =
    (orgType: "org" | "media" | "orgGroups" | "mediaGroups") =>
      (list: string[]) => {
        setError("");
        const fields = {
          org: "organisations",
          media: "media",
          orgGroups: "orgGroups",
          mediaGroups: "mediaGroups",
        };
        dispatch(
          setQuery({
            ...query,
            [fields[orgType]]: list.filter((v) => v.length > 0),
          })
        );
        //dispatch(setNeedsUpdate())
      };

  const [selectedSujet, setSelectedSujet] = useState<TSujetEntry>()
  const [viewedSujet, setViewedSujet] = useState<TSujetEntry>()
  // const [sujetUrl, setSujetUrl] = useState("");
  const [sujetDialogOpen, setSujetDialogOpen] = useState(false)

  const onOpenAds = (item: TSujetEntry) => {
    if (item) {
      setSelectedSujet(item);
      // setSujetUrl('/api/files/renderOne/' + item.fileName);
      setSujetDialogOpen(true);
    }
  }

  const zoomToRootInitial = (echartsInstance: EChartsType) => {
    if (!treemapModel || !treemapRoot) {
      return
    }

    const instance = echartsInstance as any
    const chartView = instance?._chartsViews?.[0]

    if (!chartView?.seriesModel?._viewRoot) {
      return
    }

    const viewRoot = chartView.seriesModel._viewRoot
    const nodes = treemapRoot.dataIndices.map(i => viewRoot?.hostTree?.getNodeByDataIndex(i))

    if ((nodes?.length ?? 0) > 0 && instance?._api) {
      // zoom to all nodes to root to force load, because echarts only loads a limited depth initially
      nodes.forEach((node) => {
        instance._api.dispatchAction({
          type: 'treemapRootToNode',
          from: chartView.uid,
          seriesId: treemapRoot.seriesId,
          targetNode: node,
        })
      })

      if (treemapRoot.isLeaf) {
        setViewedSujet(treemapRoot.sujet)
        document.documentElement.setAttribute(`data-treemap-zoom-data-index`, nodes[nodes.length - 1].dataIndex.toString())
      }
    }
  }

  const onChartClickTreemap = useMemo(() => (params: SujetTreemapParams) => {
    //console.warn("click", params.type, params, JSON.stringify(params, [""]))
    const { value, data, seriesId, treePathInfo } = params

    // console.warn("click treemap", params)

    const isLeaf = data && !(data['children'] && data['children'].length > 0)
    // const { name, value, treePathInfo } = params
    // const treePath = treePathInfo as TreePathNode[]

    const key = typeof value === "number" ? undefined : value?.[1]
    const sujet = typeof value === "number" ? undefined : value?.[2]
    const keys = typeof value === "number" ? undefined : value?.[3]

    if (treePathInfo.length === 1) {
      // reset zoom dataIndex
      document.documentElement.setAttribute("data-treemap-zoom-data-index", "")
      setViewedSujet(undefined)
      dispatch(setTreemapRoot(null))
      return
    }

    const leafDepth = parseInt(treemapOptions.series?.["leafDepth"]) || -1
    const zoomDataIndex = document.documentElement.getAttribute("data-treemap-zoom-data-index")
    const zoomNotActiveAlready = (Math.min((keys?.length ?? 0), leafDepth + 1) !== (treePathInfo.length)) || zoomDataIndex
    const depth = ((keys?.length ?? 0) - treePathInfo.length - 1) + leafDepth

    // if (data) {
    if (data && zoomNotActiveAlready && (depth !== 1)) {
      // zoom in one node on click, if node doesnt already zoom in because max leafDepth
      document.documentElement.setAttribute("data-treemap-zoom-data-index", params.dataIndex?.toString())

      const instance = treemapRef?.getEchartsInstance();
      instance?._api?.dispatchAction({
        type: 'treemapRootToNode',
        from: instance._chartsViews?.[0]?.uid,
        seriesId: seriesId,
        targetNode: instance._chartsViews?.[0]?.findTarget(params['event'].offsetX, params['event'].offsetY)?.node
      })

      const dataIndices = treePathInfo.slice(1).map(n => n.dataIndex)

      dispatch(setTreemapRoot({
        dataIndices,
        seriesId,
        isLeaf: false,
      }))
    }

    // dont do anything on breadcrumb click
    if (!sujet || params.selfType === "breadcrumb") {
      const rootDataIndex = document.documentElement.getAttribute("data-treemap-root-data-index")

      if (params.nodeData?.dataIndex !== (Number(rootDataIndex) || "")) {
        document.documentElement.setAttribute("data-treemap-root-data-index", "")
        setViewedSujet(undefined)

        const dataIndices = treePathInfo.slice(1).map(n => n.dataIndex)

        dispatch(setTreemapRoot({
          dataIndices,
          seriesId,
          isLeaf: isLeaf ?? false,
          sujet,
        }))
      }

      const instance = treemapRef?.getEchartsInstance();
      setTimeout(() => {
        instance?.resize()
      }, (Number(instance?.getOption().animationDurationUpdate) || 0) * 0.6)

      return
    }

    if (key === "fileName" && isLeaf) {
      // open sujet popup
      // onOpenAds(sujet)
      const rootDataIndex = document.documentElement.getAttribute("data-treemap-root-data-index")

      if (data && rootDataIndex === params.dataIndex.toString()) {
        onOpenAds(sujet)
      }

      // zoom in one node on click
      if (data) {
        const instance = treemapRef?.getEchartsInstance();

        instance?._api?.dispatchAction({
          type: 'treemapRootToNode',
          from: instance._chartsViews?.[0]?.uid,
          seriesId: seriesId,
          targetNode: instance._chartsViews?.[0]?.findTarget(params['event'].offsetX, params['event'].offsetY)?.node
        })

        const dataIndices = treePathInfo.slice(1).map(n => n.dataIndex)

        dispatch(setTreemapRoot({
          dataIndices,
          seriesId,
          isLeaf: true,
          sujet,
        }))

        document.documentElement.setAttribute(`data-treemap-root-data-index`, params.dataIndex.toString())
        setViewedSujet(sujet)

        setTimeout(() => {
          instance?.resize()
        }, (Number(instance?.getOption().animationDurationUpdate) || 0) * 0.8)
      }
    }

  }, [treemapOptions.series?.["leafDepth"]])

  const onChartDblClickTreemap = useMemo(() => (params: SujetTreemapParams) => {
    // console.warn("click", params.type, params)

    const { name, value } = params
    // const treePath = treePathInfo as TreePathNode[]

    const key = typeof value === "number" ? undefined : value?.[1]
    // const sujet: TSujetEntry | undefined = typeof value === "number"  ? undefined : value?.[2]

    // dont filter if the level is the last for drilldown
    // treePath length corresponds to the level (depth)
    // this needs to be updated when leafDepth changes
    // if (treePath.length - 1 === 3) {
    //   return
    // }

    // dont do anything on breadcrumb click
    if (params.selfType === "breadcrumb") {
      return
    }

    if (key === "media") {
      // filter by media
      dispatch(
        setQuery({
          ...query,
          organisations: [],
          mediaOwner: [],
          media: [name],
          orgGroups: [],
          mediaGroups: [],
        })
      );
    }

    if (key === "mediaOwner") {
      // filter by media
      dispatch(
        setQuery({
          ...query,
          organisations: [],
          mediaOwner: [name],
          media: [],
          orgGroups: [],
          mediaGroups: [],
        })
      );
    }

    if (key === "organisation") {
      // filter by organisation
      dispatch(
        setQuery({
          ...query,
          organisations: [name],
          mediaOwner: [],
          media: [],
          orgGroups: [],
          mediaGroups: [],
        })
      )
    }

    // not used on double click for now
    // if (key === "fileName") {
    //   // open sujet popup
    // }

  }, [])

  const createAdString = (category: string, subCategories: string[]) => {
    return t("Advertising") + (category ?
      ": " + t(category) + (subCategories.length > 0 ? ` (${subCategories.map(c => t(c)).join(", ")})` : "") : "");
  }

  const customToolTip = (params) => {
    const fmt = new Intl.NumberFormat(i18n.language, {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    });
    const numberOfSujets = params.data.numberOfSujets ?? 0;
    const category = params.data.category ?? "";
    const subCategories = params.data.subCategories ?? [];
    const isLink = params.data.source && params.data.target;
    let value = params.data.absolute
    if (!isLink) {
      const node = params.data;
      return `
                <b>${node.name.startsWith("Other")
          ? t(node.name) + '</b>'
          : (lineBreakHtml(node.name, 0, isMobilePortrait ? 40 : undefined) + '</b>' +
            (node.mediaOwner ? `<br/> (${t('media_owner_short')}: ${lineBreakHtml(node.mediaOwner, 0, isMobilePortrait ? 40 : undefined)})` : ""))
        }</b><br />
                ${fmt.format(value)}
                `;
    } else {
      const orgNode = sankeyOptions.series.nodes.find((n) => n.name === params.data.source);
      const orgPercent =
        ((params.data.absolute / orgNode.absolute) * 100).toFixed(2);
      const mediaNode = sankeyOptions.series.nodes.find((n) => n.name === params.data.target);
      const mediaPercent = ((params.data.absolute / mediaNode.absolute) * 100).toFixed(2);
      return (
        `
            ${t('von: ')}<b>${params.data.source.startsWith("Other")
          ? t(params.data.source)
          : lineBreakHtml(params.data.source, 8, isMobile ? 40 : undefined)
        }
            ${!params.data.source.startsWith("Other")
          ? `${isFinite(+orgPercent) && +orgPercent % 100 !== 0
            ? "(" + orgPercent + "%)"
            : ""
          } </b>`
          : ""
        }
            <br/>${t('an')}: <b>
            ${params.data.target.startsWith("Other")
          ? t(params.data.target)
          : lineBreakHtml(params.data.target, 6, isMobile ? 40 : undefined)
        }
            ${!params.data.target.startsWith("Other")
          ? `${isFinite(+mediaPercent) && +mediaPercent % 100 !== 0
            ? "(" + mediaPercent + "%)"
            : ""
          } `
          : ""
        }</b><br />
            ${fmt.format(value)} <br/> ${params.data.transferType === "2" ?
          lineBreakHtml(createAdString(category, subCategories)) :
          (t("Funding"))}
         ` +
        (numberOfSujets > 0
          ? `<br/>` + t("amount_ads", { amount: numberOfSujets })
          : ``)
      );
    }
  };

  const sankeyOptions = useMemo(
    () => {
      const title = lineBreakName(t("flows_transfer_title", {
        paymentType: t(
          query.pType == 2
            ? "advertising_payments"
            : query.pType == 4
              ? "funding_payments"
              : "advertising_and_funding_payments"
        ),
        period:
          query.to !== query.from
            ? `${periodToString_(query.from ?? 0)} - ${periodToString_(
              query.to ?? 0
            )}`
            : periodToString_(query.to ?? 0),
      }), "\n", 0, isMobile ? document.documentElement.clientWidth / 14 : 1000)
      const subtitle = t("flows_transfer_sub_title", {
        payers:
          query.organisations.length > 0 || (query.orgGroups?.length ?? 0) > 0
            ? [...query.organisations, ...(query.orgGroups ?? [])].join(", ")
            : t("all"),
        receivers:
          query.media.length > 0 || (query.mediaGroups?.length ?? 0) > 0
            ? [...query.media, ...(query.mediaGroups ?? [])].join(", ")
            : t("all"),
      }) +
      `\n${getAmountRangeString(query, amountRange, i18n, t)}`
      return {
        ...defaultSankeyOptions(
          isMobile,
          customToolTip,
          title,
          subtitle +
          `\n${t("Source")}: KommAustria, ${t(
            "Date"
          )}: ${new Date().toLocaleString()}` +
          `\nLink: ${window.location.href}`,
          chartModel
        ),
        ...chartAriaDescription(t, title, subtitle, false),
      }
    },
    [t, chartModel, i18n.language]
  );

  const selectPeriod = (period) => {
    dispatch(setRange({ from: Number(period), to: Number(period) }));
    setActiveTab("flows");
    setUpdateRoute(false);
  };
  const toggleStates = [
    { label: t("Advertising"), value: 2 },
    { label: t("Funding"), value: 4 },
    { label: t("Both"), value: 0 },
  ];

  const onChartClick = (params) => {
    const isLink = params.data.source && params.data.target;
    const flowQuery = {
      organisations: [],
      media: [],
      orgGroups: [],
      mediaGroups: [],
    } as any;
    if (isLink) {
      if (
        params.data.target.startsWith("Andere") ||
        params.data.target.startsWith("Other")
      )
        return;
      switch (params.data.srcType) {
        case "org":
          flowQuery.organisations = [params.data.source];
          break;
        case "orgGroup":
          flowQuery.orgGroups = [params.data.source];
          break;
        case "mediaGroup":
          flowQuery.mediaGroups = [params.data.source];
          break;
      }
      switch (params.data.targetType) {
        case "media":
          flowQuery.media = [params.data.target];
          break;
        case "orgGroup":
          flowQuery.orgGroups = [params.data.target];
          break;
        case "mediaGroup":
          flowQuery.mediaGroups = [params.data.target];
          break;
      }
      dispatch(setQuery({ ...query, ...flowQuery }));
    } else {
      if (
        params.data.name.startsWith("Andere") ||
        params.data.name.startsWith("Other")
      )
        return;
      if (params.data.type === "org")
        dispatch(
          setQuery({
            ...query,
            organisations: [params.data.name],
            mediaOwner: [],
            media: [],
            orgGroups: [],
            mediaGroups: [],
          })
        );
      else if (params.data.type === "media")
        dispatch(
          setQuery({
            ...query,
            media: [params.data.name],
            mediaOwner: [],
            organisations: [],
            orgGroups: [],
            mediaGroups: [],
          })
        );
      else if (params.data.type === "orgGroup")
        dispatch(
          setQuery({
            ...query,
            orgGroups: [params.data.name],
            media: [],
            mediaOwner: [],
            organisations: [],
            mediaGroups: [],
          })
        );
      else if (params.data.type === "mediaGroup")
        dispatch(
          setQuery({
            ...query,
            mediaGroups: [params.data.name],
            media: [],
            mediaOwner: [],
            orgGroups: [],
            organisations: [],
          })
        );
    }
  };

  const onChartMouseMove = (params) => {
    const echartInstance = echartRef.getEchartsInstance();
    echartInstance.getZr().setCursorStyle("pointer");
  };

  const [activeTab, setActiveTab] = useState(tab ?? "flows");
  const [updateRoute, setUpdateRoute] = useState(true);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
    if (newTab === "ads") dispatch(setAdsSeen(true));
  };

  useEffect(() => {
    if (tab !== undefined && tab !== "undefined") {
      setActiveTab(tab);
    }
  }, [tab]);

  useEffect(() => {
    setTab(activeTab, updateRoute);
  }, [activeTab, updateRoute]);

  const onEvents = {
    click: onChartClick,
    mousemove: onChartMouseMove,
  };

  const touchDoubleClickChecker = useTouchDoubleClickChecker();

  const [touchEchartsEvents] = useState({
    click: (event) => {
      touchDoubleClickChecker(
        () => onChartClick(event),
        () => { },
        250
      );
    },
    dblclick: onChartClick,
  });

  const Settings = () => {
    return (
      <div className="settings" data-test-id="flowTransferSettings">
        <Grid container spacing={3} data-test-id="flowSettings">
          <Grid item xs={12} lg={4} md={4}>
            <TransferMultiSelectComp
              value={query.organisations}
              searchNames={searchNames}
              orgType="org"
              placeholder={t("Select a Payer")}
              label={t("Payers")}
              aria-label={t("Payers")}
              onChange={(e) => {
                updateSelection("org")(e);
              }}
            />
            <Render when={groupTypes && groupTypes.length > 0}>
              <GroupTypeSelector
                id="select-group-type-org-transfer-flow"
                value={query.orgGroupType}
                availableGroupTypes={groupTypes}
                type="org"
                onChange={(e) => {
                  dispatch(setOrgGroupType(e.target.value));
                }}
                aria-label={t("Payers") + " selector"}
              />
            </Render>
            <Render
              when={
                query.orgGroupType != undefined && query.orgGroupType.length > 0
              }
            >
              <MultiSelectComp
                options={groups
                  .filter(
                    (g) =>
                      g.type === "org" && g.group_type === query.orgGroupType
                  )
                  .map((g) => g.name)
                  .sort((a, b) => a.localeCompare(b))}
                value={query.orgGroups as string[]}
                placeholder={t("Select a Group")}
                label={t("Select payer groups")}
                getOptionLabel={(option) => option}
                onChange={(newValue) => updateSelection("orgGroups")(newValue)}
              />
            </Render>
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <div className="col-center-element">
              <Render when={!isMobileLandscape}>
                <ToggleSwitch
                  values={toggleStates}
                  selected={switchState}
                  onChange={(pT) =>
                    dispatch(
                      setQuery({ ...query, pType: pT === 0 ? [2, 4] : pT })
                    )
                  }
                />

                <SwitchButton
                  simple={true}
                  selValue={!query.showAbsoluteValues || false}
                  disabled={logarithmSwitchDisabled}
                  onlabel={
                    <span>
                      {t("Logarithmic scale")} <Help text={"scale_help"} />
                    </span>
                  }
                  offlabel={
                    <span>
                      {t("Logarithmic scale")} <Help text={"scale_help"} />
                    </span>
                  }
                  onIconD={Config.switchButton.icons.numbersIconD}
                  offIconD={Config.switchButton.icons.percentIconD}
                  onChange={(checked: boolean) => {
                    dispatch(
                      setShowAbsoluteValues({
                        showAbsoluteValues: !checked,
                        summariseData: query.summariseValues ?? false,
                        colorSettings,
                        summarizeOptions: {
                          percentThreshold: moduleSettings.percentThreshold,
                          minAmountOfFlows: moduleSettings.minAmountOfFlows,
                        }
                      })
                    );
                    //dispatch(setNeedsUpdate());
                  }}
                ></SwitchButton>
                <SwitchButton
                  simple={true}
                  disabled={summarizeSwitchDisabled}
                  selValue={query.summariseValues ?? false}
                  onlabel={
                    <span>
                      {t("summarise_values")}{" "}
                      <Help
                        translatedText={
                          Config.flows.summarise.minAmountOfFlows
                            ? t("summarise_values_percent_minAmount", {
                              percent: (
                                Config.flows.summarise.percentThreshold * 100
                              ).toString(),
                              minAmount:
                                Config.flows.summarise.minAmountOfFlows.toString(),
                            })
                            : t("summarise_values_percent", {
                              percent: (
                                Config.flows.summarise.percentThreshold * 100
                              ).toString(),
                            })
                        }
                      />
                    </span>
                  }
                  offlabel={
                    <span>
                      {t("summarise_values")}{" "}
                      <Help
                        translatedText={
                          Config.flows.summarise.minAmountOfFlows
                            ? t("summarise_values_percent_minAmount", {
                              percent: (
                                Config.flows.summarise.percentThreshold * 100
                              ).toString(),
                              minAmount:
                                Config.flows.summarise.minAmountOfFlows.toString(),
                            })
                            : t("summarise_values_percent", {
                              percent: (
                                Config.flows.summarise.percentThreshold * 100
                              ).toString(),
                            })
                        }
                      />
                    </span>
                  }
                  onChange={(checked: boolean) => {
                    dispatch(setSummariseValues(checked));
                    dispatch(setLogarithmSwitchDisabled(checked));
                    dispatch(
                      setData({
                        data: data,
                        showAbsoluteValues: checked ? true : (query.showAbsoluteValues ?? true),
                        summariseData: checked,
                        colorSettings,
                        summarizeOptions: {
                          percentThreshold: moduleSettings.percentThreshold,
                          minAmountOfFlows: moduleSettings.minAmountOfFlows,
                        },
                      })
                    );
                  }}
                ></SwitchButton>
              </Render>
            </div>
          </Grid>

          <Grid item xs={12} lg={4} md={4}>
            <Render when={isMobileLandscape}>
              <ToggleSwitch
                values={toggleStates}
                selected={switchState}
                onChange={(pT) =>
                  dispatch(
                    setQuery({ ...query, pType: pT === 0 ? [2, 4] : pT })
                  )
                }
              />
              <SwitchButton
                simple={true}
                selValue={query.showAbsoluteValues ?? true}
                onlabel={t("Absolute visualisation")}
                offlabel={t("Relative visualisation")}
                onIconD={Config.switchButton.icons.numbersIconD}
                offIconD={Config.switchButton.icons.percentIconD}
                onChange={(checked: boolean) => {
                  dispatch(
                    setShowAbsoluteValues({
                      showAbsoluteValues: checked,
                      summariseData: query.summariseValues ?? false,
                      colorSettings,
                      summarizeOptions: {
                        percentThreshold: moduleSettings.percentThreshold,
                        minAmountOfFlows: moduleSettings.minAmountOfFlows,
                      }
                    })
                  );
                  //dispatch(setNeedsUpdate());
                }}
              ></SwitchButton>
            </Render>
            <MediaOwnerMultiSelectComp
              value={selectedMediaOwners}
              pType={query.pType}
              onChange={(entries: IMedia[]) => {
                setSelectedMediaOwners(entries);
                dispatch(setMedia(entries.filter((e) => e.type === "media").map((e) => e.name)));
                dispatch(setMediaOwner(entries.filter((e) => e.type === "mediaOwner").map((e) => e.name)));
              }}
            />
            <Render when={groupTypes && groupTypes.length > 0}>
              <GroupTypeSelector
                id="select-group-type-transfer-flow"
                value={query.mediaGroupType}
                availableGroupTypes={groupTypes}
                type="media"
                onChange={(e) => {
                  dispatch(setMediaGroupType(e.target.value));
                }}
              />
            </Render>
            <Render
              when={
                query.mediaGroupType != undefined &&
                query.mediaGroupType.length > 0
              }
            >
              <MultiSelectComp
                options={groups
                  .filter(
                    (g) =>
                      g.type === "media" &&
                      g.group_type === query.mediaGroupType
                  )
                  .map((g) => g.name)
                  .sort((a, b) => a.localeCompare(b))}
                value={query.mediaGroups as string[]}
                placeholder={t("Select a Group")}
                label={t("Select beneficiary groups")}
                onChange={(newValue) =>
                  updateSelection("mediaGroups")(newValue)
                }
                getOptionLabel={(option) => option}
              />
            </Render>
          </Grid>
          <Grid item xs={12}>
            <PeriodSlider
              label={t("Period")}
              startPeriod={query.from ?? 0}
              endPeriod={query.to ?? 0}
              step={1}
              onChange={(start, end) =>
                dispatch(setRange({ from: start, to: end }))
              }
              labelFormater={periodToString_}
              periods={periods}
              marksFilter={(period) => useHalfyears ? true : period.startsWith("Q1")}
              aria-label={t("Period Slider")}
            ></PeriodSlider>

            <AmountSlider
              label={t("Payment Range")}
              availableAmounts={amountRange}
              value={query.amountFilterRange ?? [0, 0]}
              onChange={(range) => dispatch(setAmountFilterRange(range))}
            />
          </Grid>
        </Grid>
      </div>
    );
  };
  // const mode = ((query.media?.length > 0) && (query.organisations?.length > 0)) ? 'both' : query.media?.length > 0 ? 'media' : 'org'
  return (
    <>
      <ShowError error={error} onClose={() => setError("")} />
      <ModalLoader isPending={pending} />
      <div className={isMobile ? "flows-mobile-settings" : "flows-settings"}>
        <SettingsViewer id="transfer-flows" fold={true} open={!isMobile}>
          <Settings />
        </SettingsViewer>
      </div>
      <Render when={data?.length === 0 && !pending}>
        <Alert severity="warning">
          {t("No Data found that matches your settings")}
        </Alert>
      </Render>
      <TabsMui
        value={activeTab}
        onChange={handleTabChange}
        orientation={isMobilePortrait ? "vertical" : "horizontal"}
        centered
        sx={{ marginY: 2 }}
      >
        <TabMui
          label={
            <span>
              <FontAwesomeIcon icon={faStream} /> {t("Flows")}
            </span>
          }
          value="flows"
        />
        <TabMui
          value="timeline"
          label={
            <span>
              <FontAwesomeIcon icon={faChartLine} /> {t("Timeline")}
            </span>
          }
        />
        {settings.isModuleEnabled(Modules.Sujets) && (
          <TabMui
            value="ads"
            label={
              <span>
                <FontAwesomeIcon icon={faListUl} />{" "}
                <Render when={adsSeen || activeTab === "ads"}>
                  {t("ads")}
                </Render>
                <Render when={!adsSeen && activeTab !== "ads"}>
                  <Badge badgeContent={sujets.length} color="primary" >
                    <span style={{ marginRight: ".5em" }}>{t("ads")}</span>
                  </Badge>
                </Render>
              </span>
            }
          />
        )}
      </TabsMui>
      <Render when={activeTab === "flows" && data?.length > 0 && !error}>
        <Render when={summarizeSwitchDisabled && !pending}>
          <Alert severity="info">{t("summarize_autoenabled_info")}</Alert>
        </Render>
        <Grid
          container
          justifyContent="flex-end"
          data-test-id="timelineForecastToggle"
        >
          <Hint translationKey={`additional_info_transfer_flows`} />
        </Grid>
        <Box sx={{ marginY: 3 }}>
          <div className="text-end info">
            <FontAwesomeIcon icon={faInfoCircle} />{" "}
            {t(
              `${isTouchSupported() ? "Double click" : "Click"
              } on the chart to get more information`
            )}
          </div>
        </Box>
        {
          //<pre>{JSON.stringify(chartModel.links, null, 2)}</pre>
        }
        {/* <div className="text-end info"></div> */}

        <ResponsiveContainer
          width="100%"
          height={
            chartModel.links.length * 20 < minHeight
              ? minHeight
              : chartModel.links.length * 20
          }
        >



          <ReactECharts
            ref={(e) => {
              echartRef = e;
            }}
            option={sankeyOptions}
            opts={{ locale: i18n.language.split("-")[0] }}
            style={{
              height: "100%",
              width: "100%",
              cursor: "pointer !important",
            }}
            onEvents={isTouchSupported() ? touchEchartsEvents : onEvents}
          />


        </ResponsiveContainer>
        <Legend />
        <Fold
          id={`flows-transfer-table`}
          header={t("Raw Data")}
          fold={true}
          open={false}
          icon={<></>}
        >
          <DataTable
            title={t("flows_transfer_title", {
              paymentType: t(
                query.pType == 2
                  ? "advertising_payments"
                  : query.pType == 4
                    ? "funding_payments"
                    : "advertising_and_funding_payments"
              ),
              period:
                query.to !== query.from
                  ? `${periodToString_(query.from ?? 0)} - ${periodToString_(
                    query.to ?? 0
                  )}`
                  : periodToString_(query.to ?? 0),
            })}
            pagination={true}
            paginationComponentOptions={getTranslatedPaginationOptions(t)}
            columns={columns}
            data={filteredData}
            actions={
              <ExportToExcel
                data={filteredData}
                columns={columns}
                fileName="MoneyFlow"
              />
            }
          />
        </Fold>
      </Render>

      <Render when={activeTab === "timeline" && timeline?.length > 0 && !error}>
        <Grid
          container
          justifyContent="flex-end"
          data-test-id="timelineForecastToggle"
        >
          <Hint translationKey={`additional_info_transfer_timeline`} />
        </Grid>
        <div className="text-end info">
          <FontAwesomeIcon icon={faInfoCircle} />{" "}
          {t(
            `${isTouchSupported() ? "Double click" : "Click"
            } on the chart to get more information`
          )}
        </div>
        <ReactECharts
          option={timelineOptions}
          onEvents={{ click: handleClick }}
          style={{ height: 450 }}
          opts={{ locale: i18n.language.split("-")[0] }}
        />
        <Fold
          id={`flows-transfer-timeline-table`}
          header={t("Raw Data")}
          fold={true}
          open={false}
          icon={<></>}
        >
          <DataTable
            title={t("flows_timeline_title", {
              paymentType: t(
                query.pType == 2
                  ? "advertising_payments"
                  : query.pType == 4
                    ? "funding_payments"
                    : "advertising_and_funding_payments"
              ),
            })}
            pagination={true}
            paginationComponentOptions={getTranslatedPaginationOptions(t)}
            columns={timelineColums}
            data={timeline}
            actions={
              <ExportToExcel
                data={timeline}
                columns={timelineColums}
                fileName="MoneyFlow"
              />
            }
          />
        </Fold>
      </Render>
      <div
        className="ads-transfer-container"
        style={{
          display: activeTab === "ads" ? "block" : "none",
        }}
      >
        <Render when={settings.isModuleEnabled(Modules.Sujets) && sujets.length === 0 && !pending && !error}>
          <Alert severity="info">{t(switchState === 4 ? 'no_sujets_for_funding' : 'no_sujets_found')}</Alert>
        </Render>
        <Render when={!error && settings.isModuleEnabled(Modules.Sujets) && sujets.length > 0}>


          <Grid
            container
            justifyContent="flex-end"
            data-test-id="timelineForecastToggle"
          >
            <Hint translationKey={`additional_info_flows_sujets`} />
          </Grid>
          <br />

          <Grid container spacing={3} data-test-id="flowSettings">
            <Grid item xs={12} lg={8} md={8}>
              <div className="text-end info">
                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                {/* {t(`Bereiche mit ▶ können mit einem Klick für mehr Details angezeigt werden. Klicke auf ein Sujet um es anzuzeigen.`)} */}
                {t("ads_help1")}
                <br />
                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                {/* {`Doppelklicke auf einen Rechtsträger oder ein Medium um danach zu filtern.`} */}
                {t("Double click on an organisation or medium to filter by it.")}
              </div>
            </Grid>

            <Grid item xs={12} lg={4} md={4}>

              <SujetViewModeSelector
                id="select-sujet-treemap-view-mode"
                value={sujetViewMode}
                options={[t("Campaign"), t("Category")]}
                onChange={(e) => {
                  // dispatch(setOrgGroupType(e.target.value));
                  dispatch(setTreemapModel([]))
                  dispatch(setTreemapRoot(null))
                  dispatch(setSujetViewMode(sujetViewModes[e.target.value]))

                  setViewedSujet(undefined)
                  document.documentElement.setAttribute("data-treemap-root-data-index", "")
                }}
                aria-label={t("View Mode") + " selector"}
              />
              <SwitchButton
                simple={true}
                selValue={roamEnabled}
                onChange={(checked) => {
                  setRoamEnabled(checked)
                  const instance = treemapRef?.getEchartsInstance()
                  instance?.clear()
                  setTimeout(() => {
                    zoomToRootInitial(instance)
                  });
                }}
                label={<span>{t("Enable zoom")}<Help text={"zoom_enable_help"} /></span>}
              />
            </Grid>
          </Grid>

          <ReactECharts
            ref={(e) => { treemapRef = e; }}
            option={treemapOptions}
            onEvents={{
              click: onChartClickTreemap,
              dblclick: onChartDblClickTreemap,
            }}
            onChartReady={(e) => zoomToRootInitial(e)}
            style={{ height: isMobileLandscape ? 500 : 700, marginTop: 8 }}
            opts={{ locale: i18n.language.split("-")[0] }}
            theme={theme}
          />
          <div style={{ marginTop: "0.5rem" }}>
            <Legend center />
          </div>

          {viewedSujet && (
            <div style={{ marginTop: "0.5rem", marginBottom: "1rem" }}>
              <Link
                to={`/ads/sujets?fileName=${encodeURIComponent(viewedSujet.fileName)}&organisation=${encodeURIComponent(viewedSujet.organisation)}`}
                aria-label={`${t("Other uses of sujet")} ${viewedSujet?.fileName}`}
              >
                <Button variant="contained" startIcon={<PhotoLibrary />} color="primary">
                  {`${t("Other uses of sujet")} ${viewedSujet?.fileName}`}
                </Button>
              </Link>
            </div>
          )}

          <Fold
            id={`flows-transfer-ads-table`}
            header={t("Raw Data")}
            fold={true}
            open={false}
            icon={<></>}
          >
            <DataTable
              title={t("flows_ads_title", {
                paymentType: t(
                  query.pType == 2
                    ? "advertising_payments"
                    : query.pType == 4
                      ? "funding_payments"
                      : "advertising_and_funding_payments"
                ),
                period:
                  query.to !== query.from
                    ? `${periodToString_(query.from ?? 0)} - ${periodToString_(
                      query.to ?? 0
                    )}`
                    : periodToString_(query.to ?? 0),
              })}
              pagination={true}
              paginationComponentOptions={getTranslatedPaginationOptions(t)}
              columns={adsColumns}
              data={sujets}
            // actions={
            //   <ExportToExcel
            //     data={timeline}
            //     columns={timelineColums}
            //     fileName="MoneyFlow"
            //   />
            // }
            />
          </Fold>

          <SujetDialog
            open={sujetDialogOpen}
            onClose={() => {
              setSelectedSujet(undefined);
              setSujetDialogOpen(false);
            }}
            sujet={selectedSujet}
          />
        </Render>
      </div>


      {
        /*
        <table className="period-debug">
          <thead>
            <tr>
              <th>Nodes</th>
              <th>Links</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><pre>{JSON.stringify(chartModel.nodes, null, 2)}</pre></td>
              <td><pre>{JSON.stringify(chartModel.links, null, 2)}</pre></td>
            </tr>

          </tbody>
        </table>
        */
      }

    </>
  );
};
