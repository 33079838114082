import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { AppState } from "../..";
import qs from "query-string";
import {
  Help,
  IfNoError,
  ModalLoader,
} from "../../components/helper-components";
import {
  fundingTypes,
  getGroupTypesByType,
  getTranslatedPaginationOptions,
  isEqual,
  isObjectSubset,
  isTouchSupported,
  toCurrency,
} from "../../helpers/helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { getTopFundings, objectToParams } from "../../services/data-service";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ReactECharts from "echarts-for-react";
//import { flowsFundingSlice } from "../flows/flows-funding";
import { TInfoState } from "../../App";
import { SwitchButton } from "../../components/switch-button/switchButton";
import { useMediaQuery } from "react-responsive";
import { Grid, MenuItem, TextField } from "@mui/material";
import { PeriodSlider } from "../../components/slider/rangeSlider";
import { MultiSelectComp } from "../../components/multi-select/multi-select";
import { GroupTypeSelector } from "../../components/group-type-selector";
import Config from "../../config/settings";
import { Fold, SettingsViewer } from "../../components/settings-viewer";

import * as echarts from "echarts/core";
import german from "echarts/i18n/langDE-obj";
import { defaultSunBurtsOptions } from "./top-transfers";
import DataTable from "react-data-table-component-with-filter";
import { ExportToExcel } from "../../components/excel-exporter";
import { useSettingsStore } from "../../context/SettingsStore";

echarts.registerLocale("de", german);

/* eslint react-hooks/exhaustive-deps: 0 */

type TopFundingRecord = {
  receiver: string;
  total: number;
  isGrouping?: boolean;
  children?: TopFundingRecord[];
};

export type TTopFundingResult = {
  top: TopFundingRecord[];
  all: number;
};

export type TTopFundingQuery = {
  groupType: string;
  from?: number;
  to?: number;
  fundingType: number[];
  x: number;
  onlyGroups: boolean;
};

type TableRecord = {
  receiver: string;
  total: number;
  group: string;
};

type TTopFundingState = {
  data?: TTopFundingResult;
  chartModel?: TopFundingRecord[];
  sunburstModel: ReturnType<typeof resultToSunburstModel>;
  pending: boolean;
  query: TTopFundingQuery;
  lastQuery?: TTopFundingQuery;
  needsUpdate: boolean;
  pristine: boolean;
  table: TableRecord[];
};

const resultToSunburstModel = (data: TTopFundingResult) => [
  ...data.top.map((t) =>
    t.children
      ? {
          name: t.receiver,
          value: t.total,
          percent: Math.round((t.total / data.all) * 10000) / 100,
          children: t.children.map((c) => ({
            name: c.receiver,
            value: c.total,
            percent: Math.round((c.total / data.all) * 10000) / 100,
          })),
        }
      : {
          name: t.receiver,
          percent: Math.round((t.total / data.all) * 10000) / 100,
          value: t.total,
        }
  ),
  {
    name: "Others",
    value: data.all - data.top.reduce((acc, { total }) => acc + total, 0),
    percent:
      Math.round(
        ((data.all - data.top.reduce((acc, { total }) => acc + total, 0)) /
          data.all) *
          10000
      ) / 100,
  },
];

const resultToChartModel = (data: TTopFundingResult): TopFundingRecord[] => [
  ...data.top,
  {
    receiver: "Others",
    total: data.all - data.top.reduce((acc, { total }) => acc + total, 0),
  },
];

const defaultQuery: TTopFundingQuery = {
  groupType: "",
  fundingType: fundingTypes.map((_, idx) => idx),
  x: 5,
  onlyGroups: false,
};

export const topFundingSlice = createSlice({
  name: "topFunding",
  initialState: {
    pending: true,
    pristine: true,
    sunburstModel: [],
    query: {
      x: 5,
      fundingType: fundingTypes.map((_, idx) => idx),
      groupType: "",
      onlyGroups: false,
    },
    needsUpdate: true,
    table: [],
  } as TTopFundingState,
  reducers: {
    setFundingData: (
      state: TTopFundingState,
      action: PayloadAction<TTopFundingResult>
    ) => ({
      ...state,
      data: action.payload,
      chartModel: resultToChartModel(action.payload),
      sunburstModel: resultToSunburstModel(action.payload),
      pending: false,
      table: action.payload.top.reduce(
        (acc, e) =>
          e.isGrouping
            ? [
                ...acc,
                ...(e.children ?? []).map((m) => ({ ...m, group: e.receiver })),
              ]
            : [...acc, { ...e, group: "" }],
        [] as TableRecord[]
      ),
    }),
    setPending: (state: TTopFundingState, action: PayloadAction<boolean>) => ({
      ...state,
      pending: action.payload,
    }),
    setQuery: (
      state: TTopFundingState,
      action: PayloadAction<TTopFundingQuery>
    ) => ({
      ...state,
      query: action.payload,
      needsUpdate: true,
    }),
    setNeedsUpdate: (
      state: TTopFundingState,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      needsUpdate: action.payload,
    }),
    setTouched: (state: TTopFundingState, action: PayloadAction<void>) => ({
      ...state,
      pristine: false,
    }),
    setX: (state: TTopFundingState, action: PayloadAction<number>) => ({
      ...state,
      query: { ...state.query, x: action.payload },
      needsUpdate: true,
    }),
    setRange: (
      state: TTopFundingState,
      action: PayloadAction<{ from: number; to: number }>
    ) => ({
      ...state,
      query: {
        ...state.query,
        from: action.payload.from,
        to: action.payload.to,
      },
      needsUpdate: true,
    }),
    setGroupType: (state: TTopFundingState, action: PayloadAction<string>) => ({
      ...state,
      query: {
        ...state.query,
        groupType: action.payload,
        onlyGroups: action.payload.length > 0 && state.query.onlyGroups,
      },
      needsUpdate: true,
    }),
    saveQuery: (state: TTopFundingState, action: PayloadAction<void>) => ({
      ...state,
      lastQuery: state.query,
    }),
  },
});

const {
  setFundingData,
  setPending,
  setQuery,
  setNeedsUpdate,
  setTouched,
  setGroupType,
  setX,
  setRange,
  saveQuery,
} = topFundingSlice.actions;

//const flowsFundingActions = flowsFundingSlice.actions;

export const TopFundings = () => {
  const { t, i18n } = useTranslation();
  const moduleSettings = useSettingsStore().modules.top_fundings;
  const [error, setError] = useState("");
  const location = useLocation();
  const {
    query,
    pending,
    sunburstModel,
    needsUpdate,
    pristine,
    lastQuery,
    table,
  } = useSelector<AppState, TTopFundingState>((state) => state.topFundings);

  const { periodsFunding, groupTypes } = useSelector<AppState, TInfoState>(
    (state) => state.info
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updateX = (x: number) => {
    if (query.x !== x) {
      dispatch(setX(x));
    }
  };

  const updateFundingType = (list: string[]) => {
    setError("");
    dispatch(
      setQuery({
        ...query,
        fundingType:
          list.length > 0
            ? list
                .filter((v) => v.length > 0)
                .map((x) => fundingTypes.indexOf(x))
            : fundingTypes.map((x) => fundingTypes.indexOf(x)),
      })
    );
    //dispatch(setNeedsUpdate())
  };

  const sunBurstLabelFormatter = ({ name, value, data, status }) => {
    if (name === "Others") {
      name = t(name);
    }
    if (data.percent < 0.45) {
      return " ";
    }
    switch (status) {
      case "emphasis":
        return `${name}\n${value.toLocaleString(i18n.language, {
          style: "currency",
          currency: "EUR",
        })} (${data.percent}%)`;
      case "normal":
        return name.length > 30 ? `${name.substring(0, 29)}...` : `${name}\n`;
      default:
        return status;
    }
  };

  const isMobileLandscape = useMediaQuery({
    maxHeight: 575.98,
    orientation: "landscape",
  });
  const isMobilePortrait = useMediaQuery({ maxWidth: 600 });
  const isMobile = isMobileLandscape || isMobilePortrait;

  useEffect(() => {
    let q = { ...defaultQuery };
    if (location.search) {
      try {
        const params = new URLSearchParams(location.search);
        const x = params.get("x");
        const from = params.get("from");
        const to = params.get("to");
        const fundingType = params.getAll("fundingType");
        const groupType = params.get("groupType");
        const onlyGroups = params.get("onlyGroups");
        if (x) {
          q.x = parseInt(x);
        }
        if (from) {
          q.from = parseInt(from);
        }
        if (to) {
          q.to = parseInt(to);
        }
        if (fundingType) {
          q.fundingType = fundingType.map((x) => parseInt(x));
        }
        if (groupType) {
          q.groupType = groupType;
        }
        if (onlyGroups) {
          q.onlyGroups = onlyGroups === "true";
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (q["from"] && q["to"]) {
      if (!isObjectSubset(q, query)) {
        dispatch(setQuery(q));
        dispatch(setNeedsUpdate(true));
      }
    }
  }, [location]);

  const columns = useMemo(
    () => [
      ...(query.groupType.length > 0
        ? [
            {
              name: t("Gruppe"),
              selector: (row) => row["group"],
              sortable: true,
            },
          ]
        : []),
      {
        name: t("Beneficiary"),
        selector: (row) => row["receiver"],
        sortable: true,
      },
      {
        name: t("Amount"),
        selector: (row) => row["total"],
        sortable: true,
        format: toCurrency("total"),
        right: true,
      },
    ],
    [t, i18n.language, query.groupType]
  );

  const sunBurstOptions = useMemo(
    () =>
      defaultSunBurtsOptions(
        isMobilePortrait,
        sunBurstLabelFormatter,
        t("top_fundings_title", {
          count: query.x,
        }),
        t("top_fundings_sub_title", {
          period:
            query.to !== query.from
              ? `${query.from ?? 0} - ${query.to ?? 0}`
              : query.to ?? 0,
          fundingTypes: query.fundingType
            .map((x) => fundingTypes[x])
            .join(", "),
        }) +
          `\n${t("Source")}: KommAustria, ${t(
            "Date"
          )}: ${new Date().toLocaleString()}` +
          `\nLink: ${window.location.href}`,
        sunburstModel,
        t
      ),
    [sunburstModel, i18n.language]
  );
/*
  const openDetails_ = ({
    name,
    children,
  }: {
    name: string;
    children?: [];
  }) => {
    const isGrouping = children && children.length > 0;

    dispatch(
      flowsFundingActions.setQuery({
        fundingType: query.fundingType,
        receiver: [],
        receiverGroups: [],
        [isGrouping ? "receiverGroups" : "receiver"]: [name],
        from: query.from,
        to: query.to,
        mediaGroupType: query.groupType,
      })
    );
    navigate("/flows/fundings/flows");
  };
*/
  useEffect(() => {
    if (pristine) {
      const preSelectedMediaGroupType = getGroupTypesByType(
        groupTypes,
        "media"
      ).find((g) => g.name === moduleSettings.defaultMediaGroupType);
      if (preSelectedMediaGroupType) {
        dispatch(setGroupType(preSelectedMediaGroupType._id?.toString() ?? ""));
      }
      dispatch(setTouched());
    }
  }, []);

  useEffect(() => {
    if (periodsFunding && periodsFunding.length > 0) {
      const maxPeriodIndex = periodsFunding.length - 1;
      const maxPeriod = periodsFunding[maxPeriodIndex];
      if (!query.from || !query.to) {
        dispatch(
          setRange({
            from: maxPeriod,
            to: maxPeriod,
          })
        );
      } else {
        dispatch(setNeedsUpdate(true));
      }
    }
  }, [periodsFunding]);

  useEffect(() => {
    if (query.to && query.from && moduleSettings.enabled) {
      const queryString = objectToParams(query);
      const currentQueryStringObj = qs.parse(location.search);
      const queryStringObjFromQueryObject = qs.parse(queryString);
      console.log(
        "isEqual: " +
          isEqual(currentQueryStringObj, queryStringObjFromQueryObject)
      );
      if (!isEqual(currentQueryStringObj, qs.parse(queryString))) {
        navigate(`/top/fundings/?${queryString}`);
      }
      dispatch(setNeedsUpdate(true));
    }
  }, [query]);

  useEffect(() => {
    if (needsUpdate && query.to && query.from) {
      if (!lastQuery || !isEqual(query, lastQuery)) {
        dispatch(saveQuery());
        Promise.resolve(dispatch(setPending(true)))
          .then(() => getTopFundings(query))
          .then((result) => dispatch(setFundingData(result)))
          .catch((err) => {
            setError(err?.response?.data ?? err.message);
          })
          .finally(() => {
            dispatch(setPending(false));
            dispatch(setNeedsUpdate(false));
          });
      } else {
        dispatch(setNeedsUpdate(false));
      }
    }
  }, [needsUpdate, query.from, query.to]);

  const Settings = () => {
    return (
      <div className="settings">
        <div className="row justify-content-between">
          <Grid container spacing={3} className="control-form">
            <Grid item xs={12} sm={12} md={7} lg={7} xl={5}>
              {/* <div className="label">{t("Funding type")}</div>
                <Multiselect
                  id="fundingBase"
                  options={fundingTypes}
                  isObject={false}
                  style={selectionBoxStyle}
                  emptyRecordMsg={t("Start typing")}
                  placeholder={t("Select a funding type")}
                  selectedValueDecorator={valueDecorator}
                  onSelect={updateFundingType}
                  onRemove={updateFundingType}
                  showCheckbox={true}
                  selectedValues={query.fundingType.map(
                    (x) => fundingTypes[x]
                  )}
                /> */}
              <MultiSelectComp
                options={fundingTypes}
                value={query.fundingType.map((x) => fundingTypes[x])}
                label={t("Funding type")}
                placeholder={t("Select a funding type")}
                onChange={(e) => {
                  updateFundingType(e);
                }}
                getOptionLabel={(v) => v}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
              <TextField
                id="select-x"
                select
                label={t("Number of Entries")}
                aria-label={t("Number of Entries")}
                defaultValue=""
                variant={Config.input.labelVariant}
                fullWidth={true}
                value={query.x}
                onChange={(e) => updateX(parseInt(e.target.value))}
              >
                {[5, 10, 15, 20].map((nr) => (
                  <MenuItem key={nr} value={nr}>
                    {`Top ${nr}`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={8} sm={8} md={3} lg={3} xl={3}>
              <GroupTypeSelector
                id="select-group-type-funding"
                value={query.groupType}
                availableGroupTypes={groupTypes}
                type="media"
                onChange={(e) => {
                  dispatch(setGroupType(e.target.value));
                }}
              />
            </Grid>
            {query.groupType.length > 0 && (
              <Grid
                item
                xs={10}
                sm={4}
                md={3}
                xl={2}
                data-test-id="onlyGroupsToggle"
              >
                <SwitchButton
                  simple={true}
                  selValue={query.onlyGroups}
                  label={
                    <div className="label">
                      {t("Only Groups")} <Help text="groups_only_help" />
                    </div>
                  }
                  onChange={(checked: boolean) => {
                    dispatch(setQuery({ ...query, onlyGroups: checked }));
                  }}
                ></SwitchButton>
              </Grid>
            )}
          </Grid>
        </div>
        <div className="row justify-content-between">
          <div className="col-12">
            <PeriodSlider
              label={t("Period")}
              startPeriod={query.from ?? 0}
              endPeriod={query.to ?? 0}
              step={1}
              onChange={(start, end) =>
                dispatch(setQuery({ ...query, from: start, to: end }))
              }
              periods={periodsFunding}
            ></PeriodSlider>
          </div>
        </div>
      </div>
    );
  };

  //custom colors for echart
  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    var colorsFromCSS = [
      getComputedStyle(document.documentElement)
        .getPropertyValue("--echart-color-1")
        .trim(),
      getComputedStyle(document.documentElement)
        .getPropertyValue("--echart-color-2")
        .trim(),
      getComputedStyle(document.documentElement)
        .getPropertyValue("--echart-color-3")
        .trim(),
      getComputedStyle(document.documentElement)
        .getPropertyValue("--echart-color-4")
        .trim(),
      getComputedStyle(document.documentElement)
        .getPropertyValue("--echart-color-5")
        .trim(),
      getComputedStyle(document.documentElement)
        .getPropertyValue("--echart-color-6")
        .trim(),
      getComputedStyle(document.documentElement)
        .getPropertyValue("--echart-color-7")
        .trim(),
      getComputedStyle(document.documentElement)
        .getPropertyValue("--echart-color-8")
        .trim(),
      getComputedStyle(document.documentElement)
        .getPropertyValue("--echart-color-9")
        .trim(),
      getComputedStyle(document.documentElement)
        .getPropertyValue("--echart-color-10")
        .trim(),
    ];

    setColors(colorsFromCSS);
  }, []);

  const theme = Config.skin === "rtr" ? { color: colors } : {};

  return (
    <>
      <ModalLoader isPending={pending} />
      <IfNoError error={error}>
        <div className={isMobile ? "top-settings-mobile" : "top-settings"}>
          <SettingsViewer
            id="top-fundings-settings"
            fold={true}
            open={!isMobile}
          >
            <Settings />
          </SettingsViewer>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="info text-end">
              <FontAwesomeIcon icon={faInfoCircle} />{" "}
              {t(
                `${
                  isTouchSupported() ? "Double click" : "Click"
                } on the chart to get more information`
              )}
            </div>
            {/*<pre>{JSON.stringify(query,null,2)}</pre>
                                  <pre>{JSON.stringify(periods,null,2)}</pre>*/}
            <div className="sunburst-echart">
            
                <ReactECharts
                  option={sunBurstOptions}
                  style={{ height: 900 }}
                  opts={{ locale: i18n.language.split("-")[0] }}
                  aria-label={t("Sunburst Chart")}
                  theme={theme}
                />
          
            </div>
          </div>
        </div>
        <Fold
          id={`top-transfer-table`}
          header={t("Raw Data")}
          fold={true}
          open={false}
          icon={<></>}
        >
          <DataTable
            title={
              t("top_fundings_title", {
                count: query.x,
              }) +
              " - " +
              t("top_fundings_sub_title", {
                period:
                  query.to !== query.from
                    ? `${query.from ?? 0} - ${query.to ?? 0}`
                    : query.to ?? 0,
                fundingTypes: query.fundingType
                  .map((x) => fundingTypes[x])
                  .join(", "),
              }) +
              `, ${t("Source")}: KommAustria, ${t(
                "Date"
              )}: ${new Date().toLocaleString()}`
            }
            pagination={true}
            paginationComponentOptions={getTranslatedPaginationOptions(t)}
            columns={columns}
            data={table}
            actions={
              <ExportToExcel
                data={table}
                columns={columns}
                fileName="MoneyFlow"
              />
            }
          />
        </Fold>
      </IfNoError>
    </>
  );
};
