import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IfNoError, Render } from '../components/helper-components';
import { BuildForm } from '../components/form-builder/mui-element-factory';
import { faUserPlus, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FormDescription } from '../components/form-builder/field-types';
import { useDispatch } from 'react-redux';
import { setNeedsUpdate } from './list-users';
import { IGrouping } from '../../../server/models/grouping';
import { createGroup, getGroupTypeList, searchMediaOwners, searchSearchOrganisations } from '../services/data-service';
import { PercentageError } from "../components/PercentageError";
import { IGroupTypeDocument } from "../../../server/models/group-type";
import { GroupMemberOption } from '../components/multi-select/group-member-selector';

type GroupProps = {
  default: 'media' | 'org'
}

export const NewGroup: React.FC<GroupProps> = (props) => {
  const [group, setGroup] = useState<IGrouping | undefined>(undefined);
  const [groupTypes, setGroupTypes] = useState<IGroupTypeDocument[]>([]);
  const [error] = useState("");
  const { t } = useTranslation();
  const search = (orgType:  GroupMemberOption["type"]) => (name: string) =>
    ["media", "mediaOwner"].includes(orgType) ?
      searchMediaOwners(name) :
      searchSearchOrganisations(name)
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [errorEntities, setErrorEntities] = useState<{ name: string, type:'org'|'media'|'mediaOwner', percent: number }[]>([]);
  const editGroupFormDescription = useMemo<FormDescription<IGrouping>>(() => ({
    fields: [
      {
        name: "name", type: "text", label: t("Name"),
        validators: { required: "required" }, help: t("Pick a name for this group")
      },
      {
        name: "isActive", type: "checkbox", label: t("Active"),
        help: t("Activate or deactivate this group")
      },
      {
        name: "type", type: "radiobutton", label: "Group Category", help: t("Select the type of this group"),
        options: ['media', 'org'], disabled: true
      },

      {
        name: "group_type",
        type: "select",
        label: t("Group Type"),
        options: [...groupTypes.map(g => g._id)],
        labels: [...groupTypes.map(g => t(g.name))],
        disabled: false,
        validators: { required: "required" }
      },
      {
        name: "region", type: "select", label: t("Federal State"),
        options: ["EU", "AT", "AT-1", "AT-2", "AT-3", "AT-4", "AT-5", "AT-6", "AT-7", "AT-8", "AT-9"],
        labels: [t("EU"), t("AT"), t("AT-1"), t("AT-2"), t("AT-3"), t("AT-4"),
        t("AT-5"), t("AT-6"), t("AT-7"), t("AT-8"), t("AT-9")],
        disabled: false
      },
      {
        name: "transferTypes",
        type: "multicheckbox",
        label: t("transferTypes"),
        help: t("Owner of this group"),
        options: [2, 4],
        inline: true,
      },
      {
        name: "members", type: "multiselect", onSearch: search(group ? group.type : 'media'),
        emptyRecordMsg: t('Start typing'), placeHolder: ""
      }
    ],
    name: t("Edit Group"),
    submitHandler: async (group_) => {
      try {
        await createGroup(group_);
        dispatch(setNeedsUpdate())
      } catch (err: any) {
        if (err.response.status === 409) {
          setShow(true)
          setErrorEntities(err.response.data);
        }
        throw err;
      }
    },
    submitAction: { label: t("Create Group"), icon: faUserPlus, nextRoute: "/groups" },
    cancelAction: { label: t("Cancel"), icon: faArrowCircleLeft, nextRoute: 'back' },
  }), [group, dispatch, t, groupTypes]);
  useEffect(() => {
    setGroup({
      name: "",
      type: props.default,
      group_type: '',
      region: 'AT',
      isActive: true,
      members: [],
      transferTypes: [2,4],
      serverSide: true
    })
  }, [props.default])
  useEffect(() => {
    if (group) {
      getGroupTypeList().then((gt) => {
        const appropriateGroupTypes = gt.filter(
          (g) =>
            g.type === (group.type === "media" ? "B" : "P") || g.type === "A"
        );
        setGroupTypes(appropriateGroupTypes);
      });
    }
  }, [group, group?.type])
  return <>
    <IfNoError error={t(error)}>

          <Render when={groupTypes.length > 0}>
            <BuildForm {...editGroupFormDescription} initialState={group} ></BuildForm>
          </Render>
          <Render when={groupTypes.length === 0}>
            <div className="alert alert-warning" role="alert">
              {t("No group types available")}
            </div>
          </Render>
    </IfNoError>
    <PercentageError show={show} setShow={setShow} errorEntities={errorEntities} />
  </>
}
