import React from "react";

export const Logo = () => (
  <svg
    width="400"
    height="40"
    viewBox="0 0 400 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M400 7H204V35H400V7Z" fill="url(#paint0_linear_1029_3322)" />
    <path
      d="M217.013 27.8505L212.726 15.4153H212.643C212.754 17.2653 212.809 18.9901 212.809 20.6175V27.8644H210.624V12.9533H214.04L218.147 24.8043H218.202L222.434 12.9533H225.864V27.8505H223.541V20.4923C223.541 19.7551 223.554 18.7814 223.596 17.5991C223.637 16.4168 223.665 15.6935 223.693 15.4431H223.61L219.157 27.8644H217.013V27.8505Z"
      fill="white"
    />
    <path
      d="M234.023 28.0591C232.281 28.0591 230.925 27.5444 229.93 26.529C228.948 25.5136 228.45 24.0948 228.45 22.3005C228.45 20.4644 228.906 19.0178 229.819 17.9607C230.732 16.9036 231.976 16.375 233.58 16.375C235.06 16.375 236.222 16.834 237.079 17.7243C237.937 18.6284 238.365 19.8663 238.365 21.452V22.7456H230.898C230.925 23.8445 231.23 24.679 231.783 25.2633C232.336 25.8475 233.11 26.1396 234.12 26.1396C234.784 26.1396 235.392 26.0839 235.973 25.9448C236.54 25.8196 237.162 25.611 237.812 25.3189V27.2662C237.231 27.5444 236.651 27.7392 236.056 27.8504C235.461 28.0034 234.784 28.0591 234.023 28.0591ZM233.58 18.1972C232.82 18.1972 232.211 18.4336 231.769 18.9205C231.312 19.4073 231.036 20.1028 230.953 21.0347H236.042C236.028 20.1028 235.807 19.3934 235.364 18.9205C234.936 18.4336 234.341 18.1972 233.58 18.1972Z"
      fill="white"
    />
    <path
      d="M244.727 28.0591C243.33 28.0591 242.238 27.5444 241.449 26.529C240.661 25.5136 240.274 24.081 240.274 22.2449C240.274 20.3949 240.675 18.9622 241.463 17.9329C242.251 16.9036 243.358 16.389 244.754 16.389C246.22 16.389 247.354 16.9314 248.115 18.0303H248.253C248.143 17.2235 248.087 16.5837 248.087 16.1108V12.0074H250.48V27.8644H248.613L248.198 26.3899H248.087C247.313 27.5027 246.206 28.0591 244.727 28.0591ZM245.363 26.1257C246.345 26.1257 247.05 25.8475 247.506 25.2911C247.949 24.7347 248.184 23.8445 248.198 22.5926V22.2588C248.198 20.84 247.963 19.8246 247.506 19.2404C247.05 18.6562 246.331 18.3502 245.349 18.3502C244.505 18.3502 243.869 18.6979 243.413 19.3795C242.957 20.0611 242.735 21.0348 242.735 22.3005C242.735 23.5524 242.957 24.4982 243.399 25.152C243.828 25.7918 244.492 26.1257 245.363 26.1257Z"
      fill="white"
    />
    <path
      d="M253.384 13.607C253.384 13.1758 253.494 12.8559 253.729 12.6194C253.965 12.383 254.296 12.2717 254.725 12.2717C255.14 12.2717 255.472 12.383 255.707 12.6194C255.942 12.8559 256.053 13.1758 256.053 13.607C256.053 14.0104 255.942 14.3303 255.707 14.5668C255.472 14.8032 255.154 14.9145 254.725 14.9145C254.296 14.9145 253.965 14.8032 253.729 14.5668C253.494 14.3303 253.384 14.0104 253.384 13.607ZM255.901 27.8504H253.522V16.5837H255.901V27.8504Z"
      fill="white"
    />
    <path
      d="M263.88 28.0591C262.138 28.0591 260.782 27.5444 259.787 26.529C258.805 25.5136 258.307 24.0948 258.307 22.3005C258.307 20.4644 258.763 19.0178 259.676 17.9607C260.589 16.9036 261.833 16.375 263.438 16.375C264.917 16.375 266.079 16.834 266.936 17.7243C267.794 18.6284 268.222 19.8663 268.222 21.452V22.7456H260.755C260.782 23.8445 261.087 24.679 261.64 25.2633C262.193 25.8475 262.967 26.1396 263.977 26.1396C264.641 26.1396 265.249 26.0839 265.83 25.9448C266.397 25.8196 267.019 25.611 267.669 25.3189V27.2662C267.088 27.5444 266.508 27.7392 265.913 27.8504C265.332 28.0034 264.641 28.0591 263.88 28.0591ZM263.451 18.1972C262.691 18.1972 262.082 18.4336 261.64 18.9205C261.183 19.4073 260.907 20.1028 260.824 21.0347H265.913C265.899 20.1028 265.678 19.3934 265.249 18.9205C264.793 18.4336 264.198 18.1972 263.451 18.1972Z"
      fill="white"
    />
    <path
      d="M280.586 27.8504H278.193V20.9235C278.193 20.0611 278.013 19.4073 277.668 18.9761C277.322 18.5449 276.769 18.3363 276.008 18.3363C274.999 18.3363 274.266 18.6284 273.796 19.2265C273.325 19.8246 273.09 20.8261 273.09 22.231V27.8504H270.712V16.5837H272.579L272.91 18.0581H273.035C273.367 17.5295 273.851 17.1122 274.473 16.8201C275.095 16.528 275.787 16.375 276.534 16.375C279.217 16.375 280.572 17.7521 280.572 20.5062V27.8504H280.586Z"
      fill="white"
    />
    <path
      d="M287.168 26.6681C287.459 26.6681 287.749 26.6403 288.026 26.5986C288.302 26.5569 288.524 26.5151 288.689 26.4595V27.7531C288.51 27.8365 288.233 27.92 287.887 27.9756C287.528 28.0313 287.21 28.0591 286.933 28.0591C284.79 28.0591 283.711 26.9185 283.711 24.6512V18.0025H282.121V17.1818L283.711 16.4724L284.416 14.0939H285.384V16.6811H288.607V18.0025H285.384V24.5678C285.384 25.2355 285.536 25.7501 285.855 26.1118C286.173 26.4734 286.615 26.6681 287.168 26.6681Z"
      fill="#0071D6"
    />
    <path
      d="M295.811 16.4863C296.309 16.4863 296.752 16.5281 297.139 16.6115L296.904 18.1833C296.448 18.0859 296.047 18.0303 295.687 18.0303C294.788 18.0303 294.014 18.3919 293.378 19.1292C292.741 19.8664 292.423 20.7705 292.423 21.8693V27.8644H290.736V16.695H292.119L292.313 18.7675H292.396C292.811 18.0442 293.308 17.4878 293.889 17.0844C294.47 16.6811 295.12 16.4863 295.811 16.4863Z"
      fill="#0071D6"
    />
    <path
      d="M305.381 27.8505L305.049 26.2648H304.966C304.413 26.9602 303.86 27.4332 303.307 27.6835C302.754 27.9339 302.076 28.0591 301.246 28.0591C300.14 28.0591 299.283 27.767 298.66 27.1967C298.038 26.6264 297.72 25.8197 297.72 24.7625C297.72 22.5092 299.518 21.3269 303.099 21.2156L304.98 21.1599V20.4645C304.98 19.5882 304.787 18.9483 304.413 18.531C304.04 18.1137 303.445 17.9051 302.615 17.9051C301.689 17.9051 300.638 18.1972 299.476 18.7675L298.965 17.4739C299.518 17.1818 300.112 16.9453 300.762 16.7645C301.412 16.5976 302.062 16.5141 302.726 16.5141C304.054 16.5141 305.035 16.8062 305.672 17.4044C306.308 17.9886 306.626 18.9483 306.626 20.2419V27.8644H305.381V27.8505ZM301.592 26.6681C302.643 26.6681 303.459 26.376 304.054 25.8057C304.648 25.2215 304.953 24.4148 304.953 23.3855V22.384L303.265 22.4535C301.924 22.4953 300.97 22.7039 300.375 23.0795C299.78 23.455 299.49 24.0253 299.49 24.8043C299.49 25.4163 299.67 25.8753 300.043 26.1952C300.403 26.5012 300.928 26.6681 301.592 26.6681Z"
      fill="#0071D6"
    />
    <path
      d="M317.357 27.8505V20.6314C317.357 19.7273 317.15 19.0457 316.735 18.6006C316.32 18.1555 315.684 17.9329 314.799 17.9329C313.637 17.9329 312.78 18.2529 312.24 18.8788C311.701 19.5047 311.425 20.5479 311.425 22.0084V27.8644H309.737V16.695H311.106L311.383 18.225H311.466C311.812 17.6687 312.296 17.2514 312.918 16.9454C313.54 16.6393 314.232 16.4863 314.992 16.4863C316.334 16.4863 317.33 16.8063 318.007 17.46C318.685 18.1138 319.017 19.1431 319.017 20.5758V27.8644H317.357V27.8505Z"
      fill="#0071D6"
    />
    <path
      d="M329.264 24.8043C329.264 25.8475 328.877 26.6403 328.116 27.2106C327.356 27.7809 326.263 28.0591 324.88 28.0591C323.414 28.0591 322.267 27.8227 321.437 27.3497V25.778C321.976 26.0561 322.543 26.2648 323.152 26.4178C323.76 26.5708 324.355 26.6543 324.922 26.6543C325.793 26.6543 326.471 26.5152 326.941 26.237C327.411 25.9588 327.646 25.5276 327.646 24.9434C327.646 24.5122 327.452 24.1366 327.079 23.8306C326.706 23.5246 325.973 23.1629 324.894 22.7318C323.857 22.3423 323.124 22.0085 322.695 21.7164C322.267 21.4242 321.935 21.1043 321.727 20.7288C321.52 20.3671 321.409 19.922 321.409 19.4213C321.409 18.5171 321.783 17.7938 322.516 17.2653C323.248 16.7367 324.258 16.4724 325.544 16.4724C326.733 16.4724 327.909 16.7228 329.043 17.2096L328.448 18.5867C327.328 18.1277 326.319 17.8912 325.42 17.8912C324.618 17.8912 324.023 18.0164 323.622 18.2668C323.221 18.5171 323.013 18.8649 323.013 19.31C323.013 19.6021 323.083 19.8664 323.248 20.075C323.401 20.2837 323.649 20.4784 323.981 20.6731C324.313 20.8679 324.963 21.1321 325.931 21.4938C327.245 21.9806 328.13 22.4675 328.6 22.9543C329.029 23.455 329.264 24.0671 329.264 24.8043Z"
      fill="#0071D6"
    />
    <path
      d="M336.787 28.0591C336.068 28.0591 335.404 27.92 334.81 27.6557C334.215 27.3914 333.717 26.9742 333.302 26.4178H333.178C333.261 27.0715 333.302 27.6835 333.302 28.2677V32.8579H331.615V16.695H332.984L333.219 18.225H333.302C333.731 17.613 334.243 17.1679 334.81 16.9036C335.377 16.6393 336.04 16.5002 336.787 16.5002C338.253 16.5002 339.401 17.001 340.189 18.0164C340.991 19.0318 341.392 20.4506 341.392 22.2727C341.392 24.1088 340.991 25.5276 340.175 26.543C339.373 27.5445 338.239 28.0591 336.787 28.0591ZM336.552 17.919C335.418 17.919 334.602 18.2389 334.09 18.8649C333.579 19.4908 333.33 20.5062 333.316 21.8832V22.2588C333.316 23.8306 333.579 24.9573 334.09 25.6249C334.616 26.3065 335.446 26.6403 336.594 26.6403C337.548 26.6403 338.308 26.2509 338.848 25.4719C339.387 24.693 339.664 23.6219 339.664 22.2449C339.664 20.8539 339.387 19.7829 338.848 19.0457C338.295 18.2946 337.534 17.919 336.552 17.919Z"
      fill="#0071D6"
    />
    <path
      d="M350.768 27.8505L350.436 26.2648H350.353C349.8 26.9602 349.247 27.4332 348.694 27.6835C348.141 27.9339 347.463 28.0591 346.633 28.0591C345.527 28.0591 344.67 27.767 344.047 27.1967C343.425 26.6264 343.107 25.8197 343.107 24.7625C343.107 22.5092 344.905 21.3269 348.487 21.2156L350.367 21.1599V20.4645C350.367 19.5882 350.174 18.9483 349.8 18.531C349.427 18.1137 348.832 17.9051 348.003 17.9051C347.076 17.9051 346.025 18.1972 344.863 18.7675L344.352 17.4739C344.905 17.1818 345.499 16.9453 346.149 16.7645C346.799 16.5976 347.449 16.5141 348.113 16.5141C349.441 16.5141 350.423 16.8062 351.059 17.4044C351.695 17.9886 352.013 18.9483 352.013 20.2419V27.8644H350.768V27.8505ZM346.979 26.6681C348.03 26.6681 348.846 26.376 349.441 25.8057C350.035 25.2215 350.34 24.4148 350.34 23.3855V22.384L348.652 22.4535C347.311 22.4953 346.357 22.7039 345.762 23.0795C345.168 23.455 344.877 24.0253 344.877 24.8043C344.877 25.4163 345.057 25.8753 345.43 26.1952C345.79 26.5012 346.315 26.6681 346.979 26.6681Z"
      fill="#0071D6"
    />
    <path
      d="M360.214 16.4863C360.711 16.4863 361.154 16.5281 361.541 16.6115L361.306 18.1833C360.85 18.0859 360.449 18.0303 360.089 18.0303C359.19 18.0303 358.416 18.3919 357.78 19.1292C357.144 19.8664 356.826 20.7705 356.826 21.8693V27.8644H355.138V16.695H356.521L356.715 18.7675H356.798C357.213 18.0442 357.711 17.4878 358.291 17.0844C358.872 16.6811 359.508 16.4863 360.214 16.4863Z"
      fill="#0071D6"
    />
    <path
      d="M367.681 28.0591C366.036 28.0591 364.75 27.5584 363.795 26.5569C362.841 25.5554 362.371 24.1505 362.371 22.3701C362.371 20.5757 362.814 19.1431 363.699 18.0859C364.584 17.0288 365.759 16.5002 367.253 16.5002C368.649 16.5002 369.742 16.9593 370.558 17.8773C371.374 18.7953 371.775 20.0194 371.775 21.5216V22.5926H364.127C364.155 23.9001 364.487 24.9016 365.109 25.5832C365.731 26.2648 366.617 26.5986 367.751 26.5986C368.94 26.5986 370.129 26.3482 371.291 25.8475V27.3497C370.696 27.614 370.129 27.7948 369.604 27.9061C369.064 28.0035 368.428 28.0591 367.681 28.0591ZM367.225 17.9051C366.34 17.9051 365.621 18.1972 365.095 18.7814C364.57 19.3656 364.252 20.1724 364.155 21.2017H369.963C369.963 20.1306 369.728 19.3239 369.258 18.7536C368.774 18.1833 368.11 17.9051 367.225 17.9051Z"
      fill="#0071D6"
    />
    <path
      d="M381.925 27.8505V20.6314C381.925 19.7273 381.718 19.0457 381.303 18.6006C380.888 18.1555 380.252 17.9329 379.367 17.9329C378.205 17.9329 377.348 18.2529 376.809 18.8788C376.269 19.5047 375.993 20.5479 375.993 22.0084V27.8644H374.305V16.695H375.675L375.951 18.225H376.034C376.38 17.6687 376.864 17.2514 377.486 16.9454C378.108 16.6393 378.8 16.4863 379.561 16.4863C380.902 16.4863 381.898 16.8063 382.575 17.46C383.253 18.1138 383.585 19.1431 383.585 20.5758V27.8644H381.925V27.8505Z"
      fill="#0071D6"
    />
    <path
      d="M393.846 27.8504H385.798V26.6959L391.855 18.0024H386.171V16.6949H393.694V18.0164L387.72 26.5568H393.846V27.8504Z"
      fill="#0071D6"
    />
    <path
      d="M52.601 7.79243H55.6784V14.3871L61.2179 7.79243H64.9985L58.6903 15.0246L65.2631 23.136H61.2399L55.7225 16.0138H55.6784V23.136H52.601V7.79243Z"
      fill="#0071D7"
    />
    <path
      d="M70.6486 21.1134C72.9127 21.1134 73.5061 19.091 73.5061 17.2006C73.5061 15.4641 72.5832 13.8371 70.6486 13.8371C68.7358 13.8371 67.8123 15.5082 67.8123 17.2006C67.8123 19.0693 68.4063 21.1134 70.6486 21.1134ZM70.6486 11.551C74.012 11.551 76.5837 13.8154 76.5837 17.5524C76.5837 20.8056 74.4071 23.3996 70.6486 23.3996C66.9113 23.3996 64.7353 20.8056 64.7353 17.5524C64.7353 13.8154 67.3071 11.551 70.6486 11.551Z"
      fill="#0071D7"
    />
    <path
      d="M78.1889 11.8149H80.9363V13.3977H80.98C81.9036 12.0128 83.2442 11.5511 84.3434 11.5511C85.9267 11.5511 87.0478 12.1448 87.6849 13.5515C88.3665 12.1889 89.839 11.5511 91.224 11.5511C94.0159 11.5511 94.8075 13.4639 94.8075 15.9917V23.136H91.8618V16.3872C91.8618 15.3325 91.8618 13.8373 90.1692 13.8373C88.2127 13.8373 87.9707 16.1678 87.9707 17.6404V23.136H85.025V16.3872C85.025 15.3325 85.025 13.8373 83.3324 13.8373C81.3759 13.8373 81.1346 16.1678 81.1346 17.6404V23.136H78.1889V11.8149Z"
      fill="#0071D7"
    />
    <path
      d="M97.1817 11.8149H99.9287V13.3977H99.9737C100.897 12.0128 102.238 11.5511 103.337 11.5511C104.92 11.5511 106.041 12.1448 106.678 13.5515C107.36 12.1889 108.833 11.5511 110.217 11.5511C113.009 11.5511 113.801 13.4639 113.801 15.9917V23.136H110.855V16.3872C110.855 15.3325 110.855 13.8373 109.162 13.8373C107.206 13.8373 106.964 16.1678 106.964 17.6404V23.136H104.018V16.3872C104.018 15.3325 104.018 13.8373 102.325 13.8373C100.369 13.8373 100.128 16.1678 100.128 17.6404V23.136H97.1817V11.8149Z"
      fill="#0071D7"
    />
    <path
      d="M122.681 10.8698H122.638L120.417 17.2008H124.902L122.681 10.8698ZM120.989 7.79243H124.462L130.486 23.136H127.078L125.759 19.6187H119.538L118.198 23.136H115.01L120.989 7.79243Z"
      fill="#0071D7"
    />
    <path
      d="M141.499 23.1358H138.707V21.5972H138.663C137.762 22.6963 136.486 23.3996 134.948 23.3996C132.266 23.3996 131.101 21.509 131.101 18.9593V11.8147H134.047V17.8602C134.047 19.2451 134.068 21.1134 135.959 21.1134C138.091 21.1134 138.553 18.8055 138.553 17.3545V11.8147H141.499V23.1358Z"
      fill="#0071D7"
    />
    <path
      d="M150.986 14.1891C150.085 13.8812 149.426 13.7054 148.348 13.7054C147.557 13.7054 146.612 13.9909 146.612 14.9583C146.612 16.7611 151.733 15.6179 151.733 19.7068C151.733 22.3446 149.382 23.3996 146.986 23.3996C145.865 23.3996 144.722 23.202 143.644 22.9159L143.82 20.4981C144.743 20.9597 145.711 21.2454 146.722 21.2454C147.469 21.2454 148.656 20.9597 148.656 19.8606C148.656 17.6403 143.534 19.1569 143.534 15.0686C143.534 12.6284 145.666 11.551 147.975 11.551C149.36 11.551 150.261 11.7712 151.184 11.9685L150.986 14.1891Z"
      fill="#0071D7"
    />
    <path
      d="M154.585 13.9694H152.409V11.8147H154.585V9.55091L157.53 8.60559V11.8147H160.146V13.9694H157.53V19.2451C157.53 20.2122 157.794 21.1134 158.915 21.1134C159.443 21.1134 159.948 21.0038 160.256 20.8056L160.344 23.1358C159.729 23.3117 159.047 23.3996 158.167 23.3996C155.859 23.3996 154.585 21.9706 154.585 19.7285V13.9694Z"
      fill="#0071D7"
    />
    <path
      d="M161.85 11.8149H164.466V14.3871H164.51C164.642 13.3318 165.85 11.5511 167.609 11.5511C167.895 11.5511 168.202 11.5511 168.51 11.6393V14.6071C168.247 14.453 167.719 14.3651 167.191 14.3651C164.795 14.3651 164.795 17.3546 164.795 18.9815V23.136H161.85V11.8149Z"
      fill="#0071D7"
    />
    <path
      d="M169.853 11.8149H172.799V23.136H169.853V11.8149ZM169.853 6.93498H172.799V9.74892H169.853V6.93498Z"
      fill="#0071D7"
    />
    <path
      d="M179.365 21.2454C180.267 21.2454 180.97 20.8497 181.432 20.2342C181.916 19.5968 182.047 18.7835 182.047 17.904H180.773C179.453 17.904 177.497 18.1239 177.497 19.8606C177.497 20.828 178.31 21.2454 179.365 21.2454ZM175.914 12.4087C177.057 11.8809 178.596 11.551 179.849 11.551C183.301 11.551 184.729 12.98 184.729 16.3215V17.772C184.729 18.9152 184.751 19.7726 184.773 20.6077C184.795 21.4654 184.839 22.2567 184.905 23.1358H182.311C182.201 22.5425 182.201 21.7951 182.179 21.4434H182.135C181.454 22.6963 179.981 23.3996 178.64 23.3996C176.639 23.3996 174.683 22.1905 174.683 20.0364C174.683 18.3439 175.496 17.3545 176.618 16.8049C177.739 16.2553 179.189 16.1456 180.42 16.1456H182.047C182.047 14.3209 181.234 13.7054 179.497 13.7054C178.244 13.7054 176.991 14.1891 176.002 14.9363L175.914 12.4087Z"
      fill="#0071D7"
    />
    <path
      d="M52.6115 34.0925V26.7851H53.5424V29.9935H53.5728L56.1236 26.7851H57.1758L54.5244 30.0844L57.3279 34.0925H56.1334L53.5728 30.2767H53.5424V34.0925H52.6115Z"
      fill="#0071D7"
    />
    <path
      d="M61.0704 31.2589C61.0704 29.8621 60.6355 29.5584 59.9978 29.5584C59.3603 29.5584 58.9248 29.8621 58.9248 31.2589C58.9248 32.9189 59.1376 33.5159 59.9978 33.5159C60.8579 33.5159 61.0704 32.9189 61.0704 31.2589ZM58.0545 31.6131C58.0545 30.1151 58.2267 28.8804 59.9978 28.8804C61.7692 28.8804 61.9407 30.1151 61.9407 31.6131C61.9407 33.87 61.0806 34.1938 59.9978 34.1938C58.9146 34.1938 58.0545 33.87 58.0545 31.6131Z"
      fill="#0071D7"
    />
    <path
      d="M65.7993 34.0925V30.5501C65.7993 30.034 65.6575 29.5583 64.9893 29.5583C64.7367 29.5583 64.3924 29.7098 64.2711 29.9025C64.1194 30.1555 64.0785 30.4592 64.0785 30.6516V34.0925H63.2487V30.0442C63.2487 29.6897 63.2283 29.3356 63.1979 28.9811H64.0785V29.6192H64.0989C64.3416 29.0724 64.777 28.8802 65.3746 28.8802C65.8402 28.8802 66.3765 29.0724 66.5684 29.5382C66.852 28.9916 67.3177 28.8802 67.7833 28.8802C68.3195 28.8802 69.1796 29.0018 69.1796 30.2059V34.0925H68.3499V30.5501C68.3499 30.034 68.2081 29.5583 67.5399 29.5583C67.2266 29.5583 67.1356 29.5684 66.9127 29.7506C66.67 29.953 66.629 30.4592 66.629 30.6516V34.0925H65.7993Z"
      fill="#0071D7"
    />
    <path
      d="M73.1611 34.0925V30.5501C73.1611 30.034 73.0192 29.5583 72.3511 29.5583C72.0978 29.5583 71.7542 29.7098 71.6328 29.9025C71.4804 30.1555 71.4402 30.4592 71.4402 30.6516V34.0925H70.6105V30.0442C70.6105 29.6897 70.59 29.3356 70.5597 28.9811H71.4402V29.6192H71.4607C71.7034 29.0724 72.1387 28.8802 72.7356 28.8802C73.202 28.8802 73.7382 29.0724 73.9301 29.5382C74.2138 28.9916 74.6794 28.8802 75.1451 28.8802C75.6813 28.8802 76.5414 29.0018 76.5414 30.2059V34.0925H75.7116V30.5501C75.7116 30.034 75.5698 29.5583 74.9017 29.5583C74.5877 29.5583 74.4974 29.5684 74.2744 29.7506C74.0317 29.953 73.9908 30.4592 73.9908 30.6516V34.0925H73.1611Z"
      fill="#0071D7"
    />
    <path
      d="M80.7035 28.9813H81.5332V33.0299C81.5332 33.3841 81.5536 33.7385 81.584 34.0927H80.7035V33.4753H80.6731C80.3994 33.9411 79.9139 34.1938 79.3777 34.1938C78.4866 34.1938 78.0012 33.7487 78.0012 32.8781V28.9813H78.8309V32.5239C78.8309 33.1413 79.1145 33.5564 79.7016 33.5564C80.1468 33.5564 80.7035 33.2223 80.7035 32.3724V28.9813Z"
      fill="#0071D7"
    />
    <path
      d="M85.8074 34.0925V30.5501C85.8074 30.0036 85.6148 29.5583 84.9065 29.5583C83.9956 29.5583 83.8636 30.368 83.8636 31.0664V34.0925H83.0339V30.0442C83.0339 29.6897 83.0142 29.3356 82.9838 28.9811H83.8636V29.6393H83.9046C84.2284 29.0622 84.6129 28.8802 85.2705 28.8802C86.3641 28.8802 86.6372 29.5382 86.6372 30.5501V34.0925H85.8074Z"
      fill="#0071D7"
    />
    <path
      d="M88.9218 34.0925H88.092V28.9811H88.9218V34.0925ZM88.0412 26.7851H88.9719V27.6758H88.0412V26.7851Z"
      fill="#0071D7"
    />
    <path
      d="M91.2035 34.0925H90.3738V26.7851H91.2035V31.1879H91.224L92.7826 28.9811H93.7943L92.0939 31.1777L94.0984 34.0925H93.0457L91.224 31.2282H91.2035V34.0925Z"
      fill="#0071D7"
    />
    <path
      d="M95.6852 32.5747C95.6852 33.0704 95.9279 33.5159 96.4846 33.5159C96.9905 33.5159 97.6184 33.2019 97.5373 31.5522C96.7478 31.5626 95.6852 31.4915 95.6852 32.5747ZM97.5578 33.3538H97.5373C97.2847 33.9411 96.8896 34.1938 96.2518 34.1938C95.1688 34.1938 94.8555 33.4955 94.8555 32.5138C94.8555 30.9654 96.3731 30.8942 97.5373 30.9246C97.5578 30.2466 97.5676 29.5077 96.6772 29.5077C96.1205 29.5077 95.827 29.8823 95.8778 30.4186H94.9768C95.0171 29.2748 95.6245 28.8804 96.7076 28.8804C98.0234 28.8804 98.3677 29.5584 98.3677 30.4186V32.8781C98.3677 33.283 98.408 33.698 98.4686 34.0927H97.5578V33.3538Z"
      fill="#0071D7"
    />
    <path
      d="M100.22 28.9812V27.9998L101.05 27.6252V28.9812H102.163V29.6091H101.05V32.7364C101.05 33.0602 101.05 33.4752 101.809 33.4752C101.87 33.4752 102.001 33.4551 102.183 33.4347V34.0725C101.91 34.0926 101.637 34.1535 101.363 34.1535C100.574 34.1535 100.22 33.8297 100.22 33.2427V29.6091H99.3797V28.9812H100.22Z"
      fill="#0071D7"
    />
    <path
      d="M103.993 34.0925H103.164V28.9811H103.993V34.0925ZM103.114 26.7851H104.044V27.6758H103.114V26.7851Z"
      fill="#0071D7"
    />
    <path
      d="M108.317 31.2589C108.317 29.8621 107.882 29.5584 107.245 29.5584C106.607 29.5584 106.172 29.8621 106.172 31.2589C106.172 32.9189 106.385 33.5159 107.245 33.5159C108.105 33.5159 108.317 32.9189 108.317 31.2589ZM105.302 31.6131C105.302 30.1151 105.474 28.8804 107.245 28.8804C109.016 28.8804 109.188 30.1151 109.188 31.6131C109.188 33.87 108.328 34.1938 107.245 34.1938C106.162 34.1938 105.302 33.87 105.302 31.6131Z"
      fill="#0071D7"
    />
    <path
      d="M113.289 34.0925V30.5501C113.289 30.0036 113.096 29.5583 112.388 29.5583C111.477 29.5583 111.345 30.368 111.345 31.0664V34.0925H110.516V30.0442C110.516 29.6897 110.496 29.3356 110.465 28.9811H111.345V29.6393H111.386C111.71 29.0622 112.095 28.8802 112.752 28.8802C113.846 28.8802 114.119 29.5382 114.119 30.5501V34.0925H113.289Z"
      fill="#0071D7"
    />
    <path
      d="M117.096 34.1938C115.993 34.1938 115.305 33.7079 115.335 32.5342H116.276C116.276 32.8277 116.287 33.5159 117.127 33.5159C117.623 33.5159 118.007 33.2629 118.007 32.7364C118.007 31.8256 115.457 31.8054 115.457 30.2769C115.457 29.7406 115.781 28.8804 117.218 28.8804C118.129 28.8804 118.908 29.3156 118.838 30.3279H117.917C117.927 29.791 117.643 29.5077 117.117 29.5077C116.672 29.5077 116.327 29.7507 116.327 30.1959C116.327 31.0968 118.878 31.0767 118.878 32.6353C118.878 33.7586 118.109 34.1938 117.096 34.1938Z"
      fill="#0071D7"
    />
    <path
      d="M121.916 33.5159C122.958 33.5159 122.958 32.4026 122.958 31.6332C122.958 30.267 122.837 29.5584 121.946 29.5584C121.026 29.5584 120.985 30.6517 120.985 31.3294C120.985 32.1091 120.894 33.5159 121.916 33.5159ZM120.985 34.0927H120.155V26.7852H120.985V29.4977H121.015C121.299 29.0926 121.653 28.8804 122.139 28.8804C123.788 28.8804 123.829 30.348 123.829 31.6232C123.829 33.87 122.999 34.1938 122.179 34.1938C121.643 34.1938 121.289 33.9612 121.005 33.4852H120.985V34.0927Z"
      fill="#0071D7"
    />
    <path
      d="M127.944 31.1779C127.944 29.6899 127.529 29.5077 126.922 29.5077C126.395 29.5077 125.96 29.7607 125.95 31.1779H127.944ZM125.95 31.8054C125.95 33.2325 126.335 33.5159 126.983 33.5159C127.549 33.5159 127.843 33.0603 127.873 32.544H128.784C128.774 33.6776 128.075 34.1938 127.003 34.1938C125.92 34.1938 125.059 33.87 125.059 31.6131C125.059 30.1151 125.232 28.8804 127.003 28.8804C128.46 28.8804 128.825 29.6799 128.825 31.3702V31.8054H125.95Z"
      fill="#0071D7"
    />
    <path
      d="M132.972 34.0925V30.6312C132.972 30.0036 132.77 29.5583 132.061 29.5583C131.231 29.5583 131.019 30.2264 131.019 31.0865V34.0925H130.189V26.7851H131.019V29.6393H131.059C131.394 29.042 131.778 28.8802 132.446 28.8802C133.316 28.8802 133.802 29.3154 133.802 30.4691V34.0925H132.972Z"
      fill="#0071D7"
    />
    <path
      d="M138.439 28.1211H137.488V27.0686H138.439V28.1211ZM138.146 31.2589C138.146 29.8621 137.711 29.5584 137.073 29.5584C136.436 29.5584 136 29.8621 136 31.2589C136 32.9189 136.213 33.5159 137.073 33.5159C137.933 33.5159 138.146 32.9189 138.146 31.2589ZM136.658 28.1211H135.707V27.0686H136.658V28.1211ZM135.13 31.6131C135.13 30.1151 135.302 28.8804 137.073 28.8804C138.844 28.8804 139.016 30.1151 139.016 31.6131C139.016 33.87 138.156 34.1938 137.073 34.1938C135.99 34.1938 135.13 33.87 135.13 31.6131Z"
      fill="#0071D7"
    />
    <path
      d="M141.19 29.7506H141.21C141.554 28.9715 141.979 28.8802 142.789 28.8802V29.7404C142.718 29.7305 142.637 29.7203 142.566 29.7098C142.496 29.7002 142.424 29.6897 142.343 29.6897C141.422 29.6897 141.19 30.3881 141.19 31.0766V34.0925H140.36V28.9811H141.19V29.7506Z"
      fill="#0071D7"
    />
    <path
      d="M145.572 29.5584C144.682 29.5584 144.561 30.267 144.561 31.6332C144.561 32.4026 144.561 33.5159 145.603 33.5159C146.534 33.5159 146.534 32.3619 146.534 31.3294C146.534 30.6517 146.494 29.5584 145.572 29.5584ZM146.534 26.7852H147.365V34.0927H146.534V33.4852H146.514C146.231 33.9612 145.877 34.1938 145.34 34.1938C144.52 34.1938 143.69 33.87 143.69 31.6232C143.69 30.348 143.731 28.8804 145.381 28.8804C145.867 28.8804 146.221 29.0926 146.504 29.4977H146.534V26.7852Z"
      fill="#0071D7"
    />
    <path
      d="M151.647 31.1779C151.647 29.6899 151.232 29.5077 150.626 29.5077C150.099 29.5077 149.664 29.7607 149.654 31.1779H151.647ZM149.654 31.8054C149.654 33.2325 150.039 33.5159 150.686 33.5159C151.253 33.5159 151.546 33.0603 151.577 32.544H152.488C152.478 33.6776 151.779 34.1938 150.706 34.1938C149.624 34.1938 148.763 33.87 148.763 31.6131C148.763 30.1151 148.935 28.8804 150.706 28.8804C152.164 28.8804 152.528 29.6799 152.528 31.3702V31.8054H149.654Z"
      fill="#0071D7"
    />
    <path
      d="M158.661 27.6455H158.641L157.498 31.5418H159.835L158.661 27.6455ZM158.095 26.7851H159.279L161.658 34.0925H160.615L160.089 32.3516H157.265L156.718 34.0925H155.777L158.095 26.7851Z"
      fill="#0071D7"
    />
    <path
      d="M165.419 28.9813H166.249V33.0299C166.249 33.3841 166.269 33.7385 166.3 34.0927H165.419V33.4753H165.389C165.116 33.9411 164.63 34.1938 164.093 34.1938C163.203 34.1938 162.717 33.7487 162.717 32.8781V28.9813H163.546V32.5239C163.546 33.1413 163.83 33.5564 164.417 33.5564C164.862 33.5564 165.419 33.2223 165.419 32.3724V28.9813Z"
      fill="#0071D7"
    />
    <path
      d="M169.251 34.1938C168.148 34.1938 167.459 33.7079 167.49 32.5342H168.431C168.431 32.8277 168.441 33.5159 169.281 33.5159C169.777 33.5159 170.162 33.2629 170.162 32.7364C170.162 31.8256 167.611 31.8054 167.611 30.2769C167.611 29.7406 167.935 28.8804 169.373 28.8804C170.284 28.8804 171.063 29.3156 170.992 30.3279H170.071C170.081 29.791 169.798 29.5077 169.271 29.5077C168.826 29.5077 168.482 29.7507 168.482 30.1959C168.482 31.0968 171.032 31.0767 171.032 32.6353C171.032 33.7586 170.263 34.1938 169.251 34.1938Z"
      fill="#0071D7"
    />
    <path
      d="M172.674 28.9812V27.9998L173.504 27.6252V28.9812H174.617V29.6091H173.504V32.7364C173.504 33.0602 173.504 33.4752 174.263 33.4752C174.324 33.4752 174.455 33.4551 174.637 33.4347V34.0725C174.364 34.0926 174.091 34.1535 173.817 34.1535C173.028 34.1535 172.674 33.8297 172.674 33.2427V29.6091H171.834V28.9812H172.674Z"
      fill="#0071D7"
    />
    <path
      d="M176.472 29.7506H176.493C176.837 28.9715 177.262 28.8802 178.072 28.8802V29.7404C178 29.7305 177.92 29.7203 177.849 29.7098C177.778 29.7002 177.707 29.6897 177.626 29.6897C176.705 29.6897 176.472 30.3881 176.472 31.0766V34.0925H175.642V28.9811H176.472V29.7506Z"
      fill="#0071D7"
    />
    <path
      d="M179.96 34.0925H179.13V28.9811H179.96V34.0925ZM179.08 26.7851H180.011V27.6758H179.08V26.7851Z"
      fill="#0071D7"
    />
    <path
      d="M182.1 32.5747C182.1 33.0704 182.343 33.5159 182.9 33.5159C183.406 33.5159 184.034 33.2019 183.953 31.5522C183.163 31.5626 182.1 31.4915 182.1 32.5747ZM183.973 33.3538H183.953C183.699 33.9411 183.305 34.1938 182.667 34.1938C181.584 34.1938 181.271 33.4955 181.271 32.5138C181.271 30.9654 182.788 30.8942 183.953 30.9246C183.973 30.2466 183.983 29.5077 183.092 29.5077C182.536 29.5077 182.242 29.8823 182.293 30.4186H181.392C181.432 29.2748 182.04 28.8804 183.123 28.8804C184.438 28.8804 184.782 29.5584 184.782 30.4186V32.8781C184.782 33.283 184.823 33.698 184.884 34.0927H183.973V33.3538Z"
      fill="#0071D7"
    />
    <path
      d="M35.0686 14.2168L33.943 15.3424L35.0686 16.468L36.1941 15.3424L35.0686 14.2168ZM30.685 33.9429L29.5595 35.0684L30.685 36.1941L31.8106 35.0684L30.685 33.9429ZM2.19192 27.3261L1.06635 28.452L2.19192 29.5775L3.31749 28.452L2.19192 27.3261ZM16.4681 2.19187L15.3425 3.31744L14.217 2.19187L15.3425 1.0663L16.4681 2.19187Z"
      fill="#0071D7"
    />
    <path
      d="M17.4622 1.64372C17.2177 0.700055 16.3614 0 15.3425 0C14.1343 0 13.1509 0.983405 13.1509 2.19195C13.1509 3.4005 14.1343 4.38354 15.3425 4.38354C16.3614 4.38354 17.2177 3.68348 17.4622 2.7398H38.9042V26.2971H40.0002V1.64372H17.4622ZM30.685 32.8725C29.6642 32.8725 28.807 33.5748 28.5642 34.5206H7.12356V10.9595H6.02749V35.6164H28.5662C28.812 36.5582 29.6675 37.2566 30.685 37.2566C31.8936 37.2566 32.8766 36.273 32.8766 35.0644C32.8766 33.8556 31.8936 32.8725 30.685 32.8725ZM37.2565 15.343C37.2565 14.1345 36.2732 13.1514 35.065 13.1514C33.8564 13.1514 32.8731 14.1345 32.8731 15.343C32.8731 16.3633 33.575 17.2208 34.5206 17.4639V38.9042H10.959V40H35.6167V17.4619C36.5585 17.2161 37.2565 16.3606 37.2565 15.343ZM2.73981 7.12335V26.3728C3.68349 26.617 4.38347 27.4736 4.38347 28.4928C4.38347 29.7014 3.40042 30.6845 2.19188 30.6845C0.983336 30.6845 0 29.7014 0 28.4928C0 27.4736 0.699986 26.617 1.64395 26.3728V6.02726H26.2976V7.12335H2.73981Z"
      fill="#0071D7"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1029_3322"
        x1="204"
        y1="20.9972"
        x2="400.003"
        y2="20.9972"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0071D7" />
        <stop offset="0.2093" stopColor="#4999E2" />
        <stop offset="0.3165" stopColor="#81B8EB" />
        <stop offset="0.4224" stopColor="#B2D4F2" />
        <stop offset="0.5027" stopColor="#D1E5F7" />
        <stop offset="0.5479" stopColor="#DCEBF9" />
        <stop offset="0.6671" stopColor="#EAF3FB" />
        <stop offset="0.8529" stopColor="#FAFCFE" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);
