import { Typography } from "@mui/material";
import { t } from "i18next";
import { FC, useMemo } from "react";
import { useSettingsStore } from "../../context/SettingsStore";
import { getCategoryColor, categoriesByTransferType } from "../../helpers/helpers";

interface Props {
  center?: boolean;
  linkOpacity?: number;
}

export const Legend: FC<Props> = ({ center, linkOpacity = 0.4 }) => {
  //const sujetSettings = useSettingsStore().modules.sujets;
  //const colorIndex = useSettingsStore().modules.flows_transfer.categoryColorIndex;
  const colorSettings = useSettingsStore().modules.colors;
  const categoryColorGetter = useMemo(() => getCategoryColor(colorSettings), [colorSettings]);

  return (
    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: center ? "center" : "flex-start" }}>
      {[...categoriesByTransferType[2], ...categoriesByTransferType[4]].map((category, index) => (
        <div key={index} style={{ marginRight: "10px", marginBottom: "10px", display: "flex", alignItems: "center" }}>
          <div style={{
            width: "15px",
            height: "15px",
            backgroundColor: categoryColorGetter(category),
            display: "inline-block",
            marginRight: "5px",
            opacity: linkOpacity,
          }} />
          <span><Typography variant="caption">{t(category).toString()}</Typography></span>
        </div>
      ))}
    </div>
  );
};
