import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Render } from './helper-components';

type InfoProps = {
    translationKey?: string;
    message?: string;
}

export const Hint = ({ translationKey, message }: InfoProps) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();
    const isMobileLandscape = useMediaQuery({
        maxHeight: 575.98,
        orientation: "landscape",
    });
    const isMobilePortrait = useMediaQuery({ maxWidth: 600 });
    const isMobile = isMobileLandscape || isMobilePortrait;

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            {isMobile ? <IconButton aria-describedby="mouse-over-popover"
                onClick={handlePopoverOpen} >
                <InfoIcon color='primary' />
            </IconButton>
                :
                <>
                    <Typography
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                    >

                        <InfoIcon color='primary' />
                    </Typography>
                </>}
            <Popover
                id="mouse-over-popover"
                sx={ isMobile ? {} : { pointerEvents: 'none' }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handlePopoverClose}
                
            >
                <div style={{ padding: 15, maxWidth: 700 }}>
                    <Render when={isMobile}>
                        <IconButton aria-describedby="mouse-over-popover"
                            onClick={handlePopoverClose} >
                            <CloseIcon color='primary' />
                        </IconButton>
                    </Render>
                    <span dangerouslySetInnerHTML={{ __html: translationKey ? t(translationKey) : message }}></span>
                </div>
            </Popover>

        </div>
    );
}