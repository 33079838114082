import slugify from "slugify";
import { getPFColor } from "../colors";
import Config from "../config/settings";
import i18n from "../i18n";

import { GroupType } from "../models/models";
import { PayersRecipientsModuleSettings } from "../models/modules";
import { IAmountFilterRange, IOrgAndMediaQuery } from "../states/base_states";
import { ColorsModuleSettings } from "../pages/colors.settings";

export const periodToString = (useHalfyears: boolean, t) => (p: number) =>
  p ? `${useHalfyears ? t('Half')+' ' : 'Q'}${p.toString().slice(4)}/${p.toString().slice(0, 4)}` : "";

export const objectNormalize = (obj) =>
  Object.entries(obj).reduce(
    (acc, [k, v]) =>
      Array.isArray(v) && v.length === 0
        ? acc
        : { ...acc, [k]: Array.isArray(v) ? v.join(" ") : [v].join(" ") },
    {}
  );

export const isEqual = (
  obj1: any,
  obj2: any,
  exclude: string[] = []
): boolean => {
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  if (obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1).filter(key => !exclude.includes(key));
  const keys2 = Object.keys(obj2).filter(key => !exclude.includes(key));

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key)) {
      return false;
    }

    if (!isEqual(obj1[key], obj2[key], exclude)) {
      return false;
    }
  }

  return true;
}


export const isObjectSubset = (subset: Record<string, any>, superset: Record<string, any>): boolean => {
  for (const key in subset) {
    const subsetValue = subset[key];
    const supersetValue = superset[key];

    if (Array.isArray(subsetValue)) {
      if (!Array.isArray(supersetValue) || subsetValue.length !== supersetValue.length) {
        return false;
      }

      for (let i = 0; i < subsetValue.length; i++) {
        if (subsetValue[i] !== supersetValue[i]) {
          return false;
        }
      }
    } else if (subsetValue !== supersetValue) {
      return false;
    }
  }
  return true;
}

export const objectIncluded = (
  subObject: { [key: number]: string },
  superObject: { [key: number]: string }
) => {
  if (Object.keys(subObject).length === 0) {
    return false;
  }
  const subKeys = Object.keys(subObject);
  let ok = true;
  Object.keys(superObject).forEach((k) => {
    if (!subKeys.includes(k)) {
      ok = false;
    }
  });
  if (!ok) {
    return false;
  }
  /*
    if (!Object.keys(superObject).sort().join(' ').includes(Object.keys(subObject).sort().join(' '))) {
        return false;
    }*/
  const keys = Object.keys(subObject).sort();
  for (let i = 0; i < keys.length; i++) {
    const k = keys[i];
    if (Array.isArray(subObject[k])) {
      let array = [...subObject[k]];
      if (
        array.sort().join(" ") !==
        (Array.isArray(superObject[k])
          ? [...superObject[k]].sort().join(" ")
          : [superObject[k]].join(" "))
      ) {
        return false;
      }
    } else if (Array.isArray(superObject[k])) {
      if (superObject[k][0] !== subObject[k]) {
        return false;
      }
    } else {
      if (superObject[k] !== subObject[k]) {
        return false;
      }
    }
  }
  return true;
};

export const isTouchSupported = () =>
  "ontouchstart" in window && "ontouchend" in window;
export const zip = (a, b) => a.map((k, i) => [k, b[i]]);

export const toCurrency = (field: string) => (row: any) =>
  row[field]?.toLocaleString(i18n.language, {
    style: "currency",
    currency: "EUR",
  });


export const fundingTypes = [
  "Pressefoerderung",
  "Publizistikfoerderung",
  "Privatrundfunkfonds",
  "Nichtkommerzieller Rundfunkfonds",
  "Fernsehfonds",
  "Digitale Transformation"
]

export const fundingTypesIndices = () => fundingTypes.map((_, idx) => `${idx}`)

/* the default value 22 stands for advertising */
export const getFundingValueFromName = (name: string): number =>
  fundingTypes.indexOf(name) < 0 ? 22 : fundingTypes.indexOf(name)

const colorMap = fundingTypes.reduce((acc, ft, idx) => ({ [ft]: getPFColor(idx + 1, fundingTypes.length) }), {})

export const getColorFromPaymentDetailType = (paymentType: string): string =>
  paymentType in colorMap ? colorMap[paymentType] : "#b3bef5";


export const getGroupTypesByType = (groupType: GroupType[], type: 'org' | 'media'): GroupType[] =>
  groupType.filter(g => g.type === 'A' || g.type === (type === 'org' ? 'P' : 'B'))


export const commonChartOptions = (isMobile: boolean) => ({
  title: {
    show: true,
    textStyle: {
      fontSize: isMobile ? Config.mobile.charts.title_font_size : Config.desktop.charts.title_font_size,
      height: 80,
      fontWeight: isMobile ? Config.mobile.charts.title_font_weight : Config.desktop.charts.title_font_weight,
      fontFamily: getComputedStyle(document.documentElement).getPropertyValue('--font-family'),
      overflow: "truncate",
      color: getComputedStyle(document.documentElement).getPropertyValue('--charts-title-color'),
    },
    subtextStyle: {
      fontSize: 14,
      height: 40,
      fontFamily: getComputedStyle(document.documentElement).getPropertyValue('--font-family'),
      overflow: "truncate",
      color: getComputedStyle(document.documentElement).getPropertyValue('--charts-subtitle-color'),
    },
  },
  textStyle: {
    fontFamily: getComputedStyle(document.documentElement).getPropertyValue('--font-family'),
    color: getComputedStyle(document.documentElement).getPropertyValue('--charts-text-color'),
  },
})

export const range = (start: number, end: number): number[] => {
  const result = [] as number[];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
};

export const arraysEqual = <T>(a: T[], b: T[]): boolean => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++ ) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}


export const getRangeString = ([from, to]: [number,number], i18n, t): string => {
   const start = parseFloat(from.toString())
   const end = parseFloat(to.toString())
   return `${t('from')} ${start.toLocaleString(i18n.language, {style: 'currency', currency: 'EUR',})} ` +
          `${t('to')} ${end.toLocaleString(i18n.language, {style: 'currency', currency: 'EUR',})}`
}

export const getAmountRangeString = <Q extends IAmountFilterRange>(query: Q ,amountRange: [number, number], i18n, t): string =>
  `${t('amounts')}: `+ (arraysEqual(amountRange, query.amountFilterRange ?? [0,0]) ? t('all') :
        getRangeString(query.amountFilterRange ?? [0,0], i18n, t))

export type PType = 'advertising' | 'funding' | 'both'

export const getPaymentType = <Q extends {pType:number[] | 2 | 4 | 31}>(query: Q): PType => {
  if (Array.isArray(query.pType) && query.pType.length > 1) {
    return 'both';
  }
  const pType = Array.isArray(query.pType) ? query.pType[0] : query.pType;
  if (pType === 4) {
    return 'funding';
  } else  {
    return 'advertising';
  }
}

export const typedEntries = <T extends Record<string, any>>(obj: T): [keyof T, T[keyof T]][] => {
  return Object.entries(obj) as [keyof T, T[keyof T]][];
}

export const partition = <T>(arr: T[], fn: (arg:T)=>boolean): [T[],T[]] =>
  arr.reduce(
    (acc, val) => {
      acc[fn(val) ? 0 : 1].push(val);
      return acc;
    },
    [[] as T[] , [] as T[]]
  );

export const getDefaultQuery = (moduleSettings: PayersRecipientsModuleSettings, groupTypes: GroupType[]) => {
  let defaultQuery = {} as IOrgAndMediaQuery;
  const {
    defaultOrganisations, defaultOrgGroupType, defaultOrgGroups,
    defaultMedia, defaultMediaGroupType, defaultMediaGroups } = moduleSettings;
  if (defaultOrganisations) {
    defaultQuery.organisations = defaultOrganisations;
  }
  if (defaultOrgGroupType) {
    const preSelectedOrgGroupType = getGroupTypesByType(
      groupTypes,
      "org"
    ).find((g) => g.name === defaultOrgGroupType);
    if (preSelectedOrgGroupType) {
      defaultQuery.orgGroupType = preSelectedOrgGroupType._id;
    }
    if (defaultOrgGroups) {
      defaultQuery.orgGroups = defaultOrgGroups;
    }
  }
  if (defaultMedia) {
    defaultQuery.media = defaultMedia;
  }
  if (defaultMediaGroupType) {
    const preSelectedMediaGroupType = getGroupTypesByType(
      groupTypes,
      "media"
    ).find((g) => g.name === defaultMediaGroupType);
    if (preSelectedMediaGroupType) {
      defaultQuery.mediaGroupType = preSelectedMediaGroupType._id;
    }
    if (defaultMediaGroups) {
      defaultQuery.mediaGroups = defaultMediaGroups;
    }
  }
  return defaultQuery;
}

export const categoriesByTransferType = {
  2: ["unbekannt","Print", "Online","Hörfunk","Fernsehen","Out of Home"],
  4: ["Förderung"],
  31: ["ORF-Beitrag gemäß § 31 Abs. 1 ORF-G","Kompensation nach § 31 Abs. 11 und 13a ORF-G"],
};

export const slugifyCategory = (cat: string): string => slugify(cat, { replacement: '_', lower: true, strict: true});

export const getCategoryColor = (colorSettings: ColorsModuleSettings) => (category: string):string =>
  colorSettings[slugifyCategory(category)]


export const getTranslatedPaginationOptions = (t) => {
  console.log("Creating pagination options")
  return {
    rowsPerPageText: t('Rows per page')+':',
    rangeSeparatorText: t('of'),
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: t('All'),
  }
}

export const shortenName = (name: string | undefined, maxLength = 30) => {
  return (name?.length ?? 0) - maxLength <= 4 ? name : name?.slice(0, maxLength) + "…"
}

// TODO: use linebreak/wordbreak library?
export const lineBreakName = (name: string | undefined, lineBreakChar = "\n", padding: string | number = "", threshold = 60) => {
  return lineBreakNameByChar(name, lineBreakChar, " ", padding, threshold)
}

export const lineBreakNameByChar = (name: string | undefined, lineBreakChar = "\n", breakChar = " ", padding: string | number = "", threshold = 60) => {
  if (!name || (name.length ?? 0) <= threshold) {
    return name
  }

  const breakLength = Math.ceil(((name?.length ?? 0) / 2) + name.length * 0.1)
  const padString = typeof padding === "number" ? " ".repeat(padding) : padding

  const breakIndex = name.slice(0, breakLength).lastIndexOf(breakChar)

  if (breakIndex === -1) {
    const newBreakChar = breakChar === " " ? "_" : breakChar === "_" ? "-" : null
    if (newBreakChar) {
      return lineBreakNameByChar(name, lineBreakChar, newBreakChar, padding, threshold)
    } else {
      return name
    }
  }

  return `${name.slice(0, breakIndex + 1)}${lineBreakChar}${padString}${name.slice(breakIndex + 1)}`
}

export const lineBreakHtml = (name: string | undefined, padding: string | number = "", threshold = 60) => {
  const padString = typeof padding === "number" ? "&nbsp;".repeat(padding) : padding
  return lineBreakName(name, "<br />", padString.replace(" ", "&nbsp;"), threshold)
}

export const chartAriaDescription = (
    t: (...m: any) => string, 
    title: string, 
    subtitle: string, 
    decal = false,
    msgKey = "chart_description_template"
  ) => ({
  aria: { 
    label: {enabled: true},
    decal: {show: decal},
    description: t(msgKey, {title,subtitle}),
 }})