import {ModuleSettings} from "../../models/modules";

export interface FlowsTransferModuleSettings extends ModuleSettings {
  defaultOrgGroupType: string;
  defaultMediaGroupType: string;
  defaultOrganisations: string[];
  defaultMedia: string[];
  defaultOrgGroups: string[];
  defaultMediaGroups: string[];
  categoryColorIndex: number;
  maxNumberOfFlows: number;
  maxNumberOfFlowsMobile: number;
  percentThreshold: number;
  minAmountOfFlows: number;
}

export const defaultFlowsTransferModuleSettings: FlowsTransferModuleSettings = {
  enabled: false,
  defaultOrgGroupType: "membership",
  defaultMediaGroupType: "membership",
  defaultOrganisations: ["Bundeskanzleramt"],
  defaultMedia: [],
  defaultOrgGroups: [],
  defaultMediaGroups: [],
  categoryColorIndex: 14,
  maxNumberOfFlows: 800,
  maxNumberOfFlowsMobile: 300,
  percentThreshold: 0.01,
  minAmountOfFlows: 30,
}
