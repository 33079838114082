import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./home.css";

// import Swiper core and required modules

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import i18n from "../i18n";
import { TInfoState } from "../App";
import { AppState } from "..";
import { useSelector } from "react-redux";
import { blogToCard } from "./blog/view-blogs";
import React from "react";

import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import {
  Table as TableMui,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Button,
  Box,
} from "@mui/material";
import Config from "../config/settings";
import { Highlight } from "../components/highlight";


type Props = {
  skin: string;
};

export const Home: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { blogs, lastChange, lastImport } = useSelector<AppState, TInfoState>((state) => state.info);
  const currentLang: string = i18n.language;

  const cardData = [
    {
      link: "/overview",
      imageSrc: "/img/Overview.png",
      altText: t("overview_img_alt_text"),
      title: t("Overview"),
      text: t("Overview_Text"),
      actionText: t("Go to the page!"),
    },
    {
      link: "/top",
      imageSrc:"/img/Top.png",
      altText: t("top_img_alt_text"),
      title: t("Top Values"),
      text: t("Top_Text"),
      actionText: t("Go to the page!"),
    },
    {
      link: "/flows",
      imageSrc: "/img/Flow.png",
      altText: t("flow_img_alt_text"),
      title: t("Money Flows"),
      text: t("Flow_Text"),
      actionText: t("Go to the page!"),
    },
    {
      link: "/search",
      imageSrc: "/img/Search.png",
      altText: t("search_img_alt_text"),
      title: t("Search"),
      text: t("Search_Text"),
      actionText: t("Go to the page!"),
    },
  ];

  const rtrCardData = [
    {
      link: "/top",
      imageSrc: "/img/rtr/top.png",
      altText: t("top_img_alt_text"),
      title: t("Top Values"),
      text: t("Top_Text"),
    },
    {
      link: "/search",
      imageSrc: "/img/rtr/Search.png",
      altText: t("search_img_alt_text"),
      title: t("Search"),
      text: t("Search_Text"),
    },
  ];

  const mtTableData = [
    t("Werbeaufträge und Medienkooperationen"),
    t("Förderungen*"),
    t("ORF Gebühren"),
  ];

  const pfTableData = [
    { type: t("pref"), objective: t("pref_o") },
    { type: t("pubf"), objective: t("pubf_o") },
    { type: t("prif"), objective: t("prif_o") },
    { type: t("nikorf"), objective: t("nikorf_o") },
    { type: t("ferf"), objective: t("ferf_o") },
  ];

  const PageContent = () => {
    const teaserHeight = 380;
    const mdSize = 12;
    const lgSize = 6;
    const xlSize = 6;
    return (
      <>
        {blogs.length > 0 && blogToCard(currentLang, t)(blogs[0])}

        <Card className="card-text">
          <CardHeader title={t("Background")} />
          <CardContent>
            <p>{t("Welcome_Text")}</p>
            <p>{t("Welcome_Text_2")}</p>
          </CardContent>
        </Card>
        <Grid container spacing={3}>
          {cardData.map((card, index) => (
            <Grid key={index} item xs={12} sm={mdSize} md={mdSize} lg={lgSize} xl={xlSize}>
              <Card
                className="start-site-info-card card-teaser"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box>

                  <CardHeader title={card.title} />
                  <Link to={card.link} aria-label={`Link to ${card.title}`}>
                    <CardMedia
                      component="img"
                      height={teaserHeight}
                      src={card.imageSrc}
                      alt={card.altText || card.title}
                    />
                  </Link>
                  <CardContent sx={{ paddingBottom: "0 !important" }}>
                    {card.text}
                  </CardContent>
                </Box>
                <CardActions sx={{ marginTop: "0 !important" }}>
                  {/* <Link to={card.link}>{card.actionText}</Link> */}
                  <Button
                    variant="contained"
                    color="primary"
                    href={card.link}
                    aria-label={`Button to ${card.title}`}
                  >
                    {card.actionText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={3} sx={{ marginTop: ".25em" }}>
          <Grid item xs={12}>
            <Card className="card-text" sx={{ overflow: "visible" }}>
              <CardContent>
                {t("Description_Text")}{" "}
                <a
                  href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=20007610 "
                  target="_blank"
                  rel="noreferrer"
                  aria-label={t("Link to the Austrian Media Act")}
                >
                  [1]
                </a>
                <Grid
                  container
                  spacing={3}
                  className="start-site-info-card-row"
                  sx={{ marginTop: 0.5 }}
                >
                  <Grid item sm={mdSize}>
                    <Card className="start-site-info-card">
                      <CardHeader title={t("Media Transparency")} />
                      <CardContent>
                        {t("MT_Text")}
                        <TableMui
                          aria-label={t("Content of Media Transparency")}
                          sx={{ marginTop: 4 }}
                        >
                          <caption>
                            {t("Content of Media Transparency")}
                          </caption>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: "700" }}>
                                {t("MT_table")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {mtTableData.map((item, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  bgcolor:
                                    index % 2 === 0
                                      ? Config.home.tableRowComplementaryColor
                                      : "transparent",
                                }}
                              >
                                <TableCell>{item}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </TableMui>
                        <br />
                        {t("MT_Text_2")}
                        <br /> <br />*{t("MT_Text_3")}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={mdSize}>
                    <Card className="start-site-info-card">
                      <CardHeader title={t("Fundings")} />
                      <CardContent>
                        {t("PF_Text")}
                        <TableMui sx={{ marginTop: 4 }}>
                          <caption>{t("Content of Fundings")}</caption>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: "700" }}>
                                {t("PF_Type")}
                              </TableCell>
                              <TableCell sx={{ fontWeight: "700" }}>
                                {t("Objective")}{" "}
                                <a
                                  href="https://www.rtr.at/rtr/service/opendata/OD_Uebersicht.de.html"
                                  target="_blank"
                                  rel="noreferrer"
                                  aria-label={t(
                                    "Link to available open data sets provided by RTR"
                                  )}
                                >
                                  [2]
                                </a>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pfTableData.map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  bgcolor:
                                    index % 2 === 0
                                      ? Config.home.tableRowComplementaryColor
                                      : "inherit",
                                }}
                              >
                                <TableCell>{row.type}</TableCell>
                                <TableCell>{row.objective}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </TableMui>
                        <br />
                        {t("PF_Text_2")}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  };

  const RtrPageContent = () => {
    const teaserHeight = 350;
    return (
      <>
        <Card className="card-text" sx={{ mt: 2 }}>
          <CardHeader title={t("rtr_home_title")} className="dark-font" />
          <CardContent>
            <Box sx={{ mt: 3 }}>
              <span
                dangerouslySetInnerHTML={{ __html: t("rtr_home_description") }}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <b>{t("Hint")}: </b>
              <span dangerouslySetInnerHTML={{ __html: t("rtr_home_hint") }} />
            </Box>
          </CardContent>
        </Card>
        <Grid container justifyContent="flex-end" sx={{fontSize: '80%'}}>
          { ((lastChange && lastImport && lastChange > lastImport) || (lastChange && lastImport === undefined)) &&
             <Link to="/changes" aria-label={t('Link to data changes overview')}>{t("last_change", {
              date: new Date(lastChange).toLocaleDateString(),
              time: new Date(lastChange).toLocaleTimeString()
            })}</Link> }
          { ((lastImport && lastChange && lastImport > lastChange) || (lastImport && lastChange === undefined)) &&
                       <Link to="/changes" >{t("last_import", {
                        date: new Date(lastImport).toLocaleDateString(),
                        time: new Date(lastImport).toLocaleTimeString()
                      })}</Link>
          }
          { (!lastImport && !lastChange) &&
            <p>{t('no_change_since_latest_import')}</p>
          }
        </Grid>
        <Grid container spacing={3} sx={{ padding: 2 }}>
          {rtrCardData.map((card, index) => (
            <Grid key={index} item xs={12} sm={6} lg={6} xl={6}>
              <Link
                to={card.link}
                aria-label={`Link to ${card.title}`}
                className="no-text-decoration"
              >
                <Card
                  className="start-site-info-card card-teaser card-framed transition"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <CardMedia
                      component="img"
                      height={teaserHeight}
                      src={card.imageSrc}
                      alt={card.altText || card.title}
                    />
                    <Box sx={{ p: 2 }}>
                      <CardHeader title={card.title} />

                      <CardContent sx={{ paddingBottom: "0 !important" }}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: card.text,
                          }}
                        />
                      </CardContent>
                    </Box>
                  </Box>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
        <Highlight />
      </>
    );
  };

  return <>{props.skin === "fhj" ? <PageContent /> : <RtrPageContent />}</>;
};
