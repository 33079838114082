const palettes = require("nice-color-palettes");
const interpolate = require("color-interpolate");
const pallete = require("color-interpolate")(palettes[81]);

const palletePF = require("color-interpolate")(palettes[20]);

export const getColor = (idx: number, count: number, offset: number = 0.25) =>
  pallete(((idx + 1) / count) * (1 - 2 * offset) + offset);


export const getColorFromPalette = (idx: number, count: number, palette: any[], offset: number = 0.00) => {
  const interpolator = interpolate(palette)
  const maxIndex = count > 1 ? count - 1 : 1
  console.log(`Idx: ${idx} (${(idx) / maxIndex}%), Count: ${count}, length of colors: ${palette.length}`)
  return interpolator(((idx) /maxIndex) * (1 - 2 * offset) + offset)
}



export const getPFColor = (idx: number, count: number, offset: number = 0.25) =>
  palletePF(((idx + 1) / count) * (1 - 2 * offset) + offset);


