import {AdsUniverseModuleSettings} from "../pages/universe.settings";
import {AboutUsModuleSettings} from "../pages/about_us.settings";
import {BlogModuleSettings} from "../pages/blog/blog.settings";
import {FlowsFundingsModuleSettings} from "../pages/flows/flows-fundings.settings";
import {FlowsMixedModuleSettings} from "../pages/flows/flows-mixed.settings";
import {FlowsTransferModuleSettings} from "../pages/flows/flows-transfer.settings";
import {SujetsModuleSettings} from "../pages/ads/ads.settings";
import {TopFundingsModuleSettings} from "../pages/top/top-fundings.settings";
import {TopTransferModuleSettings} from "../pages/top/top-transfer.settings";
import { ColorsModuleSettings } from "../pages/colors.settings";

export enum Modules {
  Colors = 'colors',
  AboutUs = 'aboutUs',
  AdsUniverse = 'adsUniverse',
  Blog = 'blog',
  Flows_Fundings = 'flows_fundings',
  Flows_Mixed = 'flows_mixed',
  Flows_Transfer = 'flows_transfer',
  Sujets = 'sujets',
  Top_Fundings = 'top_fundings',
  Top_Transfer = 'top_transfer',
}

export interface ModuleSettings {
  enabled: boolean;
}

export interface PayersRecipientsModuleSettings extends ModuleSettings {
  defaultOrgGroupType: string;
  defaultMediaGroupType: string;
  defaultOrganisations: string[];
  defaultMedia: string[];
  defaultOrgGroups: string[];
  defaultMediaGroups: string[];
}

export const PayersRecipientsModuleDefaults: PayersRecipientsModuleSettings = {
  enabled: false,
  defaultOrgGroupType: "membership",
  defaultMediaGroupType: "membership",
  defaultOrganisations: ["Bundeskanzleramt"],
  defaultMedia: [],
  defaultOrgGroups: [],
  defaultMediaGroups: [],
}

export interface ModulesSettings extends Record<Modules, ModuleSettings> {
  colors: ColorsModuleSettings;
  aboutUs: AboutUsModuleSettings;
  adsUniverse: AdsUniverseModuleSettings;
  blog: BlogModuleSettings;
  flows_fundings: FlowsFundingsModuleSettings;
  flows_mixed: FlowsMixedModuleSettings;
  flows_transfer: FlowsTransferModuleSettings;
  sujets: SujetsModuleSettings;
  top_fundings: TopFundingsModuleSettings;
  top_transfer: TopTransferModuleSettings;
}
