import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { IfNoError } from "../components/helper-components";
import { BuildForm } from "../components/form-builder/mui-element-factory";
import { faArrowCircleLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { FormDescription } from "../components/form-builder/field-types";
import { useDispatch } from "react-redux";
import { setNeedsUpdate } from "./list-users";
import { getTransfer, updateTransfer } from "../services/data-service";
import { IStoredTransfer } from "../../../server/models/transfer";

export const EditTransfer: React.FC = () => {
  const { id } = useParams();
  const [transfer, setTransfer] =
    useState<IStoredTransfer | undefined>(undefined);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const editTransferFormDescription = useMemo<FormDescription<IStoredTransfer>>(
    () => ({
      fields: [
        {
          name: "organisation",
          type: "text",
          label: t("Organisation"),
          validators: { required: "required" },
        },
        // {
        //   name: "organisationType",
        //   type: "select",
        //   label: t("Type"),
        //   help: t("Select the type of this organisation"),
        //   options: orgTypes,
        //   disabled: false,
        //   labels: orgTypes.map(t),
        // },
        {
          name: "media",
          type: "text",
          label: t("Media"),
          validators: { required: "required" },
        },
        {
          name: "transferType",
          type: "text",
          label: t("Transfer Type"),
          validators: { required: "required" },
        } ,
        {
          name: "federalState",
          type: "select",
          label: t("Federal State"),
          options: [
            "EU",
            "AT",
            "AT-1",
            "AT-2",
            "AT-3",
            "AT-4",
            "AT-5",
            "AT-6",
            "AT-7",
            "AT-8",
            "AT-9",
          ],
          labels: [
            t("EU"),
            t("AT"),
            t("AT-1"),
            t("AT-2"),
            t("AT-3"),
            t("AT-4"),
            t("AT-5"),
            t("AT-6"),
            t("AT-7"),
            t("AT-8"),
            t("AT-9"),
          ],
          disabled: false,
        },
        {
          name: "period",
          type: "text",
          label: t("Period"),
          validators: { required: "required" },
        },
        {
          name: "year",
          type: "text",
          label: t("Year"),
          validators: { required: "required" },
        },
        {
          name: "quarter",
          type: "text",
          label: t("Quarter"),
          validators: { required: "required" },
        },
        {
          name: "amount",
          type: "number",
          label: t("Amount"),
          validators: { required: "required" },
        },
      ],
      name: t("Edit Transfer"),
      submitHandler: (transfer_) => {
        updateTransfer(transfer_).then(() => dispatch(setNeedsUpdate()));
      },
      submitAction: {
        label: t("Update Transfer"),
        icon: faSave,
        nextRoute: "/transfer",
      },
      cancelAction: {
        label: t("Cancel"),
        icon: faArrowCircleLeft,
        nextRoute: "back",
      },
      hiddenFields: ["_id"],
    }),
    [dispatch, t]
  );
  useEffect(() => {
    getTransfer(id || "")
      .then(setTransfer)
      .catch((err) => (err.message ? setError(err.message) : setError(err)));
  }, [id]);
  return (
    <IfNoError error={t(error)}>
      <div className="flex-row-center">
        <div className="col-lg-4 col-md-6 col-sm-9 col-xs-12">
          {transfer && (
            <BuildForm
              {...editTransferFormDescription}
              initialState={transfer}
            ></BuildForm>
          )}
        </div>
      </div>
      <pre>{/*JSON.stringify(group,null,2)*/}</pre>
    </IfNoError>
  );
};
