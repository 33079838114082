import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable, {TableColumn} from 'react-data-table-component-with-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../index';
import { IfNoError } from '../components/helper-components';
import { IZipCodeDocument } from '../../../server/models/zipcodes';
import { getZipCodes, countEntities } from '../services/data-service';
import { Box, Button, Card, CardContent, CardHeader } from '@mui/material';
import { getTranslatedPaginationOptions } from '../helpers/helpers';

interface TZipCodeState  {
  zipCodes: IZipCodeDocument[];
  needsUpdate: boolean;
  page: number;
  size: number;
  count: number;
  pending: boolean;
}

export const zipCodeSlice = createSlice({
  name: 'zipCodes',
  initialState: {
    zipCodes: [],
    needsUpdate: true,
    pending: false,
    page: 1,
    size: 10,
    count: 0
  } as TZipCodeState,
  reducers: {
    setZipCodeList: (state, action: PayloadAction<IZipCodeDocument[]>) => (
      {...state, zipCodes: action.payload, needsUpdate: false}
    ),
    setNeedsUpdate: (state, action: PayloadAction<void>) => ({...state, needsUpdate: true}),
    setPage: (state, action: PayloadAction<number>) => ({...state, page: action.payload, needsUpdate: true}),
    setSize: (state, action: PayloadAction<number>) => ({...state, size: action.payload, needsUpdate: true}),
    setCount: (state, action: PayloadAction<number>) => ({...state, count: action.payload}),
    setPending: (state, action: PayloadAction<boolean>) => ({...state, pending: action.payload})
  }
})

export const {setZipCodeList, setNeedsUpdate,
  setPage, setSize, setCount,
  setPending} = zipCodeSlice.actions;


export const ListZipCodes = () => {
    const {zipCodes, needsUpdate, page, size, pending, count } = useSelector<AppState,TZipCodeState>(state => state.zipCodes)
    const [error,setError] = useState<string>("");
    const [sortBy, setSortBy] = useState<string | null>(null)
    const [sortOrder, setSortOrder] = useState<'desc' | 'asc' >('asc')
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const columns = useMemo<TableColumn<IZipCodeDocument>[]>(()=>[
      {
        name: t('Zip Code'),
        selector: row => row['zipCode'],
        sortable: true,
      },
      {
        name: t('Federal State'),
        selector: row => row['federalState'],
        sortable: true,
        right: false,
      },
      {
        name: t('Code'),
        selector: row => row['federalStateCode'],
        sortable: true,
      },
    ],[t]);
    useEffect(()=>{
      countEntities('/zipcodes/count').then(c=>dispatch(setCount(c)))
    },[dispatch])
    useEffect(()=>{
      if (zipCodes.length===0 || needsUpdate) {
        dispatch(setPending(true))
        getZipCodes(page, size, sortBy ?? '', sortOrder)
        .then(zips => {
          console.log("Got ZIPS " + zips.length)
          return dispatch(setZipCodeList(zips))
        }).catch(err => err.message ? setError(err.message):setError(err))
        .finally(()=>dispatch(setPending(false)))
      }},[needsUpdate, dispatch, page, size, sortBy, sortOrder, zipCodes.length])
    return <>
      <IfNoError error={error}>
        <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
          <Button href="/zip/upload" variant='contained' startIcon={<FontAwesomeIcon icon={faFileUpload} /> }>{t("Zip Upload")}</Button>
        </Box>
        <Card>
          <CardHeader title={t("Zip Codes")} />
          <CardContent className='no-padding'>
            <DataTable
              columns={columns}
              data={zipCodes}
              pagination={true}
              paginationServer={true}
              progressPending={pending}
              onChangePage={e => dispatch(setPage(e))}
              onChangeRowsPerPage={e => dispatch(setSize(e))}
              paginationTotalRows={count}
              paginationComponentOptions={getTranslatedPaginationOptions(t)}
              onSort = {(column, sortDirection) => {
                setSortOrder(sortDirection)
                setSortBy(column.selector?.toString() ?? null)
                dispatch(setNeedsUpdate())
              }}
            />
          </CardContent>
        </Card>
      </IfNoError>
    </>


}
