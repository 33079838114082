import { Dialog, DialogContent, DialogTitle, SvgIcon } from "@mui/material";
import { FC } from "react"
import Config from "../../config/settings";
import { Render } from "../helper-components";
import { Close } from "@mui/icons-material";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

export interface SujetEntryDialog {
  mimeType: string
  fileName: string
  sujet: string
}

export interface SujetDialogProps<T extends SujetEntryDialog = SujetEntryDialog> {
  open: boolean
  onClose?: () => void
  sujet: T | undefined
 }

export const SujetDialog: FC<SujetDialogProps> = ({ open, onClose, sujet }) => {
  //const imageUrl = `https://picsum.photos/seed/${generateHash(sujet?.fileName ?? "x")}/480`

  const isAudio = Config.ads.audioTypes.includes(sujet?.mimeType ?? "")
  const isVideo = Config.ads.videoTypes.includes(sujet?.mimeType ?? "")
  const isDocument = Config.ads.fileTypes.includes(sujet?.mimeType ?? "")
  // const isImage = Config.ads.imageTypes.includes(sujet?.mimeType ?? "")
  const isImage = !isAudio && !isDocument && !isVideo

  const url = sujet?.sujet

  // if (isDocument && sujet) {
  //   return (
  //     <PDFDialog open={open} onClose={onClose} sujet={sujet} />
  //   )
  // }

  return (
    <Render when={sujet !== undefined}>
      <Dialog open={open} onClose={() => onClose?.()} scroll="paper" maxWidth={isAudio ? "md" : "lg"} fullWidth>

        <DialogTitle style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
            <div className="dialog-ad-header">
              {sujet?.fileName}
            </div>
            <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
              {/* <div ref={titleRef}></div> */}

              <SvgIcon className="dialog-ad-close-icon" component={Close} onClick={() => onClose?.()} inheritViewBox />
            </div>
          {/* </div> */}
        </DialogTitle>

        <DialogContent>
          <div style={{ padding: '0 0', maxHeight: '800', maxWidth: '600', }}>

            <Render when={isAudio}>
              <iframe
                title={sujet?.fileName}
                src={url?.replace("https://mtp.rtr.gv.at/media", "/api/sujet/media")}
                //src={url}
                width="100%"
                height="80"
                id="rtr-audio"
                style={{
                  borderWidth: 0,
                }}
              />
            </Render>

            <Render when={isVideo || isImage}>
              <iframe
                title={sujet?.fileName}
                src={url?.replace("https://mtp.rtr.gv.at/media", "/api/sujet/media")}
                //src={url}
                width="100%"
                height="600"
                id="rtr-video-image"
                style={{
                  borderWidth: 0,
                  aspectRatio: "16 / 9",
                }}
              />
            </Render>

            <Render when={isDocument}>
              <iframe
                title={sujet?.fileName}
                src={url?.replace("https://mtp.rtr.gv.at/media", "/api/sujet/media")}
                //src={url}
                width="100%"
                height="1020"
                allow="fullscreen"
                id="rtr-document"
                style={{
                  borderWidth: 0,
                  aspectRatio: "9 / 16",
                }}
              />
            </Render>

            {/* <Render when={isImage}>
              <img
                src={sujet?.sujet} alt={sujet?.fileName}
                style={{ height: '100%', width: '100%', pointerEvents: "none" }}
                unselectable="on"
                draggable="false"
              />
            </Render> */}

            {/* <Render when={isVideo}>
              <video controls autoPlay={true} width="100%" height="auto" loop={false} muted={false} draggable="false" >
                <source src={sujet?.sujet} type={sujet?.mimeType ?? ""} />
              </video>
            </Render> */}

            {/* <Render when={isAudio}>
              <audio controls src={sujet?.sujet} draggable="false" />
            </Render> */}

            {/* <Render when={isDocument}>
              <PDFViewer
                file={getProxyURL(sujet?.sujet || "")}
                scaleState={[scale, setScale]}
                rotationState={[rotation, setRotation]}
                toolsContainer={titleRef.current}
              />
            </Render> */}
          </div>
        </DialogContent>

      </Dialog>
    </Render>
  )
}
