const colorNames = new Map<string, string>()

export const parseColor = (rgba: string) => {
  const rgbaMatch = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)/g.exec(rgba)

  const red = parseInt(rgbaMatch?.[1] ?? "0");
  const green = parseInt(rgbaMatch?.[2] ?? "0");
  const blue = parseInt(rgbaMatch?.[3] ?? "0");

  const alpha = parseFloat(rgbaMatch?.[4] ?? "1");
  return [red, green, blue, alpha] as const
}

export const getContrastColor = (rgba: string) => {

  const [red, green, blue, alpha] = parseColor(rgba)

  // blend with white to account for transparency
  const r = Math.round((red * alpha) + (255 * (1 - alpha)))
  const g = Math.round((green * alpha) + (255 * (1 - alpha)))
  const b = Math.round((blue * alpha) + (255 * (1 - alpha)))

  // calculate perceptive luminance
  const luminance = Math.round(((r * 299) + (g * 587) + (b * 114)) / 1000);

  // console.warn("rgbaMatch", rgba, rgbaMatch, red, green, blue, alpha, r, g, b, luminance)

  // Return black/darkgray for bright colors, white for dark colors
  return [luminance > 160 ? "#333" : "#fff", luminance] as const
}

const getColorFromComputedStyle = (colorName: string, alpha: number) => {
  const colorNameCached = colorNames.get(colorName)
  if (colorNameCached) {
    return colorNameCached
  }

  const color = document.createElement("div");
  color.style.color = colorName;
  document.body.appendChild(color);
  const computedColor = getComputedStyle(color).color;
  document.body.removeChild(color);

  const rgbaColor = computedColor.replace("rgb", "rgba").replace(")", `, ${alpha})`)
  colorNames.set(colorName, rgbaColor)

  return rgbaColor
}

const convertHexToRgba = (hex: string, alpha: number) => {
  const red = parseInt(hex.slice(1, 3), 16);
  const green = parseInt(hex.slice(3, 5), 16);
  const blue = parseInt(hex.slice(5, 7), 16);
  return `rgba(${red},${green},${blue},${alpha})`;
}

export const colorToRGBA = (color: string, alpha: number) => {
  if (color.startsWith("#")) {
    return convertHexToRgba(color, alpha);
  }
  return getColorFromComputedStyle(color, alpha);
}

export const shadeColor = (rgba: string, amount: number) => {

  const [red, green, blue, alpha] = parseColor(rgba)

  const lightenFactor = amount >= 0 ? 1 : -1
  const adjust = 255 * Math.abs(amount) * lightenFactor

  // blend with white
  const r = Math.round((red * (1 - amount)) + adjust)
  const g = Math.round((green * (1 - amount)) + adjust)
  const b = Math.round((blue * (1 - amount)) + adjust)

  const r1 = Math.min(255, Math.max(0, r))
  const g1 = Math.min(255, Math.max(0, g))
  const b1 = Math.min(255, Math.max(0, b))

  return `rgba(${r1},${g1},${b1},${alpha})`
}
