import React, { useMemo, useState } from 'react';
import { IStoredUser } from '../../../server/models/types';
import {useEffect} from 'react';
import { getUsers } from '../services/user-service';
import { useTranslation } from 'react-i18next';
import DataTable, {TableColumn} from 'react-data-table-component-with-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../index';
import { IfNoError } from '../components/helper-components';
import { Card, CardContent, CardHeader, Button } from '@mui/material';
import { getTranslatedPaginationOptions } from '../helpers/helpers';


interface TUsersState  {
  users: IStoredUser[];
  needsUpdate: boolean;
}

export const userSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    needsUpdate: true
  } as TUsersState,
  reducers: {
    setUserList: (state, action: PayloadAction<IStoredUser[]>) => (
      {...state, users: action.payload, needsUpdate: false}
    ),
    setNeedsUpdate: (state, action: PayloadAction<void>) => ({...state, needsUpdate: true}),
  }
})

export const {setUserList, setNeedsUpdate} = userSlice.actions;

export const ShowUsers = () => {
    const {users, needsUpdate } = useSelector<AppState,TUsersState>(state => state.users)
    const [error,setError] = useState<string>("");
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const columns = useMemo<TableColumn<IStoredUser>[]>(()=>[
        {
          name: t('Username'),
          selector: row => row['username'],
          sortable: true,
        },
        {
          name: t('Email'),
          selector: row => row['email'],
          sortable: true,
          right: false,
        },
        {
          name: t('Active'),
          selector: row => row['active'],
          sortable: true,
          cell: row => row.active ? <FontAwesomeIcon icon={faCheck}/>:<FontAwesomeIcon icon={faTimes} color="red"/>
        },
        {
          name: t('Roles'),
          selector: row => row['roles']?.join(", "),
          cell: row => <span>{row.roles?.join(", ") }</span> ,
        },
        {
          name: t('Actions'),
            cell: row => <Button href={`/users/${row._id}`} color='primary'
            variant='contained' aria-label={t('Edit')} 
            size='small'><FontAwesomeIcon icon={faUserEdit}/></Button>
        },
      ],[t]);
    useEffect(()=>{
      if (users.length === 0 || needsUpdate) {
        getUsers()
        .then(users_ =>
          dispatch(setUserList(users_))
        ).catch(err => err.message ? setError(err.message):setError(err))
      }},[needsUpdate, users.length, dispatch])
    return <div>
     <IfNoError error={error}>
        <Card>
          <CardHeader title={t("Available Users")}/>
          <CardContent className='no-padding'>
            <DataTable
              columns={columns}
              data={users}
              paginationComponentOptions={getTranslatedPaginationOptions(t)}
            />
          </CardContent>
        </Card>
      </IfNoError>
    </div>


}
