import MobileApp from "./MobileApp";
import {infoSlice, mergePeriods, TInfoState} from "./App";
import {forwardRef, useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import {
  getPeriods,
  getGroupList,
  getPeriodsFunding,
  getGroupTypes,
  getActiveBlogData,
  getAppVersion,
  getLatestChangeTimestamp,
} from "./services/data-service";

import {AppState} from ".";
import {ModalLoader, Render, RenderModule,} from "./components/helper-components";
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import {CssVarsProvider as JoyCssVarsProvider} from "@mui/joy/styles";
import {useMediaQuery} from "react-responsive";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  Route,
  RouterProvider,
} from "react-router-dom";
import {Login} from "./components/login_form";
import {Register} from "./components/register_form";
import {AboutUs} from "./pages/about_us";
import {BlogPage} from "./pages/blog/blog";
import {News} from "./pages/blog/view-blogs";
import {FileUpload} from "./pages/file-upload";
import {Flows} from "./pages/flows/flows";
import {FlowsFunding} from "./pages/flows/flows-funding";
import {FlowsMixed} from "./pages/flows/flows-mixed";
import {FlowsTransfers} from "./pages/flows/flows-transfers";
import {EditGroup} from "./pages/group-edit";
import {NewGroup} from "./pages/group-new";
import {Home} from "./pages/home";
import {Impress} from "./pages/impress";
import {ShowAllGroups} from "./pages/list-groups";
import {ListOrganisations} from "./pages/list-organisations";
import {ListTransfers} from "./pages/list-transfers";
import {ShowUsers} from "./pages/list-users";
import {ListZipCodes} from "./pages/list-zipcodes";
import {EditOrganisation} from "./pages/organisation-edit";
import {NewOrganisation} from "./pages/organisation-new";
import {Overview} from "./pages/overview";
import {Top} from "./pages/top/top";
import {TopFundings} from "./pages/top/top-fundings";
import {TopTransfers} from "./pages/top/top-transfers";
import {EditTransfer} from "./pages/transfer-edit";
import {EditUser} from "./pages/user-edit";
import {Search} from "./pages/search";
import Universe from "./pages/universe";
import {AdminGroupTypes} from "./pages/admin-group-types";
import Config from "./config/settings";
import {EditSettings} from "./pages/settings-edit";
import {EditLanguages} from "./pages/languages-edit";
import "./config/skin.css";
import {LinkProps} from "@mui/material/Link";
import {Modules} from "./models/modules";
import {NoPermission, NotFound} from "./helpers/errorCards";
import {AuthContext} from "./context/auth-context";
import { Changes } from "./pages/changes";
import { MediaList } from "./pages/list-media";
import { IconButton, Snackbar } from "@mui/material";
import { Ads } from "./pages/ads/ads";

const {
  setPeriods,
  setPending,
  setGroups,
  setPeriodsFunding,
  setMixedPeriods,
  setInitialized,
  setShowAbsoluteVisualisation,
  setGroupTypes,
  setBlogs,
  setAppVersion,
  setLastChange,
  setLastImport,
  setFundingPeriods,
  setMessage,
} = infoSlice.actions;

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const {href, ...other} = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const cssVar = (name: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(name).trim();

const materialTheme = materialExtendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: cssVar("--primary-color"),
          contrastText: cssVar("--primary-contrast-color"),
        },
        success: {
          main: cssVar("--success-color"),
          contrastText: cssVar("--primary-contrast-color"),
        },
      },
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});

const ProtectedRoute = ({children}) => {
  const {user, hasRole} = useContext(AuthContext);
  if (user && hasRole("admin")) return children;
  return <NoPermission/>
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<MobileApp/>}>
      <Route
        path="/universe"
        element={<RenderModule module={Modules.AdsUniverse}> <Universe/> </RenderModule>}
      />
      <Route path="/home" element={<Home skin={Config.skin}/>}/>
      <Route path="/top" element={<RenderModule modules={[Modules.Top_Transfer, Modules.Top_Fundings]}> <Top/> </RenderModule>}>
        <Route index element={<RenderModule module={Modules.Top_Transfer}> <TopTransfers/> </RenderModule>}/>
        <Route path="transfers" element={<RenderModule module={Modules.Top_Transfer}> <TopTransfers/> </RenderModule>}/>
        <Route path="fundings" element={<RenderModule module={Modules.Top_Fundings}> <TopFundings/> </RenderModule>}/>
      </Route>
      <Route path="/home" element={<Home skin={Config.skin} />} />
      <Route path="/flows" element={
        <RenderModule modules={[Modules.Flows_Transfer, Modules.Flows_Fundings, Modules.Flows_Mixed]}>
          <Flows/>
        </RenderModule>}>
        {/*<Route index element={<RenderModule module={Modules.Flows_Transfer}> <FlowsTransfers/> </RenderModule>}/>*/}
        <Route
          path="transfers/:tab"
          element={<RenderModule module={Modules.Flows_Transfer}> <FlowsTransfers/> </RenderModule>}
        />
        <Route
          path="fundings/:tab"
          element={<RenderModule module={Modules.Top_Fundings}> <FlowsFunding/> </RenderModule>}
        />
        <Route
          path="mixed/:tab"
          element={<RenderModule module={Modules.Flows_Mixed}> <FlowsMixed/> </RenderModule>}/>
      </Route>
      <Route path="/overview" element={<Overview/>}/>
      <Route path="/login_" element={<Login/>}/>
      <Route path="/register_" element={<Register/>}/>
      <Route path="/search" element={<Search/>}/>
      <Route path="/ads/:tab" element={<RenderModule module={Modules.Sujets}> <Ads/> </RenderModule>}/>
      <Route path="/ads" element={<RenderModule module={Modules.Sujets}> <Ads/> </RenderModule>}/>
      <Route path="/impress" element={<Impress/>}/>
      <Route path="/about" element={<RenderModule module={Modules.AboutUs}> <AboutUs/> </RenderModule>}/>
      <Route path="/changes" element={<Changes/>}/>
      <Route
        path="/news"
        element={<RenderModule module={Modules.Blog}> <News/> </RenderModule>}
      />

      {/* Admin routes */}
      <Route path="/users/:id" element={<ProtectedRoute><EditUser/></ProtectedRoute>}/>
      <Route path="/users" element={<ProtectedRoute><ShowUsers/></ProtectedRoute>}/>
      <Route path="/groups" element={<ProtectedRoute><ShowAllGroups/></ProtectedRoute>}/>
      <Route path="/edit_groups/:id" element={<ProtectedRoute><EditGroup/></ProtectedRoute>}/>
      <Route path="/group-types" element={<ProtectedRoute><AdminGroupTypes/></ProtectedRoute>}/>
      <Route path="/newgroup/media" element={<ProtectedRoute><NewGroup default="media"/></ProtectedRoute>}/>
      <Route path="/newgroup/org" element={<ProtectedRoute><NewGroup default="org"/></ProtectedRoute>}/>

      <Route
        path="/zip/upload"
        element={<ProtectedRoute><FileUpload url="/zipcodes/import" name="Zip Code Upload"/></ProtectedRoute>}
      />
      <Route path="/zip/list" element={<ProtectedRoute><ListZipCodes/></ProtectedRoute>}/>
      <Route path="/organisation/list" element={<ProtectedRoute><ListOrganisations/></ProtectedRoute>}/>
      <Route
        path="/organisation/upload"
        element={
          <ProtectedRoute><FileUpload url="/organisations/import" name="Organisations Upload"/></ProtectedRoute>
        }
      />
      <Route path="/organisation/:id" element={<ProtectedRoute><EditOrganisation/></ProtectedRoute>}/>
      <Route path="/organisation" element={<ProtectedRoute><NewOrganisation/></ProtectedRoute>}/>
      <Route
        path="/transfer/upload"
        element={<ProtectedRoute><FileUpload url="/transfers/import" name="Transfers Upload"/></ProtectedRoute>}
      />
      <Route
        path="/fundings/upload"
        element={<ProtectedRoute><FileUpload url="/fundings/import" name="Fundings Upload"/></ProtectedRoute>}
      />
      <Route path="/transfer/:id" element={<ProtectedRoute><EditTransfer/></ProtectedRoute>}/>
      <Route path="/transfer" element={<ProtectedRoute><ListTransfers/></ProtectedRoute>}/>
      <Route
        path="/blog"
        element={<ProtectedRoute><RenderModule module={Modules.Blog}> <BlogPage/> </RenderModule></ProtectedRoute>}
      />
      <Route path="/settings" element={<ProtectedRoute><EditSettings/></ProtectedRoute>}/>
      <Route path="/languages" element={<ProtectedRoute><EditLanguages/></ProtectedRoute>}/>
      <Route path="/media" element={<ProtectedRoute><MediaList/></ProtectedRoute>}/>

      {/* Define a catch-all route for not found errors */}
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

const ResponsiveApp = () => {
  const isMobileLandscape = useMediaQuery({
    maxHeight: 575.98,
    orientation: "landscape",
  });
  const isMobilePortrait = useMediaQuery({maxWidth: 600});
  const isMobile = isMobileLandscape || isMobilePortrait;
  const dispatch = useDispatch();
  const {initialized, useHalfyears, message} = useSelector<AppState, TInfoState>(
    (state) => state.info
  );

  useEffect(() => {
    dispatch(setShowAbsoluteVisualisation(!isMobile));
    Promise.all([
      getPeriods(),
      getGroupList(),
      getPeriodsFunding(),
      getGroupTypes(),
      getActiveBlogData(),
      getAppVersion(),
      getLatestChangeTimestamp(),
    ])
      .then(([periods, groups, periodsFunding, groupTypes, blogs, appVersion, lastChanges]) => {
        dispatch(setPeriods(periods));
        dispatch(setFundingPeriods(periodsFunding));
        dispatch(setMixedPeriods(mergePeriods(
          useHalfyears ? periods.halfyears : periods.quarters,
          periodsFunding)));
        dispatch(setInitialized());
        dispatch(setGroups(groups));
        dispatch(setGroupTypes(groupTypes));
        dispatch(setPeriodsFunding(periodsFunding));
        dispatch(setBlogs(blogs));
        dispatch(setAppVersion(appVersion))
        if (lastChanges.lastChange) {
          dispatch(setLastChange(lastChanges.lastChange));
        }
        if (lastChanges.lastImport) {
          dispatch(setLastImport(lastChanges.lastImport));
        }
      })

      .finally(() => dispatch(setPending(false)));
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className={`App ${Config.skin}`}>
        <Render when={initialized}>
          <MaterialCssVarsProvider
            theme={{[MATERIAL_THEME_ID]: materialTheme}}
          >
            <JoyCssVarsProvider>
              {/* {isMobile ? <MobileApp /> : <App />} */}
                <RouterProvider router={router}/>
            </JoyCssVarsProvider>
          </MaterialCssVarsProvider>
        </Render>
        <Render when={!initialized}>
          <ModalLoader isPending={true}/>
        </Render>
        <Snackbar
        open={message !== ""}
        autoHideDuration={6000}
        onClose={()=>dispatch(setMessage(""))}
        message={message}
        action={
          <IconButton color="inherit" size="small"
            onClick={()=>dispatch(setMessage(""))}
            >
            <CloseIcon/>
          </IconButton>
        }
      />
      </div>
    </>
  );
};

export default ResponsiveApp;
