import { FormControlLabel, Switch, Theme, styled } from "@mui/material";
import "./switchButton.scss";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Help } from "../helper-components";
import Config from "../../config/settings";

export type SwitchButtonProps = {
  simple?: boolean;
  selValue: boolean;
  offlabel?: React.ReactNode;
  onlabel?: React.ReactNode;
  label?: React.ReactNode;
  disabled?: boolean;
  helpText?: string;
  offIconD?: string;
  onIconD?: string;
  id?: string;
  labelPlacement?: "start" | "end";
  helperTooltip?: string;
  onChange: (v: boolean) => void;
};
type MaterialUISwitchProps = {
  theme?: Theme;
  onIconD: string;
  offIconD: string;
};

export const MaterialUISwitch = styled(Switch, {
  shouldForwardProp: (prop) =>
    !["onIconD", "offIconD"].includes(prop.toString()),
})<MaterialUISwitchProps>(({ theme, onIconD, offIconD }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="${onIconD}"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "var(--secondary-color)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "var(--primary-color)",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="${offIconD}" /></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "var(--secondary-color)",
    borderRadius: 20 / 2,
  },
}));

const RenderHelp = ({ helpText }: { helpText: string | undefined }) => {
  return helpText ? <Help text={helpText} /> : <></>;
};

export const SwitchButton = ({
  selValue,
  onlabel,
  offlabel,
  label,
  labelPlacement = "start",
  offIconD,
  helperTooltip,
  onIconD,
  id,
  onChange,
  disabled,
  simple = false,
}: SwitchButtonProps) => {
  const [value, setValue] = useState(selValue);
  const onChanged = (e: any, v: any) => {
    setValue(!value);
    onChange(!value);
  };
  return (
    <div>
      {/* <SvgIcon component={NumbersIcon} inheritViewBox /> */}
      <FormControlLabel
        disabled={disabled}
        htmlFor={id}
        sx={{
          marginLeft: 0,
          "& .MuiFormControlLabel-label": {
            fontSize: "0.9rem",
            color: "var(--primary-text-color)",
          },
        }}
        control={
          <div>
            {simple ? (
              <Switch checked={value} onChange={(e, v) => onChanged(e, v)} id={id} disabled={disabled} />
            ) : (
              <MaterialUISwitch
                id={id}
                sx={{ m: 1 }}
                checked={value}
                disabled={disabled}
                offIconD={offIconD ?? Config.switchButton.icons.minusIconD}
                onIconD={onIconD ?? Config.switchButton.icons.checkIconD}
                onChange={(e, v) => onChanged(e, v)}
              />
            )}
            <RenderHelp helpText={helperTooltip} />
          </div>
        }
        label={label ? label : value ? onlabel : offlabel}
        labelPlacement={labelPlacement}
      />
    </div>
  );
};

export type SwitchButtonProps2 = {
  simple?: boolean;
  checked: boolean;
  offlabel?: string;
  onlabel?: string;
  label?: React.ReactNode;
  offIconD?: string;
  onIconD?: string;
  labelPlacement?: "start" | "end";
  onChange: (v: boolean) => void;
};

export const SwitchButton2 = ({
  checked,
  onlabel,
  offlabel,
  label,

  onChange,
  simple = false,
}: SwitchButtonProps2) => {
  const [isChecked, setIsChecked] = useState(checked);
  return (
    <Stack direction="column" spacing={0.2} alignItems="left">
      <Typography
        sx={{
          color: "text.secondary",
          fontWeight: 400,
          fontSize: "0.8rem",
          lineHeight: "1.4375em",
        }}
      >
        {label}
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography
          sx={() => ({
            color: !isChecked ? "text.primary" : "text.secondary",
          })}
        >
          {offlabel}
        </Typography>
        {simple ? (
          <label aria-label="switchButton">
            <Switch
              id="switchButton"
              checked={isChecked}
  
              onChange={(e, checked) => {
                setIsChecked(checked);
                onChange(checked);
              }}
            />
          </label>
        ) : (
          <MaterialUISwitch
            offIconD={Config.switchButton.icons.payerIconD}
            checked={isChecked}
            onChange={(e, checked) => {
              setIsChecked(checked);
              onChange(checked);
            }}
            onIconD={Config.switchButton.icons.receiptIconD}
          />
        )}

        <Typography
          sx={() => ({
            color: isChecked ? "text.primary" : "text.secondary",
          })}
        >
          {onlabel}
        </Typography>
      </Stack>
    </Stack>
  );
};
