import { FC, useMemo } from "react";
import { AdvertisingDocument } from "../../../../../server/models/advertisings";
import { Box, Stack } from "@mui/material";
import { TableColumn } from "react-data-table-component-with-filter";
import { useTranslation } from "react-i18next";
import { toCurrency, getTranslatedPaginationOptions } from "../../../helpers/helpers";
import { CustomDataTable } from "../../data-table/DataTable";

interface Props {
  data: AdvertisingDocument
}

export const AdvertisingsDetails: FC<Props> = ({ data }) => {
  const { t, i18n } = useTranslation();

  const detailsColumns = useMemo<TableColumn<AdvertisingDocument["ads"][0]>[]>(() => [
    {
      name: t('halfyear'),
      selector: row => row['halfyear'],
      sortable: true,
      filterable: false,
      width: '8%'
    },
    {
      name: t('media'),
      selector: row => row['media'],
      sortable: true,
      filterable: true,
      wrap: true,
    },
    {
      name: t('mediaOwner'),
      selector: row => row['mediaOwner'],
      sortable: true,
      filterable: true,
      wrap: true,
    },
    {
      name: t('category'),
      selector: row => row['category'],
      sortable: true,
      filterable: true,
      filterValues: ['Online', 'Print', 'Hörfunk', 'Fernsehen', 'Out of Home'],
      width: '12%'
    },
    {
      name: t('subCategory'),
      selector: row => row['subCategory'],
      sortable: true,
      filterable: true
    },
    {
      name: t('amount'),
      selector: row => row['amount'],
      sortable: true,
      filterable: false,
      format: toCurrency("amount"),
      right: true,
      width: '12%'
    },
    {
      name: t('campaign'),
      selector: row => row['campaign'],
      sortable: true,
      filterable: true,
      wrap: true,
    },
  ], [t, i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box sx={{ border: 1, marginLeft: 6 }}>
      <Stack spacing={1} sx={{ marginLeft: 1, marginBottom: 2 }}>
        <span>{t('organisation')}: {data.organisation}</span>
        <span>{t('fileName')}: {data.fileName}</span>
      </Stack>

      <CustomDataTable
        columns={detailsColumns}
        data={data.ads}
        pagination={true}
        paginationComponentOptions={getTranslatedPaginationOptions(t)}
        paginationPerPage={30} />
    </Box>
  )
}
